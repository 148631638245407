import React, { useState, useEffect } from "react";
import { Link, NavLink, useLocation, useNavigate, useParams } from "react-router-dom";
import Breadcrumb from "../../Breadcrumb";
import SidebarMenu from "../../SidebarMenu";
import "../../../css/viewuser.css";
import "../riskAssesment/riskassessment.css";
import toggle_btn from "../../../images/toggle_side_down.svg";
import { fetchData, justPostData, postData,postDataRisk } from "../../apiUtils";
import { auditRoutes } from "../../subroutes";
import BounceLoader from "react-spinners/BounceLoader";
import RenderIcons from "../../RenderIcons";
const Riskassessment = ({ tableSize }) => {
  const [APIData, setAPIData] = useState({});
  const [APIAllData, setAPIAllData] = useState([]);
  const [APIAllDataPlan, setAPIAllDataPlan] = useState([]);

  const [removeId, setRemoveId] = useState(false);
  const [isAnyOpen, setIsAnyOpen] = useState(false);
  const [isAnyOpenPlan, setIsAnyOpenPlan] = useState(false);

  const [showPopup, setShowPopup] = useState(false);
  const [loading, setLoading] = useState(false); // Added state for loading spinner
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const [expandSubTable, setSubExpandTable] = useState({});
  const [toggleAll, setToggleAll] = useState(false);
  const [Errors, setErrors] = useState("");
  const [lastId, setLastId] = useState("");
  const [selectedId, setSelectedId] = useState("");
  const userRoles = localStorage.getItem("userRoles");
  const {raid}=useParams();
  const Roles = userRoles.includes("Admin") && userRoles.includes("Audit Team");
  
  useEffect(() => {
    fetchData(
      `/api/audit_universe/risk-assessments/`,
      (data) => {
        setAPIAllData(data);
        setLoading(true); // Set loading to false after fetching data
      },
      setErrors
    );


    fetchData(
      `/api/audit_universe/audit-plan/`,
      (data) => {
        setAPIAllDataPlan(data);
        setLoading(true); // Set loading to false after fetching data
      },
      setErrors
    );

  }, []);

  useEffect(() => {
    if (APIAllData.length > 0) {
      setLoading(true); // Set loading to true before fetching data
      // const rid = removeId ? selectedId : APIAllData[APIAllData.length - 1].id;
      fetchData(
        `/api/audit_universe/risk-assessments/${raid}`,
        (data) => {
          setAPIData(data);
          setLoading(false); // Set loading to false after fetching data
        },
        setErrors
      );
      const anyOpenPlan = APIAllDataPlan.some((item) => item.status === "Open");
      setIsAnyOpenPlan(anyOpenPlan)
      const anyOpen = APIAllData.some((item) => item.status === "Open");

      setIsAnyOpen(anyOpen);
      if (!removeId) {
        setSelectedId(APIAllData[APIAllData.length - 1].id);
      }
    }
  }, [APIAllData]);

  const renderRiskStatus = (status) => {
    switch (status ? status.toLowerCase() : "--") {
      case "closed":
        return <div className="status closed">Completed</div>;
      case "open":
        return <div className="status open">Open</div>;
      case "inprogress":
      default:
        return <div className="status inprogress">{status}</div>;
    }
  };

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleSelect = (id) => {
    setSelectedId(id);
    setIsOpen(false);
    setRemoveId(true);

  };

  const toggleSubTable = (audit_entity) => {
    setToggleAll(false);
    setSubExpandTable((prevState) => ({
      ...prevState,
      [audit_entity]: !prevState[audit_entity],
    }));
  };

  const toggleAllTables = () => {
    setToggleAll(!toggleAll);
    const expandedAll = {};
    if (APIData.risk_analysis) {
      APIData.risk_analysis.forEach(
        (analysis) => (expandedAll[analysis.audit_entity.name] = !toggleAll)
      );
    }
    setSubExpandTable(expandedAll);
  };

  const renderIcons = () => {
    return auditRoutes.map((route, index) => {
      if (route.subRoutes) {
        return <SidebarMenu route={route} key={index} />;
      }
      return (
        <NavLink
          to={route.path}
          key={index}
          className="navlink"
          activeclassname="active1"
        >
          <div className="navicon">
            <img src={route.icon} alt="Dashboard Icon" />
          </div>
          <div className="navicon">{route.name}</div>
        </NavLink>
      );
    });
  };

  const navigateGrapp = () => {
    navigate("/AuditUniverse/riskAssesment/graphview");
  };

  const goToAnswer = (analysis) => {
    if (!analysis || !analysis.questionnaire) {
      return;
    }
    return function () {
      navigate("/AuditUniverse/riskAssesment/answerQuestionnaire", {
        state: { questionnaire_id: analysis.questionnaire.id },
      });
    };
  };

  const createRisk = () => {
    const date = new Date();
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");

    postDataRisk(
      `/api/audit_universe/risk-assessments/`,
      {
        title: `risk assessment for the year of ${year}`,
        assessment_date: `${year}-${month}-${day}`,
        details: "start risk assessment now",
      },
      setErrors,
    );
  };

  const [searchQuery, setSearchQuery] = useState("");

  const filteredRiskAnalyses = APIData.risk_analysis
    ? APIData.risk_analysis.filter((analysis) =>
        analysis.audit_entity.name
          .toLowerCase()
          .includes(searchQuery.toLowerCase())
      )
    : [];

  const renderStatus = (status) => {
    switch (status) {
      case "Open":
        return <span style={{ color: "blue" }}>Planned</span>;
      case "Closed":
        return <span style={{ color: "green" }}>Done</span>;

      default:
        return <span style={{ color: "black" }}>In Progress</span>;
    }
  };

  const handleChange = (e) => {
    setSelectedId(e.target.value);
    setRemoveId(true);
    setLoading(true); // Set loading to true when dropdown selection changes
  };

  const createPlan = () => {
    postData(
      `/api/audit_universe/audit-plan/`,
      {
        "risk_assessment_id": APIData.id,
        "title": APIData.title
      },
      navigate,
      setErrors,
      `/AuditUniverse/auditPlanning`
    );
  };

  const handleSendClick = () => {
    setShowPopup(true);
    setTimeout(() => setShowPopup(false), 2000); 
  };

  

  return (
    <>
      <section className="navroutes">
      <RenderIcons homeRoutes={auditRoutes} />

        <Breadcrumb/>

        <div className="filter-container">
          <div className="filter">
            <div className="views">
              <h3 className="h2_interbold" >Risk Assessment</h3>
              <div className="viewbuttons">
                <button className="active2">List view</button>
                <button className="active2" onClick={navigateGrapp}>
                  Graph View
                </button>
              </div>
            </div>

            <div className="searchncbtn">
              {renderRiskStatus(APIData.status)}

              {/* <div className="custom-dropdown">
      <div className="selected-item" onClick={handleToggle}>
        {APIAllData.find((item) => item.id === selectedId)?.title || 'Select an option'}
      </div>
      {/* {isOpen && (
        <div className="dropdownMenu">
          {APIAllData.slice()
            .reverse()
            .map((item) => (
              <div
                key={item.id}
                className="dropdownItem"
                onClick={() => handleSelect(item.id)}
              >
                {item.title}
              </div>
            ))}
        </div>
      )} */}
    {/* </div> */}

              <button className="exportrabutton">Export</button>
            </div>
          </div>
       
          {/* <div style={{display : Roles ? ' ' : 'none'}}> */}
          {/* {  isAnyOpen && ( */}
            <div className="postRA">
            {APIData.status !== "Open" && 

              <button className="createrabutton" onClick={createRisk}>
                Create New
              </button>
            }
              {APIData.status === "Closed" && !isAnyOpenPlan &&
              <button className="createrabutton" onClick={createPlan}>
                    Create Audit Plan
                  </button>
              }

            {/* </div> */}
          {/* )} */}
          </div>
        </div>

        <div
          className="aetablecontainer"
          style={{ width: tableSize ? "80vw" : "90.5vw" }}
        >
          {loading ? (
          <div className="spinner-bounce-loader">
          <BounceLoader color="#a6fafa"/> 
          </div>
            
          ): APIData.length === 0 ? (
            <p className="data-error-text">No data found</p> 
          ) : (
            <table style={{ width: "100%" }}>
            <thead>
              <tr>
                <th>
                  <a onClick={toggleAllTables}>
                    <div className="tableToggle">
                      {toggleAll ? "Hide Table" : "Show Table"}{" "}
                    </div>
                  </a>
                </th>
                <th>Impact</th>
                <th>Likelihood</th>
                <th>Risk Score</th>
              </tr>
            </thead>
    
            <tbody>
              {filteredRiskAnalyses.sort((a, b) => a.audit_entity.name.localeCompare(b.audit_entity.name)).map((analysis, index) => (
                <React.Fragment key={index}>
                  <tr className="riskBU" onClick={goToAnswer(analysis)}>
                    <td>
                      <div className="vsfvf">
                        <div className="toggle_btn_heading">
                          <button
                            onClick={() =>
                              toggleSubTable(analysis.audit_entity.name)
                            }
                            style={{
                              transform: expandSubTable[analysis.audit_entity.name]
                                ? "rotate(0deg)"
                                : "rotate(270deg)",
                            }}
                          >
                            <img src={toggle_btn} alt="toggle button" />
                          </button>
                          <h6>{analysis.audit_entity.name}</h6>
                        </div>
    
                        <div className="status_remind_btn">
                          <button onClick={handleSendClick}>Send</button>
                          <button >
                            {renderStatus(analysis.questionnaire.status)}
                          </button>
                        </div>
                      </div>
                    </td>
    
                    <td className="score_tag">
                      <span className="high">{analysis["impact"]}</span>
                    </td>
    
                    <td className="score_tag">
                      <span className="moderate">
                        {analysis["likelihood"]}
                      </span>
                    </td>
    
                    <td className="score_tag">
                      <span className="low">{analysis["total score"]}</span>
                    </td>
                  </tr>
    
                  <tr>
                    <td colSpan={5}>
                      <table
                        className="innertable"
                        style={{
                          display: toggleAll
                            ? ""
                            : expandSubTable[analysis.audit_entity.name]
                            ? ""
                            : "none",
                          width: "100%",
                        }}
                      >
                        <thead>
                          <tr>
                            <th></th>
                            <th>
                              Financial <br /> Statement <br /> / Materiality
                            </th>
                            <th>
                              Legal/ <br />
                              Compliance
                            </th>
                            <th>Operational</th>
                            <th>IT</th>
                            <th>Total Impact</th>
                            <th>
                              Complexity <br /> of <br /> Process
                            </th>
                            <th>Volume</th>
                            <th>
                              Known <br /> Issues
                            </th>
                            <th>
                              Changes in <br /> Personnel or <br /> Processes
                            </th>
                            <th>Monitoring</th>
                            <th>Total Likelihood</th>
                            <th>
                              Fraud Risk <br /> (F)
                            </th>
                            <th>
                              Management <br /> Initiative <br /> (A)
                            </th>
                            <th>Total Score</th>
                          </tr>
                        </thead>
    
                        <tbody>
                          <tr>
                            <td>{analysis.audit_entity.name}</td>
                            <td>{analysis["financial and materiality"]}</td>
                            <td>{analysis["legal & compliance"]}</td>
                            <td>{analysis["operational"]}</td>
                            <td>{analysis["it"]}</td>
                            <td>{analysis["impact"]}</td>
                            <td>{analysis["complexity of process"]}</td>
                            <td>{analysis["volume"]}</td>
                            <td>{analysis["known issues"]}</td>
                            <td>{analysis["changes in personnel or processes"]}</td>
                            <td>{analysis["monitoring"]}</td>
                            <td>{analysis["likelihood"]}</td>
                            <td>{analysis["fraud risk"]}</td>
                            <td>{analysis["management initiatives"]}</td>
                            <td>{analysis["total score"]}</td>
                          </tr>
                          {analysis.business_units.map((BU, bIndex) => (
                            <React.Fragment key={bIndex}>
                              <tr>
                                <td>{BU.audit_unit.name}</td>
                                <td>{BU["financial and materiality"]}</td>
                                <td>{BU["legal & compliance"]}</td>
                                <td>{BU["operational"]}</td>
                                <td>{BU["it"]}</td>
                                <td>{BU["impact"]}</td>
                                <td>{BU["complexity of process"]}</td>
                                <td>{BU["volume"]}</td>
                                <td>{BU["known issues"]}</td>
                                <td>{BU["changes in personnel or processes"]}</td>
                                <td>{BU["monitoring"]}</td>
                                <td>{BU["likelihood"]}</td>
                                <td>{BU["fraud risk"]}</td>
                                <td>{BU["management initiatives"]}</td>
                                <td>{BU["total score"]}</td>
                              </tr>
                            </React.Fragment>
                          ))}
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </React.Fragment>
              ))}
            </tbody>
            
          </table>
          )}
        </div>

        {showPopup && (
          <div className="popup">
            <p>Notification Sent</p>
          </div>
        )}
      </section>
    </>
  );
};

export default Riskassessment;
