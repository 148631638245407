import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { justUpdateData, fetchData } from '../../../apiUtils';

const UserDropdown = ({ otherKeyData, task, title, assigned_toName, taskId }) => {
  const [errors, setErrors] = useState("");
  const [APIData, setAPIData] = useState([]);

  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedUser, setSelectedUser] = useState(null);
  const dropdownRef = useRef(null);
  const taskID = localStorage.getItem("user_id");
  const taskAssinged = localStorage.getItem("taskAssinged");
  const userRoles = localStorage.getItem("userRoles");
  const isAdminRole = userRoles.includes("Admin") && userRoles.includes("Audit Team");

  const navigate = useNavigate();

  useEffect(() => {
    if (taskId) {
      fetchData(`/api/system/task/${taskId}/`, setAPIData, setErrors);
    }
  }, [taskId]);

  useEffect(() => {
    const user = otherKeyData.find(u => u.id === parseInt(taskAssinged));
    if (user) {
      setSelectedUser(user);
    }
  }, [otherKeyData, taskAssinged]);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
        setSearchTerm('');
        setSelectedUser(null);
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  const handleToggleDropdown = () => {
    if (isAdminRole) {
      setIsOpen(!isOpen);
      if (!isOpen) {
        setSearchTerm('');
        setSelectedUser(null);
      }
    }
  };

  const handleSearchChange = event => {
    setSearchTerm(event.target.value);
  };

  const handleSelectChange = user => {
    if (title) {
      justUpdateData(
        `/api/system/task/${taskId}`,
        {
          title: title,
          assigned_to_id: user.user_id,
        },
        navigate,
        setErrors
      );
    }
    setSelectedUser(user);
    setIsOpen(false);
  };

  const filteredUsers = otherKeyData.filter(user => user.name ? 
    user.name.toLowerCase().includes(searchTerm.toLowerCase())  : ''
  );

  return (
    <div ref={dropdownRef} className="dropdown">
      <div className="dropdown-toggle" onClick={handleToggleDropdown}>
        {selectedUser ? selectedUser.name : assigned_toName || 'Other Key Stakeholders'}
      </div>
      {isOpen && (
        <div className="dropdown-menu">
          <input
            type="text"
            className="dropdown-search"
            placeholder="Search by first name..."
            value={searchTerm}
            onChange={handleSearchChange}
          />
          <ul className="dropdown-list">
            {filteredUsers.map(user => (
              <li
                key={user.id}
                className="dropdown-item"
                onClick={() => handleSelectChange(user)}
              >
                {user.name}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default UserDropdown;
