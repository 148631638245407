import React from "react";

import "../auditUniverse/riskAssesment/createQuestionnaire/createQuestionnaire.css"

import { NavLink,useLocation } from "react-router-dom";
import Breadcrumb from "../Breadcrumb";
import SidebarMenu from "../SidebarMenu";
import "../../css/viewuser.css";

import { auditBusiness, auditExecutionTop, auditExecutionQA } from "../subroutes";
import { useState } from "react";
import QuestionTemp from "./QuestionTemplate";
import { fetchData } from "../apiUtils";
import { useEffect,useRef } from "react";



const CreateQasurvey = ({ tableSize }) => {


  const [openDropdown, setOpenDropdown] = useState(null);
  const dropdownRef = useRef(null);

  const userRoles = localStorage.getItem("userRoles");


var routes=[];
if (userRoles.includes("Admin")) {
routes = auditExecutionTop;
} else if (userRoles.includes("Business")) {
routes = auditBusiness;
}
else if (userRoles.includes("Quality Assurance")) {
routes = auditExecutionQA;
} 
//else if (userRoles.includes("Audit Commission")) {
//   routes = forAuditCommission;
// } else if (userRoles.includes("Quality Assurance")) {
//   routes = forQA;
// } 
else {
console.log("No matching role found");
}

const handleDropdownToggle = (index) => {
  setOpenDropdown(openDropdown === index ? null : index);
};


const handleClickOutside = (event) => {
  if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
    setOpenDropdown(null);
  }
};

useEffect(() => {
  document.addEventListener('mousedown', handleClickOutside);

  return () => {
    document.removeEventListener('mousedown', handleClickOutside);
  };
}, []);
   
const renderIcons = () => {
  return routes.map((route, index) => {
    if (route.routes) {
      return (
        <div key={index} className="dropdown-container-exec" ref={dropdownRef}>
          <div
            className="dropdown-toggle"
            style={{ display: 'flex', gap: '.5vw' }}
            onClick={() => handleDropdownToggle(index)}
          >
            <div className="navicon">
              <img src={route.icon} alt="Dropdown Icon" />
            </div>
            <div className="navicon">{"Q.A Survey"}</div>
          </div>
          {openDropdown === index && (
            <div className="dropdown-template">
              {route.routes.map((subroute, subindex) => (
                <NavLink
                  to={subroute.path}
                  key={subindex}
                  className="navlink template-nav"
                  activeClassName="active1"
                >
                  <div className="navicon"></div>
                  <div className="navicon">{subroute.name}</div>
                </NavLink>
              ))}
            </div>
          )}
        </div>
      );
    }

    return (
      <NavLink
        to={route.path}
        key={index}
        className="navlink"
        activeclassname="active1"
      >
        <div className="navicon">
          <img src={route.icon} alt="Dashboard Icon" />
        </div>
        <div className="navicon">{route.name}</div>
      </NavLink>
    );
  });
};
  const location = useLocation();

  const [sectionsData, setSectionsData] = useState([]);
  const [questionsData, setQuestionsData] = useState([]);
  const [sectionErrors, setSectionErrors] = useState("");
  const [questionErrors, setQuestionErrors] = useState("");

  const [inputs, setInputs] = useState({});
  const [inputValues, setInputValues] = useState({});

  const [combinedData, setCombinedData] = useState([]);


  const addInput = (sectionId) => {
    setInputs({
      ...inputs,
      [sectionId]: [
        ...(inputs[sectionId] || []),
        { id: Date.now(), editing: true, value: "" },
      ],
    });
  };

  const removeInput = (sectionId, inputId) => {
    setInputs({
      ...inputs,
      [sectionId]: inputs[sectionId].filter((input) => input.id !== inputId),
    });
  };

  const handleInputChange = (sectionId, inputId, value) => {
    setInputValues({
      ...inputValues,
      [sectionId]: {
        ...inputValues[sectionId],
        [inputId]: value,
      },
    });
  };

  const handleEdit = (sectionId, inputId) => {
    setInputs({
      ...inputs,
      [sectionId]: inputs[sectionId].map((input) => {
        if (input.id === inputId) {
          return { ...input, editing: true };
        }
        return input;
      }),
    });
  };


  const showValues = () => {
  };
  return (
    <>
      <div className="bumenucontainer-exec">{renderIcons()}</div>

      <hr className="breadcrumbabv" />

      <Breadcrumb />

      <div className="filter-container">
        <div className="filter">
          <div className="views">
            <h3 className="h2_interbold" >Create Q.A Survey</h3>
          </div>

          <div className="create_ques_btn_con">
            <button onClick={showValues}> Save </button>
          </div>
        </div>
      </div>

      <div
        className="questionniarecontainer"
        style={{ width: tableSize ? "80vw" : "90.5vw" }}
      >
        <div className="section_container">
          <QuestionTemp tempID={location?.state?.data}  tableSize={tableSize}/>
        </div>
      </div>
    </>
  );
};

export default CreateQasurvey;