import React, { useState, useEffect } from "react";
import {
  NavLink,
  useLocation,
  Link,
  useNavigate,
  useParams,
} from "react-router-dom";
import axios from "axios";
import Breadcrumb from "../../Breadcrumb";
import SidebarMenu from "../../SidebarMenu";
import "../../../css/viewuser.css";
import { fetchData, fetchData1, updateData } from "../../apiUtils";
import { auditExecution } from "../../subroutes";
import create from "../../../images/createbutton.svg";
import openarrow from "../../../images/openarrow.svg";
import "../auditplan/auditplan.css";
import ProgressBar from "../progressbar/ProgressBar";
import "../auditfieldwork/auditfieldwork.css";

import pencil from "../../../images/editpencil_wbg.svg";
import deleteicon from "../../../images/delete_wbg.svg";
import BounceLoader from "react-spinners/BounceLoader";
import RenderIcons from "../../RenderIcons";

const AuditFieldwork = ({ tableSize }) => {
  const [errors, setErrors] = useState("");
  const [APIData, setAPIData] = useState([]);
  const [auditData, setAuditData] = useState([]);
  const [areAllTasksDone, setAreAllTasksDone] = useState();
  const [loading, setLoading] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();
  const successMessageColor = location.state?.successMessageColor;
  const successMessage = location.state?.successMessage;
  const [showMessage, setShowMessage] = useState(!!successMessage);
  const originalUrl = location.state?.from;
  const { id } = useParams();

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      navigate(".", { replace: true });
    }, 5000);

    return () => clearTimeout(timeoutId);
  }, []);

  useEffect(() => {
    fetchData(`/api/audit_engagement/audit/${id}/`, setAuditData, setErrors);
  }, [id]);

  useEffect(() => {
    fetchData1(
      `/api/audit_engagement/workpaper/?audit_id=${id}`,
      setAPIData,
      setErrors,
      setLoading
    );
  }, []);


  useEffect(() => {
    setAreAllTasksDone(APIData.every(task => task.status === "Completed"));

  }, [APIData]);

  const auditEntityName = [
    ...new Set(APIData.map((item) => item.audit.audit_entity.name)),
  ];

 
  const Viewuser = (data) => {
    const workId = data;
    navigate(`/AuditExecution/${id}/Fieldwork/${workId}`);
  };

  const [filters, setFilters] = useState({
    status: "",
    id: "",
    title: "",
    preparer: "",
    reviewer: "",
    due: "",
  });

  const handleFilterChange = (filterKey, e) => {
    setFilters({ ...filters, [filterKey]: e.target.value });
  };

  const filteredData = APIData.filter((data) => {
    const { status, task, id, objective, preparer, reviewer, created_at } =
      data;

    const matchesStatus = filters.status
      ? status &&
      status.toLowerCase().includes(filters.status.toLowerCase())
      : true;

    const matchesId = filters.id
      ? (id || "").toString().includes(filters.id)
      : true;

    const matchesPreparer = filters.preparer
      ? preparer &&
      preparer.name.toLowerCase().includes(filters.preparer.toLowerCase())
      : true;

    const matchesReviewer = filters.reviewer
      ? reviewer &&
      reviewer.name.toLowerCase().includes(filters.reviewer.toLowerCase())
      : true;

    const matchesObjective = filters.objective
      ? (objective || "")
        .toLowerCase()
        .includes(filters.objective.toLowerCase())
      : true;

    const matchesCreatedAt = filters.created_at
      ? (created_at || "").toString().includes(filters.created_at)
      : true;

    return (
      matchesId &&
      matchesStatus &&
      matchesPreparer &&
      matchesReviewer &&
      matchesObjective
    );
  });

  const renderFieldWorkData = (data) => {
    return
  };


  const updateWithPhase = (e) => {
    e.preventDefault();
    updateData(
      `/api/audit_engagement/audit/${id}`,
      {
        risk_assessment: auditData?.risk_assessment?.id,
        audit_entity_id: auditData?.audit_entity?.id,
        auditors_id: auditData?.auditors.map(auditor => auditor.id),
        business_users_id: auditData?.business_users.map(business_users => business_users.id),
        actual_start_date: auditData?.actual_start_date,
        actual_end_date: auditData?.actual_end_date,
        planned_start_date: auditData?.audit_event?.planned_start_date,
        planned_end_date: auditData?.audit_event?.planned_end_date,
        audit_scope: auditData?.audit_scope,
        audit_phases: auditData?.audit_phases,
        other_relevant_information: auditData?.other_relevant_information,
        phase: 'Fieldwork Complete'
      },
      navigate,
      setErrors,
      `/auditExecution/${id}/Fieldwork/`,
      window.location.reload()
    );
  };




  return (
    <>
      {errors && <p className="errormessage">{errors}</p>}

      {successMessage && <p className="successmessage">{successMessage}</p>}

      <section className="navroutes">
      <RenderIcons homeRoutes={auditExecution} id ={id}/>

        <div className="filter-container">
          <div className="filter">
           
            <Breadcrumb />

            <div className="searchncbtn">
              <input type="text" placeholder="Search" className="searchbar , searchbar_hide" />

              <span className="auditstatusphase"><p>{auditData.phase}</p></span>

              <div className="createuser">
                <Link to={`/AuditExecution/${id}/Fieldwork/CreateWorkpaper`}>
                  <button className="createbutton">
                    <img src={create} alt="" />
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>




        <div
          className="fieldwork_table_con"
          style={{ width: tableSize ? "79.5vw" : "90vw" }}
        >
           <table>
            {/* <Link to="/AuditExecution/ViewWorkpaper"> */}

              <thead style={{ position: "sticky", top: "0", zIndex: 1000 }}>
                <tr className="tablehead">
                  <th>
                    <h4 className="thhead">Status</h4>
                    <input
                      type="text"
                      placeholder="--"
                      className="thfilter"
                      onChange={(e) => handleFilterChange("status", e)}
                      value={filters.status}
                    />
                  </th>

                  <th>
                    <h4 className="thhead"> UID </h4>
                    <input
                      type="text"
                      placeholder="--"
                      className="thfilter"
                      onChange={(e) => handleFilterChange("id", e)}
                      value={filters.id}
                    />
                  </th>

                  <th>
                    <h4 className="thhead"> Title</h4>
                    <input
                      type="text"
                      placeholder="--"
                      className="thfilter"
                      onChange={(e) => handleFilterChange("objective", e)}
                      value={filters.objective}
                    />
                  </th>

                  <th>
                    <h4 className="thhead">Preparer</h4>
                    <input
                      type="text"
                      placeholder="--"
                      className="thfilter"
                      onChange={(e) => handleFilterChange("preparer", e)}
                      value={filters.preparer}
                    />
                  </th>

                  <th>
                    <h4 className="thhead">Reviewer</h4>
                    <input
                      type="text"
                      placeholder="--"
                      className="thfilter"
                      onChange={(e) => handleFilterChange("reviewer", e)}
                      value={filters.reviewer}
                    />
                  </th>

                  <th>
                    <h4 className="thhead">Due</h4>
                    <input
                      type="text"
                      placeholder="--"
                      className="thfilter"
                      onChange={(e) => handleFilterChange("created_at", e)}
                      value={filters.created_at}
                    />
                  </th>
                </tr>
              </thead>

            <tbody>

{loading ? (
          <div className="spinner-bounce-loader">
          <BounceLoader color="#a6fafa"/> 
          </div>
            
          ): filteredData.length === 0 ? (
            <p className="data-error-text">No data found</p> 
          ) : (
         
            filteredData.map((data) => (
      <tr onClick={() => Viewuser(data.id)} key={data.id}>
       <td >
  <span style={{ backgroundColor: data.status === 'Open' ? '#F3C93F' : data.status === 'Completed' ? '#65CC5C' : 'transparent' }}>{data.status ? data.status : ""}</span>
</td>
        <td>
          {" "}
          <span>{data.id}</span>{" "}
        </td>
        <td>
          <span>{data.objective ? data.objective : data.risk_title} </span>
        </td>
        <td>{data.preparer ? data.preparer.name : ""}</td>
        <td>{data.reviewer ? data.reviewer.name : ""}</td>
        <td>26-3-34</td>
      </tr>
    ))
           
          )
        }
         </tbody>

{/* </Link> */}
</table>
          {/* {auditData.phase ==="Fieldwork Complete" &&( <span>FieldWork Completed</span>)}  */}
          {auditData.phase === "Planning Complete" && areAllTasksDone && (<div onClick={(e) => updateWithPhase(e)}><button className="phasestatus" >Start Reporting</button></div>)}

          {filteredData.map((data) => (
            <div className="bucard" onClick={() => Viewuser(data.id)} key={data.id}>
              <div className="bunstkhlder">
                <h4>
                  {/* Place for variable */}
                  <p className="buplacehldr">{data.objective ? data.objective : data.risk_title}</p>
                </h4>
                <h5>
                  <p className="buplacehldr">{data.unit_head ? data.unit_head.name : "Keystakeholder"}</p>
                </h5>
              </div>
              <div className="deptnauthnbtn">
                <h5>
                  <p className="buplacehldr">{data.department ? data.department.name : "Department"}</p>
                </h5>
                <h6>
                  <p className="buplacehldr">Bu ID - </p>
                  <p className="buplacehldr">{data.id ? data.id : "Bu ID"}</p>
                </h6>
              </div>
              {/* <img src={openarrow} alt="Open Arrow" /> */}
            </div>
          ))}


        </div>



        {/* <div className="cardscontainer">
                    {filteredData.map((data) => (
                        <div className="aecard" >
                            <h1>{data.name} </h1>

                            <img src={openarrow} />
                        </div>
                    ))}
                </div> */}
      </section>
    </>
  );
};

export default AuditFieldwork;
