import React from "react";
import { NavLink, useLocation, Link, useNavigate } from "react-router-dom";
import user from "../../images/user.svg";
import department from "../../images/department.svg";
import BU from "../../images/BU.svg";
import corporate from "../../images/corporate.svg";
import SidebarMenu from "../SidebarMenu";
import { useState, useEffect } from "react";
import create from "../../images/createbutton.svg";
import save from "../../images/saveBtn.svg";
import "../../css/createbu.css";
import Breadcrumb from "../Breadcrumb";
import { fetchData, postData } from "../apiUtils";
import SingleOptionSelector from "../SingleOptionSelector";
import SingleOptionSelectorRole from "../SingleOptionSelectorRole";
import RenderIcons from "../RenderIcons";
import {homeRoutes} from "../subroutes";
import IconButton from "../iconbutton/IconButton";
import CommonInput from "../CommonInput";

const CreateBu = ({tableSize}) => {
  const [errors, setErrors] = useState("");
  const [errorMessage, setErrorMessage] = useState(false);
  const [parentDept, setParentDept] = useState("");
  const location = useLocation();
  const [name, setname] = useState("");
  const [nameError, setnameError] = useState(false);
  const [description, setDescription] = useState("");
  const [descriptionError, setDescriptionError] = useState(false);
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const navigate = useNavigate();
  const [toggleunit, setToggleunit] = useState("");
  const [toggledept, setToggledept] = useState("");
  const [toggleunitname, setToggleunitname] = useState("");
  const [department_id, setDepartment] = useState([]);
  const [selectedDepts, setSelectedDepts] = useState([]);
  const [departmentId, setSelectorIdDept] = useState("");
  const [parentToggle, setParentToggle] = useState(false);
  const [departmentIdError, setDepartmentIdError] = useState("");
  const [searchTermDept, setSearchTermDept] = useState("");
  const [expandedDept, setExpandedDept] = useState(false);
  const [selectorError, setSelectorError] = useState(false);
  const [unit_head_id, setSelectorId] = useState("");
  const [editMode, setEditMode] = useState(true);
  const [parentDepartment, setParentDepartment] = useState("");
  const [buData, setBuData] = useState("");


  useEffect(() => {
    const objWithId = department_id.find((item) => item.id === departmentId);
    const department_Id = objWithId && objWithId.department ? objWithId.department.id : null;
    setParentDept(department_Id);
  }, [departmentId]);




  const toggleExpandDepartment = () => {
    if (toggleunitname == "Department") {
      fetchData(`/api/system/departments/`, setDepartment, setErrors);
    } else {
      fetchData(`/api/system/business-units/`, setDepartment, setErrors);

    }


    setExpandedDept(true);
  };

  useEffect(() => {
    const pathSegments = location.pathname
      .split("/")
      .filter((segment) => segment !== "");
    setBreadcrumbs(pathSegments);
  }, [location.pathname]);

  const postApi = (e) => {
    e.preventDefault();

    if (!name || !description || !departmentId) {
      setErrorMessage(true);
      setErrors("Please fill in all required fields.");
      setnameError(!name ? true : false);
      setDescriptionError(!description ? true : false);
      // setSelectorError(!unit_head_id ? true : false);
      setDepartmentIdError(!departmentId ? true : false);
      setTimeout(() => {
        setErrors(null);
      }, 5000);
      return;
    }
    const buunithead = parseInt(unit_head_id, 10)

    postData(
      `/api/system/business-units/`,
      {
        name,
        description,
        department_id: parentDept ? parentDept : !parentToggle ? null : departmentId,
        parent_bu_id: !parentToggle ? departmentId : null,
        unit_head_id: buunithead,
      },
      navigate,
      setErrors,
      `/Administration/businessUnit`
    );
  };

  const toggleDepartment = (e) => {
    setParentToggle(true);
    setParentDept("");
    setToggleunitname("Department");
    setToggledept(!toggledept);
    setToggleunit(false);
    setSelectedDepts([]);
    setSearchTermDept("");
  };

  const toggleBuhead = (e) => {
    setParentToggle(false);
    setToggledept(false);
    setToggleunitname("Business Unit");
    setToggleunit(!toggleunit);
    setSelectedDepts([]);
    setSearchTermDept("");
  };

  const [selectedOption, setSelectedOption] = useState("");

  const handleSelectChange = (e) => {
    setSelectedOption(e.target.value);
  };

  return (
    <>
      {errors && <p className="errormessage">{errors}</p>}

      <section className="navroutes">
      <RenderIcons homeRoutes={homeRoutes} />

        <Breadcrumb />

        <div className="filter-container">
          <div className="filter">
            <div className="views" style={{justifyContent:"space-between",alignItems:"center",width:"100%"}}>
              <h3 className="h2_interbold" >Create Business Units</h3>
              <div className="parent" style={{ border: departmentIdError ? "1px solid #FF4646" : "", }}>

<div className="togglecon">
  <div className="toggle-option" onClick={toggleDepartment}>
    Parent Department
  </div>
  <hr />
  <div className="toggle-option" onClick={toggleBuhead}>
    Parent Business Unit{" "}
  </div>
</div>
</div>
            </div>
          </div>
        </div>

        <div className="createbucontainer" style={{width: tableSize?"80vw":"90.5vw"}}>

          <div className="delete_edit_close_btn">
            <IconButton type="close" className='closeView' tooltip="Close" link="/Administration/businessUnit" />
            <IconButton type="save" tooltip="Save" onClick={postApi} link="/" />
          </div>
    


          <form>

            <CommonInput
              labelName='BU Name'
              error={nameError}
              type='text'
              placeHolder='Payroll'
              setText={setname}
              setTextError={setnameError}
              commanDivClass='lbl_inpt'
            />

            <CommonInput
              labelName='Description'
              error={descriptionError}
              type='text'
              placeHolder='Finance Department'
              setText={setDescription}
              setTextError={setDescriptionError}
              commanDivClass='lbl_inpt'
            />




          


            <div className="lbl_slct">
              <label htmlFor="dropdown">Choose an option:</label>
              <select
                id="dropdown"
                value={selectedOption}
                onChange={handleSelectChange}
              >
                <option value="" disabled>
                  Select an option
                </option>
                <option value="Business">Business</option>
                <option value="Audit Team">Audit</option>
              </select>
            </div>



            <div
              className=""
              onClick={() => {
                toggleExpandDepartment();
                setToggleunitname("Department");
              }}
              style={{ display: toggledept ? "block" : "none" }}
            >


              <SingleOptionSelectorRole
                apiUrl="/api/system/departments/"
                setSelectorId={setSelectorIdDept}
                setSelectorError={setDepartmentIdError}
                selectorError={departmentIdError}
                Name="name"
                title="Department"
                editMode={editMode}
              />

            </div>


            
              <div
                className=""
                onClick={() => {
                  toggleExpandDepartment();
                  setToggleunitname("");
                }}
                style={{ display: toggleunit ? "block" : "none" }}
              >

               
                  <SingleOptionSelector
                    apiUrl="/api/system/business-units/"
                    setSelectorId={setSelectorIdDept}
                    setSelectorError={setDepartmentIdError}
                    selectorError={departmentIdError}
                    Name="name"
                    title="Businessunit"
                    editMode={editMode}
                  />
               
              </div>
            

              <SingleOptionSelectorRole
                apiUrl="/api/system/users/"
                setSelectorId={setSelectorId}
                setSelectorError={setSelectorError}
                selectorError={selectorError}
                Name="first_name"
                title="Bu Head"
                editMode={editMode}
                selectedOption={selectedOption}

              />
            









          </form>


        </div>
      </section>
    </>
  );
};

export default CreateBu;
