import React from 'react';
import '../../css/button.css';

const Button = ({ buttonName, buttonColor, onClick }) => {
  const buttonStyle = {
    backgroundColor: buttonColor,
    color: 'white', // Ensure text color is white for better contrast
  };

  return (
    <button style={buttonStyle} onClick={onClick}>
      {buttonName}
    </button>
  );
};

export default Button;
