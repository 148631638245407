import React, { useState, navigate, useEffect } from "react";
import "../../auditExecution/auditplan/schedulemeeting/Schedulemeeting";
import SingleOptionSelector from "../../SingleOptionSelector";
import {
  postData,
  postData2,
  fetchData,
  updateData,
  deleteData,
  deleteData2,
} from "../../apiUtils";
import {
  NavLink,
  useLocation,
  Link,
  useNavigate,
  useParams,
} from "react-router-dom";
import backlogo from "../../../images/backLogo.svg";
import MultiOptionSelector from "../../MultiOptionSelector";
import IconButton from "../../iconbutton/IconButton";
import WindowComponent from "../../WindowComponent";
import Breadcrumb from "../../Breadcrumb";
import SidebarMenu from "../../SidebarMenu";
import { auditExecution } from "../../subroutes";
import RenderIcons from "../../RenderIcons";
const ViewRcmTask = ({ tableSize, APIData }) => {
  const [errors, setErrors] = useState("");
  const [APIDataRcm, setAPIDataRcm] = useState([]);
  const [APIDataplan, setAPIDataplan] = useState([]);
  const [APIDataAudit, setAPIDataAudit] = useState([]);
  const [tempId, setTempId] = useState("");
  const [showRCMPopup, setShowRCMPopup] = useState(false);

  const [assign_to_id, setAssignTo] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [objective, setObjective] = useState("");
  const [objectiveError, setObjectiveError] = useState("");
  const [preparer_id, setPreparerId] = useState("");
  const [PreparerError, setPreparerError] = useState(false);
  const [reviewer_id, setReviewerId] = useState("");
  const [reviewerError, setReviewerError] = useState(false);
  const [assignError, setAssignError] = useState(false);
  const [participants_id, setParticipants] = useState([]);
  const [scheduled_start_date, setStartDate] = useState("");
  const [start_dateError, setStart_dateError] = useState(false);
  const [scheduled_end_date, setEndDate] = useState("");
  const [end_dateError, setEnd_dateError] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [accept, setAccept] = useState(false);

  const { id, planid,rid } = useParams();
  const navigate = useNavigate();
  var auditEntityId = "";
  useEffect(() => {
    fetchData(`/api/audit_engagement/audit/${id}/`, setAPIDataRcm, setErrors);
  }, [id]);

  useEffect(() => {
    fetchData(
      `/api/audit_engagement/plan/${planid}/`,
      setAPIDataplan,
      setErrors
    );
  }, [planid]);



  useEffect(() => {
    if(APIDataplan){
      setObjective(APIDataplan.objective);
    }
  }, [APIDataplan]);



  useEffect(() => {
    if (APIDataRcm && APIDataRcm.audit_entity) {
      fetchData(
        `/api/audit_universe/audit-entities/${APIDataRcm.audit_entity.id}/`,
        setAPIDataAudit,
        setErrors
      );
    }
  }, [APIDataRcm]);

  useEffect(() => {
    if (APIDataRcm.rcm_approved === true && APIDataplan.task && APIDataplan.task.status !== 'Done') {
      setAccept(true);
    }
  }, [APIDataAudit]);

  const updateAPIData = (e) => {
    e.preventDefault();
    if (!objective || !preparer_id || !reviewer_id || !assign_to_id || !id) {
      setObjectiveError(!objective ? true : false);
      setPreparerError(!preparer_id ? true : false);
      setReviewerError(!reviewer_id ? true : false);
      setAssignError(!assign_to_id ? true : false);
      setErrors("Please fill in all required fields.");
      setTimeout(() => {
        setErrors(null);
      }, 5000);
      return;
    }
    updateData(
      `/api/audit_engagement/plan/${planid}`,
      {
        objective,
        preparer_id,
        reviewer_id,
        assign_to_id,
        type: "Fill Rcm Template",
        audit_id: id,
      },
      navigate,
      setErrors,
      `/AuditExecution/${id}/Planning/${planid}`,
      window.location.reload()
    );
  };
  const handleEditClick = () => {
    setEditMode(!editMode);
  };
  const onDelete = (e) => {
    e.preventDefault();
    deleteData2(
      `/api/audit_engagement/plan/${planid}`,
      navigate,
      setErrors,
      `/AuditExecution/${id}/Planning`
    );
  };

  const handleAccountClick = () => {
    setShowRCMPopup(!showRCMPopup);
  };

  console.log(rid)

  const viewTemp = () => {

    navigate(`/AuditExecution/${id}/Planning/${planid}/RiskControlMatrix/`);
  };

  const updateAccept = (e) => {
    e.preventDefault();

    updateData(
      `/api/audit_engagement/plan/${planid}`,
      {
        objective,
        preparer_id,
        reviewer_id,
        assign_to_id,
        type: "Fill Rcm Template",
        audit_id: id,
        task_data: "Done",
      },
      navigate,
      setErrors,
      `/AuditExecution/${id}/Planning`
    );
  };
  console.log(APIData)

  const initialidpreparer=APIData?.preparer_id
  const handleCancelClick=() => {
    setEditMode(false)
    window.location.reload();

  }
  const handlePopupClose = () => {
    setShowRCMPopup(false);
  };

  const handlePopUp = () => {
    setShowRCMPopup(false);
  };
  const handlePopUpYes = () => {
    postData2(
      `/api/audit_universe/rcm-template/`,
      {
        audit_entity_id: APIDataAudit.id,
      },
      navigate,
      setErrors,
      `/AuditExecution/${id}/Planning/${planid}/${tempId}/`,
      (data) => {
        console.log("data : ", data);
        const successMessageColor = "#4285F4";
        navigate(`/AuditExecution/${id}/Planning/${planid}/${data.id}/`, {
          state: {
            successMessage: "Successfully Created!",
            successMessageColor,
          },
        });
      }
    );
    setShowRCMPopup(false);
    // window.location.reload()
    // setShowRCMPopup(false);
  };



  return (
    <>
      {errors && <p className="errormessage">{errors}</p>}
      {successMessage && <p className="successmessage">{successMessage}</p>}

      <section className="navroutes">
      
        <RenderIcons homeRoutes={auditExecution} id ={id}/>



        <div className="filter-container">
          
           
              <div
                style={{
                  width: tableSize ? "79.5vw" : "90vw",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >


            <Breadcrumb />

                <Link to={`/AuditExecution/${id}/Planning`}>
                  <div
                    className="backBtnDiv"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      gap: "1vw",
                      backgroundColor: "#FFF3CC",
                      padding: ".3vw 1.2vw",
                      borderRadius: "2.5vw",
                      
                    }}
                  >
                    <img src={backlogo} style={{ width: "1.2vw" }} />
                    <p
                      style={{
                        fontFamily: "intersemibold",
                        fontSize: ".8vw",
                        color: "#775F0F",
                      }}
                    >
                      Back to all tasks
                    </p>
                  </div>
                </Link>
              </div>
        </div>
        <h3>RCM</h3>

        <div
      className="createmeeting"
      style={{
        width: tableSize ? "79.5vw" : "90vw",
        position: "relative",
        height: "61vh",
      }}
    >
      <div
        className="delete_edit_close_btn"
        style={{ marginBottom: "20vh" }}
      >
        <div className="delete_edit_close_btn">
        {editMode && <IconButton
            type="close"
            className="closeView"
            tooltip="Cancel"
            // link={`/AuditExecution/${id}/Planning`}
            onClick={handleCancelClick}
          />}
          {editMode ? (
            <IconButton
              type="save"
              tooltip="Save"
              onClick={updateAPIData}
              link="/"
            />
          ) : (
            <IconButton
              type="edit"
              tooltip="Edit"
              onClick={handleEditClick}
            />
          )}
          <WindowComponent onDelete={onDelete} />
        </div>
      </div>

      <form>
      <div
        className="lng_lbl_inpt"
      >
        <label>Objective</label>
        <input
          type="text"
          style={{
            border: objectiveError ? "1px solid #FF4646" : "",
            backgroundColor:editMode ? "#EEF1FC" : "#e6e6e6",

          }}
          placeholder="Objective"
          value={objective}
          readOnly={!editMode}
          onChange={(e) => {
            setObjective(e.target.value);
            setObjectiveError(false);
          }}
        />
      </div>
       
          <SingleOptionSelector
            apiUrl="/api/audit_universe/audit-team/"
            setSelectorId={setPreparerId}
            setSelectorError={setPreparerError}
            selectorError={PreparerError}
            Name="name"
            title="Preparer"
            initialId={APIData.preparer ? APIData.preparer.id : ""}
            initialName={APIData.preparer ? APIData.preparer.name : ""}
            editMode={editMode}
          />
       

       
          <SingleOptionSelector
            apiUrl="/api/audit_universe/admin-audit-team/"
            setSelectorId={setReviewerId}
            setSelectorError={setReviewerError}
            selectorError={reviewerError}
            Name="name"
            title="Reviewer"
            initialId={APIData.reviewer ? APIData.reviewer.id : ""}
            initialName={APIData.reviewer ? APIData.reviewer.name : ""}
            editMode={editMode}
          />
       
      


          <SingleOptionSelector
            apiUrl="/api/audit_universe/business-stakeholders/"
            setSelectorId={setAssignTo}
            setSelectorError={setAssignError}
            selectorError={assignError}
            Name="name"
            title="Assign To"
            initialId={APIData.assign_to ? APIData.assign_to.id : ""}
            initialName={APIData.assign_to ? APIData.assign_to.name : ""}
            editMode={editMode}
          />
       

        </form>
       

        {showRCMPopup && (
          <div
            className="popupRCM"
            style={{
              position: "absolute",
              top: "10vh",
              left: "22vw",
              height: "auto",
              zIndex: "1000",
              backgroundColor: "#14172A",
              padding: "2vw 6vw",
              borderRadius: "10px",
            }}
          >
            <div
              className="popup-innerRCM"
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <p style={{ fontFamily: "intersemibold", color: "#fff" }}>
                There is NO Template Do You want to Create ?
              </p>
              {/* <Link to={`/AuditExecution/${id}/Planning/${planid}/${tempId}`}>
        <button onClick={handlePopupClose} style={{width:"10vw" ,padding: ".5vw 3vw",fontFamily:"intersemibold",textAlign:"center",margin:"1vw 0vw"}}>Visit</button>
        </Link> */}
              <div style={{ display: "flex" }}>
                <button
                  onClick={handlePopUp}
                  style={{
                    width: "10vw",
                    padding: ".5vw 3vw",
                    fontFamily: "intersemibold",
                    textAlign: "center",
                    margin: "1vw 0vw",
                  }}
                >
                  close
                </button>
                <button
                  onClick={handlePopUpYes}
                  style={{
                    width: "10vw",
                    padding: ".5vw 3vw",
                    fontFamily: "intersemibold",
                    textAlign: "center",
                    margin: "1vw 0vw",
                  }}
                >
                  Yes
                </button>
              </div>
            </div>
          </div>
        )}
      

<div className="acceptDiv">

{/* {!tempId && (
          <button className="lRCMBtn" onClick={handleAccountClick}>
            RCM Template
          </button>
        )} */}

        {/* {tempId && ( */}
          <button className="lRCMBtn" onClick={viewTemp}>
            Manage RCM Template
          </button>
        {/* )} */}


{APIDataRcm.rcm_approved && 
<button className="acceptRCMBtn" onClick={(e) => updateAccept(e)}>Accept</button>
} 
       
       </div>
    </div>
      </section>
    </>
  );
};

export default ViewRcmTask;
