import React, { useState, useEffect,useRef } from "react";
import { NavLink, useLocation, Link, useNavigate, useParams } from "react-router-dom";

import Breadcrumb from "../Breadcrumb";
import SidebarMenu from "../SidebarMenu";

import { deleteData, deleteData2, deleteDataWithFetch, fetchData, postData, postDataWithFetch, updateData, updateData2, updatewithFetchData } from "../apiUtils";
import { auditExecutionQA, auditExecutionTop, auditBusiness } from "../subroutes";

import openarrow from "../../images/openarrow.svg"
import SaveRow from "../../images/saveBtn.svg";
import closeBtn from "../../images/closeicon.svg"
import create from "../../images/createbutton.svg";
import EditRow from "../../images/edit_pencil.svg";
import deleteBtn from "../../images/deleteBtn.svg";
import add_button from "../../images/add_button.svg";





import "../auditExecution/auditplan/auditplan.css"
import "../auditExecution/auditWrapUp/QAChecklistFolder/qachecklist.css"

import "../../css/viewuser.css"
import SingleOptionSelector from "../SingleOptionSelector";
import OptionDropDown from "../OptionDropDown";
import { Direction } from "@syncfusion/ej2-charts";
import MultiOptionSelector from "../MultiOptionSelector";


const QAChecklist = ({ tableSize }) => {
  const [errors, setErrors] = useState("")
  const [APIData, setAPIData] = useState([]);
  const [checklistData, setChecklistData] = useState([]);
  const [AuditData, setAuditData] = useState([]);
  const [step, setSteptaken] = useState("")
  const [phase, setPhase] = useState('')
  const [questions, setQuestions] = useState([]);
  const location = useLocation();
  const navigate = useNavigate();
  const successMessageColor = location.state?.successMessageColor;
  const successMessage = location.state?.successMessage;
  const [showMessage, setShowMessage] = useState(!!successMessage);
  const originalUrl = location.state?.from;
  const [showRow, setShowRow] = useState(false);
  const [editingIndex, setEditingIndex] = useState(null);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      navigate(".", { replace: true });
    }, 5000);

    return () => clearTimeout(timeoutId);
  }, []);


  
  const userRoles = localStorage.getItem("userRoles");


  var routes=[];
  if (userRoles.includes("Admin")) {
  routes = auditExecutionTop;
} else if (userRoles.includes("Business")) {
  routes = auditBusiness;
}
 else if (userRoles.includes("Quality Assurance")) {
  routes = auditExecutionQA;
} 
//else if (userRoles.includes("Audit Commission")) {
//   routes = forAuditCommission;
// } else if (userRoles.includes("Quality Assurance")) {
//   routes = forQA;
// } 
else {
  console.log("No matching role found");
}


  useEffect(() => {

    fetchData(`/api/audit_universe/audit-entities/`, setAPIData, setErrors);

  }, []);
  useEffect(() => {

    fetchData(`/api/audit_engagement/qa-checklist/template/`, setChecklistData, setErrors);
  }, [])

  console.log(checklistData)

  const [filters, setFilters] = useState({
    name: "",
    key_stakeholder: "",
  });

  const handleFilterChange = (filterKey, e) => {
    setFilters({ ...filters, [filterKey]: e.target.value });
  };
  const handleCreateClick = () => {
    setQuestions([...questions, { step: "", phase: "" }]);
    setShowRow(true)
  };


  const handleInputChange = (key, value) => {
    // Update the editValues object based on key and value
    setEditValues((prevValues) => ({
      ...prevValues,
      [key]: value,
    }));
  };



  const handleEditClick = (index, question) => {
    setEditingIndex(index);
    setEditValues({ ...question })
    console.log(editValues)
  };

  const handleSaveClick = () => {
    postDataWithFetch(`/api/audit_engagement/qa-checklist/template/`, {
      phase: phase,
      step: step,
    },
    !navigate,
    setErrors,
    {
      fetchurl1: `/api/audit_engagement/qa-checklist/template/`,
      Dataset1 :setChecklistData,
    },
  );
    setShowRow(false)
    setPhase('')
    setSteptaken('')

  };


  const handleCancelClickRow =() => {
    setShowRow(false);
  }


  const handleCancelClick = () => {
    setEditingIndex(null);
    setEditValues({});
  };

  const handleDeleteClick = (index, id) => {
    deleteDataWithFetch(`/api/audit_engagement/qa-checklist/template/${id}`,
      navigate,
      setErrors,
      {
        fetchurl1: `/api/audit_engagement/qa-checklist/template/`,
        Dataset1 :setChecklistData,
      },
      `/AuditExecution/Qachecklist`,    
    )
    
  };

  const handleUpdateClick = (id) => {

    updatewithFetchData(`/api/audit_engagement/qa-checklist/template/${id}`, {
      phase: editValues.phase,
      step: editValues.step,
    },
      !navigate,
      setErrors,
      {
        fetchurl1: `/api/audit_engagement/qa-checklist/template/`,
        Dataset1 :setChecklistData,
      },
    )
    setEditingIndex(null);
    setEditValues({})
  }


  const [editingRow, setEditingRow] = useState(null);
  const [editValues, setEditValues] = useState({});
  const [openDropdown, setOpenDropdown] = useState(null);
  const dropdownRef = useRef(null);


  const handleDropdownToggle = (index) => {
    setOpenDropdown(openDropdown === index ? null : index);
  };

 
  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setOpenDropdown(null);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const renderIcons = () => {
    return routes.map((route, index) => {
      if (route.routes) {
        return (
          <div key={index} className="dropdown-container-exec" ref={dropdownRef}>
            <div
              className="dropdown-toggle"
              style={{ display: 'flex', gap: '.5vw' }}
              onClick={() => handleDropdownToggle(index)}
            >
              <div className="navicon">
                <img src={route.icon} alt="Dropdown Icon" />
              </div>
              <div className="navicon">{"Q.A Checklist"}</div>
            </div>
            {openDropdown === index && (
              <div className="dropdown-template">
                {route.routes.map((subroute, subindex) => (
                  <NavLink
                    to={subroute.path}
                    key={subindex}
                    className="navlink template-nav"
                    activeClassName="active1"
                  >
                    <div className="navicon"></div>
                    <div className="navicon">{subroute.name}</div>
                  </NavLink>
                ))}
              </div>
            )}
          </div>
        );
      }

      return (
        <NavLink
          to={route.path}
          key={index}
          className="navlink"
          activeclassname="active1"
        >
          <div className="navicon">
            <img src={route.icon} alt="Dashboard Icon" />
          </div>
          <div className="navicon">{route.name}</div>
        </NavLink>
      );
    });
  };


  const filteredData = APIData.filter((data) => {

    const fullName = data.name.toLowerCase();
    const NameMatch = filters.name ? fullName.includes(filters.name.toLowerCase()) : true;
    const keyStakeholderName = "";
    const KeyStakeholder = filters.key_stakeholder ? keyStakeholderName.includes(filters.key_stakeholder.toLowerCase()) : true;

    const businessUnitMatch = filters.business_unit ? data.business_units.some(unit => {
      const unitNameLower = unit.name.toLowerCase();
      const filterUnitLower = filters.business_unit.toLowerCase();
      return unitNameLower.includes(filterUnitLower);
    }) : true;

    return NameMatch && KeyStakeholder && businessUnitMatch;
  });



  console.log(editValues)


  return (
    <>
      {errors && <p className="errormessage">{errors}</p>}

      {successMessage && <p className="successmessage">{successMessage}</p>}

      <section className="navroutes">

        <div className="filter-container">


        </div>

        <div className="bumenucontainer-exec">{renderIcons()}</div>
        <hr className="breadcrumbabv" />

        <Breadcrumb />
        <div className="filter-container">
        <div className="filter">
          <div className="views">
            <h3 className="h2_interbold" >Create Q.A Checklist</h3>
          </div>
        </div>
      </div>
        <div className="questionniarecontainer" style={{ width: tableSize ? "79.5vw" : "90vw" }}>
          <div className="checkques_con">
            {checklistData.map((question, index) => (

              <div key={index} className="aecard">

                {editingIndex === index ? (
                  <>
                    <div style={{ display: 'flex', justifyContent: "space-between", alignItems: 'center', gap: '1vw',backgroundColor:'#fff',padding:'.2vw .8vw',borderRadius:'8px' }}>

                      <div className="questionCQ">
                        <input
                          style={{ backgroundColor: '#eef1fc', width:tableSize?'47vw':'60vw' }}
                          type="text"
                          value={editValues.step}
                          onChange={(e) => handleInputChange("step", e.target.value)}
                        />
                      </div>

                      <div className="questionCQ1">
                        <select
                          className="custom-select"
                          style={{ height: '5vh' }}
                          value={editValues.phase}
                          onChange={(e) => handleInputChange("phase", e.target.value)}
                        >
                          <option value="" disabled>--Select a phase--</option>
                          <option value="Planning">Planning</option>
                          <option value="Fieldwork">Fieldwork</option>
                          <option value="Reporting">Reporting</option>
                          <option value="Follow Up">Follow Up</option>
                        </select>
                      </div>
                    <div className="questionCQ1" style={{width:'4.5vw'}}>
                      <button onClick={() => handleUpdateClick(question.id)} style={{ border: 'none',marginRight:'1vw', background: 'transparent' }}>
                        <img src={SaveRow} alt="Save" style={{ width: '2vw' }} />
                      </button>

                      <button onClick={handleCancelClick} style={{ border: 'none', background: 'transparent' }}>
                        <img src={closeBtn} alt="Cancel" style={{ width: '2vw' }} />
                      </button>
                      </div>
                    </div>
                  </>
                ) : (

                  <div className="checklist_question" style={{ backgroundColor: '#fff', padding: ' .5vw 1vw', borderRadius: '10px' }}>
                    <h4 style={{maxWidth:tableSize?'50vw':''}}>{question.step}</h4>
                    <div style={{display:'flex',gap:'2.5vw',alignItems:'center'}}>
                    <span className="checklist_phase" >{question.phase}</span>
                    <div>
                      <button onClick={() => handleEditClick(index, question)} style={{ marginRight: "1vw", border: "none", background: "transparent" }}>
                        <img src={EditRow} alt="Edit" style={{ width: '2vw' }} />
                      </button>
                      <button onClick={() => handleDeleteClick(index, question.id)} style={{ marginRight: "1vw", border: "none", background: "transparent" }}>
                        <img src={deleteBtn} style={{ width: '2vw' }} alt="Delete" />
                      </button>
                    </div>
                    
                    </div>
                  </div>
                )}

              </div>

            ))}


            {showRow &&
              <>
                <div style={{ display: 'flex', justifyContent: "space-between", alignItems: 'center', gap: '1vw',backgroundColor:'#fff',padding:'.2vw .8vw',borderRadius:'8px' }}>
                  <div className="questionCQ" >
                    <input style={{ backgroundColor: '#eef1fc', width:tableSize?'47vw':'60vw' }} type="text" autoFocus value={step} onChange={(e) => setSteptaken(e.target.value)} />
                  </div>
                  <div className="questionCQ1">
                    <select className="custom-select" style={{ height: '5vh' }} value={phase} onChange={(e) => setPhase(e.target.value)}>
                      <option value="none">--Select a phase--</option>
                      <option value="Planning">Planning</option>
                      <option value="Fieldwork">Fieldwork</option>
                      <option value="Reporting">Reporting</option>
                      <option value="Follow Up">Follow Up</option>
                    </select>
                  </div>

                  <div className="questionCQ1" style={{width:'4.5vw'}}>
                      <button onClick={handleSaveClick} style={{ border: 'none',marginRight:'1vw', background: 'transparent' }}>
                        <img src={SaveRow} alt="Save" style={{ width: '2vw' }} />
                      </button>

                      <button onClick={handleCancelClickRow} style={{ border: 'none', background: 'transparent' }}>
                        <img src={closeBtn} alt="Cancel" style={{ width: '2vw' }} />
                      </button>
                      </div>
                </div>
              </>
            }
            <div className="checklistcr_btn" style={{ height: '8vh' }}>
              <button onClick={handleCreateClick} style={{ float: 'right' }} >
                <img src={add_button} alt="Add" /> Add Question
              </button>
            </div>
          </div>
        </div>

        <div className="cardscontainer">
          {filteredData.map((data) => (
            <div className="aecard" >
              <h1>{data.name} </h1>

              <img src={openarrow} />
            </div>
          ))}
        </div>

      </section>
    </>
  );
};

export default QAChecklist;
