import React from "react";
import { NavLink, useLocation, Link, useNavigate } from "react-router-dom";
import user from "../../images/user.svg";
import department from "../../images/department.svg";
import BU from "../../images/BU.svg";
import corporate from "../../images/corporate.svg";
import SidebarMenu from "../SidebarMenu";
import { useState } from "react";
import "../../css/department.css";
import create from "../../images/createbutton.svg";
import save from "../../images/saveBtn.svg";
import Breadcrumb from "../Breadcrumb";
import { fetchData, postData } from "../apiUtils";
import SingleOptionSelector from "../SingleOptionSelector";
import SingleOptionSelectorRole from "../SingleOptionSelectorRole";
import RenderIcons from "../RenderIcons";
import { homeRoutes } from "../subroutes";
import IconButton from "../iconbutton/IconButton";
import CommonInput from "../CommonInput";

const CreateDepartment = ({tableSize}) => {
  const location = useLocation();
  const [errors, setErrors] = useState("");
  const [errorMessage, setErrorMessage] = useState(false);
  const [name, setDepartmentname] = useState("");
  const [nameError, setDepartmentnameError] = useState(false);
  const [description, setDescription] = useState("");
  const [descriptionError, setDescriptionError] = useState(false);
  const [department_head_id, setSelectorId] = useState("");
  const [selectorError, setSelectorError] = useState(false);
  const [editMode, setEditMode] = useState(true);

  const [selectedOption, setSelectedOption] = useState("");

  const handleSelectChange = (e) => {
    setSelectedOption(e.target.value);
  };

  const navigate = useNavigate();

  const postApi = (e) => {
    e.preventDefault();

    if (!name || !description) {
      setErrorMessage(true);
      setErrors("Please fill in all required fields.");
      setDepartmentnameError(!name ? true : false);
      setDescriptionError(!description ? true : false);
      setTimeout(() => {
        setErrors(null);
      }, 5000);
      return;
    }

    const departmentHead = parseInt(department_head_id, 10);

    postData(
      `/api/system/departments/`,
      {
        name,
        description,
        department_head_id: departmentHead,
      },
      navigate,
      setErrors,
      `/Administration/departments`
    );
  };

  return (
    <>
      {errors && <p className="errormessage">{errors}</p>}

      <section className="navroutes">
      <RenderIcons homeRoutes={homeRoutes} />


        <Breadcrumb />

        <div className="filter-container">
          <div className="filter">
            <div className="views">
              <h3>Create Departments</h3>
            </div>
          </div>
        </div>

        <div className="department_conatiner" style={{width: tableSize?"80vw":"90.5vw"}}>

          <div className="delete_edit_close_btn">
            <IconButton
              type="close"
              className="closeView"
              tooltip="Close"
              link="/Administration/user"
            />

            <IconButton type="save" tooltip="Save" onClick={postApi} link="/" />
          </div>

          <form>

            <CommonInput
              labelName="Department Name"
              error={nameError}
              type="text"
              placeHolder="Finance Department"
              setText={setDepartmentname}
              setTextError={setDepartmentnameError}
              commanDivClass="lbl_inpt"
            />



            <CommonInput
              labelName="Department Id"
              error={descriptionError}
              type="text"
              placeHolder="541-AF-9466D"
              setText={setDescription}
              setTextError={setDescriptionError}
              commanDivClass="lbl_inpt"
            />

            

              <div className="lbl_slct">
                <label htmlFor="dropdown">Choose an option:</label>
                <select
                  id="dropdown"
                  value={selectedOption}
                  onChange={handleSelectChange}
                >
                  <option value="" disabled>
                    Select an option
                  </option>
                  <option value="Business">Business</option>
                  <option value="Audit Team">Audit</option>
                </select>
              </div>

            


            <SingleOptionSelectorRole
              Name="first_name"
              title="Department Head"
              apiUrl="/api/system/users/"
              setSelectorId={setSelectorId}
              setSelectorError={setSelectorError}
              selectorError={selectorError}
              editMode={editMode}
              selectedOption={selectedOption}
            />


          </form>
        </div>
      </section>
    </>
  );
};

export default CreateDepartment;
