import React from "react";
import "../css/auditmemo.css";

const AuditMemo =({ tableSize })=>{




    return(
        <div className="auditMemo" style={{width: tableSize ? "79.5vw":"90vw"}}>
              <h3>Audit Memo</h3>
              <form >
              <div className="lbl_txa">
                  <label>Audit Scope</label>
                  
                  <textarea
                    placeholder="Define Audit Scope"
                  />  
                </div>  

                <div className="lbl_txa">
                  <label>Audit Key Phases/Duration</label>
                  
                  <textarea
                    placeholder="Audit Key Phases/Duration"
                  />  
                </div>  

                <div className="lbl_txa">
                  <label>Other Relevant Information</label>
                  
                  <textarea
                    placeholder="Other Relevant Information"
                  />  
                </div>  
              </form>
            </div>
    );
};

export default AuditMemo;