import React, { useState, useEffect, useRef } from 'react';
import './MultiSelect.css';

const MultiSelect = ({ options, selectedOptions = [], onChange, initailData }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredOptions, setFilteredOptions] = useState(options);
  const [currentSelectedOptions, setCurrentSelectedOptions] = useState(selectedOptions);
  const searchInputRef = useRef(null);

  useEffect(() => {
    if (Array.isArray(initailData)) {
      setCurrentSelectedOptions(initailData);
      onChange(initailData); 
    }
  }, [initailData, onChange]);

  useEffect(() => {
    setFilteredOptions(
      searchQuery.trim() === ''
        ? options
        : options.filter(option =>
            option.name.toLowerCase().includes(searchQuery.toLowerCase())
          )
    );
  }, [searchQuery, options]);

  // Focus on the search input when dropdown opens
  useEffect(() => {
    if (isOpen && searchInputRef.current) {
      searchInputRef.current.focus();
    }
  }, [isOpen]);

  // Toggle dropdown visibility
  const toggleDropdown = () => {
    setIsOpen(prevIsOpen => !prevIsOpen);
  };

  // Handle option selection
  const handleOptionClick = (option) => {
    const newSelectedOptions = currentSelectedOptions.some(item => item.id === option.id)
      ? currentSelectedOptions.filter(item => item.id !== option.id)
      : [...currentSelectedOptions, { id: option.id, name: option.name }];

    setCurrentSelectedOptions(newSelectedOptions);
    onChange(newSelectedOptions);
  };

  // Handle clear selection
  const handleClearSelection = (e) => {
    e.stopPropagation();
    setCurrentSelectedOptions([]);
    onChange([]);
  };

  // Handle blur event to close dropdown
  const handleBlur = (e) => {
    if (!e.currentTarget.contains(e.relatedTarget)) {
      setTimeout(() => {
        setIsOpen(false);
      }, 200);
    }
  };

  // Handle unselecting an option
  const handleUnselectOption = (id, e) => {
    e.stopPropagation();
    const newSelectedOptions = currentSelectedOptions.filter(item => item.id !== id);
    setCurrentSelectedOptions(newSelectedOptions);
    onChange(newSelectedOptions);
  };

  return (
    <>
      <div className="multi-select-header" onClick={toggleDropdown}>
        {currentSelectedOptions.length > 0
          ? currentSelectedOptions.map(({ id, name }) => (
              <span key={id} className="selected-option">
                {name}
                <button
                  className="unselect-button"
                  onClick={(e) => handleUnselectOption(id, e)}
                >
                  x
                </button>
              </span>
            ))
          : 'Select options'}
      </div>
      <div className="multi-select" tabIndex={0} onBlur={handleBlur}>
        {isOpen && (
          <div className="multi-select-dropdown">
            <input
              type="text"
              className="multi-select-search"
              placeholder="Search options..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              ref={searchInputRef}
              onClick={(e) => e.stopPropagation()}
            />
            {filteredOptions.length > 0 ? (
              filteredOptions.map(option => (
                <div
                  key={option.id}
                  className={`multi-select-option ${currentSelectedOptions.some(item => item.id === option.id) ? 'selected' : ''}`}
                  onClick={() => handleOptionClick(option)}
                >
                  {option.name}
                </div>
              ))
            ) : (
              <div className="no-options">No options found</div>
            )}
            <div className="multi-select-clear" onClick={handleClearSelection}>
              Clear
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default MultiSelect;
