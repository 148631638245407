import React, { useState, useEffect } from "react";
import {
  NavLink,
  useLocation,
  Link,
  useNavigate,
  useParams,
} from "react-router-dom";
import RenderIcons from "../../../RenderIcons";
import axios from "axios";
import Breadcrumb from "../../../Breadcrumb";
import SidebarMenu from "../../../SidebarMenu";
import "../../../../css/viewuser.css";
import { fetchData, postData2 } from "../../../apiUtils";
import { auditExecution } from "../../../subroutes";
import openarrow from "../../../../images/openarrow.svg";
import backBtn from "../../../../images/backLogo.svg";
import "../createObservation/createobservation.css";
import SingleOptionSelector from "../../../SingleOptionSelector";
import SingleOptionSelectorWP from "../../../SingleOptionSelectorWP";
import AuditReportingSeclector from "../AuditReportingSelector";
import { postData } from "../../../apiUtils";
import MultiOptionTest from "../../../MultiOptionTest";
import MultiOptionWorkPaper from "../../../MultiOptionWorkPaper";
import addSubObv from "../../../../images/cut.svg";
import deleteObv from "../../../../images/deleteBtn.svg";
import savesubObv from "../../../../images/saveBtn.svg";
import { useScroll } from "framer-motion";

const CreateObservation = ({ tableSize }) => {
  const [errors, setErrors] = useState("");
  const [APIData, setAPIData] = useState([]);
  const location = useLocation();
  const navigate = useNavigate();
  const [short_description, setShortDescription] = useState("");
  const [summary, setSummary] = useState("");
  const [auditData, setAuditData] = useState([])
  const [item, setItem] = useState({});
  const [objective, setTitle] = useState("");

  const [rating, setObservationRating] = useState("");
  const [shortDescriptionError, setShortDescriptionError] = useState(false);
  const [description, setDescription] = useState("");
  const [recommendation, setRecommendation] = useState("");
  const [exposure_to_losses, setExposureLosses] = useState("");
  const [potential_impact, setPotentialImpact] = useState("");
  const [likelihood, setLikelihood] = useState("");
  const [risk1, setRisk01] = useState("");
  const [risk2, setRisk02] = useState("");
  const [risk3, setRisk03] = useState("");
  const [action_plan, setActionPlan] = useState("");
  const [assign_to_id, setAssign_to_id] = useState("");
  const [level_1_ownerError, setLevel1OwnerError] = useState("");
  const [level_2_owner, setLevel2Owner] = useState("");
  const [level_2_ownerError, setLevel2OwnerError] = useState("");
  const [action_due_date, setActionDueDate] = useState("");
  const [response_audit, setAuditResponse] = useState("");
  const [response_due_date, setResponseActionDueDate] = useState("");
  const [preliminary_close_observation, setPreliminaryCloseObservation] =
    useState("");

  const [preparer_id, setPreparerId] = useState("");
  const [PreparerError, setPreparerError] = useState(false);
  //const [assign_to_id, setAssign_to_id] = useState("");
  const [assign_to_idError, setAssign_to_idError] = useState(false);
  const [reviewer_id, setReviewerId] = useState("");
  const [reviewerError, setReviewerError] = useState(false);
  const [testIds, setTestIds] = useState([]);
  const [workPId, setWorkPId] = useState([]);

  const [workPIdError, setWorkPIdError] = useState("");

  const [testIdsError, setTestIdsError] = useState(false);

  const [editMode, setEditMode] = useState(true);

  const successMessageColor = location.state?.successMessageColor;
  const successMessage = location.state?.successMessage;
  const [showMessage, setShowMessage] = useState(!!successMessage);
  const originalUrl = location.state?.from;
  const { id, reportId } = useParams();

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      navigate(".", { replace: true });
    }, 5000);

    return () => clearTimeout(timeoutId);
  }, []);

  useEffect(() => {
    fetchData(`/api/audit_engagement/audit/${id}/`, setAuditData, setErrors);
  }, [id]);


  useEffect(() => {
    fetchData(`/api/audit_universe/audit-entities/`, setAPIData, setErrors);
  }, []);

  const [filters, setFilters] = useState({
    name: "",
    key_stakeholder: "",
  });

  const handleFilterChange = (filterKey, e) => {
    setFilters({ ...filters, [filterKey]: e.target.value });
  };
  const preparerId = auditData.preparer ? auditData.preparer.id : null;
  const preparerName = auditData.preparer ? auditData.preparer.name :null;
  const assignId=auditData.assign_to?auditData.assign_to.id:null;
  const assignName=auditData.assign_to?auditData.assign_to.name:null;
  const reviewerName = auditData.reviewer? auditData.reviewer.name : null;
  const reviewerId = auditData.reviewer ? auditData.reviewer.id : null;


  const [observationId ,setObservationId]=useState("")
  const createObservationTest = () => {
    const mergedArray = Object.values(item).flat();

    postData2(
      `/api/audit_engagement/observation/`,
      {
        audit_id: id,
        objective,
        short_description,
        preparer_id,
        reviewer_id,
        assign_to_id,
        workpapertestcase_id: Object.values(item).flat(),
      },
      navigate,
      setErrors,
      `/AuditExecution/${id}/Reporting/ReportingObservationCreation/${reportId}/`,
     
     
      (data) => {
        console.log("data : ", data);
        const successMessageColor = "#4285F4";
        navigate(`/AuditExecution/${id}/Reporting/ReportingObservationCreation/${data.id}/`, {
          state: {
            successMessage: "Successfully Created!",
            successMessageColor,
          },
        });
      }
    );
  };

 
  
 

  const filteredData = APIData.filter((data) => {
    const fullName = data.name.toLowerCase();
    const NameMatch = filters.name
      ? fullName.includes(filters.name.toLowerCase())
      : true;
    const keyStakeholderName = data.key_stakeholder
      ? data.key_stakeholder.name.toLowerCase()
      : "";
    const KeyStakeholder = filters.key_stakeholder
      ? keyStakeholderName.includes(filters.key_stakeholder.toLowerCase())
      : true;

    const businessUnitMatch = filters.business_unit
      ? data.business_units.some((unit) => {
          const unitNameLower = unit.name.toLowerCase();
          const filterUnitLower = filters.business_unit.toLowerCase();
          return unitNameLower.includes(filterUnitLower);
        })
      : true;

    return NameMatch && KeyStakeholder && businessUnitMatch;
  });
  const [inputList, setInputList] = useState([]);
  const [subObservations, setSubObservations] = useState([]);

  const handleAdd = () => {
    setInputList([...inputList, { value: "", isEditing: true }]);
  };

  const handleSave = (index) => {
    const updatedList = [...inputList];
    updatedList[index].isEditing = false;
    setInputList(updatedList);

    // Save the data in the required format
    const updatedObservations = [...subObservations, updatedList[index].value];
    setSubObservations(updatedObservations);
  };

  const handleCancel = (index) => {
    const updatedList = inputList.filter((_, i) => i !== index);
    setInputList(updatedList);
  };

  const handleRemove = (index) => {
    const updatedList = inputList.filter((_, i) => i !== index);
    setInputList(updatedList);

    const updatedObservations = subObservations.filter((_, i) => i !== index);
    setSubObservations(updatedObservations);
  };

  const handleChange = (e, index) => {
    const updatedList = [...inputList];
    updatedList[index].value = e.target.value;
    setInputList(updatedList);
  };


  

  return (
    <>
      {errors && <p className="errormessage">{errors}</p>}

      {successMessage && <p className="successmessage">{successMessage}</p>}

      <section className="navroutes">
      <RenderIcons homeRoutes={auditExecution} id ={id}/>

          <div className="filter-container">
            <div className="filter">
                       <Breadcrumb /> 


              <div className="searchncbtn">
                <input type="text" placeholder="Search" className="searchbar" />

                <div className="createuser" onClick={createObservationTest}>
                <div className="backToReport">
            <Link
              className="backbtnObv"
              to={`/AuditExecution/${id}/Reporting/InitialObservation`}
            >
              <img src={backBtn} />
              <p>Back to Initial Observation</p>
            </Link>

            
          </div>
                </div>
              </div>
            </div>
          </div>





          




          <div
            className="create_observation_con"
            style={{ width: tableSize ? "80vw" : "90vw" }}
          >
            
              <form action="">
                <div className="assign_reviewer">
                  
                    {/* <h3>Preparer</h3> */}

                    <AuditReportingSeclector
                      apiUrl="/api/audit_universe/audit-team/"
                      setSelectorId={setPreparerId}
                      setSelectorError={setPreparerError}
                      selectorError={PreparerError}
                      Name="name"
                      title="Preparer"
                      editMode={editMode}
                      initialId={preparerId}
                      initialName={preparerName}

                    />
                  

                    {/* <h3>Reviewer</h3> */}

                    <AuditReportingSeclector
                      apiUrl="/api/audit_universe/admin-audit-team/"
                      setSelectorId={setReviewerId}
                      setSelectorError={setReviewerError}
                      selectorError={reviewerError}
                      Name="name"
                      title="Reviewer"
                      editMode={editMode}
                      initialId={reviewerId}
                      initialName={reviewerName}
                    />
                  
                  
                      {/* <h3>Owner</h3> */}

                      <AuditReportingSeclector
                        apiUrl="/api/audit_universe/business-stakeholders/"
                        setSelectorId={setAssign_to_id}
                        setSelectorError={setLevel1OwnerError}
                        selectorError={level_1_ownerError}
                        Name="name"
                        title="Owner"
                        editMode={editMode}
                        initialId={assignId}
                        initialName={assignName}
                      />
                    

                  {/* <div
                    className="label_selector"
                    style={{ width: tableSize ? "28vw" : "31vw" }}
                  >
                    <h3>Status</h3>

                    <div className="observation_status_con">
                      Published Draft
                    </div>
                  </div> */}
                </div>

                <div className="observation_main_starts">
                  
                  <div className="other_detail_section" style={{height:"60vh"}}>
                    <h4>Overview</h4>

                    <div className="lng_lbl_inpt">
                      <label>Title</label>
                      <input
                        type="text"
                        placeholder="Title"
                        value={objective}
                        onChange={(e) => setTitle(e.target.value)}
                      />
                    </div>

                    {/* <div className="observation_label_input">
                      <label>Observation Rating</label>
                      <input
                        type="text"
                        placeholder="Observation Rating"
                        value={rating}
                        onChange={(e) => setObservationRating(e.target.value)}
                      />
                    </div> */}

                    <div className="lbl_txa">
                      <label>Short Description</label>
                      <textarea
                        type="text"
                        placeholder="Short Description"
                        value={short_description}
                        style={{
                          border: shortDescriptionError
                            ? "1px solid #FF4646"
                            : "",
                        }}
                        onChange={(e) => {
                          setShortDescription(e.target.value);
                          setShortDescriptionError(false);
                        }}
                      />{" "}
                    </div>

                  
                  </div>

                  <div className="workpap_file_upload">
                    <div className="referance_data">
                      <MultiOptionWorkPaper
                        apiUrl="/api/audit_engagement/workpaper/"
                        setSelectorError={setWorkPIdError}
                        selectorError={workPIdError}
                        title="Select Workpaper"
                        Name="objective"
                        setTestIds={setTestIds}
                        editMode={!editMode}
                        item={item}
                        setItem={setItem}
                      />
                    </div>

                  </div>
                </div>

              </form>
            
              <div className="filter-container">
            <div className="filter" style={{display:"flex", justifyContent: 'end', position:"absolute", top:'90vh', right : '3vw'}}>


              <div className="searchncbtn">
                <button className="create_obsr_button">
                    Next
                  </button>
              
              </div>
            </div>
          </div>
          </div>

          <div className="cr_obs_bottom_space"></div>

          <div className="cardscontainer">
            {filteredData.map((data) => (
              <div className="aecard">
                <h1>{data.name} </h1>

                <img src={openarrow} />
              </div>
            ))}
          </div>
      </section>
    </>
  );
};

export default CreateObservation;
