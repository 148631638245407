import React, { useState } from "react";
import { NavLink, Link, useNavigate } from "react-router-dom";
import SidebarMenu from "../../SidebarMenu";
import create from "../../../images/createbutton.svg";
import save from "../../../images/saveBtn.svg";
import "../../../css/viewuser.css";
import Breadcrumb from "../../Breadcrumb";
import "../../../css/createentity.css";
import "../../../css/formality.css";
import { postData } from "../../apiUtils";
import SingleOptionSelector from "../../SingleOptionSelector";
import { auditRoutes } from "../../subroutes";
import MultiOptionSelector from "../../MultiOptionSelector";
import LongMultiOptionSelector from "../../LongMultiOptionSelector"
import IconButton from "../../iconbutton/IconButton";
import CommonInput from "../../CommonInput";
import RenderIcons from "../../RenderIcons";
const CreateEntity = ({ tableSize }) => {
  const [errors, setErrors] = useState("");
  const [firstName, setFirstName] = useState("");
  const [firstNameError, setFirstNameError] = useState(false);
  const [avatarColor, setAvatarColor] = useState("");
  const [other_stakeholders_id, setOther_stakeholders_id] = useState([]);
  const [description, setDescription] = useState("");
  const [descriptionError, setDescriptionError] = useState(false);
  const [selectedKeyError, setSelectedKeyError] = useState(false);
  const [key_stakeholder_id, setKey_stakeholder_id] = useState("");
  const [business_units_id, setBusinessUnit_id] = useState([]);
  const [editMode, setEditMode] = useState(true);

  const [name, setName] = useState("");
  const navigate = useNavigate();
  const getRandomColor = (letter) => {
    const colorSeed = letter.charCodeAt(0) * 137;
    const color = `hsl(${colorSeed % 360}, 70%, 50%)`;
    return color;
  };
  const handleFirstNameChange = (event) => {
    const newName = event.target.value;
    setFirstName(newName);
    setFirstNameError(false);
    setName(newName);
    if (newName.length > 0) {
      const initialLetter = newName[0].toUpperCase();
      setAvatarColor(getRandomColor(initialLetter));
    }
  };
  const postApi = (e) => {
    e.preventDefault();
    if (!firstName || !description || !key_stakeholder_id) {
      setFirstNameError(!firstName ? true : false);
      setDescriptionError(!description ? true : false);
      setSelectedKeyError(!key_stakeholder_id ? true : false);
      setErrors("Please fill in all required fields.");
      setTimeout(() => {
        setErrors(null);
      }, 5000);
      return;
    }
    postData(
      `/api/audit_universe/audit-entities/`,
      {
        name,
        description,
        business_units_id,
        key_stakeholder_id,
        other_stakeholders_id,
      },
      navigate,
      setErrors,
      `/AuditUniverse/auditEntities`
    );
  };
  const handleChange = (event) => {
    setDescription(event.target.value);
    setDescriptionError(false);
    event.target.style.height = "auto";
    event.target.style.height = event.target.scrollHeight + "px";
  };

  return (
    <>
      {errors && <p className="errormessage">{errors}</p>}

      <section className="navroutes">
      <RenderIcons homeRoutes={auditRoutes} />

        <Breadcrumb />

        <div className="filter-container">
          <div className="filter">
            <div className="views">
              <h3 className="h2_interbold" >Create Audit Entity</h3>
            </div>
          </div>
        </div>

        <div
          className="auditContainer"
          style={{ width: tableSize ? "80vw" : "90.5vw",height:"67vh",overflowY:"scroll" }}
        >
          <div className="delete_edit_close_btn">
            <IconButton
              type="close"
              className="closeView"
              tooltip="Close"
              link="/AuditUniverse/auditEntities"
            />

            <IconButton type="save" tooltip="Save" onClick={postApi} link="/" />
          </div>

         
            <form>
           
               
                  <div className="lbl_inpt">
                    <label>Name</label>
                    <input
                      style={{
                        border: firstNameError ? "1px solid #FF4646" : "",
                      }}
                      type="text"
                      placeholder="John"
                      value={name}
                      id="firstName"
                      onChange={handleFirstNameChange}
                    />
                  </div>

                
                    
                      <SingleOptionSelector
                        apiUrl="/api/audit_universe/admin-business-stakeholders/"
                        setSelectorId={setKey_stakeholder_id}
                        setSelectorError={setSelectedKeyError}
                        selectorError={selectedKeyError}
                        Name="name"
                        title="Key Stakeholder"
                        editMode={editMode}
                      />
              

              <div className="lbl_txa">
                  <label>Description</label>

                  <textarea
                    style={{
                      border: descriptionError ? "1px solid #FF4646" : "",
                    }}
                    placeholder="Description"
                    value={description}
                    onChange={handleChange}
                  />
                </div>
            
                  <LongMultiOptionSelector
                    apiUrl="/api/system/business-units/"
                    setSelectorId={setBusinessUnit_id}
                    Name="name"
                    // classNameInput={tableSize ? "entityBUOpne" : "entityBUClose" }
                    title="Business Unit "
                    editMode={editMode}
                    // width={tableSize ? "auditEntityBUOpne" : "auditEntityBUClose" }
                  />
                

                
                  
                    <LongMultiOptionSelector
                      apiUrl="/api/audit_universe/business-stakeholders/"
                      setSelectorId={setOther_stakeholders_id}
                      Name="name"
                      // classNameInput={tableSize ? "entityBUOpne" : "entityBUClose" }
                      title="Other Key Stakeholders"
                      editMode={editMode}
                      // width={tableSize ? "auditEntityBUOpne" : "auditEntityBUClose" }
                    />
                 
             
              
            </form>
          
        </div>
      </section>
    </>
  );
};

export default CreateEntity;
