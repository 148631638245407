import React from 'react'
import { useState, useEffect } from 'react';
import { NavLink } from "react-router-dom";
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { fetchData, postData, postData2 } from '../../apiUtils';
import { auditRoutes } from '../../subroutes';
import SidebarMenu from '../../SidebarMenu';
import Breadcrumb from '../../Breadcrumb';
import IconButton from '../../iconbutton/IconButton';
import OptionDropDown from '../../OptionDropDown';
import SingleOptionSelector from '../../SingleOptionSelector';
import RenderIcons from '../../RenderIcons';
import {auditExecution} from '../../subroutes';
const CreateWorkpaper = ({ tableSize }) => {
  const [errors, setErrors] = useState("");
  const [objective, setObjective] = useState("");
  const [risk_id, setRiskId] = useState("");
  const [entityId, setEntityId] = useState("");
  const [editMode, setEditMode] = useState(true);

  const [risk_category, setRiskCategory] = useState("");
  const [risk_description, setDescribeRisk] = useState("");
  const [risk_title, setTitleRisk] = useState("");

  const [inherent_assessment_likelihood, setLikelikhood] = useState("");
  const [inherent_assessment_impact, setImpact] = useState("");
  const [inherent_risk_evaluation, setIRE] = useState("");
  const [inherent_risk_score, setIRS] = useState("");

  const [control_existing, setExistingControl] = useState("");
  const [control_desired, setDesiredControl] = useState("");
  const [control_evaluation, setEvaluation] = useState("");
  const [control_design, setControlDesign] = useState("");
  const [control_type, setcontrolType] = useState("");
  const [control_frequency, setcontrolFrequency] = useState("");
  const [control_gap, setControlGap] = useState("");
  const [residual_assessment_likelihood, setResLikelihood] = useState("");
  const [residual_assessment_impact, setResImpact] = useState("");
  const [residual_risk_score, setRRS] = useState("");
  const [residual_risk_evaluation, setRRE] = useState("");

  const [sample_rationale, setSampleRationale] = useState("");
  const [sample_rationaleError, setSampleRationaleError] = useState(false);

  const [record_work_done, setRecordWorkDone] = useState("");
  const [record_work_doneError, setRecordWorkDoneError] = useState(false);

  const [sample_size, setSampleSize] = useState("");
  const [sample_sizeError, setSampleSizeError] = useState(false);

  const [workpaper_reference, setWorkpaperReference] = useState("");
  const [workpaper_referenceError, setWorkpaperReferenceError] = useState(false);

  const [testing_status, setTestingStatus] = useState("");
  const [testing_statusError, setTestingStatusError] = useState(false);


  const [audit_procedure_step, setAuditStepDetails] = useState("");
  const [audit_procedure_data, setDataRequired] = useState("");

  const [likelikhoodError, setLikelikhoodError] = useState(false);
  const [impactError, setImpactError] = useState(false);
  const [IRSError, setIRSError] = useState(false);
  const [IREError, setIREError] = useState(false);
  const [existingControlError, setExistingControlError] = useState(false);
  const [desiredControlError, setDesiredControlError] = useState(false);
  const [evaluationError, setEvaluationError] = useState(false);
  const [controlDesignError, setControlDesignError] = useState(false);
  const [controlTypeError, setcontrolTypeError] = useState(false);
  const [controlFrequencyError, setcontrolFrequencyError] = useState(false);
  const [controlGapError, setControlGapError] = useState(false);
  const [resLikelihoodError, setResLikelihoodError] = useState(false);
  const [resImpactError, setResImpactError] = useState(false);
  const [RRS, setRRSError] = useState(false);
  const [RRE, setRREError] = useState(false);

  const [auditStepDetailsError, setAuditStepDetailsError] = useState(false);
  const [dataRequiredError, setDataRequiredError] = useState(false);

  const [objectiveError, setObjectiveError] = useState(false);
  const [riskIdError, setRiskIdError] = useState(false);
  const [riskCategoryError, setRiskCategoryError] = useState(false);
  const [describeRiskError, setDescribeRiskError] = useState(false);
  const [titleRiskError, setTitleRiskError] = useState(false);

  const [preparer_id, setPreparerId] = useState("");
  const [PreparerError, setPreparerError] = useState(false);
  const [reviewer_id, setReviewerId] = useState("");
  const [reviewerError, setReviewerError] = useState(false);
  const [assign_to_id, setAssign_to_id] = useState("");
  const [assign_to_idError, setAssign_to_idError] = useState(false);



  const [APIData, setAPIData] = useState([]);
  const location = useLocation();
  const navigate = useNavigate();
  const successMessageColor = location.state?.successMessageColor;
  const successMessage = location.state?.successMessage;
  const [showMessage, setShowMessage] = useState(!!successMessage);
  const originalUrl = location.state?.from;
  const { id, planid, tempId, workId } = useParams();



  const fullUrl = window.location.origin + location.pathname;
  const segments = location.pathname.split('/');
  const auditUniverse = segments.find(segment => segment === 'auditUniverse');



  useEffect(() => {
    const timeoutId = setTimeout(() => {
      navigate(".", { replace: true });
    }, 5000);

    return () => clearTimeout(timeoutId);
  }, []);

  useEffect(() => {
    fetchData(`/api/audit_engagement/audit/${id}/`, setAPIData, setErrors);
  }, []);

  useEffect(() => {
    if (APIData.audit_entity) {
      if (!auditUniverse) {
        setEntityId(APIData.audit_entity.id);
      }
    }
  }, [APIData]);

  const Viewuser = (data) => {
    const id = data.id;
    navigate(`/auditUniverse/auditEntities/${id}/`);
  };
  const options = [
    // 'Financial',
    // 'Operational',
    // 'Strategic',
    // 'Compliance',
    // 'Reputational',
    { label: ' Financial', value: 'Financial' },
    { label: 'Operational', value: 'Operational' },
    { label: 'Strategic', value: 'Strategic' },
    { label: 'Compliance', value: 'Compliance' },
    { label: 'Reputational', value: 'Reputational' }
  ];
  const inherentAssessmentImpactOptions = [
    { label: 'Low', value: 1 },
    { label: 'Medium', value: 2 },
    { label: 'High', value: 3 },
    { label: 'Very High', value: 4 },
    { label: 'Critical', value: 5 },
  ];

  const inherentAssessmentLikeOptions = [
    { label: 'Low', value: 1 },
    { label: 'Medium', value: 2 },
    { label: 'High', value: 3 },
    { label: 'Very High', value: 4 },
    { label: 'Critical', value: 5 }
  ];

  const residualAssessmentImpactOptions = [
    { label: 'Low', value: 1 },
    { label: 'Medium', value: 2 },
    { label: 'High', value: 3 },
    { label: 'Very High', value: 4 },
    { label: 'Critical', value: 5 }
  ];

  const residualAssessmentLikeOptions = [
    { label: 'Low', value: 1 },
    { label: 'Medium', value: 2 },
    { label: 'High', value: 3 },
    { label: 'Very High', value: 4 },
    { label: 'Critical', value: 5 }
  ];

  const controlEvaluationOptions = [
    // 'Effective', 'Ineffective', 'Needs Improvement'
    { label: 'Effective', value: 'Effective' },
    { label: 'Ineffective', value: 'Ineffective' },
    { label: 'Needs Improvement', value: 'Needs Improvement' },
  ];
  const controlDesignOptions = [
    // 'Manual', 'Automated'
    { label: 'Manual', value: 'Manual' },
    { label: 'Automated', value: 'Automated' }
  ];
  const controlTypeOptions = [
    // 'Preventive', 'Detective', 'Corrective'
    { label: 'Preventive', value: 'Preventive' },
    { label: 'Detective', value: 'Detective' },
    { label: 'Corrective', value: 'Corrective' }
  ];
  const controlGapOptions = [
    { label: 'No Gap', value: 'No Gap' },
    { label: 'Minor Gap', value: 'Minor Gap' },
    { label: 'Significant Gap', value: 'Significant Gap' }
  ];

  const controlFrequencyOptions = [
    { label: 'Daily', value: 'Daily' },
    { label: 'Weekly', value: 'Weekly' },
    { label: 'Monthly', value: 'Monthly' },
    { label: 'Quarterly', value: 'Quarterly' },
    { label: 'Annually', value: 'Annually' }
  ];




  


  function afSpinner() {
    if (!APIData || APIData.length === 0) {
      return (
        <tr>
          <td colSpan="3" className="spinner-container">
            <div className="spinner"></div>
          </td>
        </tr>
      );
    }


  }
  const [urlNav, setUrlNav] = useState(`/AuditExecution/${id}/Fieldwork/`)


  // useEffect(() => {
  //   if(entityId){
  //         setUrlNav(`/AuditExecution/${id}/Planning/${planid}/${tempId}/`)
  // }
  // else{
  //   setUrlNav(`/auditUniverse/auditEntities/${id}/${tempId}/`)
  // }
  // }, [entityId]);

  const postApi = (e) => {
    e.preventDefault();
    if (
      !objective ||
      !risk_id ||
      !risk_category ||
      !risk_description ||
      !risk_title ||
      !inherent_assessment_likelihood ||
      !inherent_assessment_impact ||
      !control_existing ||
      !control_desired ||
      !control_evaluation ||
      !control_design ||
      !control_type ||
      !control_frequency ||
      !control_gap ||
      !residual_assessment_likelihood ||
      !residual_assessment_impact ||
      !audit_procedure_step ||
      !audit_procedure_data

    ) {
      setObjectiveError(!objective ? true : false);
      setRiskIdError(!risk_id ? true : false);
      setRiskCategoryError(!risk_category ? true : false);
      setDescribeRiskError(!risk_description ? true : false);
      setTitleRiskError(!risk_title ? true : false);
      setLikelikhoodError(!inherent_assessment_likelihood ? true : false);
      setImpactError(!inherent_assessment_impact ? true : false);
      setExistingControlError(!control_existing ? true : false);
      setDesiredControlError(!control_desired ? true : false);
      setEvaluationError(!control_evaluation ? true : false);
      setControlDesignError(!control_design ? true : false);
      setcontrolTypeError(!control_type ? true : false);
      setcontrolFrequencyError(!control_frequency ? true : false);
      setControlGapError(!control_gap ? true : false);
      setResLikelihoodError(!residual_assessment_likelihood ? true : false);
      setResImpactError(!residual_assessment_impact ? true : false);
      setAuditStepDetailsError(!audit_procedure_step ? true : false);
      setDataRequiredError(!audit_procedure_data ? true : false);
      setErrors("Please fill in all required fields.");
      setTimeout(() => {
        setErrors(null);
      }, 5000);
      return;
    }
    postData(
      `/api/audit_engagement/workpaper/`,
      {
        audit_id: id,
        preparer_id,
        reviewer_id,
        objective,
        risk_id,
        risk_category,
        risk_description,
        risk_title,
        inherent_assessment_likelihood,
        inherent_assessment_impact,
        control_existing,
        control_desired,
        control_evaluation,
        control_design,
        control_type,
        control_frequency,
        control_gap,
        residual_assessment_likelihood,
        residual_assessment_impact,
        audit_procedure_step,
        audit_procedure_data,
        residual_risk_score,
        sample_rationale,
        record_work_done,
        sample_size,
        workpaper_reference,
        testing_status,
        audit_entity: entityId ? entityId : id,
        rcm_template_id: tempId,

      },

      navigate,
      setErrors,
      `/AuditExecution/${id}/Fieldwork`,
    );
  };

  const sendToNext = () => {
    if (!objective || !preparer_id || !reviewer_id ) {
      setObjectiveError(!objective ? true : false);
      setReviewerError(!reviewer_id ? true : false);
      setPreparerError(!preparer_id ? true : false);
      setAssign_to_idError(!assign_to_id ? true : false);
      setErrors("Please fill in all required fields.");
      setTimeout(() => {
        setErrors(null);
      }, 5000);
      return;
    }
    // else{
    postData2(
      `/api/audit_engagement/workpaper/`,
      {
        audit_id: id,
        preparer_id,
        reviewer_id,
        assign_to_id,
        objective,
      },
      navigate,
      setErrors,
      `/AuditExecution/${id}/Fieldwork/${workId}`,
      (data) => {
        console.log("data : ", data);
        const successMessageColor = "#4285F4";
        navigate(`/AuditExecution/${id}/Fieldwork/${data.id}/`, {
          state: {
            successMessage: "Successfully Created!",
            successMessageColor,
          },
        });
      }
    );


    // }
  }

  return (
    <>
      <section className="navroutes">
      <RenderIcons homeRoutes={auditExecution} id ={id}/>

        <Breadcrumb />

        <div className="filter-container">
          <div className="filter">
            <div className="views">
              <h3 className="h2_interbold" >Create Workpaper</h3>
              {/* <div className="viewbuttons">
                <button className="active2">List view</button>
              </div> */}
            </div>

            <div className="searchncbtn">
              {/* <input
                type="text"
                placeholder="Search"
                className="searchbar"
                onChange={(e) => handleFilterChange("name", e)}
                value={filters.name}
              /> */}

              <div className="createuser">
                {/* <Link to="/">
                  <button className="createbutton">
                    <img src={create} alt="" />
                  </button>
                </Link> */}
              </div>
            </div>
          </div>
        </div>

        <div className="RCM_template_con" style={{ width: tableSize ? "80vw" : "90.5vw" }} >

          <div className="delete_edit_close_btn">
            <IconButton type="close" className='closeView' tooltip="Close" link={urlNav} />

            {/* <IconButton type="save" tooltip="Save" onClick={postApi} link="/" /> */}
          </div>


          <form action="">
            <div className="lng_lbl_inpt">
              <label htmlFor="">Objective</label>
              <input
                type="text"
                placeholder="Unauthorized Access to Financial Data"
                style={{
                  width: tableSize ? "75vw" : "84.5vw",
                  backgroundColor:"#EEF1FC",
                  border: objectiveError ? "1px solid #FF4646" : "",
                }}
                value={objective}
                onChange={(e) => {
                  setObjective(e.target.value);
                  setObjectiveError(false);
                }}
              />
            </div>

       

             
                <SingleOptionSelector
                  apiUrl="/api/audit_universe/audit-team/"
                  setSelectorId={setPreparerId}
                  setSelectorError={setPreparerError}
                  selectorError={PreparerError}
                  Name="name"
                  title="Preparer"
                  editMode={editMode}
                />
             

                <SingleOptionSelector
                  apiUrl="/api/audit_universe/admin-audit-team/"
                  setSelectorId={setReviewerId}
                  setSelectorError={setReviewerError}
                  selectorError={reviewerError}
                  Name="name"
                  title="Reviewer"
                  editMode={editMode}
                />
            
             
                <SingleOptionSelector
                  apiUrl="/api/audit_universe/business-stakeholders/"
                  setSelectorId={setAssign_to_id}
                  setSelectorError={setAssign_to_idError}
                  selectorError={assign_to_idError}
                  Name="name"
                  title="Assign To"
                  editMode={editMode}
                />
 

          </form>

          <button onClick={sendToNext}  className='next_create_wp ' > Next </button>

        </div>

        <div className="cardscontainer">

        </div>
      </section>
    </>

  );
};

export default CreateWorkpaper;