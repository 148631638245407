import React, { useState, useEffect } from "react";
import {
  NavLink,
  useLocation,
  Link,
  useNavigate,
  useParams,
} from "react-router-dom";
import axios from "axios";
import Breadcrumb from "../../Breadcrumb";
import SidebarMenu from "../../SidebarMenu";
import "../../../css/viewuser.css";
import { fetchData, updateData, postData } from "../../apiUtils";
import { auditExecution } from "../../subroutes";
import create from "../../../images/createbutton.svg";
import openarrow from "../../../images/openarrow.svg";
import cut_button from "../../../images/cutbutton.svg";
import "../auditplan/auditplan.css";
import ProgressBar from "../progressbar/ProgressBar";
import "./auditreporting.css";
import heatmap from "../../../images/heatmapSVG.svg"
import pencil from "../../../images/editpencil_wbg.svg";
import deleteicon from "../../../images/delete_wbg.svg";
import RenderIcons from "../../RenderIcons";
const GenerateReportMoreDetails = ({ tableSize }) => {
  const [errors, setErrors] = useState("");
  const [APIData, setAPIData] = useState([]);
  const [auditData, setAuditData] = useState([]);
  const [areAllTasksDone, setAreAllTasksDone] = useState();

  const location = useLocation();
  const navigate = useNavigate();
  const successMessageColor = location.state?.successMessageColor;
  const successMessage = location.state?.successMessage;
  const [showMessage, setShowMessage] = useState(!!successMessage);
  const originalUrl = location.state?.from;
  const { id, ObservationUpdateId, rid } = useParams();
  const [selectedTaskId, setSelectedTaskId] = useState(null);
  const [task, setTaskDetails] = useState();
  const [showPopup, setShowPopup] = useState(false);


  const [introduction, setIntroduction] = useState();
  const [introductionerror, setIntroductionError] = useState();
  const [audit_background, setBackground] = useState();
  const [audit_backgrounderror, setBackgroundError] = useState();
  const [final_opinion, setFinalOpinion] = useState();
  const [final_opinionerror, setFinalOpinionError] = useState();
  const [reportData,SetReportData]=useState([])



  useEffect(() => {
    const timeoutId = setTimeout(() => {
      navigate(".", { replace: true });
    }, 5000);

    return () => clearTimeout(timeoutId);
  }, []);

  useEffect(()=>{
    fetchData(`/api/audit_engagement/report/?audit_id=${id}`,SetReportData, setErrors,
      (data) => {
        console.log("Fetched files:", data.files); // Check data structure
        SetReportData(data.files || []);
      },
    );
  },[])

  useEffect(() => {
    fetchData(
      `/api/audit_engagement/observation/?audit_id=${id}`,
      setAPIData,
      setErrors,
      
    );
  }, []);

  useEffect(() => {
    fetchData(`/api/audit_engagement/audit/${id}/`, setAuditData, setErrors);
  }, [id]);


  useEffect(() => {
    setIntroduction(auditData?.introduction);
    setBackground(auditData?.audit_background);
    setFinalOpinion(auditData?.final_opinion);
  }, [auditData]);

  useEffect(() => {
    setAreAllTasksDone(APIData.every((task) => task?.task?.status === "done"));
  }, [APIData]);

  const [filters, setFilters] = useState({
    id: "",
    status: "",
    workpaper_id: "",
    summary: "",
    preparer: "",
    reviewer_name: "",
    created_at: "",
  });

  const handleFilterChange = (filterKey, e) => {
    setFilters({ ...filters, [filterKey]: e.target.value });
  };


  const filteredData = APIData.filter((data) => {
    const {
      id,
      status,
      workpaper_id,
      summary,
      preparer,
      reviewer,
      created_at,
      task,
      workpapertestcase,
    } = data;

    const matchesId = filters.id
      ? (id || "").toString().includes(filters.id)
      : true;

    const matchesStatus = filters.status
      ? task && task.status.toLowerCase().includes(filters.status.toLowerCase())
      : true;

    const matchesWorkpaperId = filters.workpaper_id
      ? workpapertestcase.some((wpTestCase) =>
        wpTestCase.workpaper.id
          .toString()
          .includes(filters.workpaper_id.toString())
      )
      : true;

    const matchesSummary = filters.summary
      ? (summary || "").toLowerCase().includes(filters.summary.toLowerCase())
      : true;

    const matchesPreparer = filters.preparer
      ? preparer &&
      preparer.name.toLowerCase().includes(filters.preparer.toLowerCase())
      : true;

    const matchesReviewer = filters.reviewer
      ? reviewer &&
      reviewer.name.toLowerCase().includes(filters.reviewer.toLowerCase())
      : true;

    const matchesCreatedAt = filters.created_at
      ? (created_at || "").toString().includes(filters.created_at)
      : true;

    return (
      matchesId &&
      matchesStatus &&
      matchesSummary &&
      matchesWorkpaperId &&
      matchesPreparer &&
      matchesReviewer &&
      matchesCreatedAt
    );
  });


  const updateMoreDetails = (e) => {
    if (!introduction || !audit_background || !final_opinion) {
      setIntroductionError(!introduction ? true : false)
      setBackgroundError(!audit_background ? true : false)
      setFinalOpinionError(!final_opinion ? true : false)
      setErrors("Please fill in all required fields.");
      setTimeout(() => {
        setErrors(null);
      }, 5000);
      return;
    }

    e.preventDefault();
    updateData(
      `/api/audit_engagement/audit/${id}`,
      {
        risk_assessment: auditData?.risk_assessment?.id,
        audit_entity_id: auditData?.audit_entity?.id,
        auditors_id: auditData?.auditors.map((auditor) => auditor.id),
        business_users_id: auditData?.business_users.map(
          (business_users) => business_users.id
        ),
        actual_start_date: auditData?.actual_start_date,
        actual_end_date: auditData?.actual_end_date,
        planned_start_date: auditData?.audit_event?.planned_start_date,
        planned_end_date: auditData?.audit_event?.planned_end_date,
        audit_scope: auditData?.audit_scope,
        audit_phases: auditData?.audit_phases,
        other_relevant_information: auditData?.other_relevant_information,
        introduction,
        audit_background,
        final_opinion
      },
      setErrors,
    );
  };

  const generateReportData = (e) => {
    if (!auditData?.final_opinion || !auditData?.introduction || !auditData?.audit_background) {
      return setErrors("please fill the information");
    }
    e.preventDefault();
    postData(`/api/audit_engagement/generate-pdf/${id}/`);

  }

  const handlePopupClose = (task) => {
    console.log(task);

    updateData(
      `/api/system/task/${task?.task?.id}`,
      {
        title: task.objective,
        status: "done",
      },
      navigate,
      setErrors,
      `/AuditExecution/${id}/Reporting`
    );
    setAPIData((prevAPIData) =>
      prevAPIData.map((task) => {
        if (task.id === selectedTaskId) {
          return {
            ...task,
            task: {
              ...task.task,
              status: "Done",
            },
          };
        }
        return task;
      })
    );
    window.location.reload();

    setShowPopup(false);
  };

  const handlePopupCloseNo = () => {
    setShowPopup(false);
  };

  const viewObservation = (obejctionId) => {
    console.log("hello ")
  };

  const renderAuditObservationStatus = (status) => {
    console.log("i am here ")
  };


  const userRoles = localStorage.getItem("userRoles");

  const Roles = userRoles.includes("Admin") && userRoles.includes("Admin");


  const navToReportPage = (navTo) => {
    switch (navTo) {
      case "Initial Observation":
        navigate(`/AuditExecution/${id}/Reporting/InitialObservation`)
        break;

      case "QA Approval For Initial Observation":
        navigate(`/AuditExecution/${id}/Reporting/QAApprovalInitialObservation`)
        break;

      case "Schedule Closing Meeting":
        navigate(`/AuditExecution/${id}/Reporting/ScheduleClosingMeeting`)
        break;

      case "Business Owner Approval":
        navigate(`/AuditExecution/${id}/Reporting/BusinessOwnerApproval`)
        break;

      case "Finalize Observation":
        navigate(`/AuditExecution/${id}/Reporting/FinalizeObservation`)
        break;

      case "Generate Report":
        navigate(`/AuditExecution/${id}/Reporting/GenerateReport`)
        break;

      case "QA Approval For Finalize  Observation":
        navigate(`/AuditExecution/${id}/Reporting/qaAPprovalFinalizeObservation`)
        break;

      case "Audit Director Approval For Finalize Observation":
        navigate(`/AuditExecution/${id}/Reporting/AuditDirectorApproval`)
        break;

      case "Publish Report":
        navigate(`/AuditExecution/${id}/Reporting/PublishReport`)
        break;

      default:
        return "Invalid Task";
    }
  };


  const moreDetails = () => {
    console.log("i ahahahaha")
    navigate(`/AuditExecution/${id}/Reporting/GenerateReportList`)
  }


  return (
    <>
      {errors && <p className="errormessage">{errors}</p>}

      {successMessage && <p className="successmessage">{successMessage}</p>}
      {showPopup && (
        <div className="popupATP">
          <div className="subpopupATP">
            <p>Do you want the task to be done?</p>
            <div className="subpopupBtnDiv">
              <button onClick={() => handlePopupClose(task)}>Yes</button>
              <button onClick={handlePopupCloseNo}>No</button>
            </div>
          </div>
        </div>
      )}
      <section className="navroutes">
      <RenderIcons homeRoutes={auditExecution} id ={id}/>

        <div className="filter-container">
          <div className="filter">

          <Breadcrumb />
<div style={{display: 'flex'}}>
            <div className="searchncbtn">
              <input type="text" placeholder="Search" className="searchbar" />
              <span className="auditstatusphase">
                <p>{auditData.phase}</p>
              </span>
            </div>
            <div className="gen-rep-btn-div" ><button onClick={generateReportData}>Generate Report</button></div>
</div>
          </div>
        </div>



        <div className="gen-rep-div">

          <div className="btnToggle"><button onClick={() => moreDetails()} >List</button><span>|</span><button className="detail" >Finalized Details</button></div>
        </div>
        <div className="moreDetailContainer" style={{width:tableSize?"80vw":"90.5vw"}}>
        <form>
          <h2>More Details</h2>

          
          <div className="lbl_txa">
            <label htmlFor="">Introduction</label>
            <textarea style={{ border: introductionerror ? "1px solid #FF4646" : "", }} value={introduction} onChange={(e) => {
              setIntroduction(e.target.value)
              setIntroductionError(false)
            }} placeholder="Introduction"></textarea>
          </div>
          <div className="lbl_txa">
            <label htmlFor="">Background</label>
            <textarea style={{ border: audit_backgrounderror ? "1px solid #FF4646" : "", }} value={audit_background} onChange={(e) => { 
            setBackground(e.target.value);
             setBackgroundError(false) }} placeholder="background"></textarea>
          </div>
          <div className="lbl_txa">
            <label htmlFor="">Final Opinion</label>
            <textarea  style={{border: final_opinionerror ? "1px solid #FF4646" : "",}}value={final_opinion} onChange={(e) => {setFinalOpinion(e.target.value);
              setFinalOpinionError(false)
            }} placeholder="final Opinion"></textarea>
          </div>
          <div style={{display:'flex',justifyContent:'space-between',alignItems:'center'}}>
          <div className="heat_map_div">
            <img src={heatmap} />
          </div>
          <div className="uploaded_files_section_inner" style={{backgroundColor:'#EEF1FC',padding:'.5vw',height:'15vh',width:'30vw'}}>
              {reportData.map((file, index) => {
                const fileName = file?.filename || "Unnamed File";
                const fileExtension = fileName.split(".").pop();
                const fileId = file?.id; // Ensure this matches your data structure

                if (!fileId) {
                  console.error("File ID is missing for:", fileName);
                  return null; // Skip rendering if ID is missing
                }

                return (
                  <div className="fm_selected_file" key={index}>
                    <img
                      src={cut_button}
                      className="deleteFile"
                      // onClick={() =>
                      //   handleDeleteModalOpen(file.associated_with_id)
                      // }
                      alt="Delete"
                    />

                    <div className="extension_section">
                      <h5>.{fileExtension}</h5>
                    </div>
                    <a
                    style={{marginLeft:'1.5vw'}}
                      href={`${process.env.REACT_APP_API_URL}/api/system/documents/${fileId}/view`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <h6>{fileName}</h6>
                    </a>
                  </div>
                );
              })}
            </div>
            </div>
          <div className="save-GRMD"><button onClick={(e) => updateMoreDetails(e)}>save</button></div>
          </form>
        </div>

        {/* <div className="cardscontainer">
                    {filteredData.map((data) => (
                        <div className="aecard" >
                            <h1>{data.name} </h1>

                            <img src={openarrow} />
                        </div>
                    ))}
                </div> */}
      </section>
    </>
  );
};

export default GenerateReportMoreDetails;
