import React, { useState, useRef, useEffect } from "react";
import add_button from "../../images/add_button.svg";
import deleteQuestion from "../../images/delete_wbg.svg";
import saveQuestion from "../../images/confirm.svg";
import { justDeleteData, justPostData, fetchData, justUpdateData, postDataWithFetch, deleteData, updateData, updateData2, deleteDataWithFetch, updatewithFetchData, deleteData2 } from "../apiUtils";
import { useNavigate, useLocation, useParams } from "react-router-dom";

function YourComponent({tableSize}) {
  const location = useLocation();
  const [inputs, setInputs] = useState({});
  const [trackId, setTrackId] = useState([]);
  const [errors, setErrors] = useState("");
  const navigate = useNavigate();

  const [showButton, setShowButton] = useState(false);
  const [inputValues, setInputValues] = useState({});
  const [editingInputId, setEditingInputId] = useState(null);

  const inputRefs = useRef([]);

  const [sectionsData, setSectionsData] = useState([]);
  const [questionsData, setQuestionsData] = useState([]);
  const [users, setUsers] = useState([]);
  const [sectionErrors, setSectionErrors] = useState("");
  const [questionErrors, setQuestionErrors] = useState("");
  const [initialQuestions, setInitialQuestions] = useState([]);
  const [inputTypes, setInputTypes] = useState({});
  const { id } = useParams();



  useEffect(() => {

    setSectionsData([
      {
        "id": 1,
        "section_name": "PLANNING THE AUDIT",
      },
      {
        "id": 2,
        "section_name": "CONDUCTING THE AUDIT",

      },
      {
        "id": 3,
        "section_name": "REPORTING AUDIT RESULTS",

      },
      {
        "id": 4,
        "section_name": "QUALITY OF INTERNAL AUDIT",

      },
      {
        "id": 5,
        "section_name": "OTHER SERVICES PROVIDED BY INTERNAL AUDIT",

      },
      {
        "id": 6,
        "section_name": "Open-ended Questions",

      }
    ]);

    fetchData(
      `/api/audit_engagement/qa-survey/template/`,
      setQuestionsData,
      setQuestionErrors
    );
  }, [location.state?.data]);

  useEffect(() => {
    const initialQuestions1 = sectionsData.map((section) => ({
      section: section.section_name,
      id: section.id,
      questions: questionsData
        .filter((question) => question.section_id === section.id)
        .map((question) => ({
          id: question.id,
          text: question.question,
        })),
    }));
    setInitialQuestions(initialQuestions1);
  }, [sectionsData, questionsData]);


  const addInput = (sectionId, inputId) => {
    const newId = Date.now();
    setInputs({
      ...inputs,
      [sectionId]: [
        ...(inputs[sectionId] || []),
        { id: newId, editing: true, value: "" },
      ],
    });
    setEditingInputId(newId);
  };

  const handleTypeChange = (sectionId, inputId, value) => {
    setInputTypes({
      ...inputTypes,
      [sectionId]: {
        ...inputTypes[sectionId],
        [inputId]: value,
      },
    });
  };

  const removeInput = (sectionId, inputId) => {
    // Update the inputs state, removing the input with the given inputId
    setInputs((prevInputs) => ({
      ...prevInputs,
      [sectionId]: prevInputs[sectionId].filter((input) => input.id !== inputId),
    }));
  
    // Update the inputRefs, removing the reference for the given inputId
    inputRefs.current = inputRefs.current.filter(
      (_, index) => index !== inputId // Assuming inputId refers to the index in inputRefs
    );
  };
  

  const removeQuestion =  (inputId, sectionId) => {
    console.log('testagdklfjasldfjlasdfjlka')
     deleteData(
        `/api/audit_engagement/qa-survey/template/${inputId}`,
        navigate,
        setErrors,
        `/AuditExecution/QaSurvey/`
      );
  
      
  };
  

  const handleInputChange = (sectionId, inputId, value) => {
    setInputValues({
      ...inputValues,
      [sectionId]: {
        ...inputValues[sectionId],
        [inputId]: value,
      },
    });
  };

  const handleEdit = (inputId) => {
    setEditingInputId(inputId);
  };

  const handshowHideButton = (inputId, lastQuestionIndex) => {


    // Check if the input ID is present in the trackId array
    if (trackId.includes(lastQuestionIndex)) {

      // Remove the input ID from the trackId array
      const updatedTrackId = trackId.filter((id) => id !== lastQuestionIndex);
      setTrackId(updatedTrackId, () => {
        // Callback function to ensure the button is shown after updating trackId
        setShowButton(true);
      });
    } else {
      console.log("ID does not exist in trackId array");

      // Hide the button
      setShowButton(false);
    }
  };

  const handleSave = (sectionId, inputId) => {
    setShowButton(false);


    const sectionData = sectionsData.find((section) => section.id === sectionId);

    postDataWithFetch(
      `/api/audit_engagement/qa-survey/template/`,
      {
        question: inputValues[sectionId]?.[inputId],
        section_id: sectionId,  // Section ID
        section: sectionData ? sectionData.section_name : "",
        answer: inputTypes[sectionId]?.[inputId] || "text",
      },
      !navigate,
      setErrors,
      {
        fetchurl1: `/api/audit_engagement/qa-survey/template/`,
        Dataset1 :setQuestionsData,
      },
    );

    setInputs('');
    setInputValues('');

    // window.location.reload();
  };


  const saveInputValue = (sectionId, inputId) => {
    if (!trackId.includes(inputId)) {
      setTrackId([...trackId, inputId]);
    }
    if (inputValues[sectionId]?.[inputId] !== undefined) {
      handleSave(sectionId, inputId);
    }
    setShowButton(true);
  };

  const updateInputValue =  (sectionId, inputId) => {

     updatewithFetchData(
        `/api/audit_engagement/qa-survey/template/${inputId}`, {
        question: inputValues[sectionId]?.[inputId],
      },
        navigate,
        setErrors,
        {
          fetchurl1: `/api/audit_engagement/qa-survey/template/`,
          Dataset1 :setQuestionsData,
        }
      );

      // setInputValues('');
      setEditingInputId('')
      // setInputs('');
      console.log("inputValues : " , inputValues)
      // setEditingInputId('')
      // setInputValues({})
  };
 

  return (

    <>
  {initialQuestions.map((section) => (
  <div className="section" key={section.id} style={{ width: tableSize ? "72vw" : "85vw" }}>
    <div className="section_top"   style={{width: tableSize ? "63vw" : "75vw"}}>
      <input   
        type="text"
        placeholder="Section"
        value={section.section}
        style={{width: tableSize ? "63vw" : "75vw"}}
        readOnly
      />
    </div>
    <div className="questions_con">
      {questionsData
          .filter((question) => question.section === section.section)
          .map((question) => (
            <div key={question.id} className="question">
              <input   
                type="text"
                placeholder="Question"
                value={
                  editingInputId === question.id
                    ? inputValues[section.id]?.[question.id] || question.question
                    : inputValues[section.id]?.[question.id]
                      ? inputValues[section.id]?.[question.id]
                      : question.question
                }
                readOnly={editingInputId !== question.id}
                onClick={() => handleEdit(question.id)}
                onChange={(e) =>
                  handleInputChange(section.id, question.id, e.target.value)
                }
                //onBlur={() => updateInputValue(section.id, question.id)}
                style={{
                  border:
                    editingInputId === question.id
                      ? "2px solid #BECBF9"
                      : "none",
                      width: tableSize ? "63vw" : "75vw"
                }}
              />
              {editingInputId === question.id && (
                <>
                  <div className="buttonCQ">
                    <button
                      onClick={() => updateInputValue(section.id, question.id)}
                    >
                      <img src={saveQuestion} className="saveCQ" />
                    </button>
                    <button
                      onClick={() => {
                        removeQuestion(question.id);
                      }}
                    >
                      <img src={deleteQuestion} className="deleteCQ" />
                    </button>
                  </div>
                </>
              )}
            </div>
          ))
      }
      {inputs[section.id]?.map((input, index) => (
        <div key={input.id} className="questionCQ">
        
          {section.id === 6 ? (
            <textarea
            style={{  width: tableSize ? "63vw" : "75vw",backgroundColor:'#eef1fc',height:'10vh',borderRadius:'5px'}}
              value={inputValues[section.id]?.[input.id] || ""}
              onChange={(e) => {
                handleInputChange(section.id, input.id, e.target.value);
                handshowHideButton(section.id, input.id);
              }}
              onClick={() => handshowHideButton(section.id, input.id)}
              onFocus={() => handshowHideButton(section.id, input.id)}
              autoFocus
            />

          ) : (
            <input
            style={{width: tableSize ? "51.7vw" : "62.5vw"}}
              type="text"
              value={inputValues[section.id]?.[input.id] || ""}
              onChange={(e) => {
                handleInputChange(section.id, input.id, e.target.value);
                handshowHideButton(section.id, input.id);
              }}
              onClick={() => handshowHideButton(section.id, input.id)}
              onFocus={() => handshowHideButton(section.id, input.id)}
              autoFocus
            />
          )}
         <div className="questionCQ" style={{height:'5vh',alignSelf:'center'}}>
      <select className="custom-select"
        onChange={(e) => handleTypeChange(section.id, input.id, e.target.value)} 
      >
         <option value=" ">--Select--</option>
        <option value="checkbox">Checkbox</option>
        <option value="text">Text</option>
        <option value="boolean">Boolean</option>
      </select>
    </div>
       
          {!showButton && !trackId.includes(input.id) && (
            <>
              <div className="buttonCQ">
                {inputValues[section.id]?.[input.id] && (
                  <button
                    onClick={() => saveInputValue(section.id, input.id)}
                  >
                    <img src={saveQuestion} className="saveCQ" />
                  </button>
                )}
                <button onClick={() => removeInput(section.id, input.id)}>
                  <img src={deleteQuestion} className="deleteCQ" />
                </button>
              </div>
            </>
          )}
        </div>
      ))}
    </div>
    <div className="add_question_con"   style={{width: tableSize ? "63vw" : "75vw"}}>
      <button onClick={() => addInput(section.id, inputs.id)}>
        <img src={add_button} alt="Add" /> Add Question
      </button>
    </div>
  </div>
))}

    </>
  );
}

export default YourComponent;
