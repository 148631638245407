import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import breadIcon from "../images/breadIcon.svg";

const Breadcrumb = () => {
  const location = useLocation();
  const [breadcrumbs, setBreadcrumbs] = useState([]);

  const segmentMapping = {
    administration: "Administration",
    user: "Users",
    departments : "Departments",
    businessunit:"Business Units",
    teams:"Teams",
    corporateStructure:"Corporate Structure",
    riskAssessmentTable: "Risk Assessment",
    graphview : "Graph View",
    auditPlanning : "Audit Planning",
    auditUniverse: "Audit Universe",
    auditEntities: "Audit Entities",
    createEntity: "Create Entity",
    Rcm : "RCM",
    AllRcm : "All RCM",
    AuditExecution : "Audit Execution",
    Detail : "Detail",
    FileManager : "File Manager",
    Planning : "Planning",
    RiskControlMatrix : "Risk Control Matrix",
    Fieldwork: "FieldWork",
    Reporting : "Reporting",
    InitialObservation : "Initial Observation",
    QAApprovalInitialObservation : "Q.A Approval Initial Observation",
    ScheduleClosingMeeting: "Schedule Closing Meeting",
    BusinessOwnerApproval: "Business Owner Approval",
    FinalizeObservation : "Finalize Observation",
    GenerateReportList : "Generate Report List",
    qaAPprovalFinalizeObservation : "Q.A Approval Finalize Observation",
    AuditDirectorApproval : "Audit Director Approval",
    PublishReport : "Publish Report",
    WrapupQA: "Wrapup Q.A",
    CreateWorkpaper : "Create Workpaper",
    QASurvey : "Q.A Survey",
    QAChecklist : "Q.A Checklist",
    issuetracking : "Issue Tracking",
    Analytics : "Analytics",
    issuetracking : "Issue Tracking",
    issues : "Issues",
    createissue : "Create Issue"
  };
  useEffect(() => {
    const formatSegment = (segment) => {
      // Convert both segment and mapping keys to lowercase for comparison
      const normalizedSegment = segment.toLowerCase();

      // Find the matching key in segmentMapping by comparing in lowercase
      const matchingKey = Object.keys(segmentMapping).find(
        (key) => key.toLowerCase() === normalizedSegment
      );

      // Use the matched value from mapping, or fallback to formatted segment
      if (matchingKey) {
        return segmentMapping[matchingKey];
      }

      // Default formatting (capitalize, add spaces, etc.)
      const formattedSegment = segment
        .replace(/([a-z])([A-Z])/g, "$1 $2")
        .replace(/\./g, ". ")
        .replace(/\s+/g, " ")
        .toLowerCase()
        .split(" ")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");

      return formattedSegment;
    };

    const pathSegments = location.pathname
      .split("/")
      .filter((segment) => segment !== "")
      .map((segment, index, segments) => {
        const fullPath = `/${segments.slice(0, index + 1).join("/")}`;
        return {
          segment: formatSegment(segment),
          fullPath,
        };
      });

    setBreadcrumbs(pathSegments);
  }, [location.pathname]);

  return (
    <div className="breadcrumb">
      {breadcrumbs.map(({ segment, fullPath }, index) => (
        <span key={segment}>
          {index > 0}
          {index === breadcrumbs.length - 1 ? (
            <strong>{segment}</strong>
          ) : (
            <Link to={fullPath} style={{ color: "black" }}>
              {segment}
            </Link>
          )}
          {index < breadcrumbs.length - 1 && (
            <img src={breadIcon} alt="Breadcrumb Icon" />
          )}
        </span>
      ))}
    </div>
  );
};

export default Breadcrumb;
