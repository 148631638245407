import React, { useRef } from "react";
import axios from "axios";
import MultiSelect from "./MultiSelect";
import MultiSelectView from "./MultiSelectView";

import { useState, useEffect } from "react";
import { auditExecution } from "../../subroutes";
import {
  useLocation,
  useNavigate,
  useParams,
  NavLink,
  Link,

} from "react-router-dom";
import {
  fetchData,
  fetchDataObject,
  postData,
  updateData,
  deleteData,
  deleteData2,
  fetchFile,
  justPostData,
  updateData2,
} from "../../apiUtils";
import SidebarMenu from "../../SidebarMenu";
import Breadcrumb from "../../Breadcrumb";
import IconButton from "../../iconbutton/IconButton";
import SingleOptionSelector from "../../SingleOptionSelector";
import WindowComponent from "../../WindowComponent";
import RenderIcons from "../../RenderIcons";
import EditRow from "../../../images/edit_pencil.svg";
import closeBtn from "../../../images/closeicon.svg";
import cutbutton from "../../../images/cutbutton.svg";
import createbutton from "../../../images/createbutton.svg";
import create_plus from "../../../images/create_plus.svg";
import SaveRow from "../../../images/saveBtn.svg";
import backlogo from "../../../images/backLogo.svg";
import deleteBtn from "../../../images/deleteBtn.svg";
import sendComment from "../../../images/send.svg";
import SuperAvatar from "../../SuperAvatar";
import SaveBtn from "../../../images/saveBtn.svg"
import arrowFlip from "../../../images/arrow-left.svg"
import BounceLoader from "react-spinners/BounceLoader";

const avaUserName = localStorage.getItem("userName");
const lastName = localStorage.getItem("userLastName");

const ViewAuditFieldwork = ({ tableSize }) => {
  const [options, setOptions] = useState([]);

  const [errors, setErrors] = useState("");
  const [objective, setObjective] = useState("");
  const [APITestData, setAPITestData] = useState([]);
  const [APIEvidenceData, setAPIEvidenceData] = useState([]);
  const [APIData, setAPIData] = useState([]);
  const [loading, setLoading] = useState(false);

  const [nonEdit, setNonEdit] = useState(true);

  const [risk_id, setRiskId] = useState("");
  const [entityId, setEntityId] = useState("");

  const [risk_category, setRiskCategory] = useState("");
  const [risk_description, setDescribeRisk] = useState("");
  const [risk_title, setTitleRisk] = useState("");

  const [inherent_assessment_likelihood, setLikelikhood] = useState("");
  const [inherent_assessment_impact, setImpact] = useState("");
  const [inherent_risk_evaluation, setIRE] = useState("");
  const [inherent_risk_score, setIRS] = useState("");

  const [control_existing, setExistingControl] = useState("");
  const [control_desired, setDesiredControl] = useState("");
  const [control_evaluation, setEvaluation] = useState("");
  const [control_design, setControlDesign] = useState("");
  const [control_type, setcontrolType] = useState("");
  const [control_frequency, setcontrolFrequency] = useState("");
  const [control_gap, setControlGap] = useState("");
  const [residual_assessment_likelihood, setResLikelihood] = useState("");
  const [residual_assessment_impact, setResImpact] = useState("");
  const [residual_risk_score, setRRS] = useState("");
  const [residual_risk_evaluation, setRRE] = useState("");
  const [APIQData, setAPIQData] = useState("");
  const [taskData, setTaskData] = useState([]);

  const [inputValue, setInputValue] = useState("");
  const [comments, setComments] = useState([]);

  const [test_case, setTestCase] = useState("");
  const [test_case1, setTestCase1] = useState("");

  const [sample_rationale, setSampleRationale] = useState("");
  const [sample_rationaleError, setSampleRationaleError] = useState(false);

  const [record_work_done, setRecordWorkDone] = useState("");
  const [record_work_doneError, setRecordWorkDoneError] = useState(false);

  const [sample_size, setSampleSize] = useState("");
  const [sample_size1, setSampleSize1] = useState("");

  const [sample_sizeError, setSampleSizeError] = useState(false);

  const [note, setNote] = useState("");
  const [note1, setNote1] = useState("");


  const [workpaper_reference, setWorkpaperReference] = useState("");
  const [workpaper_referenceError, setWorkpaperReferenceError] =
    useState(false);

    const [preliminaryError, setPreliminaryError] =
    useState(false);

  const [testing_status, setTestingStatus] = useState("");
  const [testing_statusError, setTestingStatusError] = useState(false);

  const [step, setStep] = useState("");
  const [step1, setStep1] = useState("");

  const [audit_procedure_data, setDataRequired] = useState("");

  const [likelikhoodError, setLikelikhoodError] = useState(false);
  const [impactError, setImpactError] = useState(false);
  const [IRSError, setIRSError] = useState(false);
  const [IREError, setIREError] = useState(false);
  const [existingControlError, setExistingControlError] = useState(false);
  const [desiredControlError, setDesiredControlError] = useState(false);
  const [evaluationError, setEvaluationError] = useState(false);
  const [controlDesignError, setControlDesignError] = useState(false);
  const [controlTypeError, setcontrolTypeError] = useState(false);
  const [controlFrequencyError, setcontrolFrequencyError] = useState(false);
  const [controlGapError, setControlGapError] = useState(false);
  const [resLikelihoodError, setResLikelihoodError] = useState(false);
  const [resImpactError, setResImpactError] = useState(false);
  const [RRS, setRRSError] = useState(false);
  const [RRE, setRREError] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isTestCreateOpen, setIsTestCreateOpen] = useState(false);
  const [statusValues, setStatusValues] = useState(
    APITestData.map((data) => {
      return data.status || "";
    })
  );

  const [statusVal, setStatusVal] = useState("");

  const [editMode, setEditMode] = useState(false);

  const [auditStepDetailsError, setAuditStepDetailsError] = useState(false);
  const [dataRequiredError, setDataRequiredError] = useState(false);

  const [objectiveError, setObjectiveError] = useState(false);
  const [riskIdError, setRiskIdError] = useState(false);
  const [riskCategoryError, setRiskCategoryError] = useState(false);
  const [describeRiskError, setDescribeRiskError] = useState(false);
  const [titleRiskError, setTitleRiskError] = useState(false);

  const [preparer_id, setPreparerId] = useState("");
  const [PreparerError, setPreparerError] = useState(false);
  const [reviewer_id, setReviewerId] = useState("");
  const [assign_to_id, setAssign_to_id] = useState("");
  const [reviewerError, setReviewerError] = useState(false);
  const [assign_to_idError, setAssign_to_idError] = useState(false);
  const [showTest, setShowTest] = useState(false);
  const [data, setData] = useState("");

  const location = useLocation();
  const navigate = useNavigate();
  const successMessageColor = location.state?.successMessageColor;
  const successMessage = location.state?.successMessage;
  const [showMessage, setShowMessage] = useState(!!successMessage);
  const originalUrl = location.state?.from;
  const { id, planid, tempId, workId } = useParams();

  const fullUrl = window.location.origin + location.pathname;
  const segments = location.pathname.split("/");
  const auditUniverse = segments.find((segment) => segment === "auditUniverse");

  const [activeTab, setActiveTab] = useState(true);
  const [transitioning, setTransitioning] = useState(false);
  const [file, setFile] = useState(null);
  const [uploadStatus, setUploadStatus] = useState("idle");
  const [uploadProgress, setUploadProgress] = useState(0);
  const [isInputVisible, setIsInputVisible] = useState(true);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      navigate(".", { replace: true });
    }, 5000);






    return () => clearTimeout(timeoutId);
  }, []);

  useEffect(() => {
    if (errors) {
      const timer = setTimeout(() => {
        setErrors("");
      }, 5000); // Hides the error after 10 seconds (10000ms)

      return () => clearTimeout(timer); // Cleanup the timer on component unmount or when errors change
    }
  }, [errors]);

  useEffect(() => {
    fetchDataObject(
      `/api/audit_engagement/workpaper/${workId}/`,
      setAPIData,
      setErrors,
      setLoading
    );
  }, []);

  const [taskErrors, setTaskErrors] = useState("");

  useEffect(() => {
    if (APIData) {
      fetchData(
        `/api/system/comments/?task_id=${APIData.task?.id}`,
        setTaskData,
        setTaskErrors
      );
    }
  }, [APIData]);

  useEffect(() => {
    fetchData(
      `/api/audit_engagement/workpapertestcase/?workpaper_id=${workId}`,
      setAPITestData,
      setErrors
    );
  }, []);

  useEffect(() => {
    fetchData(
      `/api/audit_engagement/auditfile/?audit_id=${id}`,
      setAPIEvidenceData,
      setErrors
    );
  }, []);

  useEffect(() => {
    if (APIEvidenceData.length > 0) {
      const formattedOptions = APIEvidenceData.map((item) => ({
        id: item.id, // assuming APIEvidenceData contains an 'id' field
        name: item.filename, // using 'title' as the 'name' for display
      }));
      setOptions(formattedOptions);
    }
  }, [APIEvidenceData]);

  const [titlePage, setTitlePage] = useState("");

  useEffect(() => {
    setObjective(APIData.objective);
    setTitlePage(APIData?.objective);
    setAPIQData(APIData.task?.id);
    setRiskId(APIData.risk_id);
    setRiskCategory(APIData.risk_category);
    setDescribeRisk(APIData.risk_description);
    setTitleRisk(APIData.risk_title);
    setLikelikhood(APIData.inherent_assessment_likelihood);
    setImpact(APIData.inherent_assessment_impact);
    setIRE(APIData.risk_inherant_evaluation);
    setIRS(APIData.risk_inherant_evaluation_score);
    setExistingControl(APIData.control_existing);
    setDesiredControl(APIData.control_desired);
    setEvaluation(APIData.control_evaluation);
    setControlDesign(APIData.control_design);
    setcontrolType(APIData.control_type);
    setcontrolFrequency(APIData.control_frequency);
    setControlGap(APIData.control_gap);
    setResLikelihood(APIData.residual_assessment_likelihood);
    setResImpact(APIData.residual_assessment_impact);
    setRRS();
    setRRE();
    setTestCase();
    setRecordWorkDone();
    setSampleSize();
    setNote();
    setTestingStatus();
    setStep();
    setDataRequired();
  }, [APIData]);

  function impactmapping(value) {
    switch (value) {
      case 1:
        return "Low (1)";
      case 2:
        return "Minor (2)";
      case 3:
        return "Moderate (3)";
      case 4:
        return "Major (4)";
      case 5:
        return "Severe (5)";
      default:
        return "";
    }
  }
  function likemapping(value) {
    switch (value) {
      case 1:
        return "Remote (1)";
      case 2:
        return "Unlikely (2)";
      case 3:
        return "Possible (3)";
      case 4:
        return "Likely (4)";
      case 5:
        return "Almost Certain (5)";
      default:
        return "";
    }
  }

  const [urlNav, setUrlNav] = useState("");

  useEffect(() => {
    if (entityId) {
      setUrlNav(`/AuditExecution/${id}/Planning/${planid}/${tempId}/`);
    } else {
      setUrlNav(`/auditUniverse/auditEntities/${id}/${tempId}/`);
    }
  }, [entityId]);

  const handleTestCreate = () => {
    setIsTestCreateOpen(true);
  };

  const handleTestClose = () => {
    setIsTestCreateOpen(false);
  };
  const handleTestDelete = (Id) => {
    deleteData(
      `/api/audit_engagement/workpapertestcase/${Id}`,
      navigate,
      setErrors,
      `/AuditExecution/${id}/Fieldwork/${workId}`
    );
    window.location.reload();
  };

  // const handleEditClick = () => {
  //   setEditMode(!editMode);
  // };

  const onDelete = (e) => {
    e.preventDefault();
    deleteData2(
      `/api/audit_engagement/workpaper/${workId}`,
      navigate,
      setErrors,
      `/AuditExecution/${id}/Fieldwork`
    );
  };

  const handleCloseClick = () => {
    setIsModalOpen(false);
  };

  const handleTabToggle = () => {
    setActiveTab(!activeTab);
  };

  const onDeleteFile = (fileId) => {
    deleteData(
      `/api/audit_engagement/workpaperevidence/${fileId}`,
      navigate,
      setErrors,
      `/AuditExecution/${id}/Fieldwork/${workId}`
    );
    window.location.reload();
  };


  const postTest = () => {
    // if (statusVal === "Failed" && !note1) {
    //   setWorkpaperReferenceError(true);
    //   setErrors("Preliminary Observation Is Required")
    //   setTimeout(() => {
    //     setErrors(null);
    //   }, 5000);
    //   return;
      
    // }
    postData(
      `/api/audit_engagement/workpapertestcase/`,
      {
        workpaper_id: workId,
        step: step1,
        test_case: test_case1,
        sample_size: sample_size1,
        note: note1,
        status: statusVal,
        data: {
          files: selectedOptions,
        },
      },
      navigate,
      setErrors,
      `/AuditExecution/${id}/Fieldwork/${workId}`
    );
    setShowTest(false);
  //  window.location.reload();
  };

  const updateapi = (e) => {
    e.preventDefault();
    if (!preparer_id || !reviewer_id || !assign_to_id) {
      setPreparerError(!preparer_id ? true : false);
      setReviewerError(!reviewer_id ? true : false);
      setAssign_to_idError(!assign_to_id ? true : false);
      setErrors("Please fill in all required fields.");

      setTimeout(() => {
        setErrors(null);
      }, 5000);
      return;
    }
    updateData(
      `/api/audit_engagement/workpaper/${workId}`,
      {
        audit_id: id,
        objective,
        preparer_id,
        reviewer_id,
        assign_to_id,
      },
      navigate,
      setErrors,
      `/AuditExecution/${id}/Fieldwork/${workId}`
    );
  };
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectedOpt, setSelectedOpt] = useState([]);

  const [editingRow, setEditingRow] = useState(null);
  const [editValues, setEditValues] = useState({});

  const handleMultiSelectChange = (index, selected) => {
    // Log the current index and selected value
    console.log("Index:", index, "Selected:", selected);

    // Update selected options state
    setSelectedOptions((prev) => {
      const newState = {
        ...prev,
        [index]: selected,
      };

      // Log the new state before setting it
      console.log("New selected options state:", newState);
      return newState;
    });

    // Set selectedOpt and log it
    setSelectedOpt(selected);
    console.log("Selected Opt:", selected);
  };

  const handleMultiSelectChange1 = (selected) => {
    // Log the current index and selected value
    console.log("Selected:", selected);

    // Update selected options state
    setSelectedOptions((prev) => {
      const newState = {
        ...prev,
        selected,
      };

      // Log the new state before setting it
      console.log("New selected options state:", newState);
      return newState;
    });

    // Set selectedOpt and log it
    setSelectedOpt(selected);
    console.log("Selected Opt:", selected);
  };

  const handleEditSave = () => {
    setEditMode(!editMode);
  };
  const handleEditClick = (index, data) => {
    setEditingRow(index);
    setEditValues({
      step: data?.step || "",
      sample_size: data?.sample_size || "",
      note: data?.note || "",
      status: data?.pass,
      workpaper_id: workId,
    });
  };

  const handleInputChange = (e, field) => {
    setEditValues({
      ...editValues,
      [field]: e.target.value,
    });
  };
  const handleSaveClick = (testId) => {
 
    setEditValues({
      ...editValues,
      workpaper_id: workId,
    });

    const payload = editValues;
    console.log("Edit values", editValues);
    updateData(
      `/api/audit_engagement/workpapertestcase/${testId}`,
      {
        workpaper_id: payload.workpaper_id,
        step: payload.step,
        test_case: payload.test_case,
        sample_size: payload.sample_size,
        note: payload.note,
        status: payload.pass,
        data: {
          files: selectedOptions,
        },
      },
      setErrors,
    );
  
  };

  const handleSaveClick1 = (data) => {

    updateData(
      `/api/audit_engagement/workpapertestcase/${data.id}`,
      {
        workpaper_id: data.workpaper_id,
        step: data.step,
        test_case: data.test_case,
        sample_size: data.sample_size,
        note: data.note,
        status: data.pass,
        data: {
          files: selectedOptions,
        },
      },
      setErrors
    );
  };

  const handleCancelClickField = () => {
    setEditMode(false);
    window.location.reload();
  };

  const handleCancelClick = () => {
    setEditingRow(null);
    setEditValues({});
  };

  


  useEffect(() => {
    // Function to handle clicks outside the editing row
    const handleClickOutside = (event) => {
      if (editingRow !== null) {
        // Check if click is outside the editing row
        const rowElement = document.querySelector(
          `tr[data-index="${editingRow}"]`
        );
        if (rowElement && !rowElement.contains(event.target)) {
          handleCancelClick(); // Call your function to close edit
        }
      }
    };

    // Add event listener to the document
    document.addEventListener("mousedown", handleClickOutside);

    // Clean up the event listener when component unmounts or editing row changes
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [editingRow]); // Dependency array includes editingRowIndex

  const [isCommentVisible, setIsCommentVisible] = useState(false);

  
  const toggleCommentVisibility = () => {
    setIsCommentVisible(!isCommentVisible);
  }; 


  const daysSince = (dateString) => {
    const date = new Date(dateString);
    const now = new Date();
    const timeDiff = now - date;
    const daysDiff = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
    if (daysDiff <= 0) return "today";
    return daysDiff === Number.NaN ? 0 : "" + " days ago";
  };

  const handleAddComment = () => {
    if (inputValue.trim()) {
      const currentDateTime = new Date();
      const formattedDate = currentDateTime.toLocaleDateString("en-GB", {
        day: "2-digit",
        month: "short",
        year: "2-digit",
      });
      const formattedTime = currentDateTime.toLocaleTimeString("en-GB", {
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      });

      const newComment = {
        text: inputValue,
        date: formattedDate,
        time: formattedTime,
      };

      setComments([...comments, newComment]);
      setTaskData([...taskData, newComment]);

      justPostData(
        `/api/system/comments/`,
        {
          user_id: localStorage.getItem("user_id"),
          task_id: APIData.task?.id,
          text: inputValue,
        },
        navigate,
        setErrors
      );
      // window.location.reload();
      setInputValue("");
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleAddComment();
    }
  };
  const handleInputChange1 = (event) => {
    setInputValue(event.target.value);
  };

  // Handler for dropdown change
  const handleChange = (index, event, testId, noteData) => {  

    
    if(event.target.value === "Failed" && !noteData){
      console.log("editValues : ", noteData)
      setErrors("Please Fill Preliminary Observation ");
      return false;
      // setPreliminaryError(true);
    }

    console.log("editValues : ", noteData)

    const newStatusValues = [...statusValues];
    newStatusValues[testId] = event.target.value;
    setStatusValues(newStatusValues);

    console.log("editValues oppppppppppp: ", noteData)

    updateData(
  `/api/audit_engagement/workpapertestcase/${testId}`,
  {
    workpaper_id: workId,
    note : noteData,
    status: newStatusValues[testId],
  },
  setErrors
        );
  };

  const mutiOptionData = (data) => {
    return (
      isModalOpen && (
        <div className="file_upload_modal">
          <div className="file_upload_modalclose">
            <div className="model_heed_content">
              <h4>Uploaded Files</h4>
              <h6>You can select/deselect files for this test case.</h6>
            </div>
            <MultiSelect
              options={options}
              selectedOptions={data}
              onChange={setSelectedOptions}
              initailData={data}
              
            />
            <button onClick={handleClosePopup}>
              <img src={cutbutton} alt="Close" />
            </button>
          </div>

          <div className="upload_modalclose_search">
            <input type="search" placeholder="Search Files" />
          </div>

          <div className="selected_file_listing">
            <div className="select_file_name">
              <h4>{fileSelected}</h4>
              <button>
                <img src={cutbutton} alt="Remove" />
              </button>
            </div>
          </div>
        </div>
      )
    );
  };

  const handleTabClick = (tab) => {
    if (tab !== activeTab) {
      setTransitioning(true);
      setTimeout(() => {
        setActiveTab(tab);
        setTransitioning(false);
      }, 500);
    }
  };

  const handleFileUpload = async (e) => {
    if (file) {
      const selectedFile = e.target.files;
      const formData = new FormData();
      formData.append("file", file);
      formData.append("workpaper_id", workId);

      setUploadStatus("uploading");
      setUploadProgress(0);
      setFile(selectedFile);
      axios
        .post(
          `${process.env.REACT_APP_API_URL}/api/audit_engagement/workpaperevidence/`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
            onUploadProgress: (progressEvent) => {
              const percentCompleted = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              );
              setUploadProgress(percentCompleted);
            },
          }
        )
        .then((response) => {
          setUploadStatus("success");
          window.location.reload(); // Reload the page on successful upload
        })
        .catch((error) => {
          console.error("Error uploading file:", error);
          setUploadStatus("error");
        });
    } else {
      console.error("No file selected");
    }

    const simulateUpload = setInterval(() => {
      setUploadProgress((prev) => {
        if (prev >= 100) {
          clearInterval(simulateUpload);
          setUploadStatus("success");
          setTimeout(() => {
            setUploadStatus("");
            setIsInputVisible(true);
            setFile(null);
          }, 2000);
          return 100;
        }
        return prev + 10;
      });
    }, 500);
  };

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
    setIsInputVisible(false);
  };
  const [fileSelected, setFileSelectedData] = useState();

  const [isPopupVisible, setPopupVisible] = useState(false);

  const [openIndex, setOpenIndex] = useState(null);

  const handleDivClick = (index) => {
    setOpenIndex(index === openIndex ? null : index);
  };

  const handleClosePopup = () => {
    setPopupVisible(false);
  };

  const optionFileChange = (e) => {
    setIsModalOpen(true);
    isModalOpen && (
      <div className="file_upload_modal">
        <div className="file_upload_modalclose">
          <div className="model_heed_content">
            <h4>Uploaded Files</h4>
            <h6>You can select/deselect files for this test case.</h6>
          </div>
          <MultiSelect
            options={options}
            selectedOptions={data}
            onChange={setSelectedOptions}
            initailData={data}
          />
          <button onClick={handleCloseClick}>
            <img src={cutbutton} alt="Close" />
          </button>
        </div>
      </div>
    );
  };

  const showFiles = (data) => {
    console.log("jekekekekekek");
    if (
      data !== undefined &&
      data?.files !== undefined &&
      data?.files?.length > 0
    ) {
      return (
        <span onClick={() => mutiOptionData(data?.files)}>View Files</span>
      );
    }
    return <span>No files available</span>;
  };

  const [fileUrl, setFileUrl] = useState(null);

  const getFile = (urlData) => {
    fetchFile(urlData);
  };


  const [isOpen, setIsOpen] = useState(false);
  // console.log(JSON.stringify(APITestData?.data))

  useEffect(() => {
    console.log(selectedOptions);
  }, [selectedOptions]);

  const handleUnselectOption = (id, e) => {
    e.stopPropagation();
    const newSelectedOptions = selectedOptions.filter((item) => item.id !== id);
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  function transformData(inputData) {
    console.log(inputData, "inpiuyuy");
    return inputData.data.files.map((file) => ({
      id: file.id,
      name: file.name,
    }));
  }

  const handleSelectionChange = (newSelectedOptions) => {
    // Update state with the new selected options
    setSelectedOptions(newSelectedOptions);
  };

  const markAsDone = () => {
    console.log(APIData.task.id);
    if (APIData.task?.id) {
      console.log("cllick");

      updateData(
        `/api/audit_engagement/workpaper/${workId}`,
        {
          audit_id: id,
          objective: objective ? objective : "no objective",
          preparer_id,
          reviewer_id,
          status: "Completed",
          assign_to_id,
        },
        setErrors
      );

      updateData(
        `/api/system/task/${APIData.task?.id}`,
        {
          title: objective ? objective : "no objective",
          status: "done",
        },
        setErrors
      );
    }
  };
  

  return (
    <>
    
      {errors && <p className="errormessage">{errors}</p>}
      <section className="navroutes">
      <RenderIcons homeRoutes={auditExecution} id ={id}/>

        <div className="workpap_main_con">

          <div className="filter-container" style={{display : 'flex' ,justifyContent : 'space-between', alignItems: 'center'}}>
          <div>
          <Breadcrumb />

          </div>
<div>
            <Link to={`/AuditExecution/${id}/Fieldwork`}>
                  <div
                    className="backBtnDiv"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      gap: "1vw",
                      backgroundColor: "#FFF3CC",
                      padding: ".5vw 1vw",
                      borderRadius: "2.5vw",
                      
                    }}
                  >
                    <img src={backlogo} style={{ width: "1.2vw" }} />
                    <p
                      style={{
                        fontFamily: "intersemibold",
                        fontSize: ".8vw",
                        color: "#775F0F"
                      }}
                    >
                      Back to Fieldworks
                    </p>
                  </div>
                </Link>
</div>
          </div>

          {loading ? (
          <div className="spinner-bounce-loader">
          <BounceLoader color="#a6fafa"/> 
          </div>
            
          ): APIData.id === 0 ? (
            <p className="data-error-text">No data found</p> 
          ) : (
          <div
        className="view_workpaper_con"
        style={{ width: tableSize ? "80vw" : "90.5vw" }}
      >
        <div className="viewworkpap_delete_edit_close_btn">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              gap: "1vw",
            }}
          >
            {editMode && <IconButton
              type="close"
              className="closeView"
              tooltip="Cancel"
              onClick={handleCancelClickField}
              
            />}
            {editMode ? (
              <IconButton type="save" tooltip="Save" onClick={updateapi} />
            ) : (
              <IconButton type="edit" tooltip="Edit" onClick={handleEditSave} />
            )}
            <WindowComponent onDelete={onDelete} />
          </div>
        </div>

        <div className="view_workpap_prepearer_reviewer">
        
         <form>
          <div className="lng_lbl_inpt">
              <label>Title</label>
                <input
                  type="text"
                  placeholder="name of fieldwork"
                  value={objective}
                  style={ {backgroundColor:editMode ? "#EEF1FC" : "#e6e6e6"}}
                  onChange={(e) => setObjective(e.target.value)}
                  disabled={!editMode}
                />
              </div>

       
                <SingleOptionSelector
                  apiUrl="/api/audit_universe/audit-team/"
                  setSelectorId={setPreparerId}
                  setSelectorError={setPreparerError}
                  selectorError={PreparerError}
                  Name="name"
                  title="Preparer"
                  initialId={APIData.preparer ? APIData.preparer.id : ""}
                  initialName={APIData.preparer ? APIData.preparer.name : ""}
                  editMode={editMode}
                />
             
           

           
              
             
                <SingleOptionSelector
                  apiUrl="/api/audit_universe/admin-audit-team/"
                  setSelectorId={setReviewerId}
                  setSelectorError={setReviewerError}
                  selectorError={reviewerError}
                  Name="name"
                  title="Reviewer"
                  initialId={APIData.reviewer ? APIData.reviewer.id : ""}
                  initialName={APIData.reviewer ? APIData.reviewer.name : ""}
                  editMode={editMode}
                />
            
           
              <SingleOptionSelector
                apiUrl="/api/audit_universe/business-stakeholders/"
                setSelectorId={setAssign_to_id}
                setSelectorError={setAssign_to_idError}
                selectorError={assign_to_idError}
                Name="name"
                title="Assign To"
                initialId={APIData.assign_to ? APIData.assign_to.id : ""}
                initialName={APIData.assign_to ? APIData.assign_to.name : ""}
                editMode={editMode}
              />
           
          


            <div className="lbl_inpt">
                <div onClick={() => markAsDone()}>
                {APIData.status === "Open" &&
                    APITestData.every(test => test.status === 'Passed' || test.status === 'Failed') && (
                      <button className="auditstatusphase-1">Mark As Done</button>
                    )}
                </div>
                {APIData.status === "Completed" && (
                  <span
                    className="auditstatusphaseWP"
                  >
                    {APIData.status === "Completed"
                      ? "Completed"
                      : "Mark As Done"}
                  </span>
                )}
              </div> 
            
              </form>

        </div>

        <div className="viewworkpap_table">
          <div className="fieldTableActionBtn">
            <div
              className="fieldTableActionCreateBtn"
              onClick={handleTestCreate}
            >
              <img src={create_plus} />
            </div>
            <div className="fieldwork_tg_btn">
            <div className="comment_toggle_btn">
                  <div className="toggle_img_div"  onClick={toggleCommentVisibility}>
                  <img
        src={arrowFlip}
       
        alt="Toggle Comments"
        className={isCommentVisible ? "img-flipped" : ""}
        style={{ cursor: "pointer", transform: isCommentVisible ? 'rotate(180deg)' : 'rotate(0deg)', transition: 'transform 0.3s ease' }}
      />
      <h3>{isCommentVisible ?"Hide Comments":"Show Comments"}</h3>
     
                  </div>
                </div>
            <div className="switchable_con">
              <div className="switchable_tab" onClick={handleTabToggle}>
                {activeTab ? "Show RCM Data" : "Hide RCM Data"}
              </div>
            </div>
            </div>
          </div>
          <div className="fieldtableDiv">
            <table className="fieldtable">
              <thead>
                <tr>
                  <th>Action</th>
                  <th>Sr No.</th>
                  <th>Test</th>
                  <th>Sample Size</th>
                  <th>Preliminary Observation</th>
                  <th>Result</th>
                  <th>Evidence</th>
                </tr>
              </thead>

              <tbody>
                {APITestData.sort((u, v) => u.id <= v.id).map((data, index) => (
                  <React.Fragment key={index}>
                    <tr
                      key={data.id}
                      data-index={index}
                      onDoubleClick={() => handleEditClick(index, data)}
                    >
                      {editingRow === index ? (
                        <>
                          <td>
                            <button
                              style={{
                                marginRight: "1vw",
                                border: "none",
                                background: "transparent",
                              }}
                              onClick={(e) => handleSaveClick(data.id)}
                            >
                              <img src={SaveRow} style={{ width: "2vw" }} />
                            </button>
                            <button
                              style={{
                                marginRight: "1vw",
                                border: "none",
                                background: "transparent",
                              }}
                              onClick={handleCancelClick}
                            >
                              <img src={closeBtn} style={{ width: "2vw" }} />
                            </button>
                          </td>

                          <td>{index + 1}</td>

                          <td style={{ textAlign: "left" }}>
                            <textarea
                              type="text"
                              style={{ width: "60vw" }}
                              value={editValues?.step || ""}
                              onChange={(e) => handleInputChange(e, "step")}
                            />
                          </td>

                          <td>
                            <textarea
                              type="text"
                              style={{ border: "1px solid lightblue" }}
                              value={editValues?.sample_size || ""}
                              onChange={(e) =>
                                handleInputChange(e, "sample_size")
                              }
                            />
                          </td>

                          <td>
                            <textarea
                              type="text"
                              style={{
                                width: "60vw",
                                border: "1px solid lightblue",
                              }}
                              value={editValues.note || ""}
                              onChange={(e) => handleInputChange(e, "note")}
                            />
                          </td>

                          <td>
                            <div>
                              <select
                                id="status"
                                className="rslt_selector"
                                value={data.status}
                                onChange={(e) =>
                                  handleChange(index, e, data.id, editValues.note)
                                }
                                style={{
                                  padding: "0.5rem 1rem",
                                  borderRadius: "0.25rem",
                                  border: "1px solid #B5C5FF",
                                  backgroundColor: "#fff",
                                }}
                              >
                                <option value="">--Select--</option>
                                <option value="Passed">Passed</option>
                                <option value="Failed">Failed</option>
                              </select>
                            </div>
                          </td>

                          <td>
                            <MultiSelectView
                              options={options}
                              selectedOptions={selectedOptions}
                              onChange={handleSelectionChange}
                            />
                          </td>
                        </>
                      ) : (
                        <>
                          <td
                            style={{
                              gap: "1vw",
                              justifyContent: "center",
                            }}
                          >
                            <button
                              style={{
                                background: "transparent",
                                border: "none",
                              }}
                              onClick={() => handleEditClick(index, data)}
                            >
                              <img src={EditRow} style={{ width: "2vw" }} />
                            </button>
                            <button
                              style={{
                                background: "transparent",
                                border: "none",
                              }}
                              onClick={() => handleTestDelete(data.id)}
                            >
                              <img src={deleteBtn} style={{ width: "2vw" }} />
                            </button>
                          </td>
                          <td>{index + 1}</td>
                          <td
                            style={{
                              textAlign: "left",
                            }}
                          >
                            <span>{data?.step || "--"}</span>
                          </td>
                          <td>{data?.sample_size || "--"}</td>
                          <td>{data?.note || "--"}</td>
                          <td>
                            <div>
                              <select
                                id="status"
                                value={data?.status}
                                // disabled={!editMode}

                                onChange={(e) =>
                                  handleChange(index, e, data.id, data?.note)
                                }
                                className="rslt_selector"
                              >
                                <option value="">--Select--</option>
                                <option value="Passed">Passed</option>
                                <option value="Failed">Failed</option>
                              </select>
                            </div>
                          </td>
                          <td>
                            <div>
                              <div onClick={() => handleDivClick(index)}>
                                {data?.data?.files?.length > 0 ? (
                                  <span>View Files</span>
                                ) : (
                                  <span>No files available</span>
                                )}
                              </div>
                              <div

                                className="file-select-popup"

                                style={{
                                  display:
                                    openIndex === index ? "block" : "none",

                                }}
                              >
                                <div className="sub-file-select-popup" >
                                  <div className="file-select-popup-top">
                                    <h4>Uploaded Files</h4>
                                    <div className="btn-div-popup">
                                      <button onClick={() => handleSaveClick1(data)}>
                                        <img src={SaveBtn} />
                                      </button>
                                      <button onClick={() => handleDivClick(index)}>
                                        <img src={cutbutton} alt="Close" />
                                      </button>
                                    </div>
                                  </div>
                                  <h6>
                                    You can select/deselect files for this
                                    test case.
                                  </h6>
                                  <div className="file-select-input">
                                    <MultiSelectView
                                      options={options}
                                      selectedOptions={data?.data?.files || []}
                                      onChange={handleSelectionChange}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </td>
                        </>
                      )}
                    </tr>
                  </React.Fragment>
                ))}

                {isTestCreateOpen && (
                  <tr>
                    <td>
                      <div>
                        <button
                          onClick={postTest}
                          style={{
                            border: "none",
                            background: "transparent",
                          }}
                        >
                          <img src={SaveRow} style={{ width: "2vw" }} />
                        </button>
                        <button
                          style={{
                            marginRight: "1vw",
                            border: "none",
                            background: "transparent",
                          }}
                          onClick={handleTestClose}
                        >
                          <img src={closeBtn} style={{ width: "2vw" }} />
                        </button>
                      </div>
                    </td>
                    <td>
                      <h2></h2>
                    </td>

                    <td>
                      <div className="viewworkpap_labelandinputs">
                        <input
                          type="text"
                          placeholder="01"
                          style={{ width: tableSize ? "12vw" : "15vw" }}
                          value={step1}
                          onChange={(e) => {
                            setStep1(e.target.value);
                            setAuditStepDetailsError(false);
                          }}
                        />
                      </div>
                    </td>

                    <td>
                      <div className="viewworkpap_labelandinputs">
                        <input
                          type="text"
                          placeholder="--"
                          style={{
                            width: tableSize ? "12vw" : "15vw",
                            border: sample_sizeError ? "1px solid #FF4646" : "",
                          }}
                          value={sample_size1}
                          onChange={(e) => {
                            setSampleSize1(e.target.value);
                            setSampleSizeError(false);
                          }}
                        />
                      </div>
                    </td>
                    <td>
                      <div className="viewworkpap_labelandinputs">
                        <textarea
                          type="text"
                          placeholder="5454-"
                          style={{
                            width: tableSize ? "12vw" : "15vw",
                            border: preliminaryError
                              ? "1px solid #FF4646"
                              : "",
                          }}
                          value={note1}
                          onChange={(e) => {
                            setNote1(e.target.value);
                            setPreliminaryError(false);
                          }}
                        />
                        
                      </div>
                    </td>
                    <td>
                      <div className="viewworkpap_labelandinputs">
                        <div>
                          <select
                            id="status"
                            value={statusVal}
                            onChange={(e) => setStatusVal(e.target.value)}
                          >
                            <option value="">--Select--</option>
                            <option value="">None</option>
                            <option value="Passed">Passed</option>
                            <option value="Failed">Failed</option>
                          </select>
                        </div>
                      </div>
                    </td>

                    <td>
                      <MultiSelectView
                        options={options}
                        selectedOptions={data?.data?.files || []}
                        onChange={handleSelectionChange}
                      />
                    </td>

                    <td>
                      <div className="viewworkpap_labelandinputs"></div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          <div className={`commentSecFieldWork ${isCommentVisible ? "slide-in" : "slide-out"}`}
          style={{
            width: tableSize ? "34.7vw" : "40vw",
            position: "absolute",
            top: "43%",
            overflowY:"scroll",
            right: "0%",
            height: "55vh",
            backgroundColor: '#fff',
            zIndex:"100",
            transition: 'transform 0.3s ease',
            transform: isCommentVisible ? 'translateX(0)' : 'translateX(100%)',
            boxShadow: "0 2px 12px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"
          }}>
            <h3 style={{ fontSize: "1.2vw", fontFamily: "interbold" }}>
              Comments & Activity
            </h3>

            <div className="commentTextBox">
              <input
                type="text"
                placeholder="Enter a comment..."
                value={inputValue}
                onChange={handleInputChange1}
                onKeyDown={handleKeyDown}
                style={{ width: "90%" }}
              />
              <div className="sendCommImg">
                <img
                  onClick={handleAddComment}
                  src={sendComment}
                  alt="Send Comment"
                />
              </div>
            </div>

            <div className="all_commentsfieldwork">
              {taskData
                .slice()
                .reverse()
                .map((comment, index) => {
                  const { text, date, time } = comment;
                  return (
                    <div className="comment_wrapperFieldwork" key={index}>
                      <div className="comment_headFw">
                        <div className="avatar_name_FW">
                          <SuperAvatar
                            firstName={
                              comment.user
                                ? comment.user.name
                                : `${avaUserName} ${lastName}`
                            }
                            classOfAvatar="Avtaraudit"
                          />
                          <h5>
                            {comment.user
                              ? comment.user.name
                              : `${avaUserName} ${lastName}`}
                          </h5>
                        </div>
                        <div className="time_date_AQ">
                          <span>
                            <sub>{daysSince(comment.created_at)}</sub>
                          </span>
                        </div>
                      </div>
                      <div className="comment_FW">
                        <p>{text}</p>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>

        <div className="switchable_tabs_rcm_data">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          ></div>

          <div className="rcm_fileupload">
            <div className={`tab_content ${activeTab ? "visible" : "hidden"}`}>
              <div className="previous_rcm_data">
                <form action="">
                  <h4>Risk Control Matrix Audit Procedure</h4>

                  <div className="read_rcm_section">
                    <h5>Introductory</h5>

                    <div className="read_rcm_content">
                      <div className="viewworkpap_labelandinputs">
                        <label htmlFor="">Functions</label>
                        <p>
                          {APIData.rcap
                            ? APIData.rcap.business_unit?.name
                              ? APIData.rcap.business_unit?.name
                              : "All Functions"
                            : "All Functions"}
                        </p>
                      </div>

                      <div className="viewworkpap_labelandinputs">
                        <label htmlFor="">Process</label>
                        <p>{APIData.process ? APIData.process : "--"}</p>
                      </div>

                      <div className="viewworkpap_labelandinputs">
                        <label htmlFor="">Sub-Process</label>
                        <p>
                          {APIData.sub_process ? APIData.sub_process : "--"}
                        </p>
                      </div>

                      <div className="viewworkpap_labelandinputs">
                        <label htmlFor="">Objective Description</label>
                        <p>{APIData.objective ? APIData.objective : "--"}</p>
                      </div>
                    </div>
                  </div>

                  <div className="read_rcm_section">
                    <h5>Risk Description</h5>

                    <div className="read_rcm_content">
                      <div className="viewworkpap_labelandinputs">
                        <label htmlFor="">Risk ID</label>
                        <p>{APIData.risk_id ? APIData.risk_id : ""}</p>
                      </div>

                      <div className="viewworkpap_labelandinputs">
                        <label htmlFor="">Risk Category</label>
                        <p>
                          {APIData.risk_category ? APIData.risk_category : ""}
                        </p>
                      </div>

                      <div className="viewworkpap_labelandinputs">
                        <label htmlFor="">Risk Title</label>
                        <p>{APIData.risk_title ? APIData.risk_title : "--"}</p>
                      </div>

                      <div className="viewworkpap_labelandinputs">
                        <label htmlFor="">Risk Statement</label>
                        <p>
                          {APIData.risk_description
                            ? APIData.risk_description
                            : ""}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="read_rcm_section">
                    <h5>Inherent Assessment</h5>

                    <div className="read_rcm_content">
                      <div className="viewworkpap_labelandinputs">
                        <label htmlFor="">Inh. Likelihood</label>
                        <p>
                          {likemapping(
                            APIData.inherent_assessment_likelihood
                              ? APIData.inherent_assessment_likelihood
                              : "--"
                          )}
                        </p>
                      </div>

                      <div className="viewworkpap_labelandinputs">
                        <label htmlFor="">Inh. Impact</label>
                        <p>
                          {impactmapping(
                            APIData.inherent_assessment_impact
                              ? APIData.inherent_assessment_impact
                              : "--"
                          )}
                        </p>
                      </div>

                      <div className="viewworkpap_labelandinputs">
                        <label htmlFor="">Inherent Risk Evaluation Score</label>
                        <p>
                          {APIData.risk_inherant_evaluation_score
                            ? APIData.risk_inherant_evaluation_score
                            : "--"}
                        </p>
                      </div>
                      <div className="viewworkpap_labelandinputs">
                        <label htmlFor="">Inherent Risk Evaluation</label>
                        <p>
                          {APIData.risk_inherant_evaluation
                            ? APIData.risk_inherant_evaluation
                            : "--"}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="read_rcm_section">
                    <h5>Control</h5>

                    <div className="read_rcm_content">
                      <div className="viewworkpap_labelandinputs">
                        <label htmlFor="">Control Description</label>
                        <pre>
                          {APIData.control_description
                            ? APIData.control_description
                            : "--"}
                        </pre>
                      </div>

                      <div className="viewworkpap_labelandinputs">
                        <label htmlFor="">Control Design</label>
                        <p>
                          {APIData.control_design
                            ? APIData.control_design
                            : "--"}
                        </p>
                      </div>

                      <div className="viewworkpap_labelandinputs">
                        <label htmlFor="">Control Type</label>
                        <p>
                          {APIData.control_type ? APIData.control_type : "--"}
                        </p>
                      </div>

                      <div className="viewworkpap_labelandinputs">
                        <label htmlFor="">Control Evaluation</label>
                        <p>
                          {APIData.control_evaluation
                            ? APIData.control_evaluation
                            : "--"}
                        </p>
                      </div>

                      <div className="viewworkpap_labelandinputs">
                        <label htmlFor="">Best Practices for Reference</label>
                        <p>
                          {APIData.control_desired
                            ? APIData.control_desired
                            : "--"}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="read_rcm_section">
                    <h5>Residual Assessment</h5>

                    <div className="read_rcm_content">
                      <div className="viewworkpap_labelandinputs">
                        <label htmlFor="">Res. Likelihood</label>
                        <p>
                          {likemapping(
                            APIData.residual_assessment_likelihood
                              ? APIData.residual_assessment_likelihood
                              : "--"
                          )}
                        </p>
                      </div>

                      <div className="viewworkpap_labelandinputs">
                        <label htmlFor="">Res. Impact</label>
                        <p>
                          {impactmapping(
                            APIData.residual_assessment_impact
                              ? APIData.residual_assessment_impact
                              : "--"
                          )}
                        </p>
                      </div>

                      <div className="viewworkpap_labelandinputs">
                        <label htmlFor="">Residual Risk Evaluation Score</label>
                        <p>
                          {APIData.risk_residual_evaluation_score
                            ? APIData.risk_residual_evaluation_score
                            : "--"}
                        </p>
                      </div>
                      <div className="viewworkpap_labelandinputs">
                        <label htmlFor="">Residual Risk Evaluation </label>
                        <p>
                          {APIData.risk_residual_evaluation
                            ? APIData.risk_residual_evaluation
                            : "--"}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="read_rcm_section">
                    <h5>Audit Procedure</h5>

                    <div className="read_rcm_content">
                      <div className="viewworkpap_labelandinputs">
                        <label htmlFor="">Audit Step Details</label>
                        <pre>
                          {APIData.audit_procedure_step
                            ? APIData.audit_procedure_step
                            : "--"}
                        </pre>
                      </div>

                      <div className="viewworkpap_labelandinputs">
                        <label htmlFor="">Data Required</label>
                        <p>
                          {APIData.audit_procedure_data
                            ? APIData.audit_procedure_data
                            : "--"}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div
                    style={{
                      display: !showTest ? "none" : "flex",
                      gap: "2vh",
                    }}
                  >
                    <div className="viewworkpap_labelandinputs">
                      <label htmlFor="">Test Case</label>
                      <textarea
                        type="text"
                        placeholder="--"
                        style={{
                          width: tableSize ? "12vw" : "15vw",
                          border: sample_rationaleError
                            ? "1px solid #FF4646"
                            : "",
                        }}
                        value={test_case}
                        onChange={(e) => {
                          setTestCase(e.target.value);
                          setSampleRationaleError(false);
                        }}
                        readOnly={!editMode}
                      />
                    </div>

                    <div className="viewworkpap_labelandinputs">
                      <label htmlFor="">Sample Size</label>
                      <input
                        type="text"
                        placeholder="--"
                        style={{
                          width: tableSize ? "12vw" : "15vw",
                          border: sample_sizeError ? "1px solid #FF4646" : "",
                        }}
                        value={sample_size ? sample_size : "--"}
                        onChange={(e) => {
                          setSampleSize(e.target.value);
                          setSampleSizeError(false);
                        }}
                        readOnly={!editMode}
                      />
                    </div>

                    <div className="viewworkpap_labelandinputs">
                      <label htmlFor="">Preliminary Observation</label>
                      <input
                        type="text"
                        placeholder="--"
                        style={{
                          width: tableSize ? "12vw" : "15vw",
                          border: preliminaryError
                            ? "1px solid #FF4646"
                            : "",
                        }}
                        value={note1}
                        onChange={(e) => {
                          setNote1(e.target.value);
                          setPreliminaryError(false);
                        }}
                        readOnly={!editMode}
                      />
                    </div>

                    <div className="fieldwork_cr_btn" onClick={postTest}>
                      {" "}
                      Create{" "}
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

        {/* {isModalOpen && (
          <div className="file_upload_modal">
            <div className="file_upload_modalclose">
              <div className="model_heed_content">
                <h4>Uploaded Files</h4>
                <h6>You can select/deselect files this test case.</h6>
              </div>
              <MultiSelect
                options={options}
                selectedOptions={selectedOptions}
                onChange={setSelectedOptions}
                initailData={data?.data}
              />
              <button onClick={handleCloseClick}>
                <img src={cutbutton} />
              </button>
            </div>

            


            <div className="upload_modalclose_search">
              <input type="search" placeholder="Search Files" />
            </div>

            <div className="selected_file_listing">
              <div className="select_file_name">
                <h4>{fileSelected}</h4>
                
                <button>
                  <img src={cutbutton} />
                </button>
              </div>
            </div>

          
          </div>
        )} */}
      </div>
          )
        }
        </div>

        <div className="cardscontainer"></div>
      </section>
    </>
  );
};

export default ViewAuditFieldwork;
