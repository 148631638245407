import React, { useState, useEffect } from "react";
import { NavLink, useLocation, Link, useNavigate } from "react-router-dom";
import axios from "axios";
import Breadcrumb from "../../components/Breadcrumb";
import SidebarMenu from "../../components/SidebarMenu";
import "../../css/viewuser.css";
import { fetchData } from "../apiUtils";
import { issueTracking } from "../subroutes";
import create from "../../images/createbutton.svg"
import openarrow from "../../images/openarrow.svg"
import pie_chart from "../../images/pie_chart.png"
import "./issuetracking.css"
import RenderIcons from "../RenderIcons";
const Issuetracking = ({ tableSize }) => {
    const [errors, setErrors] = useState("")
    const [APIData, setAPIData] = useState([]);
    const location = useLocation();
    const navigate = useNavigate();
    const successMessageColor = location.state?.successMessageColor;
    const successMessage = location.state?.successMessage;
    const [showMessage, setShowMessage] = useState(!!successMessage);
    const originalUrl = location.state?.from;
    useEffect(() => {
        const timeoutId = setTimeout(() => {
            navigate(".", { replace: true });
        }, 5000);

        return () => clearTimeout(timeoutId);
    }, []);

    useEffect(() => {

        fetchData(`/api/audit_universe/audit-entities/`, setAPIData, setErrors);

    }, []);

    const Viewuser = (data) => {



        const { name, SelectedKeysOther, description, BusinessUnit } = data;

        const localStorageData = {
            name,
            SelectedKeysOther,
            description,
            BusinessUnit,
        };
        localStorage.setItem(
            "key_stakeholder_name",
            data.key_stakeholder ? data.key_stakeholder.name.split(" ")[0] : 'No Units'
        );

        localStorage.setItem(
            "key_stakeholder_id",
            data.key_stakeholder ? data.key_stakeholder.id : ''
        );
        localStorage.setItem("ID", data.id);

        localStorage.setItem("buUnits", JSON.stringify(data.business_units));
        localStorage.setItem("otherUnits", JSON.stringify(data.other_stakeholders));



        Object.entries(localStorageData).forEach(([key, value]) => {
            localStorage.setItem(key, value);
        });

        navigate("/AuditUniverse/auditEntities/ViewAuditEntity");
    };

    const [filters, setFilters] = useState({
        name: "",
        key_stakeholder: "",
    });

    const handleFilterChange = (filterKey, e) => {
        setFilters({ ...filters, [filterKey]: e.target.value });
    };

 


    const filteredData = APIData.filter((data) => {

        const fullName = data.name.toLowerCase();
        const NameMatch = filters.name ? fullName.includes(filters.name.toLowerCase()) : true;
        const keyStakeholderName = "";
        const KeyStakeholder = filters.key_stakeholder ? keyStakeholderName.includes(filters.key_stakeholder.toLowerCase()) : true;

        const businessUnitMatch = filters.business_unit ? data.business_units.some(unit => {
            const unitNameLower = unit.name.toLowerCase();
            const filterUnitLower = filters.business_unit.toLowerCase();
            return unitNameLower.includes(filterUnitLower);
        }) : true;

        return NameMatch && KeyStakeholder && businessUnitMatch;
    });





    return (
        <>
            {errors && <p className="errormessage">{errors}</p>}

            {successMessage && <p className="successmessage">{successMessage}</p>}

            <section className="navroutes">
            <RenderIcons homeRoutes={issueTracking} />


                <div className="filter-container">
                    <div className="filter">
                        <div className="views">
                            <h3 className="h2_interbold">Analytics</h3>
                         
                        </div>

                    

                    </div>
                </div>

                <div className="analytics_container" style={{ width: tableSize ? "79.5vw" : "90vw" }} >

                    <div className="pie_chart">
                        <img src={pie_chart} />
                    </div>

                    <div className="issues_by_age">

                        <div className="readings">

                            <div className="reading">
                                20
                            </div>

                            <div className="reading">
                                10
                            </div>

                            <div className="reading">
                                0
                            </div>

                        </div>

                        <div className="age_bars">

                            <div className="label_bar">
                                <div className="bar">12</div>
                                <h5>Past Due 120+ Days</h5>
                            </div>

                            <div className="label_bar">
                                <div className="bar">12</div>
                                <h5>No Due Date / Unknown</h5>
                            </div>

                            <div className="label_bar">
                                <div className="bar">12</div>
                                <h5>Due in 90 - 120 Days</h5>
                            </div>

                            <div className="label_bar">
                                <div className="bar">12</div>
                                <h5>Due in 120+ Days</h5>
                            </div>

                        </div>

                    </div>

                    <div className="issues_by_rating">

                        <div className="ir_readings">

                            <div className="ir_reading">
                                20
                            </div>

                            <div className="ir_reading">
                                10
                            </div>

                            <div className="ir_reading">
                                0
                            </div>

                        </div>

                        <div className="rating_bars">

                            <div className="ir_label_bar">

                                <div className="irbar"></div>
                                <div className="ir_label"> Blank </div>

                            </div>

                            <div className="ir_label_bar">

                                <div className="irbar"></div>
                                <div className="ir_label"> High </div>

                            </div>

                            <div className="ir_label_bar">

                                <div className="irbar"></div>
                                <div className="ir_label"> Medium </div>

                            </div>

                            <div className="ir_label_bar">

                                <div className="irbar"></div>
                                <div className="ir_label"> Low </div>

                            </div>

                        </div>

                    </div>

                    <div className="tenuare_graph">

                        <div className="reading_wrapper">

                            <div className="tg_readings">

                                <div className="tg_reading_section"></div>
                                <div className="tg_reading">0</div>

                            </div>

                            <div className="tg_readings">

                                <div className="tg_reading_section"></div>
                                <div className="tg_reading">1</div>

                            </div>

                            <div className="tg_readings">

                                <div className="tg_reading_section"></div>
                                <div className="tg_reading">2</div>

                            </div>

                            <div className="tg_readings">

                                <div className="tg_reading_section"></div>
                                <div className="tg_reading">3</div>

                            </div>

                            <div className="tg_readings">

                                <div className="tg_reading_section"></div>
                                <div className="tg_reading">4</div>

                            </div>

                        </div>

                        <div className="graph_container">

                            <div className="audit_row">
                                <h5>NIST Assignment</h5>
                                <div className="audit_bar">4</div>
                            </div>

                            <div className="audit_row">
                                <h5>Shared Service Center</h5>
                                <div className="audit_bar">4</div>
                            </div>

                            <div className="audit_row">
                                <h5>NIST Assignment</h5>
                                <div className="audit_bar">4</div>
                            </div>

                            <div className="audit_row">
                                <h5>NIST Assignment</h5>
                                <div className="audit_bar">4</div>
                            </div>

                            <div className="audit_row">
                                <h5>NIST Assignment</h5>
                                <div className="audit_bar">4</div>
                            </div>

                        </div>

                    </div>

                </div>

                <div className="cardscontainer">
                    {filteredData.map((data) => (
                        <div className="aecard" onClick={() => Viewuser(data)}>
                            <h1>{data.name} </h1>

                            <img src={openarrow} />
                        </div>
                    ))}
                </div>

            </section>
        </>
    );
};

export default Issuetracking;
