import React, { useState, useEffect } from "react";
import { NavLink, useLocation, Link, useNavigate } from "react-router-dom";
import axios from "axios";
import Breadcrumb from "../../Breadcrumb";
import SidebarMenu from "../../SidebarMenu";
import "../../../css/viewuser.css";
import { fetchData } from "../../apiUtils";
import { auditExecution } from "../../subroutes";
import create from "../../../images/createbutton.svg";
import openarrow from "../../../images/openarrow.svg";
import "../createaudit/createaudit.css";
import SingleOptionSelector from "../../SingleOptionSelector";
import InitialSingleOptionSelector from "../../InitialSingleOptionSelector";

import MultiOptionSelector from "../../MultiOptionSelector";
import { postData } from "../../apiUtils";

import IconButton from "../../iconbutton/IconButton";

import AuditMemo from "../../AuditMemo";

const Createaudit = ({ tableSize }) => {
  const [errors, setErrors] = useState("");
  const [riskErrors, setRiskErrors] = useState("");
  const [AuditErrors, setAuditErrors] = useState(false);
  const [name, setAuditName] = useState("");
  const [objectives, setObjectives] = useState("");
  const [introduction, setIntroduction] = useState("");
  const [audit_background, setAudit_background] = useState("");
  const [final_opinion, setFinal_opinion] = useState("");


  const [AuditError, setAuditError] = useState(false);
  const [auditorErrors, setAuditorErrors] = useState("");
  const [selectedRiskError, setSelectedRiskError] = useState(false);
  const [selectedPreparerError, setSelectedPreparerError] = useState(false);
  const [selectedReviewerError, setSelectedReviewerError] = useState(false);
  const [selectedassign_toError, setSelectedassign_toError] = useState(false);
  const [qaError, setQAError] = useState(false);


  const [APIData, setAPIData] = useState([]);
  const [Data, setIData] = useState("");

  const [risk_assessment_id, setAPIRiskData] = useState([]);
  const [preparer_id, setPreparer_id] = useState([]);
  const [reviewer_id, setReviewer_id] = useState([]);
  const [assign_to_id, setAssign_to_id] = useState([]);

  const [business_users_id, setAPIBSData] = useState([]);
  const [qa_id, setQA_id] = useState([]);

  const [auditors_id, setAPIAuditorData] = useState([]);
  const [audit_entity_id, setAPIAuditData] = useState("");

  const [actual_end_date, setEndDate] = useState("");
  const [actual_start_date, setStartDate] = useState("");
  const [assigned_Id, setAssigned_Id] = useState("");
  const [assigned_Name, setAssigned_Name] = useState("");
  const [editMode, setEditMode] = useState(true);

  const location = useLocation();
  const navigate = useNavigate();
  const successMessageColor = location.state?.successMessageColor;
  const successMessage = location.state?.successMessage;
  const [showMessage, setShowMessage] = useState(!!successMessage);
  const originalUrl = location.state?.from;
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      navigate(".", { replace: true });
    }, 5000);

    return () => clearTimeout(timeoutId);
  }, []);

  const [filters, setFilters] = useState({
    name: "",
    key_stakeholder: "",
  });

  const handleFilterChange = (filterKey, e) => {
    setFilters({ ...filters, [filterKey]: e.target.value });
  };

  const filteredData = APIData.filter((data) => {
    const fullName = data.name.toLowerCase();
    const NameMatch = filters.name
      ? fullName.includes(filters.name.toLowerCase())
      : true;
    const keyStakeholderName = data.key_stakeholder
      ? data.key_stakeholder.name.toLowerCase()
      : "";
    const KeyStakeholder = filters.key_stakeholder
      ? keyStakeholderName.includes(filters.key_stakeholder.toLowerCase())
      : true;

    const businessUnitMatch = filters.business_unit
      ? data.business_units.some((unit) => {
        const unitNameLower = unit.name.toLowerCase();
        const filterUnitLower = filters.business_unit.toLowerCase();
        return unitNameLower.includes(filterUnitLower);
      })
      : true;

    return NameMatch && KeyStakeholder && businessUnitMatch;
  });

  const handleEndDateChange = (event) => {
    setEndDate(event.target.value);
  };

  const handleStartDateChange = (event) => {
    setStartDate(event.target.value);
  };

  const postApi = (e) => {
    console.log("got clicked ");
    e.preventDefault();

    if (!audit_entity_id) {
      setAuditErrors(!audit_entity_id ? true : false);
      setErrors("Please fill in all required fields.");
      setTimeout(() => {
        setErrors(null);
      }, 5000);
      return;
    }

    postData(
      `/api/audit_engagement/audit/`,
      {
        name,
        objectives,
        introduction,
        audit_background,
        final_opinion,
        risk_assessment_id,
        audit_entity_id,
        auditors_id,
        business_users_id,
        qa_id,
        actual_start_date,
        actual_end_date,
        preparer_id,
        reviewer_id,
        assign_to_id,
      },
      navigate,
      setErrors,
      `/auditExecution`
    );
  };

  useEffect(() => {
    const currentDate = new Date().toISOString().split("T")[0];
    setStartDate(currentDate);
    setEndDate(currentDate);
  }, []);

  useEffect(() => {
    console.log(Data);
    setAssigned_Name(Data.key_stakeholder ? Data.key_stakeholder.name : "");
    setAssigned_Id(Data.key_stakeholder ? Data.key_stakeholder.id : "");
  }, [Data]);

  return (
    <>
      {errors && <p className="errormessage">{errors}</p>}

      {successMessage && <p className="successmessage">{successMessage}</p>}

      <section className="navroutes">
        <div className="filter-container">
          <div className="filter">
            <div className="views">
              <h3 className="h2_interbold" >Create Audit</h3>

            </div>
          </div>
        </div>

        <hr className="breadcrumbabv" />

        <Breadcrumb />

        <div className="cr_audit_con" style={{ width: tableSize ? "79.5vw" : "90vw" }}>
        <h3>Audit Details</h3>

          <div className="create_audit_container">

            <div className="delete_edit_close_btn">
              <IconButton
                type="close"
                className="closeView"
                tooltip="Close"
                link="/auditExecution/"
              />
              <IconButton
                type="save"
                tooltip="Save"
                onClick={postApi}
                link="/"
              />
            </div>

         

            <form action="">

              <div className="lng_lbl_inpt ">
                <label>Audit Name</label>
                <input
                  type="text"
                  value={name}
                  placeholder="Audit Name"
                  onChange={(e) => setAuditName(e.target.value)}
                />
              </div>
              <div className="lbl_txa">
                <label>Objectives</label>
                <textarea
                  type="text"
                  value={objectives}
                  placeholder="Audit Objectives"
                  onChange={(e) => setObjectives(e.target.value)}
                />
              </div>


               
                  <SingleOptionSelector
                    apiUrl="/api/audit_universe/risk-assessments/"
                    setSelectorId={setAPIRiskData}
                    setSelectorError={setSelectedRiskError}
                    Name="title"
                    title="Risk Assessments"
                    editMode={editMode}
                  />
                

                
                  <MultiOptionSelector
                    apiUrl="/api/audit_universe/business-stakeholders/"
                    setSelectorId={setAPIBSData}
                    setSelectorError={setSelectedRiskError}
                    Name="name"
                    title="StakeHolder"
                    initialId={assigned_Id}
                    initialName={assigned_Name}
                    editMode={editMode}
                  />
                
              

             
                <div
                  className="lbl_inpt"
                  
                >
                  <label> Start Date: </label>{" "}
                  <input
                    type="date"
                    value={actual_start_date}
                    onChange={handleStartDateChange}
                  />
                </div>

                <div
                  className="lbl_inpt"
                >
                  <label>End Date:</label>
                  <input
                    type="date"
                    value={actual_end_date}
                    onChange={handleEndDateChange}
                  />
                </div>
              

             
                
                  <SingleOptionSelector
                    apiUrl="/api/audit_universe/audit-entities/"
                    setSelectorId={setAPIAuditData}
                    setSelectorError={setAuditErrors}
                    selectorError={AuditErrors}
                    setIData={setIData}
                    Name="name"
                    title="Audit Entity"
                    editMode={editMode}
                  />
               

               
                  <MultiOptionSelector
                    apiUrl="/api/audit_universe/audit-team/"
                    setSelectorId={setAPIAuditorData}
                    Name="name"
                    title="Auditor"
                    editMode={editMode}
                  />
                
              
                  <InitialSingleOptionSelector
                    apiUrl="/api/audit_universe/audit-team/"
                    setSelectorId={setPreparer_id}
                    setSelectorError={setSelectedPreparerError}
                    Name="name"
                    title="Preparer"
                    initialIds={auditors_id}
                    editMode={editMode}
                  />
                

                
                  <InitialSingleOptionSelector
                    apiUrl="/api/audit_universe/audit-team/"
                    setSelectorId={setReviewer_id}
                    setSelectorError={setSelectedReviewerError}
                    Name="name"
                    title="Reviewer"
                    initialIds={auditors_id}
                    // initialId={assigned_Id}
                    // initialName={assigned_Name}
                    editMode={editMode}
                  />
                
             
              
               
                  <InitialSingleOptionSelector
                    apiUrl="/api/audit_universe/business-stakeholders/"
                    setSelectorId={setAssign_to_id}
                    setSelectorError={setSelectedassign_toError}
                    Name="name"
                    title="Business Owner"
                    initialIds={business_users_id}
                    editMode={editMode}
                  />
                
                
                  <SingleOptionSelector
                    apiUrl="/api/audit_universe/quality-assurance/"
                    setSelectorId={setQA_id}
                    setSelectorError={setQAError}
                    Name="name"
                    title="QA"
                    editMode={editMode}
                  />
                
              

            </form>
          </div>
{/* <div className="memodiv" > */}
          <AuditMemo tableSize={tableSize} />
          {/* </div> */}
        </div>
        {/* <div className="cardscontainer">
          {filteredData.map((data) => (
            <div className="aecard" onClick={() => Viewuser(data)}>
              <h1>{data.name} </h1>

              <img src={openarrow} />
            </div>
          ))}
        </div> */}
      </section>
    </>
  );
};

export default Createaudit;
