import React, { useState, useEffect, useRef } from "react";
import { NavLink, useLocation, Link, useNavigate } from "react-router-dom";
import SidebarMenu from "../SidebarMenu";
import "../../css/createuser.css";
import RenderIcons from "../RenderIcons";
import Breadcrumb from "../Breadcrumb";
import { postData } from "../apiUtils";
import SingleOptionSelector from "../SingleOptionSelector";
import { homeRoutes } from "../subroutes";
import MultiOptionSelector from "../MultiOptionSelector";
import IconButton from "../iconbutton/IconButton";
import CommonInput from "../CommonInput";
const CreateUser = ({tableSize}) => {
  const [errors, setErrors] = useState("");
  const [firstName, setFirstName] = useState("");
  const [firstNameError, setFirstNameError] = useState("");
  const [lastName, setLastName] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [avatarColor, setAvatarColor] = useState("");
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const location = useLocation();
  const [first_name, setfName] = useState("");
  const [last_name, setlName] = useState("");
  const [employeed_id, setEmpid] = useState("");
  const [employeed_idError, setEmpidError] = useState(false);
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState(false);
  const [contact, setContact] = useState("");
  const [contactError, setContactError] = useState(false);
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [editMode, setEditMode] = useState(true);

  const [designation, setDesignation] = useState("");
  const [designationError, setDesignationError] = useState(false);
  const navigate = useNavigate();
  const [selectedDeptsError, setSelectedDeptsError] = useState(false);
  const [department_id, setDepartmentId] = useState("");
  const [selectedBuError, setSelectedBuError] = useState(false);
  const [business_unit_id, setBusiness_unit_id] = useState("");

  const getRandomColor = (letter) => {
    const colorSeed = letter.charCodeAt(0) * 137;
    const color = `hsl(${colorSeed % 360}, 70%, 50%)`;
    return color;
  };

  const handleFirstNameChange = (event) => {
    const newName = event.target.value;
    setFirstName(newName);
    setFirstNameError(false);
    setfName(newName);

    if (newName.length > 0) {
      const initialLetter = newName[0].toUpperCase();
      setAvatarColor(getRandomColor(initialLetter));
    }
  };

  const handleLastNameChange = (event) => {
    const newName = event.target.value;
    setLastName(newName);
    setLastNameError(false);
    setlName(newName);
  };

  useEffect(() => {
    const pathSegments = location.pathname
      .split("/")
      .filter((segment) => segment !== "");
    setBreadcrumbs(pathSegments);
  }, [location.pathname]);

  const postApi = (e) => {
    e.preventDefault();

    if (
      !firstName ||
      !lastName ||
      !email ||
      !contact ||
      !designation ||
      !employeed_id ||
      !password

    ) {
      setFirstNameError(!firstName ? true : false);
      setContactError(!contact ? true : false);
      setEmpidError(!employeed_id ? true : false);
      setPasswordError(!password ? true : false);

      setLastNameError(!lastName ? true : false);
      setEmailError(!email ? true : false);
      setDesignationError(!designation ? true : false);

      setErrors("Please fill in all required fields.");
      setTimeout(() => {
        setErrors(null);
      }, 5000);
      return;
    }
    const businessUnitId = parseInt(business_unit_id, 10);
    const departmentId = parseInt(department_id, 10);

    postData(
      `/api/system/users/`,
      {
        first_name,
        last_name,
        email,
        contact,
        designation,
        employeed_id,
        department_id: departmentId,
        business_unit_id: businessUnitId,
        password,
        groups_id,
      },
      navigate,
      setErrors,
      `/Administration/user`
    );
  };

  const [groups_id, setRole] = useState([]);
  const [expandedRoles, setExpandedKeyStakeholder] = useState(false);
  const roleDivRef = useRef(null);

  const [groupData, setGroupData] = useState([
    { id: 1, name: "Admin" },
    { id: 2, name: "Audit Team" },
    { id: 3, name: "Business" },
    { id: 4, name: "Quality Assurance" },
    { id: 5, name: "Audit Commission" },
  ]);

  const toggleCloseRoles = () => {
    setExpandedKeyStakeholder(false);
  };

  const handleClickOutside = (event) => {
    if (roleDivRef.current && !roleDivRef.current.contains(event.target)) {
      setExpandedKeyStakeholder(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        !event.target.closest(".subDiv") &&
        !event.target.closest(".addbusiness-roles")
      ) {
        toggleCloseRoles();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      {errors && <p className="errormessage">{errors}</p>}
      <section className="navroutes">
      <RenderIcons homeRoutes={homeRoutes} />


        <Breadcrumb />

        <div className="filter-container">
          <div className="filter">
            <div className="views">
              <h3 className="h2_interbold" >Create User</h3>
            </div>
          </div>
        </div>

        <div className="userconatiner" style={{width: tableSize?"80vw":"90.5vw"}}>

          <div className="delete_edit_close_btn">

            <IconButton
              type="close"
              className="closeView"
              tooltip="Close"
              link="/Administration/user"
            />

            <IconButton type="save" tooltip="Save" onClick={postApi} link="/" />

          </div>

          <form>

            <div className="lbl_inpt">
              <label>First Name </label>
              <input
                style={{
                  border: firstNameError ? "1px solid #FF4646" : "",
                }}
                required
                type="text"
                placeholder="John"
                value={firstName}
                id="firstName"
                onChange={handleFirstNameChange}
              />
            </div>

            <div className="lbl_inpt">
              <label>Last Name</label>
              <input
                style={{
                  border: lastNameError ? "1px solid #FF4646" : "",
                }}
                type="text"
                placeholder="John"
                value={lastName}
                id="firstName"
                onChange={handleLastNameChange}
              />
            </div>

            <SingleOptionSelector
              apiUrl="/api/system/business-units/"
              setSelectorId={setBusiness_unit_id}
              setSelectorError={setSelectedBuError}
              selectorError={selectedBuError}
              Name="name"
              title="Business Unit"
              editMode={editMode}
            />



            <CommonInput
              labelName="Email"
              error={emailError}
              type="email"
              placeHolder="noahfrost@example.com"
              setText={setEmail}
              setTextError={setEmailError}
              commanDivClass="lbl_inpt"
            />



            <CommonInput
              labelName="Password"
              error={passwordError}
              type="password"
              placeHolder="***********"
              setText={setPassword}
              setTextError={setPasswordError}
              commanDivClass="lbl_inpt"
            />

            <CommonInput
              labelName="Confirm Password"
              error={passwordError}
              type="password"
              placeHolder="***********"
              setText={setPassword}
              setTextError={setPasswordError}
              commanDivClass="lbl_inpt"
            />


            <MultiOptionSelector
              setSelectorId={setRole}
              Name="name"
              title="Roles"
              rolesData={groupData}
              editMode={editMode}
            />


            <CommonInput
              labelName="Contact"
              error={contactError}
              type="number"
              placeHolder="+91 1234567890"
              setText={setContact}
              setTextError={setContactError}
              commanDivClass="lbl_inpt"
            />


            <SingleOptionSelector
              apiUrl="/api/system/departments/"
              setSelectorId={setDepartmentId}
              setSelectorError={setSelectedDeptsError}
              selectorError={selectedDeptsError}
              Name="name"
              title="Department"
              editMode={editMode}
            />



            <CommonInput
              labelName="Designation"
              error={designationError}
              type="text"
              placeHolder="Senior Developer"
              setText={setDesignation}
              setTextError={setDesignationError}
              commanDivClass="lbl_inpt"
            />{" "}



            <CommonInput
              labelName="Emp Id"
              error={employeed_idError}
              type="text"
              placeHolder="541-AF-9466D"
              setText={setEmpid}
              setTextError={setEmpidError}
              commanDivClass="lbl_inpt"
            />




          </form>

        </div>
      </section>
    </>
  );
};

export default CreateUser;
