import React, { useState } from 'react';
import delete_user from "../images/deleteBtn.svg";

const WindowComponent = ({ onDelete, text }) => {
  const [showWindow, setShowWindow] = useState(false);

  const toggleWindow = () => {
    setShowWindow(!showWindow);
  };

  const handleClose = () => {
    setShowWindow(false);
  };

  return (
    <div>
      <button onClick={toggleWindow} className="deleteDatadiv tooltip" style={{ backgroundColor: "#ffffff00" }}>
        {text ? (
          <span style={{fontFamily:"intersemibold",fontSize:".7vw",color:"#000",marginBottom:".9vw"}}>{text}</span>
        ) : (
          <img src={delete_user} className="deleteData" />
        )}
        <span className="tooltiptext">Delete</span>
      </button>
      {showWindow && (
        <div className="window">
          Are you sure you want to delete?
          <div className="btn-option">
            <div className="window-btn" onClick={onDelete}>Delete</div>
            <div className="window-btn" onClick={handleClose}>Close</div>
          </div>
        </div>
      )}
    </div>
  );
};

export default WindowComponent;
