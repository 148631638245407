import React, { useState, useEffect } from "react";
import arrowToggle from "../../../../images/toggle_side_down.svg";
import {
  justPostData,
  justDeleteData,
  justUpdateData,
} from "../../../apiUtils";
import { useNavigate } from "react-router-dom";
import WindowComponent from "../../../WindowComponent"

const QuestionsComps = ({
  data,
  formStatus,
  APIQData,
  showComments,
  tableSize,
}) => {
  const options = ["Yes", "No", "Partially Yes", "Don't Know", "N/A"];
  const [errors, setErrors] = useState("");
  const [submitbtn, setSubmitbtn] = useState(false);
  const [successCallback, setSuccessCallback] = useState(false);

  const navigate = useNavigate();
  const userRoles = localStorage.getItem("userRoles");
  const questionnaireID = localStorage.getItem("questionnaireID");

  const isAdminRole =
    userRoles.includes("Admin") && userRoles.includes("Audit Team");
  var showSubmit = false;
  var showApproval = false;
  var showStatus = false;
  var incomingStatus = APIQData.status.toLowerCase();
  if (isAdminRole) {
    switch (incomingStatus) {
      case "rejected":
      case "open":
        showStatus = false;
        showApproval = false;
        showSubmit = true;
        break;
      case "submitted":
        showStatus = false;
        showApproval = true;
        showSubmit = false;
        break;
      default:
        showStatus = true;
        showApproval = false;
        showSubmit = false;
    }
  } else {
    switch (incomingStatus) {
      case "rejected":
      case "open":
        showStatus = false;
        showApproval = false;
        showSubmit = true;
        break;
      default:
        showStatus = true;
        showApproval = false;
        showSubmit = false;
    }
  }

  const initialAnswers = data.sections.map((section) =>
    section.questions.map((question) =>
      question.answers.reduce((acc, curr) => {
        acc[curr.id] =
          options.findIndex((option) => option === curr.selected_option) + 1 ||
          0;
        return acc;
      }, {})
    )
  );

  const initialRemarks = data.sections.map((section) =>
    section.questions.map((question) => question.remark || "")
  );

  const [answers, setAnswers] = useState(initialAnswers);
  const [remarks, setRemarks] = useState(initialRemarks);
  const [tableVisibility, setTableVisibility] = useState(
    data.sections.map((section) => section.questions.map(() => false))
  );
  const [selectedColumnIndexes, setSelectedColumnIndexes] = useState(
    data.sections.map(() => Array(options.length).fill(null))
  );

  const [allAnswersFilled, setAllAnswersFilled] = useState(false);
  const [allAnswersFilledOnLoad, setAllAnswersFilledOnload] = useState(false);
  useEffect(() => {
    setAllAnswersFilledOnload(areAllAnswersFilled());
  }, []);

  const [allSelectedOptionsFilled, setAllSelectedOptionsFilled] =
    useState(false);

  const handleAnswerChange = (sectionIndex, questionIndex, answerId, optionIndex) => {
    const newAnswers = [...answers];
    if (newAnswers[sectionIndex][questionIndex][answerId] === optionIndex + 1) {
      newAnswers[sectionIndex][questionIndex][answerId] = 0; // Uncheck if already checked
    } else {
      newAnswers[sectionIndex][questionIndex][answerId] = optionIndex + 1;
    }
    setAnswers(newAnswers);
  };

  const handleRemarkChange = (sectionIndex, questionIndex, value) => {
    const newRemarks = [...remarks];
    newRemarks[sectionIndex][questionIndex] = value;
    setRemarks(newRemarks);
  };

  const toggleTableVisibility = (sectionIndex, questionIndex) => {
    const newVisibility = [...tableVisibility];
    newVisibility[sectionIndex][questionIndex] =
      !newVisibility[sectionIndex][questionIndex];
    setTableVisibility(newVisibility);
  };

  const showResponse = () => {
    const answersToConsole = [];
    const remarksToConsole = [];

    answers.forEach((sectionAnswers, sectionIndex) => {
      sectionAnswers.forEach((questionAnswers, questionIndex) => {
        Object.entries(questionAnswers).forEach(
          ([answerId, selectedOption]) => {
            answersToConsole.push({
              answerId: answerId,
              answer: selectedOption,
              remark: remarks[sectionIndex][questionIndex],
            });
          }
        );
      });
    });

    justPostData(
      `/api/audit_universe/answers/`,
      {
        answers: answersToConsole,
      },
      navigate,
      setErrors,
      setSuccessCallback
    );

    if (isAdminRole) {
      if (!successCallback) {
        approveStatus();
        // window.location.reload();
      }
    } else {
      if (!successCallback) {
        approveStatusOnly();
        // window.location.reload();
      }
    }
  };

  const showQuestionResponse = (sectionIndex, questionIndex) => {
    const questionAnswers = answers[sectionIndex][questionIndex];
    const questionResponses = [];
    const questionRemarks = [];

    Object.entries(questionAnswers).forEach(([answerId, selectedOption]) => {
      questionResponses.push({
        answerId: answerId,
        answer: selectedOption,
        remark: remarks[sectionIndex][questionIndex],
      });
      questionRemarks.push({
        answerId: answerId,
      });
    });

    justPostData(
      `/api/audit_universe/answers/`,
      {
        answers: questionResponses,
      },
      setErrors
    );
  };

  const removeQuestionResponse = (sectionIndex, questionIndex) => {
    const questionAnswers = answers[sectionIndex][questionIndex];
    const questionResponses = [];

    Object.entries(questionAnswers).forEach(([answerId, selectedOption]) => {
      questionResponses.push(answerId);
    });
    console.log("questionResponses : ", questionResponses);

    justDeleteData(
      `/api/audit_universe/answers/delete/${questionResponses}`,

      setErrors
    );
  };

  const approveStatus = () => {
    justUpdateData(
      `/api/audit_universe/questionnaire/${questionnaireID}/approve`,
      {
        approved: true,
        status: "Closed",
      },
      navigate,
      setErrors
    );
    // window.location.reload();
  };

  const approveStatusOnly = () => {
    justUpdateData(
      `/api/audit_universe/questionnaire/${questionnaireID}`,
      {
        approved: false,
        status: "Submitted",
      },
      navigate,
      setErrors
    );
    // window.location.reload();
  };

  const inprogressStatus = () => {
    justUpdateData(
      `/api/audit_universe/questionnaire/${questionnaireID}`,
      {
        approved: true,
        status: "Inprogress",
      },
      navigate,
      setErrors
    );
    // window.location.reload();
  };

  const rejectStatus = () => {
    justUpdateData(
      `/api/audit_universe/questionnaire/${questionnaireID}`,
      {
        approved: false,
        status: "Open",
      },
      navigate,
      setErrors
    );
    // window.location.reload();
  };

  const handleSelectAll = (sectionIndex, questionIndex, columnIndex) => {
    const newSelectedColumnIndexes = [...selectedColumnIndexes];
    const newAnswers = answers.map((section, secIdx) =>
      section.map((question, quesIdx) => {
        if (secIdx === sectionIndex && quesIdx === questionIndex) {
          const allChecked = Object.keys(question).every(
            (answerId) => question[answerId] === columnIndex + 1
          );

          const updatedAnswers = Object.keys(question).reduce(
            (acc, answerId) => {
              acc[answerId] = allChecked ? 0 : columnIndex + 1;
              return acc;
            },
            {}
          );

          return updatedAnswers;
        }
        return question;
      })
    );

    newSelectedColumnIndexes[sectionIndex][questionIndex] = columnIndex;
    setAnswers(newAnswers);
    setSelectedColumnIndexes(newSelectedColumnIndexes);
  };

  const areAllAnswersFilled = () => {
    return answers.every((section) =>
      section.every((question) =>
        Object.values(question).every((answer) => answer > 0)
      )
    );
  };

  const areAllSelectedOptionsFilled = (data) => {
    return data.sections.every((section) =>
      section.questions.every((question) =>
        question.answers.every((answer) => answer.selected_option !== null)
      )
    );
  };

  useEffect(() => {
    setAllAnswersFilled(areAllAnswersFilled());
  }, [answers]);

  useEffect(() => {
    setAllSelectedOptionsFilled(areAllSelectedOptionsFilled(data));
    if (allSelectedOptionsFilled) {
      setSubmitbtn(true);
    }
  }, [data]);

  return (
    <div style={{ position: "relative" }}>
      <div className="submit_approve_btns">
        {showApproval && (
          <>
            <button onClick={showResponse} style={{ backgroundColor: "green" }}>
              Approve
            </button>
            <button
              style={{
                display: isAdminRole ? "" : "none",
                backgroundColor: "red",
              }}
              onClick={rejectStatus}
            >
              Request Changes
            </button>
          </>
        )}
        {showSubmit && (
          <button
            style={{ backgroundColor: allAnswersFilled ? "green" : "gray" }}
            onClick={showResponse}
            disabled={!allAnswersFilled}
          >
            Submit
          </button>
        )}
        {showStatus && (
          <button style={{ backgroundColor: "gray" }} disabled>
            {incomingStatus}
          </button>
        )}
      </div>

      {data.sections.map((section, sectionIndex) => (
        <div key={sectionIndex} className="sectionDiv">
          <h2>{section.section_name}</h2>

          {section.questions.map((question, questionIndex) => (

            <div key={questionIndex} className="questionDiv">

              <button
                onClick={() =>
                  toggleTableVisibility(sectionIndex, questionIndex)
                }
                className="questionBtnSection"
              >
                <img
                  src={arrowToggle}
                  alt="Toggle Arrow"
                  className={
                    tableVisibility[sectionIndex][questionIndex]
                      ? "arrowToggle"
                      : "arrowToggle rotated"
                  }
                />
              </button>

              <div className="subquestionDiv" style={{width:tableSize?"66vw":"76vw"}}>

                <div className="headnandques" >
                  <span>{question.question}</span>

                  <table>


                    <thead className="question_head" >
                      <tr>
                        {options.map((option, optionIndex) => (
                          <td key={optionIndex}>
                            <div className="thOption">
                              {option}
                              <button
                                onClick={() =>
                                  handleSelectAll(
                                    sectionIndex,
                                    questionIndex,
                                    optionIndex
                                  )
                                }
                              >
                                <input
                                  type="checkbox"
                                  checked={
                                    selectedColumnIndexes[sectionIndex][
                                    questionIndex
                                    ] === optionIndex &&
                                    Object.keys(
                                      answers[sectionIndex][questionIndex]
                                    ).every(
                                      (answerId) =>
                                        answers[sectionIndex][
                                        questionIndex
                                        ][answerId] ===
                                        optionIndex + 1
                                    )
                                  }
                                  onChange={() =>
                                    handleSelectAll(sectionIndex, questionIndex, optionIndex)
                                  }
                                  disabled={(() => {
                                    const shouldDisable =
                                      showStatus &&
                                      !showApproval &&
                                      !showSubmit;
                                    return shouldDisable;
                                  })()}
                                />
                              </button>
                            </div>
                          </td>
                        ))}
                      </tr>
                    </thead>
                  </table>
                </div>



                {tableVisibility[sectionIndex][questionIndex] && (
                  <>
                    <div className="tableDiv" >

                      <table
                        className="answerQuestionnaireTable"
                        style={{
                          width: tableSize? 
                          "100%": 
                          "100%",
                        }}
                      >
                        

                        <tbody>
                          {Object.entries(
                            answers[sectionIndex][questionIndex]
                          ).map(([answerId, selectedOption], index) => (
                            <tr key={answerId}>
                              <td>
                                {(() => {
                                  const answer = question.answers.find(
                                    (answer) => answer.id === parseInt(answerId)
                                  );
                                  return answer && answer.audit_unit ? answer.audit_unit.name : 'Loading...';
                                })()}
                              </td>

                              {options.map((option, optionIndex) => (
                                <td key={optionIndex}>
                                  <input
                                    type="checkbox"
                                    checked={selectedOption === optionIndex + 1}
                                    onChange={() => handleAnswerChange(sectionIndex, questionIndex, answerId, optionIndex)}
                                    disabled={(() => {
                                      const shouldDisable = showStatus && !showApproval && !showSubmit;
                                      return shouldDisable;
                                    })()}
                                  />
                                </td>
                              ))}
                            </tr>
                          ))}
                        </tbody>
                      </table>

                      <div className="remarknSaveAQ">
                        <input
                          style={{
                            width: tableSize
                                ? "100%"
                                : "100%",
                          }}
                          type="text"
                          className="remarkAQ"
                          placeholder="Remark"
                          value={remarks[sectionIndex][questionIndex]}
                          onChange={(e) =>
                            handleRemarkChange(
                              sectionIndex,
                              questionIndex,
                              e.target.value
                            )
                          }
                        />
                      </div>
                      {APIQData.status.toLowerCase() !== "closed" && (
                        <div style={{
                          display: "flex", width: "inherit", alignItems: "center", gap: "1vw", justifyContent: "end",width: tableSize
                            ? "100%"
                            : "100%",
                        }}>
                          <button
                            onClick={() =>
                              showQuestionResponse(sectionIndex, questionIndex)
                            }
                            className="questionRes"
                          >
                            Save
                          </button>
                          <div style={{ marginBottom: ".1vw" }}>
                            <WindowComponent onDelete={() =>
                              removeQuestionResponse(sectionIndex, questionIndex)
                            }
                              text={"Remove"}
                            />
                          </div>
                        </div>
                      )}
                    </div>


                  </>
                )}

              </div>

            </div>

          ))}
        </div>
      ))}
    </div>
  );
};

export default QuestionsComps;
