import React from "react";
import {
  NavLink,
  useLocation,
  Link,
  useNavigate,
  useParams,
} from "react-router-dom";
import SidebarMenu from "../SidebarMenu";
import { useState, useEffect } from "react";
import "../../css/viewbu.css";
import create from "../../images/createbutton.svg";
import Breadcrumb from "../Breadcrumb";
import Avatarbu from "./AvtarBu";
import WindowComponent from "../WindowComponent";
import { fetchDataObject,fetchData1, updateData, deleteData, deleteData2 } from "../apiUtils";
import SingleOptionSelector from "../SingleOptionSelector";
import SingleOptionSelectorRole from "../SingleOptionSelectorRole";
import RenderIcons from "../RenderIcons";
import { homeRoutes } from "../subroutes";
// import pencil from "../../images/edit_pencil.svg";
// import save_details from "../../images/saveBtn.svg";
import backlogo from "../../images/backLogo.svg";
import IconButton from "../iconbutton/IconButton";
import CommonInput from "../CommonInput";
import BounceLoader from "react-spinners/BounceLoader";

const getRandomColor = (letter) => {
  const colorSeed = letter.charCodeAt(0) * 137;
  const color = `hsl(${colorSeed % 360}, 70%, 50%)`;
  return color;
};

const ViewBusinessunit = ({tableSize}) => {
  const [errorMessage, setErrorMessage] = useState(false);
  const location = useLocation();
  const [errors, setErrors] = useState("");
  const [editMode, setEditMode] = useState(false);
  const [description, setDescription] = useState("");
  const [name, setName] = useState("");
  const [Id, setId] = useState("");
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const [nameError, setnameError] = useState(false);
  const [descriptionError, setDescriptionError] = useState(false);
  const [selectedBUHeadError, setSelectedUnitsError] = useState(false);
  const [departmentIdError, setDepartmentIdError] = useState(false);
  const [parentBuError, setParentBuError] = useState(false);
  const [selectedDepts, setSelectedDepts] = useState([]);
  const [selectedHeadName, setSelectedHeadName] = useState([]);
  const [selectedHeadId, setSelectedHeadId] = useState("");
  const [selectedHeadIds, setSelectedHeadIds] = useState("");
  const [department_id, setDepartmentId] = useState("");
  const [department_ids, setDepartmentIds] = useState("");
  const [selectedBu, setSelectedBu] = useState([]);
  const [selectedBuId, setSelectedBuId] = useState("");
  const [selectedBuIds, setSelectedBuIds] = useState("");
  const [parent_bu_id, setSelectedParenteId] = useState();
  const [unit_head_id, setUnit_head_id] = useState("");
  const [APIData, setAPIData] = useState([]);
  const [APIUserData, setAPIUserData] = useState([]);
  const [APIBusinessUserData, setAPIBusinessUserData] = useState([]);
  const [loading, setLoading] = useState(false);

  const { id } = useParams();
  useEffect(() => {
    fetchDataObject(`/api/system/business-units/${id}/`, setAPIData, setErrors,setLoading);
  }, [id]);
  useEffect(() => {
    fetchData1(`/api/system/users/`, setAPIUserData, setErrors,setLoading);
  }, []);

  
  useEffect(() => {

    if (APIUserData) {
      setAPIBusinessUserData(APIUserData.filter(
        (user) => user.business_unit?.name === APIData?.name
      ));

    }
  }, [APIUserData]);

  useEffect(() => {

  

  }, [APIBusinessUserData]);


  const data = APIData.id;

  useEffect(() => {
    setId(APIData.id);
    setName(APIData.name);
    setDescription(APIData.description);
    setSelectedDepts([APIData.department ? APIData.department.name : ""]);
    setSelectedHeadName([APIData.unit_head ? APIData.unit_head.name : ""]);
    setSelectedBu([APIData.parent_bu ? APIData.parent_bu.name : ""]);
    setDepartmentId(APIData.department ? APIData.department.id : "");
    setSelectedHeadId(APIData.unit_head ? APIData.unit_head.id : "");
    setSelectedBuId(APIData.parent_bu ? APIData.parent_bu.id : "");
  }, [data]);

  useEffect(() => {
    const pathSegments = location.pathname
      .split("/")
      .filter((segment) => segment !== "");
    setBreadcrumbs(pathSegments);
  }, [location.pathname]);

  const updateAPIData = (e) => {
    e.preventDefault();
    if (!name || !description || !department_ids || !unit_head_id) {
      setErrorMessage(true);
      setErrors("Please fill in all required fields.");
      setnameError(!name ? true : false);
      setDescriptionError(!description ? true : false);
      // setParentBuError(!parent_bu_id ? true : false);
      setSelectedUnitsError(!unit_head_id ? true : false);
      setDepartmentIdError(!department_ids ? true : false);

      setTimeout(() => {
        setErrors(null);
      }, 5000);
      return;
    }

    updateData(
      `/api/system/business-units/${Id}`,
      {
        name,
        description,
        department_id: department_ids,
        parent_bu_id: parent_bu_id ? parent_bu_id : null,
        unit_head_id,
      },
      navigate,
      setErrors,
      `/Administration/businessUnit/${id}`,

    );
  };

  const handleEditClick = () => {
    setEditMode(!editMode);
  };
  const handleCancelClick=() => {
    setEditMode(false)
    window.location.reload();

  }
  const onDelete = (e) => {
    e.preventDefault();
    deleteData2(
      `/api/system/business-units/${Id}`,
      navigate,
      setErrors,
      `/Administration/businessUnit`
    );
  };

  const navigate = useNavigate();

  const handleRemoveSelectedBuHead = (unitToRemove) => {
    setSelectedUnitsError(true);
    selectedHeadName(selectedBu.filter((unit) => unit !== unitToRemove));
  };
  const SelectedBuHeadcomps = ({ name, onRemove }) => (
    <div className="member">
      <Avatarbu firstName={name} backgroundColor={getRandomColor(name)} />
      <span>{name}</span>
    </div>
  );

  return (
    <>
      {errors && <p className="errormessage">{errors}</p>}

      <section className="navroutes">
      <RenderIcons homeRoutes={homeRoutes} />

        <Link to={`/Administration/businessUnit`}>
                  <div
                    className="backBtnDiv"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      gap: "1vw",
                      backgroundColor: "#FFF3CC",
                      padding: ".5vw 1vw",
                      borderRadius: "2.5vw",
                      position:'absolute',
                      top:'12%',
                      right:'2%'
                    }}
                  >
                    <img src={backlogo} style={{ width: "1.2vw" }} />
                    <p
                      style={{
                        fontFamily: "intersemibold",
                        fontSize: ".8vw",
                        color: "#775F0F"
                      }}
                    >
                      Back to All Business Units
                    </p>
                  </div>
                </Link>
        <Breadcrumb />

        <div className="filter-container">
          <div className="filter">
            <div className="views">
              <h3 className="h2_interbold" >Business Unit</h3>
            </div>
          </div>          
        </div>

        {loading ? (
          <div className="spinner-bounce-loader">
          <BounceLoader color="#a6fafa"/> 
          </div>
            
          ): APIData.id===0 ? (
            <p className="data-error-text">No data found</p> 
          ) : (
<div className="bu-table-con" style={{height:'60vh',overflowY:'scroll'}}>
<div className="viewbuconatiner" style={{width: tableSize?"80vw":"90.5vw"}}>
        <div className="delete_edit_close_btn">
          {editMode && <IconButton
            type="close"
            className="closeView"
            tooltip="Cancel"
            onClick={handleCancelClick} 
          />}
          {editMode ? (
            <IconButton
              type="save"
              tooltip="Save"
              onClick={updateAPIData}
              link="/"
            />
          ) : (
            <IconButton type="edit" tooltip="Edit" onClick={handleEditClick} />
          )}
          <WindowComponent onDelete={onDelete} />
        </div>

        <form>
            
              <CommonInput
                labelName="BU Name"
                error={nameError}
                type="text"
                placeHolder="App Development"
                setText={setName}
                value={name}
                editMode={!editMode}
                setTextError={setnameError}
                commanDivClass="lbl_inpt"
              />
            

            
              <CommonInput
                labelName="Description "
                error={descriptionError}
                type="text"
                placeHolder="541-AF-9466D"
                setText={setDescription}
                value={description}
                editMode={!editMode}
                setTextError={setDescriptionError}
                commanDivClass="lbl_inpt"
              />
            
              
                <SingleOptionSelector
                  apiUrl="/api/system/departments/"
                  setSelectorId={setDepartmentIds}
                  setSelectorError={setDepartmentIdError}
                  selectorError={departmentIdError}
                  Name="name"
                  title="Department"
                  initialId={department_id}
                  initialName={selectedDepts}
                  editMode={editMode}
                />
              

                <SingleOptionSelector
                  apiUrl="/api/system/business-units/"
                  setSelectorId={setSelectedParenteId}
                  setSelectorError={setParentBuError}
                  selectorError={parentBuError}
                  Name="name"
                  title="Parent B.U."
                  initialId={selectedBuId}
                  initialName={selectedBu}
                  editMode={editMode}
                />



              

              {editMode ? (
                
                <SingleOptionSelector
                apiUrl="/api/system/users/"
                setSelectorId={setUnit_head_id}
                setSelectorError={setSelectedUnitsError}
                selectorError={selectedBUHeadError}
                Name="first_name"
                title="Bu Head"
                initialId={selectedHeadId}
                initialName={selectedHeadName}
                editMode={editMode}
              />
                
              ) : (
                <div className="lbl_selector">
                 
                    <label>BU Head</label>
                  

                  <div
                    className="memberscontainer"
                    style={{
                      backgroundColor: editMode ? "#EEF1FC" : "#e6e6e6",
                    }}
                  >
                    {editMode ? (
                      <></>
                    ) : (
                      
                        <>
                          
                          
                              {selectedHeadName
                                ? selectedHeadName.map((unit) => (
                                    <SelectedBuHeadcomps
                                      key={unit}
                                      name={unit}
                                      onRemove={handleRemoveSelectedBuHead}
                                    />
                                  ))
                                : ""}
                            
                          
                        </>
                      
                    )}
                  </div>
                </div>
              )}
            
          
        </form>

        <div className="editdeletecontainer"></div>
      </div>

         <div className="tablecontainer" style={{marginTop:'2vh'}}>
          <table style={{ width: tableSize ? "80vw" : "90.5vw" }}>
            <thead >
              <tr className="tablehead">
                <th>
                  <h4 className="thhead"> Name</h4>
                  
                </th>
                <th>
                  <h4 className="thhead"> Department</h4>
                 
                </th>
                <th>
                  <h4 className="thhead"> Designation</h4>
                 
                </th>
                <th>
                  <h4 className="thhead"> Roles</h4>
                 
                </th>
              </tr>
            </thead>
            <tbody>
              {APIBusinessUserData
      .sort((a, b) => a.last_name.localeCompare(b.last_name))
      .sort((a, b) => a.first_name.localeCompare(b.first_name))
      .map((user) => (
        <tr className="tabledata" >
          <td>
            {user.first_name} {user.last_name}
          </td>
          <td>{user.department ? user.department.name : " "}</td>
          <td>{user.designation}</td>
          <td>
            <div className="tagscontainer">
              {user.groups.map((unit) => (
                <div key={unit.id}>{unit.name}</div>
              ))}
            </div>
          </td>
         
        </tr>))}
            </tbody>
          </table>
        </div>
        </div>
          )
        }

       

      </section>
    </>
  );
};

export default ViewBusinessunit;