import React, { useState, useEffect } from "react";
import { NavLink, useLocation, Link, useNavigate, useParams } from "react-router-dom";
import Breadcrumb from "../../../Breadcrumb";
import SidebarMenu from "../../../SidebarMenu";
import "../../../../css/viewuser.css";
import "../../rcm/listofrcap.css";
import rcap_openbutton from "../../../../images/rcap_openbutton.svg";
import openarrow from "../../../../images/openarrow.svg";
import { fetchData, updateData,deleteData } from "../../../apiUtils";
import { auditRoutes } from "../../../subroutes";
import create from "../../../../images/createbutton.svg";
import "./creatercap.css";
import WindowComponent from "../../../WindowComponent";
import IconButton from "../../../iconbutton/IconButton";
import { auditExecution } from "../../../subroutes";
import corporate from "../../../../images/corporate.svg"; // Adjust path as necessary
import star from "../../../../images/star.svg"; // Adjust path as necessary
import risk from "../../../../images/risk.svg"; // Adjust path as necessary
import auditPlan from "../../../../images/auditPlan.svg"; // Adjust path as necessary
import audit_icon from "../../../../images/audit_icon.svg"; // Adjust path as necessary
import planning_icon from "../../../../images/planning_icon.svg"; // Adjust path as necessary
import fieldwork_icon from "../../../../images/fieldwork_icon.svg"; // Adjust path as necessary
import reporting_icon from "../../../../images/reporting_icon.svg"; // Adjust path as necessary
import wrapupqa_icon from "../../../../images/wrapup_icon.svg"; // Adjust path as necessary



const ViewRCAP = ({ tableSize }) => {

  const [APIData, setAPIData] = useState([]);
  const [APIDataAudit, setAPIDataAudit] = useState([]);

  const [APIDataRcap, setAPIDatRcap] = useState([]);
  const [errors, setErrors] = useState("");
  const [objective, setObjective] = useState('');
  const [risk_id, setRiskId] = useState("");
  const [risk_category, setRiskCategory] = useState("");
  const [risk_description, setDescribeRisk] = useState("");
  const [risk_title, setTitleRisk] = useState("");
  const [inherent_assessment_likelihood, setLikelikhood] = useState("");
  const [inherent_assessment_impact, setImpact] = useState("");
  const [control_existing, setExistingControl] = useState("");
  const [control_desired, setDesiredControl] = useState("");
  const [control_evaluation, setEvaluation] = useState("");
  const [control_design, setControlDesign] = useState("");
  const [control_type, setcontrolType] = useState("");
  const [control_frequency, setcontrolFrequency] = useState("");
  const [control_gap, setControlGap] = useState("");
  const [residual_assessment_likelihood, setResLikelihood] = useState("");
  const [residual_assessment_impact, setResImpact] = useState("");
  const [audit_procedure_step, setAuditStepDetails] = useState("");
  const [audit_procedure_data, setDataRequired] = useState("");
  const [likelikhoodError, setLikelikhoodError] = useState(false);
  const [impactError, setImpactError] = useState(false);
  const [existingControlError, setExistingControlError] = useState(false);
  const [desiredControlError, setDesiredControlError] = useState(false);
  const [evaluationError, setEvaluationError] = useState(false);
  const [controlDesignError, setControlDesignError] = useState(false);
  const [controlTypeError, setcontrolTypeError] = useState(false);
  const [controlFrequencyError, setcontrolFrequencyError] = useState(false);
  const [controlGapError, setControlGapError] = useState(false);
  const [resLikelihoodError, setResLikelihoodError] = useState(false);
  const [resImpactError, setResImpactError] = useState(false);
  const [auditStepDetailsError, setAuditStepDetailsError] = useState(false);
  const [dataRequiredError, setDataRequiredError] = useState(false);
  const [objectiveError, setObjectiveError] = useState(false);
  const [riskIdError, setRiskIdError] = useState(false);
  const [riskCategoryError, setRiskCategoryError] = useState(false);
  const [describeRiskError, setDescribeRiskError] = useState(false);
  const [titleRiskError, setTitleRiskError] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const successMessageColor = location.state?.successMessageColor;
  const [editMode, setEditMode] = useState(false);
  const successMessage = location.state?.successMessage;
  const [showMessage, setShowMessage] = useState(!!successMessage);
  const originalUrl = location.state?.from;
  const { id, rid,planid, tempId } = useParams();

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      navigate(".", { replace: true });
    }, 5000);

    return () => clearTimeout(timeoutId);
  }, []);

  useEffect(() => {
    fetchData(`/api/audit_universe/audit-entities/`, setAPIData, setErrors);
  }, []);

  useEffect(() => {
    fetchData(`/api/audit_universe/rcap/${rid}/`, setAPIDatRcap, setErrors);
  }, []);






  const fullUrl = window.location.origin + location.pathname;
  const segments = location.pathname.split('/');
  const auditUniverse = segments.find(segment => segment === 'auditUniverse');
  const [entityId, setEntityId] = useState("");








  useEffect(() => {
    fetchData(`/api/audit_engagement/audit/${id}/`, setAPIDataAudit, setErrors);
  }, []);

  useEffect(() => {
       if(APIDataAudit.audit_entity){
          if(!auditUniverse){
            setEntityId(APIDataAudit.audit_entity.id);
          }
       }
  }, [APIDataAudit]);


  useEffect(() => {
    setObjective(APIDataRcap.objective ? APIDataRcap.objective : '')
    setRiskId(APIDataRcap.risk_id ? APIDataRcap.risk_id : '')
    setRiskCategory(APIDataRcap.risk_category ? APIDataRcap.risk_category : '')
    setDescribeRisk(APIDataRcap.risk_description ? APIDataRcap.risk_description : '')
    setTitleRisk(APIDataRcap.risk_title ? APIDataRcap.risk_title : '')
    setLikelikhood(APIDataRcap.inherent_assessment_likelihood ? APIDataRcap.inherent_assessment_likelihood : '')
    setImpact(APIDataRcap.inherent_assessment_impact ? APIDataRcap.inherent_assessment_impact : '')
    setExistingControl(APIDataRcap.control_existing ? APIDataRcap.control_existing : '')
    setDesiredControl(APIDataRcap.control_desired ? APIDataRcap.control_desired : '')
    setEvaluation(APIDataRcap.control_evaluation ? APIDataRcap.control_evaluation : '')
    setControlDesign(APIDataRcap.control_design ? APIDataRcap.control_design : '')
    setcontrolType(APIDataRcap.control_type ? APIDataRcap.control_type : '')
    setcontrolFrequency(APIDataRcap.control_frequency ? APIDataRcap.control_frequency : '')
    setControlGap(APIDataRcap.control_gap ? APIDataRcap.control_gap : '')
    setResLikelihood(APIDataRcap.residual_assessment_likelihood ? APIDataRcap.residual_assessment_likelihood : '')
    setResImpact(APIDataRcap.residual_assessment_impact ? APIDataRcap.residual_assessment_impact : '')
    setAuditStepDetails(APIDataRcap.audit_procedure_step ? APIDataRcap.audit_procedure_step : '')
    setDataRequired(APIDataRcap.audit_procedure_data ? APIDataRcap.audit_procedure_data : '')
  }, [APIDataRcap]);

  const Viewuser = (data) => {
    const id = data.id;
    navigate(`/auditUniverse/auditEntities/${id}/`);
  };

  const [filters, setFilters] = useState({
    name: "",
    key_stakeholder: "",
    businessUnit: "",
  });

  const handleFilterChange = (filterKey, e) => {
    setFilters({ ...filters, [filterKey]: e.target.value });
  };

  // const renderIcons = () => {
  //   return auditRoutes.map((route, index) => {
  //     if (route.subRoutes) {
  //       return <SidebarMenu route={route} key={index} />;
  //     }

  //     return (
  //       <NavLink
  //         to={route.path}
  //         key={index}
  //         className="navlink"
  //         activeclassname="active1"
  //       >
  //         <div className="navicon">
  //           <img src={route.icon} alt="Dashboard Icon" />
  //         </div>
  //         <div className="navicon">{route.name}</div>
  //       </NavLink>
  //     );
  //   });
  // };

  //   const filteredData = APIData.filter((data) => {
  //     const fullName = data.name.toLowerCase();
  //     const NameMatch = filters.name
  //       ? fullName.includes(filters.name.toLowerCase())
  //       : true;

  //     const keyStakeholderName = data.key_stakeholder
  //       ? data.key_stakeholder.name
  //         ? data.key_stakeholder.name.toLowerCase()
  //         : ""
  //       : "";
  //     const KeyStakeholder = filters.key_stakeholder
  //       ? keyStakeholderName.includes(filters.key_stakeholder.toLowerCase())
  //       : true;

  //     const businessUnitMatch = filters.businessUnit
  //       ? data.audit_unit.some((unit) => {
  //           const unitNameLower = unit.name.toLowerCase();
  //           const filterUnitLower = filters.businessUnit.toLowerCase();
  //           return unitNameLower.includes(filterUnitLower);
  //         })
  //       : true;

  //     return NameMatch && KeyStakeholder && businessUnitMatch;
  //   });

  function afSpinner() {
    if (!APIData || APIData.length === 0) {
      return (
        <tr>
          <td colSpan="3" className="spinner-container">
            <div className="spinner"></div>
          </td>
        </tr>
      );
    }

    //     return filteredData.map((data) => (
    //       <tr className="tabledata" onClick={() => Viewuser(data)} key={data.id}>
    //         <td>{data.name}</td>
    //         <td>{data.key_stakeholder ? data.key_stakeholder.name : ""}</td>
    //         <td>
    //           <div className="tagscontainer">
    //             {data.audit_unit
    //               ? data.audit_unit.map((unit) => (
    //                   <div className="tag" key={unit.id}>
    //                     {unit.name}
    //                   </div>
    //                 ))
    //               : ""}
    //           </div>
    //         </td>
    //       </tr>
    //     ));
  }
  const [urlNav ,setUrlNav]=useState('')


useEffect(() => {
  if(entityId){
        setUrlNav(`/AuditExecution/${id}/Planning/${planid}/${tempId}/`)
}
else{
  setUrlNav(`/auditUniverse/auditEntities/${id}/${tempId}/`)

}
}, [entityId]);

  const updateAPIData = (e) => {
    e.preventDefault();
    if (!objective || !risk_id || !risk_category || !risk_description || !risk_title || !inherent_assessment_likelihood || !inherent_assessment_impact || !control_existing || !control_desired || !control_evaluation || !control_design || !control_type || !control_frequency || !control_gap || !risk_title || !residual_assessment_likelihood || !residual_assessment_impact || !audit_procedure_step || !control_desired || !audit_procedure_data) {
      setObjectiveError(!objective ? true : false);
      setRiskIdError(!risk_id ? true : false);
      setRiskCategoryError(!risk_category ? true : false);
      setDescribeRiskError(!risk_description ? true : false);
      setTitleRiskError(!risk_title ? true : false);
      setLikelikhoodError(!inherent_assessment_likelihood ? true : false);
      setImpactError(!inherent_assessment_impact ? true : false);
      setExistingControlError(!control_existing ? true : false);
      setDesiredControlError(!control_desired ? true : false);
      setEvaluationError(!control_evaluation ? true : false);
      setControlDesignError(!control_design ? true : false);
      setcontrolTypeError(!control_type ? true : false);
      setcontrolFrequencyError(!control_frequency ? true : false);
      setControlGapError(!control_gap ? true : false);
      setResLikelihoodError(!residual_assessment_likelihood ? true : false);
      setResImpactError(!residual_assessment_impact ? true : false);
      setAuditStepDetailsError(!audit_procedure_step ? true : false);
      setDataRequiredError(!audit_procedure_data ? true : false);




      setErrors("Please fill in all required fields.");
      setTimeout(() => {
        setErrors(null);
      }, 5000);
      return;
    }
    updateData(
      `/api/audit_universe/rcap/${rid}`,
      {
        objective,
        risk_id,
        risk_category,
        risk_description,
        risk_title,
        inherent_assessment_likelihood,
        inherent_assessment_impact,
        control_existing,
        control_desired,
        control_evaluation,
        control_design,
        control_type,
        control_frequency,
        control_gap,
        residual_assessment_likelihood,
        residual_assessment_impact,
        audit_procedure_step,
        audit_procedure_data,
        audit_entity: entityId ? entityId : id,
        rcm_template_id : tempId,
      },
      navigate,
      setErrors,
      urlNav
    );
  };
  const handleEditClick = () => {
    setEditMode(!editMode);
  };
  const onDelete = (e) => {
    e.preventDefault();
    deleteData(
      `/api/audit_universe/rcap/${rid}`,
      navigate,
      setErrors,
      urlNav
    );
  };


  const auditUniverseNavLinks = [
    { path: "/AuditUniverse/corporateStructure", name: "Corporate Structure", icon: corporate },
    { path: "/AuditUniverse/AuditEntities", name: "Audit Entities", icon: star },
    { path: "/AuditUniverse/riskAssessment", name: "Risk Assessment", icon: risk },
    { path: "/AuditUniverse/auditPlanning", name: "Audit Planning", icon: auditPlan }
  ];

  const auditExecutionNavLinks = [
    { path: `/AuditExecution/${id}/Detail`, name: "Detail", icon: audit_icon },
    { path: `/AuditExecution/${id}/Planning`, name: "Planning", icon: planning_icon },
    { path: `/AuditExecution/${id}/Fieldwork`, name: "Fieldwork", icon: fieldwork_icon },
    { path: `/AuditExecution/${id}/Reporting`, name: "Reporting", icon: reporting_icon },
    { path: `/AuditExecution/${id}/WrapupQA`, name: "WrapupQA", icon: wrapupqa_icon }
  ];

  const navLinks = auditUniverse ? auditUniverseNavLinks : auditExecutionNavLinks;



  
  return (
    <>
      {errors && <p className="errormessage">{errors}</p>}
      {successMessage && <p className="successmessage">{successMessage}</p>}

      <section className="navroutes">
        {/* <div className="bumenucontainer">{renderIcons()}</div> */}
        <div className="auditMenuCon">
  {navLinks.map((route, index) => {
    if (route.subRoutes) {
      return <SidebarMenu route={route}/>;
    }

    return (
      <NavLink
        to={`${route.path.replace(":id", id)}`}
        key={index}
        className="navlink"
        activeclassname="active"
      >
        <div className="navicon">
          <img src={route.icon} alt="Dashboard Icon" />
        </div>
        <div className="navicon">{route.name}</div>
      </NavLink>
    );
  })}
  </div>
        <hr className="breadcrumbabv" />
        <Breadcrumb />

        <div className="filter-container">
          <div className="filter">
            <div className="views">
              <h3 className="h2_interbold" >View RCAP</h3>
              {/* <div className="viewbuttons">
                <button className="active2">List view</button>
              </div> */}
            </div>

            <div className="searchncbtn">
              {/* <input
                type="text"
                placeholder="Search"
                className="searchbar"
                onChange={(e) => handleFilterChange("name", e)}
                value={filters.name}
              /> */}


            </div>
          </div>
        </div>

        <div
          className="RCM_template_con"
          style={{ width: tableSize ? "80vw" : "90.5vw" }}
        >
          <div className="delete_edit_close_btn">
            <IconButton type="close" className='closeView' tooltip="Close" link={urlNav} />
            {editMode ? (
              <IconButton type="save" tooltip="Save" onClick={updateAPIData} link="/" />
            ) : (
              <IconButton type="edit" tooltip="Edit" onClick={handleEditClick} />
            )}
            <WindowComponent onDelete={onDelete} />
          </div>
          <form action="">
            <div className="rcap_labelandinputs">
              <label htmlFor="">Objective</label>
              <input
                type="text"
                placeholder="Unauthorized Access to Financial Data"
                style={{
                  width: tableSize ? "75vw" : "84.5vw",
                  border: objectiveError ? "1px solid #FF4646" : "",
                }}
                value={objective}
                onChange={(e) => {
                  setObjective(e.target.value);
                  setObjectiveError(false);
                }}
                readOnly={!editMode}
              />
            </div>

            <div
              className="rcap_section"
              style={{ width: tableSize ? "75vw" : "84vw" }}
            >
              <h3>Risk</h3>

              <div className="rcap_title_description">
                <div className="rcap_id_category_title">
                  <div className="rcap_labelandinputs">
                    <label htmlFor="">Risk ID</label>
                    <input
                      type="text"
                      placeholder="NG57FRE"
                      style={{
                        width: tableSize ? "19vw" : "22vw",
                        border: riskIdError ? "1px solid #FF4646" : "",
                      }}
                      readOnly={!editMode}
                      value={risk_id}
                      onChange={(e) => {
                        setRiskId(e.target.value);
                        setRiskIdError(false);
                      }}
                    />
                  </div>

                  <div className="rcap_labelandinputs">
                    <label htmlFor="">Risk Category</label>
                    <input
                      type="text"
                      placeholder="Financial"
                      style={{
                        width: tableSize ? "19vw" : "22vw",
                        border: riskCategoryError ? "1px solid #FF4646" : "",
                      }}
                      readOnly={!editMode}
                      value={risk_category}
                      onChange={(e) => {
                        setRiskCategory(e.target.value);
                        setRiskCategoryError(false);
                      }}
                    />
                  </div>

                  <div className="rcap_labelandinputs">
                    <label htmlFor="">Risk Title</label>
                    <input
                      type="text"
                      placeholder="Unauthorized Access to Financial Data"
                      style={{
                        width: tableSize ? "39vw" : "45vw",
                        border: titleRiskError ? "1px solid #FF4646" : "",
                      }}
                      readOnly={!editMode}
                      value={risk_title}
                      onChange={(e) => {
                        setTitleRisk(e.target.value);
                        setTitleRiskError(false);
                      }}
                    />
                  </div>
                </div>

                <div className="rcap_labelandinputs">
                  <label htmlFor="">Describe Risk</label>
                  <textarea
                    type="text"
                    placeholder="Describe Risk"
                    style={{
                      width: tableSize ? "35vw" : "38vw",
                      border: describeRiskError ? "1px solid #FF4646" : "",
                    }}
                    readOnly={!editMode}
                    value={risk_description}
                    onChange={(e) => {
                      setDescribeRisk(e.target.value);
                      setDescribeRiskError(false);
                    }}
                  />
                </div>
              </div>
            </div>

            <div
              className="rcap_section"
              style={{ width: tableSize ? "75vw" : "84vw" }}
            >
              <h3>Inherit Assessment</h3>

              <div className="inherit_risk_section">
                <div className="rcap_labelandinputs">
                  <label htmlFor="">Inh. Likelikhood</label>
                  <input
                    type="text"
                    placeholder="NG57FRE"
                    style={{ width: tableSize ? "12vw" : "15vw", border: likelikhoodError ? "1px solid #FF4646" : "", }}
                    value={inherent_assessment_likelihood}
                    readOnly={!editMode}
                    onChange={(e) => {
                      setLikelikhood(e.target.value);
                      setLikelikhoodError(false);
                    }}
                  />
                </div>

                <div className="rcap_labelandinputs">
                  <label htmlFor="">Inh. Impact</label>
                  <input
                    type="text"
                    placeholder="NG57FRE"
                    style={{ width: tableSize ? "12vw" : "15vw", border: impactError ? "1px solid #FF4646" : "", }}
                    value={inherent_assessment_impact}
                    readOnly={!editMode}
                    onChange={(e) => {
                      setImpact(e.target.value);
                      setImpactError(false);
                      
                    }}
                  />
                </div>
              </div>
            </div>

            <div
              className="rcap_section"
              style={{ width: tableSize ? "75vw" : "84vw" }}
            >
              <h3>Control</h3>

              <div className="control_section">
                <div className="rcap_labelandinputs">
                  <label htmlFor="">Control Statement (Existing Control)</label>
                  <textarea
                    type="text"
                    placeholder="NG57FRE"
                    style={{ width: tableSize ? "75vw" : "85vw", border: existingControlError ? "1px solid #FF4646" : "", }}
                    value={control_existing}
                    readOnly={!editMode}
                    onChange={(e) => {
                      setExistingControl(e.target.value);
                      setExistingControlError(false);
                    }}
                  />
                </div>

                <div className="rcap_labelandinputs">
                  <label htmlFor="">
                    Best practices for refrence (Desired Control)
                  </label>
                  <textarea
                    type="text"
                    placeholder="NG57FRE"
                    style={{ width: tableSize ? "75vw" : "85vw", border: desiredControlError ? "1px solid #FF4646" : "", }}
                    value={control_desired}
                    readOnly={!editMode}
                    onChange={(e) => {
                      setDesiredControl(e.target.value);
                      setDesiredControlError(false);
                    }}
                  />
                </div>

                <div
                  className="control_section_selections"
                  style={{ width: tableSize ? "75vw" : "85vw" }}
                >
                  <div className="rcap_labelandinputs">
                    <label htmlFor="">Control Evaluation</label>
                    <input
                      type="text"
                      placeholder="NG57FRE"
                      style={{ width: tableSize ? "12vw" : "15vw", border: evaluationError ? "1px solid #FF4646" : "", }}
                      value={control_evaluation}
                      readOnly={!editMode}
                      onChange={(e) => {
                        setEvaluation(e.target.value);
                        setEvaluationError(false);
                      }}
                    />
                  </div>

                  <div className="rcap_labelandinputs">
                    <label htmlFor="">Control Design</label>
                    <input
                      type="text"
                      placeholder="NG57FRE"
                      style={{ width: tableSize ? "12vw" : "15vw", border: controlDesignError ? "1px solid #FF4646" : "", }}
                      value={control_design}
                      readOnly={!editMode}
                      onChange={(e) => {
                        setControlDesign(e.target.value);
                        setControlDesignError(false);
                      }}
                    />
                  </div>

                  <div className="rcap_labelandinputs">
                    <label htmlFor="">Control Type</label>
                    <input
                      type="text"
                      placeholder="NG57FRE"
                      style={{ width: tableSize ? "12vw" : "15vw", border: controlTypeError ? "1px solid #FF4646" : "", }}
                      value={control_type}
                      readOnly={!editMode}
                      onChange={(e) => {
                        setcontrolType(e.target.value);
                        setcontrolTypeError(false);
                      }}
                    />
                  </div>

                  <div className="rcap_labelandinputs">
                    <label htmlFor="">Control Frequency</label>
                    <input
                      type="text"
                      placeholder="NG57FRE"
                      style={{ width: tableSize ? "12vw" : "15vw", border: controlFrequencyError ? "1px solid #FF4646" : "", }}
                      value={control_frequency}
                      readOnly={!editMode}
                      onChange={(e) => {
                        setcontrolFrequency(e.target.value);
                        setcontrolFrequencyError(false);
                      }}
                    />

                  </div>

                  <div className="rcap_labelandinputs">
                    <label htmlFor="">Control Gap</label>
                    <input
                      type="text"
                      placeholder="NG57FRE"
                      style={{ width: tableSize ? "12vw" : "15vw", border: controlGapError ? "1px solid #FF4646" : "", }}
                      value={control_gap}
                      readOnly={!editMode}
                      onChange={(e) => {
                        setControlGap(e.target.value);
                        setControlGapError(false);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="rcap_labelandinputs">
              <label htmlFor="">Res. Likelihood</label>
              <input
                type="text"
                placeholder="NG57FRE"
                style={{ width: tableSize ? "12vw" : "15vw", border: resLikelihoodError ? "1px solid #FF4646" : "", }}
                value={residual_assessment_likelihood}
                readOnly={!editMode}
                onChange={(e) => {
                  setResLikelihood(e.target.value);
                  setResLikelihoodError(false);
                }}
              />
            </div>

            <div className="rcap_labelandinputs">
              <label htmlFor="">Res. Impact</label>
              <input
                type="text"
                placeholder="NG57FRE"
                style={{ width: tableSize ? "12vw" : "15vw", border: resImpactError ? "1px solid #FF4646" : "", }}
                value={residual_assessment_impact}
                readOnly={!editMode}
                onChange={(e) => {
                  setResImpact(e.target.value);
                  setResImpactError(false);
                }}
              />
            </div>

            <div className="rcap_labelandinputs">
              <label htmlFor="">Audit Step Details</label>
              <input
                type="text"
                placeholder="NG57FRE"
                style={{ width: tableSize ? "12vw" : "15vw", border: auditStepDetailsError ? "1px solid #FF4646" : "", }}
                value={audit_procedure_step}
                readOnly={!editMode}
                onChange={(e) => {
                  setAuditStepDetails(e.target.value);
                  setAuditStepDetailsError(false);
                }}
              />
            </div>

            <div className="rcap_labelandinputs">
              <label htmlFor="">Data Required</label>
              <input
                type="text"
                placeholder="NG57FRE"
                style={{ width: tableSize ? "12vw" : "15vw", border: dataRequiredError ? "1px solid #FF4646" : "", }}
                value={audit_procedure_data}
                readOnly={!editMode}
                onChange={(e) => {
                  setDataRequired(e.target.value);
                  setDataRequiredError(false);
                }}
              />
            </div>

          </form>
        </div>

        <div className="cardscontainer">
          {/* {filteredData.map((data) => (
            <div
              className="aecard"
              onClick={() => Viewuser(data)}
              key={data.id}
            >
              <h1>{data.name} </h1>
              <img src={openarrow} alt="Open" />
            </div>
          ))} */}
        </div>
      </section>
    </>
  );
};

export default ViewRCAP;
