import React, { useState, useEffect } from "react";
import { NavLink, useLocation, Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Breadcrumb from "../../Breadcrumb";
import SidebarMenu from "../../SidebarMenu";
import "../../../css/viewuser.css";
import { fetchData, postData } from "../../apiUtils";
import { auditExecution, auditExecutionInnerBU, auditExecutionInnerQA, auditBusiness } from "../../subroutes";
import create from "../../../images/createbutton.svg"
import openarrow from "../../../images/openarrow.svg"
import "../auditplan/auditplan.css"
import ProgressBar from "../progressbar/ProgressBar";
import "./auditwrapup.css"
import RenderIcons from "../../RenderIcons";
import pencil from "../../../images/editpencil_wbg.svg"
import deleteicon from "../../../images/delete_wbg.svg"

const AuditWrapUp = ({ tableSize }) => {
    const [errors, setErrors] = useState("")
    const [APIData, setAPIData] = useState([]);
    const [auditData, setAuditData] = useState([]);
    const [checklistTaskData, setChecklistTaskData] = useState([])
    const [answer1,setAnswer1]=useState([])
    const [surveyId, setQaSurveyId] = useState([]);


    const location = useLocation();
    const navigate = useNavigate();
    const successMessageColor = location.state?.successMessageColor;
    const successMessage = location.state?.successMessage;
    const [showMessage, setShowMessage] = useState(!!successMessage);
    const originalUrl = location.state?.from;
    const { id } = useParams();




    const userRoles = localStorage.getItem("userRoles");


    var routes = [];
    if (userRoles.includes("Admin")) {
        routes = auditExecution;
    } else if (userRoles.includes("Business")) {
        routes = auditExecutionInnerBU;
    }
    //  else if (userRoles.includes("Audit Team")) {
    //   routes = forAuditTeam;
    // } else if (userRoles.includes("Audit Commission")) {
    //   routes = forAuditCommission;
    // } 
    else if (userRoles.includes("Quality Assurance")) {
        routes = auditExecutionInnerQA;
    }
    else {
        console.log("No matching role found");
    }

    const wrapcarddata = [
        {
            "id": "1",
            "objective": "Audit Feedback",
            "risk_title": "Audit Risk Title 1",
            "unit_head": { "name": "John Doe" },
            "department": { "name": "Quality Assurance" }
        },
        {
            "id": "2",
            "objective": "QA Checklist",
            "risk_title": "Audit Risk Title 2",
            "unit_head": { "name": "Jane Smith" },
            "department": { "name": "Compliance" }
        }
    ];

    const Viewuser = (id) => {
        navigate(`/AuditExecution/${id}/WrapupQA/${id}`);
    };

    console.log(id)


    useEffect(() => {
        const timeoutId = setTimeout(() => {
            navigate(".", { replace: true });
        }, 5000);

        return () => clearTimeout(timeoutId);
    }, []);

    useEffect(() => {

        fetchData(`/api/audit_universe/audit-entities/`, setAPIData, setErrors);

    }, []);

    useEffect(() => {
        fetchData(`/api/audit_engagement/audit/${id}/`, setAuditData, setErrors);
    }, [id]);

    useEffect(() => {
        fetchData(`/api/audit_engagement/qa-survey/?audit_id=${id}`, setQaSurveyId, setErrors);
      }, [id]);

    useEffect(() => {
        
          fetchData(
            `/api/audit_engagement/qa-survey/answers/?audit_id=${id}`,
            setAnswer1,
            setErrors
          );
        
      }, [id]);
    //   console.log(surveyId)
      console.log(answer1)

    useEffect(() => {
        fetchData(`/api/audit_engagement/qa-checklist/tasks/?audit_id=${id}`, setChecklistTaskData, setErrors);
      }, [id])

      


     const createTask=() => {
        postData(`/api/audit_engagement/qa-checklist/tasks/`,
            {
                audit_id:parseInt(id)
            },
            navigate,
            setErrors,
            `/AuditExecution/${id}/WrapupQA/QAChecklist/`
        )
     }

     const createqasuvreytask=()=>{
        postData(`/api/audit_engagement/qa-survey/answers/create/`,
            {
                audit_id:parseInt(id)
            },
            navigate,
            setErrors,
            `/AuditExecution/${id}/WrapupQA/QASurvey/`
        )
     }
    const [filters, setFilters] = useState({
        name: "",
        key_stakeholder: "",
    });

    const handleFilterChange = (filterKey, e) => {
        setFilters({ ...filters, [filterKey]: e.target.value });
    };

   

    const filteredData = APIData.filter((data) => {

        const fullName = data.name.toLowerCase();
        const NameMatch = filters.name ? fullName.includes(filters.name.toLowerCase()) : true;
        const keyStakeholderName = "";
        const KeyStakeholder = filters.key_stakeholder ? keyStakeholderName.includes(filters.key_stakeholder.toLowerCase()) : true;

        const businessUnitMatch = filters.business_unit ? data.business_units.some(unit => {
            const unitNameLower = unit.name.toLowerCase();
            const filterUnitLower = filters.business_unit.toLowerCase();
            return unitNameLower.includes(filterUnitLower);
        }) : true;

        return NameMatch && KeyStakeholder && businessUnitMatch;
    });

    const navigateTOFeedback = ()=>{
        navigate(`/AuditExecution/${id}/WrapupQA/QASurvey`)
    }

    const navigateTOCheckList= ()=>{
        navigate(`/AuditExecution/${id}/WrapupQA/QAChecklist`)
    }

    return (
        <>
            {errors && <p className="errormessage">{errors}</p>}

            {successMessage && <p className="successmessage">{successMessage}</p>}

            <section className="navroutes">
            <RenderIcons homeRoutes={auditExecution} id ={id}/>


                <div className="filter-container" >
                    <div className="filter">
                       
                    <Breadcrumb />

                        <div className="searchncbtn">
                            <input type="text" placeholder="Search" className="searchbar , searchbar_hide" />
                            <span className="auditstatusphase"><p>{auditData.phase}</p></span>

                            <div className="auditreport_btn">
                                <button>View Audit Report</button>
                            </div>
                        </div>
                    </div>
                </div>





                {/* <div className="filter-container">
                    <div className="filter">
                        

                        <div className="searchncbtn">
                            <input type="text" placeholder="Search" className="searchbar searchbar_hide" />

                           

        
                        </div>

                    </div>
                </div> */}

                <div className="wraptable" style={{ width: tableSize ? "79.5vw" : "90vw" }}>

                    <table>

                        <thead>
                            <tr>
                                <th>Action</th>
                                <th>Task</th>
                                <th>Generate Task</th>
                                <th>Progress</th>
                            </tr>
                        </thead>

                        <tbody>

                            <tr>
                                <td>
                                    <input type="checkbox" name="" id="" />
                                </td>
                                <td onClick={()=>navigateTOFeedback()}>
                                    <div className="checkbox_detail">



                                            <h4>Audit Feedback</h4>



                                    </div>
                                </td>
                               <td>
                               {answer1.length===0 && <button onClick={createqasuvreytask} className="generate-button">
                                        Generate Task
                                    </button>}
                                </td>

                                <td>
                                    <ProgressBar  percentage={answer1[0]?.survey_answer}/>
                                    {console.log(answer1[0]?.survey_answer)}
                                </td>
                            </tr>

                            <tr>

                                <td>
                                    <input type="checkbox" name="" id="" />
                                </td>
                                <td  onClick={() => checklistTaskData.length !== 0 ? navigateTOCheckList() : null}
                                style={{ cursor: checklistTaskData.length !== 0 ? 'pointer' : 'not-allowed' }} >
                                    <div className="checkbox_detail">

                                       <h4>QA Checklist</h4>



                                    </div>



                                </td>

                                <td>
                                {checklistTaskData.length===0 &&<button className="generate-button" onClick={createTask}>
                                        Generate Task
                                    </button>}
                                </td>

                                <td>
                                    <ProgressBar percentage={checklistTaskData[0]?.survey_checklist}/>
                                </td>
                            </tr>

                        </tbody>


                    </table>

                    {wrapcarddata.map((data) => (
                        <div className="bucard" onClick={() => Viewuser(data.id)} key={data.id}>
                            <div className="bunstkhlder">
                                <h4>
                                    <p className="buplacehldr">
                                        {data.objective ? data.objective : data.risk_title}
                                    </p>
                                </h4>
                                <h5>
                                    <p className="buplacehldr">
                                        {data.unit_head ? data.unit_head.name : "Keystakeholder"}
                                    </p>
                                </h5>
                            </div>
                            <div className="deptnauthnbtn">
                                <h5>
                                    <p className="buplacehldr">
                                        {data.department ? data.department.name : "Department"}
                                    </p>
                                </h5>
                                <h6>
                                    <p className="buplacehldr">Bu ID - </p>
                                    <p className="buplacehldr">{data.id ? data.id : "Bu ID"}</p>
                                </h6>
                            </div>
                            <img src={openarrow} alt="Open Arrow" />
                        </div>
                    ))}


                </div>

                {/* <div className="cardscontainer">
                    {filteredData.map((data) => (
                        <div className="aecard" >
                            <h1>{data.name} </h1>

                            <img src={openarrow} />
                        </div>
                    ))}
                </div> */}

            </section>
        </>
    );
};

export default AuditWrapUp;
