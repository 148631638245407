import React, { useState, useEffect } from "react";
import { NavLink, useLocation, Link, useNavigate } from "react-router-dom";
import Breadcrumb from "../../components/Breadcrumb";
import SidebarMenu from "../../components/SidebarMenu";
import "../../css/viewuser.css";
import { fetchData1 } from "../apiUtils";
import { auditBusiness, auditExecutionTop, auditExecutionQA } from "../subroutes";
import create from "../../images/createbutton.svg";
import openarrow from "../../images/openarrow.svg";
import "../auditExecution/auditexecution.css";
import AfSpinner from "../AfSpinner";
import BounceLoader from "react-spinners/BounceLoader";
import RenderIcons from "../RenderIcons";
const AuditExecution = ({ tableSize }) => {
  const [errors, setErrors] = useState("");
  const [APIData, setAPIData] = useState([]);
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const successMessageColor = location.state?.successMessageColor;
  const successMessage = location.state?.successMessage;
  const [showMessage, setShowMessage] = useState(!!successMessage);
  const originalUrl = location.state?.from;

  const userRoles = localStorage.getItem("userRoles");

  let routes = [];
  if (userRoles.includes("Admin")) {
    routes = auditExecutionTop;
  } else if (userRoles.includes("Business")) {
    routes = auditBusiness;
  } else if (userRoles.includes("Quality Assurance")) {
    routes = auditExecutionQA;
  } else {
    console.log("No matching role found");
  }

  // Optional: Remove this if not needed
  /*
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      navigate(".", { replace: true });
    }, 5000);

    return () => clearTimeout(timeoutId);
  }, [navigate]);
  */

  useEffect(() => {
    fetchData1(
      `/api/audit_engagement/audit/`,
      (data) => {
        setAPIData(data);
        console.log("Fetched API Data:", data);
      },
      (error) => {
        setErrors(error);
        console.error("API Fetch Error:", error);
      },
      setLoading
    );
  }, []);

  function convertDate(dateString) {
    if (dateString) {
      const date = new Date(dateString);
      const formattedDate = date.toISOString().split("T")[0];

      return formattedDate;
    }
  }


  const [filters, setFilters] = useState({
    id: "",
    name: "", // Changed from audit_entity to name
    business_users: "",
    status: "",
    actual_start_date: "",
    actual_end_date: "",
  });

  const handleFilterChange = (filterKey, e) => {
    setFilters({ ...filters, [filterKey]: e.target.value });
  };

  const [openDropdown, setOpenDropdown] = useState(null);

  const renderIcons = () => {
    const handleDropdownToggle = (index) => {
      setOpenDropdown(openDropdown === index ? null : index);
    };

    return routes.map((route, index) => {
      if (route.routes) {
        const isActive = route.routes.some((subroute) =>
          location.pathname.startsWith(subroute.path.replace(/:\w+/, ""))
        );

        const isDropdownOpen = openDropdown === index || isActive;

        return (
          <div key={index} className="dropdown-container-exec">
            <div
              className="dropdown-toggle"
              style={{ display: "flex", gap: ".5vw" }}
              onClick={() => handleDropdownToggle(index)}
            >
              <div className="navicon">
                <img src={route.icon} alt="Dropdown Icon" />
              </div>

              <div className="navicon">{route.name}</div>
              
            </div>
            {isDropdownOpen && (
              <div className="dropdown-template">
                {route.routes.map((subroute, subindex) => (
                  <NavLink
                    to={subroute.path}
                    key={subindex}
                    className="navlink template-nav"
                    activeClassName="active1"
                  >
                    <div className="navicon"></div>
                    <div className="navicon">{subroute.name}</div>
                  </NavLink>
                ))}
              </div>
            )}
          </div>
        );
      }

      return (
        <NavLink
          to={route.path}
          key={index}
          className="navlink"
          activeClassName="active1"
        >
          <div className="navicon">
            <img src={route.icon} alt="Dashboard Icon" />
          </div>
          <div className="h5_intersemibold">{route.name}</div>
        </NavLink>
      );
    });
  };

  console.log("Fetched API Data:", APIData); // Updated log

  const filteredData = APIData.filter((data) => {
    const {
      id,
      name,
      status,
      business_users,
      actual_start_date,
      actual_end_date,
    } = data;

    const matchesId = filters.id ? id.toString().includes(filters.id) : true;

    const entityName = name ? name.toLowerCase() : "";
    const matchesEntity = filters.name
      ? entityName.includes(filters.name.toLowerCase())
      : true;

    const matchesKeyStakeholder = filters.business_users
      ? business_users.some((user) =>
        user.name.toLowerCase().includes(filters.business_users.toLowerCase())
      )
      : true;

    const matchesStatus = filters.status
      ? status.toLowerCase().includes(filters.status.toLowerCase())
      : true;

    return (
      matchesId &&
      matchesEntity &&
      matchesKeyStakeholder &&
      matchesStatus
    );
  });

  const Viewuser = (data) => {
    const id = data.id;
    navigate(`/AuditExecution/${id}/Detail`);
  };

  return (
    <>
      {errors && <p className="errormessage">{errors}</p>}
      {successMessage && (
        <p className="successmessage">{successMessage}</p>
      )}

      <section className="navroutes">
        <div className="filter-container">
        {/* <RenderIcons homeRoutes={routes} /> */}

          <div className="filter">
            <div className="views">
              <h3 className="h2_interbold" >Audits</h3>
            </div>
            <div className="searchncbtn">
              <input
                type="text"
                placeholder="Search"
                className="searchbar"
              />
              <div className="createuser">
                <Link to="/AuditExecution/Createaudit">
                  <button className="createbutton">
                    <img src={create} alt="Create Audit" />
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div className="aexe_tablecontainer">
          <table 
          // style={{ width: tableSize ? "80vw" : "90.5vw" }}
          style={{ width: "100%" }}
          >
            <thead style={{ position: "sticky", top: "0", zIndex: 1000 }}>
              <tr className="tablehead">
                <th>
                  <h4 className="thhead">UID</h4>
                  <input
                    type="text"
                    placeholder="--"
                    className="thfilter"
                    onChange={(e) => handleFilterChange("id", e)}
                    value={filters.id}
                  />
                </th>
                <th>
                  <h4 className="thhead">Audit</h4>
                  <input
                    type="text"
                    placeholder="--"
                    className="thfilter"
                    onChange={(e) => handleFilterChange("name", e)}
                    value={filters.name}
                  />
                </th>
                <th>
                  <h4 className="thhead">Business Stakeholder</h4>
                  <input
                    type="text"
                    placeholder="--"
                    className="thfilter"
                    onChange={(e) =>
                      handleFilterChange("business_users", e)
                    }
                    value={filters.business_users}
                  />
                </th>
                <th>
                  <h4 className="thhead">Status</h4>
                  <input
                    type="text"
                    placeholder="--"
                    className="thfilter"
                    onChange={(e) => handleFilterChange("status", e)}
                    value={filters.status}
                  />
                </th>
                <th>
                  <h4 className="thhead">Actual Start Date</h4>
                  <input
                    type="text"
                    placeholder="--"
                    className="thfilter"
                    onChange={(e) =>
                      handleFilterChange("actual_start_date", e)
                    }
                    value={filters.actual_start_date}
                  />
                </th>
                <th>
                  <h4 className="thhead">Actual End Date</h4>
                  <input
                    type="text"
                    placeholder="--"
                    className="thfilter"
                    onChange={(e) =>
                      handleFilterChange("actual_end_date", e)
                    }
                    value={filters.actual_end_date}
                  />
                </th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <div className="spinner-bounce-loader">
                  <BounceLoader color="#a6fafa" />
                </div>
              ) : filteredData.length === 0 ? (
                <tr>
                  <td colSpan="6" className="data-error-text">
                    No data found
                  </td>
                </tr>
              ) : (
                filteredData
                  .sort((a, b) => a.id - b.id)
                  .map((data) => (
                    <tr
                      className="tabledata"
                      onClick={() => Viewuser(data)}
                      key={data.id}
                    >
                      <td>
                        <span>{data.id}</span>
                      </td>
                      <td>{data.name}</td>
                      <td>
                        {data.business_users
                          ? data.business_users
                            .map((user) => user.name)
                            .slice(0, 2)
                            .join(", ") +
                          (data.business_users.length > 2 ? "..." : "")
                          : ""}
                      </td>
                      <td>
                        <span>{data.status}</span>
                      </td>
                      <td>
                        <span>{convertDate(data.actual_start_date)}</span>
                      </td>
                      <td>
                        <span>{convertDate(data.actual_end_date)}</span>
                      </td>
                    </tr>
                  ))
              )}
            </tbody>
          </table>

          {/* Optional: Remove if not needed */}
          {filteredData
            .sort((a, b) => a.id - b.id)
            .map((data) => (
              <div
                className="audit_card"
                onClick={() => Viewuser(data)}
                key={data.id}
              >
                <div className="bunstkhlder">
                  <h4>{data.name}</h4>
                </div>
              </div>
            ))}
        </div>

        <div className="cardscontainer">
          {/* Optionally, render cards if needed */}
        </div>
      </section>
    </>
  );
};

export default AuditExecution;
