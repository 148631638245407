import React, { useState, useEffect } from "react";
import {
  NavLink,
  useLocation,
  Link,
  useNavigate,
  useParams,
} from "react-router-dom";
import axios from "axios";
import Breadcrumb from "../../Breadcrumb";
import SidebarMenu from "../../SidebarMenu";
import "../../../css/viewuser.css";
import { fetchData1,fetchData, updateData } from "../../apiUtils";
import { auditExecution } from "../../subroutes";
import createButtonSm from "../../../images/createbuttonsm.svg";
import "../auditplan/auditplan.css";
import BounceLoader from "react-spinners/BounceLoader";
import RenderIcons from "../../RenderIcons";
const Auditplan = ({ tableSize }) => {
  const [errors, setErrors] = useState("");
  const [APIData, setAPIData] = useState([]);
  const [auditData, setAuditData] = useState([]);
  const [areAllTasksDone, setAreAllTasksDone] = useState();
  const [loading, setLoading] = useState(false);


  const location = useLocation();
  const navigate = useNavigate();
  const [showDropdown, setShowDropdown] = useState(false);
  const successMessageColor = location.state?.successMessageColor;
  const successMessage = location.state?.successMessage;
  const [showMessage, setShowMessage] = useState(!!successMessage);
  const originalUrl = location.state?.from;
  const { id } = useParams();
  const [selectedTaskId, setSelectedTaskId] = useState(null);
  const [task, setTaskDetails] = useState();
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      navigate(".", { replace: true });
    }, 5000);

    return () => clearTimeout(timeoutId);
  }, [navigate]);

  useEffect(() => {
    fetchData1(
      `/api/audit_engagement/plan/?audit_id=${id}`,
      setAPIData,
      setErrors,
      setLoading
    );
  }, [id]);


  useEffect(() => {
    fetchData(`/api/audit_engagement/audit/${id}/`, setAuditData, setErrors);
  }, [id]);



  useEffect(() => {
    setAreAllTasksDone(APIData.every(task => task?.task?.status === "Done"));

  }, [APIData]);

  const [filters, setFilters] = useState({
    name: "",
    key_stakeholder: "",
  });

  const handleFilterChange = (filterKey, e) => {
    setFilters({ ...filters, [filterKey]: e.target.value });
  };

  const auditEntityName = [...new Set(APIData.map(item => item.audit.audit_entity?.name))];
 


  const renderIcons = () => {
    return auditExecution.map((route, index) => {
      if (route.subRoutes) {
        return <SidebarMenu route={route} key={index} />;
      }

      return (
        <NavLink
          to={`${route.path.replace(":id", id)}`}
          key={index}
          className="navlink"
          activeClassName="active1"
          end
        >
          <div className="navicon">
            <img src={route.icon} alt="Dashboard Icon" />
          </div>
          <div className="navicon">{route.name}</div>
        </NavLink>
      );
    });
  };

  const Viewuser = (data) => {
    const planId = data;
    navigate(`/AuditExecution/${id}/Planning/${planId}/`);
  };



  const Dropdown = () => (
    <div className="dropdownTask">
      <Link to={`/AuditExecution/${id}/Planning/Createauditmemo`}>
        <div className="optiontask">
          <p>Prepare Audit Memo</p>
        </div>
      </Link>

      <Link to={`/AuditExecution/${id}/Planning/ScheduleMeeting`}>
        <div className="optiontask">
          <p>Schedule Meeting</p>
        </div>
      </Link>

      <Link to={`/AuditExecution/${id}/Planning/CreateInitialReqList`}>
        <div className="optiontask">
          <p>Send initial list of requirements</p>
        </div>
      </Link>

      <Link to={`/AuditExecution/${id}/Planning/RcmTask`}>
        <div className="optiontask">
          <p>Fill RCM Template</p>
        </div>
      </Link>
      <Link to={`/AuditExecution/${id}/Planning/RcmTask`}>
        <div className="optiontask">
          <p>Task</p>
        </div>
      </Link>

      <div className="optiontask">
        <p>Develop Audit Programs</p>
      </div>
    </div>
  );

  const renderAuditPlanStatus = (status) => {
    switch (status) {
      case "Done":
        return (
          <div
            className="auditstatus completed"
            style={{ backgroundColor: "#65CC5C", color: "#fff" }}
          >
            Done
          </div>
        );
      case "todo":
      default:
        return (
          <div
            className="auditstatus open"
            style={{ backgroundColor: "#5771DF", color: "#fff" }}
          >
            Todo
          </div>
        );
    }
  };

  const handleCheckboxChange = (taskId, status, task) => {
    setTaskDetails(task);
    setSelectedTaskId(taskId);
    if (status === 'Done') {
      setShowPopup(false);
    }
    else {
      setShowPopup(true);
    }

  };

  const handlePopupClose = (task) => {

    updateData(
      `/api/audit_engagement/plan/${task.id}`,
      {
        objective: task?.objective,
        preparer_id: task.preparer?.id,
        reviewer_id: task.reviewer?.id,
        assign_to_id: task.assign_to?.id,
        type: task.type,
        audit_id: id,
        task_data: "Done",
      },
      navigate,
      setErrors,
      `/AuditExecution/${id}/Planning`
    );

    setAPIData((prevAPIData) =>
      prevAPIData.map((task) => {
        if (task.id === selectedTaskId) {
          return {
            ...task,
            task: {
              ...task.task,
              status: "Done",
            },
          };
        }
        return task;
      })
    );
    setShowPopup(false);
  };


  const handlePopupCloseNo = () => {
    setShowPopup(false);
  };

  const updateWithPhase = (e) => {
    e.preventDefault();
    updateData(
      `/api/audit_engagement/audit/${id}`,
      {
        risk_assessment: auditData?.risk_assessment?.id,
        audit_entity_id: auditData?.audit_entity?.id,
        auditors_id: auditData?.auditors.map(auditor => auditor.id),
        business_users_id: auditData?.business_users.map(business_users => business_users.id),
        actual_start_date: auditData?.actual_start_date,
        actual_end_date: auditData?.actual_end_date,
        planned_start_date: auditData?.audit_event?.planned_start_date,
        planned_end_date: auditData?.audit_event?.planned_end_date,
        audit_scope: auditData?.audit_scope,
        audit_phases: auditData?.audit_phases,
        other_relevant_information: auditData?.other_relevant_information,
        phase: 'Planning Complete'
      },
      navigate,
      setErrors,
      `/AuditExecution/${id}/Planning/`,
      window.location.reload()
    );
  };



  

  return (
    <>
      {errors && <p className="errormessage">{errors}</p>}
      {successMessage && <p className="successmessage">{successMessage}</p>}

      {showPopup && (
        <div className="popupATP">
          <div className="subpopupATP">
            <p>Do you want the task to be done?</p>
            <div className="subpopupBtnDiv">
              <button onClick={() => handlePopupClose(task)}>Yes</button>
              <button onClick={handlePopupCloseNo}>No</button>
            </div>
          </div>
        </div>
      )}

      <section className="navroutes">
      <RenderIcons homeRoutes={auditExecution} id ={id}/>

        <div className="filter-container">
          <div className="filter">
          
          <Breadcrumb />

            <div className="searchncbtn">
              <span className="auditstatusphase">{auditData.phase}</span>

              <Link className="addrcmat" to={`/AuditExecution/${id}/Planning/GenericTask`}>
                <button className="createbutton">
                  <img src={createButtonSm} alt="" />
                </button>
                <h5>Add task</h5>
              </Link>

            </div>
          </div>
        </div>




        <div
          className="planTableContainer"
          style={{
            width: tableSize ? "80vw" : "90.5vw",
            overflowY: "scroll",
            height: "60vh",
            overflowX: "hidden",
          }}
        >
<table
            className="task_list"
            style={{ width: tableSize ? "80vw" : "90.5vw" }}
          >
            <thead style={{ position: "sticky", top: "0", zIndex: 1000 }}>
              <tr>
                <th>Objective</th>
                <th>Status</th>
                <th>Prepared By</th>
                <th>Reviewer</th>
                <th>Actions</th>
              </tr>
            </thead>

            <tbody>
{loading ? (
          <div className="spinner-bounce-loader">
          <BounceLoader color="#a6fafa"/> 
          </div>
            
          ): APIData.length === 0 ? (
            <p className="data-error-text">No data found</p> 
          ) : (
          
            APIData.map((task) => (
      <React.Fragment key={task.id}>

        <div className="chkAP">
          <input
            type="checkbox"
            checked={task.task ? task.task.status === "Done" : false}
            onChange={() => handleCheckboxChange(task?.id, task?.task?.status, task)}
          /></div>

        <tr className="task" onClick={() => Viewuser(task.id)}>
          <td> <p>{task.data ? task.data.type : ""}</p> </td>
          <td style={{ textAlign: 'center' }}> <span>{renderAuditPlanStatus(task.task ? task.task.status : "")}</span> </td>
          <td><p>{task.preparer ? task.preparer.name : ""}</p></td>
          <td><p>{task.reviewer ? task.reviewer.name : ""}</p></td>
        </tr>

        <div className="ap_icons" >
          <button className="remindBtn" onClick={() => setSelectedTaskId(task.id)}>
            Send Reminder
          </button>
        </div>
      </React.Fragment>
    ))
            
          )
        }
        </tbody>
        </table>
          {/* {auditData.phase ==="Planning Complete" &&( <div ><span> Planning Completed</span></div>)}  */}
          {auditData.phase === "Planning" && areAllTasksDone && (<div onClick={(e) => updateWithPhase(e)}><button className="phasestatus">Start FieldWork</button></div>)}

          {
            APIData.map((task) => (
              <React.Fragment key={task.id}>
                <div className="bucard" onClick={() => Viewuser(task.id)}>
                  <div className="bunstkhlder">
                    <h4>
                      <p className="buplacehldr">{task.data ? task.data.name : "BU Name"}</p>
                    </h4>
                    <h5>
                      <p className="buplacehldr">{task.unit_head ? task.unit_head.name : "Keystakeholder"}</p>
                    </h5>
                  </div>
                  <div className="deptnauthnbtn">
                    <h5>
                      <p className="buplacehldr">{task.department ? task.department.name : "Department"}</p>
                    </h5>
                    <h6>
                      <p className="buplacehldr">Bu ID - </p>
                      <p className="buplacehldr">{task.id ? task.id : "Bu ID"}</p>
                    </h6>
                  </div>
                  {/* <img src={openarrow} alt="Open Arrow" /> */}
                </div>
              </React.Fragment>
            ))
          }


        </div>
      </section>
    </>
  );
};

export default Auditplan;
