import React from "react";

import { NavLink, useLocation, Link, useNavigate, useParams} from "react-router-dom";
import create from "../../images/createbutton.svg";
import SidebarMenu from "../SidebarMenu";
import { useState, useEffect } from "react";
import cut from "../../images/cut.svg";
import Breadcrumb from "../Breadcrumb";
import WindowComponent from "../WindowComponent";
import { fetchDataObject, updateData, deleteData, deleteData2 } from "../apiUtils";
import SingleOptionSelector from "../SingleOptionSelector";
import { homeRoutes } from "../subroutes";
import pencil from "../../images/edit_pencil.svg";
import backlogo from "../../images/backLogo.svg";
import save_details from "../../images/saveBtn.svg";
import forgetPass from "../../images/fPass.svg";
import MultiOptionSelector from "../MultiOptionSelector";
import IconButton from "../iconbutton/IconButton";
import CommonInput from "../CommonInput";
import BounceLoader from "react-spinners/BounceLoader";
import RenderIcons from "../RenderIcons";
const ViewTeams = ({tableSize}) => {
  const [contactError, setContactError] = useState("");
  const [employeed_idError, setEmpidError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [selectedDeptsError, setSelectedDeptsError] = useState(false);
  const [lastNameError, setLastNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [designationError, setDesignationError] = useState("");
  const [selectedBuError, setSelectedUnitsError] = useState(false);
  const [errors, setErrors] = useState("");
  const [firstName, setFirstName] = useState("");
  const [togglePassword, setTogglePassword] = useState(false);
  const [APIData, setAPIData] = useState([]);
  const location = useLocation();
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const [first_name, setName] = useState("");
  const [last_name, setlName] = useState("");
  const [Id, setId] = useState("");
  const [employeed_id, setEmpid] = useState("");
  const [npassword, setNpassword] = useState("");
  const [cpassword, setCpassword] = useState("");
  const [mpassword, setMpassword] = useState(false);
  const [contact, setContact] = useState("");
  const [email, setEmail] = useState("");
  const [designation, setDesignation] = useState("");
  const [editMode, setEditMode] = useState(false);
  const [selecteddept, setSelecteddept] = useState("");
  const [selecteddeptId, setSelecteddeptId] = useState("");
  const [selectedbu, setSelectedBu] = useState("");
  const [selectedButId, setSelectedBuId] = useState("");
  const [firstNameError, setFirstNameError] = useState(false);
  const [loading, setLoading] = useState(false);



  const { id } = useParams();


  useEffect(() => {

    fetchDataObject(`/api/system/users/${id}/`, setAPIData, setErrors,setLoading);
  }, [id]);

    const handleFirstNameChange = (event) => {
      const newName = event.target.value;
      setFirstNameError(false);
      setFirstName(newName);
      setName(newName);

    };
    const handleLastNameChange = (event) => {
      const newName = event.target.value;
      setlName(newName);
      setLastNameError(false);
    };

     const password = APIData.password;
     useEffect(() => {
      setGroups_id(ids);
      setSelectedRoles(Names);
      setId(APIData.id);
      setName(APIData.first_name);
      setlName(APIData.last_name);
      setEmpid(APIData.employeed_id);
      setContact(APIData.contact);
      setEmail(APIData.email);
      setDesignation(APIData.designation);
      setDepartment(APIData.department ? APIData.department.name : '');
      setDepId(APIData.department ? APIData.department.id : '');    
      setSelecteddept(APIData.department ? APIData.department.name : '');
      setSelectedBu(APIData.business_unit ? [APIData.business_unit.name] : []);
      setSelecteddeptId(APIData.department ? APIData.department.id : '');
      setSelectedBuId(APIData.business_unit ? APIData.business_unit.id : '');
   
    }, [password]);
    const groupsData = APIData.groups ? APIData.groups : [];
    const ids = groupsData.map((item) => item.id);
    const Names = groupsData.map((item) => item.name);

    useEffect(() => {
      const pathSegments = location.pathname
        .split("/")
        .filter((segment) => segment !== "");
      setBreadcrumbs(pathSegments);
    }, [location.pathname]);


    const updateAPIData = (e) => {
      e.preventDefault();
      if (
        !first_name ||
        !last_name ||
        !email ||
        !business_unit_id ||
        !contact ||
        !designation ||
        !employeed_id ||
        !password ||
        !department_id ||
        !business_unit_id
      ) {
        setFirstNameError(!first_name ? true : false);
        setContactError(!contact ? true : false);
        setEmpidError(!employeed_id ? true : false);
        setPasswordError(!password ? true : false);
        setSelectedDeptsError(!department_id ? true : false);
        setLastNameError(!last_name ? true : false);
        setEmailError(!email ? true : false);
        setDesignationError(!designation ? true : false);
        setSelectedUnitsError(!business_unit_id ? true : false);
        setErrors("Please fill in all required fields.");
        setTimeout(() => {
          setErrors(null);
        }, 5000);
        return;
      }
      if (npassword === cpassword) {
        setMpassword(true);
        updateData(
          `/api/system/users/${Id}`,
          {
            first_name,
            last_name,
            email,
            contact,
            designation,
            employeed_id,
            department_id,
            business_unit_id,
            groups_id : role,
            password: npassword ? npassword : password,
          },
          navigate,
          setErrors,
          `/Administration/user/${id}`
        );
      } else {
        setMpassword(true);
      }
    };

    const handleEditClick = () => {
      setEditMode(!editMode);
    };
    const onDelete = (e) => {
      e.preventDefault();
      deleteData2(
        `/api/system/users/${Id}`,
        navigate,
        setErrors,
        `/Administration/user/`
      );
    };

    const [selectedDepts, setSelectedDepts] = useState([]);

    const [department, setDepartment] = useState("");
    const [depId, setDepId] = useState("");


    const [department_id, setDepartmentId] = useState("");

    const [selectedBu, setSelectedUnits] = useState([]);
    const [business_unit_id, setBusiness_unit_id] = useState("");

    const navigate = useNavigate();

    const handlePassword = () => {
      setEditMode(true);
      setTogglePassword(!togglePassword);

    };

    const [role, setRole] = useState([]);
    const [selectedRoles, setSelectedRoles] = useState([]);
    const [groups_id, setGroups_id] = useState([]);
    const [expandedRoles, setExpandedRoles] = useState(false);


  const handleCheckboxChangeRoles = (event) => {
    const selectedRoleName = event.target.value;
    const selectedRoleId = groupData.find(role => role.name === selectedRoleName)?.id;

    if (event.target.checked) {
      // Add the selected role and its ID to the arrays
      setSelectedRoles(prevState => [...prevState, selectedRoleName]);
      setGroups_id(prevState => [...prevState, selectedRoleId]);
    } else {
      // Remove the selected role and its ID from the arrays
      setSelectedRoles(prevState => prevState.filter(role => role!== selectedRoleName));
      setGroups_id(prevState => prevState.filter(id => id!== selectedRoleId));
    }
  };

  useEffect(() => {
    const initialSelectedRoles = ["Admin"]; 
    const initialGroupIds = [1]; 

    setSelectedRoles(initialSelectedRoles);
    setGroups_id(initialGroupIds);
  }, []);

    const handleRemoveSelectedKeyStakeholder = (stakeholderToRemove) => {
      const removedStakeholderId = groupData.find(
        (roles) => roles.name === stakeholderToRemove
      )?.id;

      setSelectedRoles([]);
      setGroups_id(groups_id.filter((id) => id !== removedStakeholderId));
    };

    const [groupData, setGroupData] = useState([
      { id: 1, name: "Admin" },
      { id: 2, name: "Audit Team" },
      { id: 3, name: "Business" },
      { id: 4, name: "Quality Assurance" },
      { id: 5, name: "Audit Commission" },
    ]);
    const toggleExpandRole = () => {
      setRole(groupData);
      setExpandedRoles(!expandedRoles);
    };

    const toggleCloseRoles = () => {
      setExpandedRoles(false);
    };
    const SelectedRoleComps = ({ name }) => (
      <div className="selected-unit-button">
        <span>{name}</span>
        <div onClick={() => handleRemoveSelectedKeyStakeholder(name)}>
          <img src={cut} alt="Dashboard Icon" />
        </div>
      </div>
    );
    const handleCancelClick=() => {
      setEditMode(false)
      window.location.reload();
  
    }

 
  return (
    <>
      {errors && <p className="errormessage">{errors}</p>}
      <section className="navroutes">
      <RenderIcons homeRoutes={homeRoutes} />

        <Link to={`/Administration/Teams`}>
                  <div
                    className="backBtnDiv"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      gap: "1vw",
                      backgroundColor: "#FFF3CC",
                      padding: ".5vw 1vw",
                      borderRadius: "2.5vw",
                      position:'absolute',
                      top:'12%',
                      right:'2%'
                    }}
                  >
                    <img src={backlogo} style={{ width: "1.2vw" }} />
                    <p
                      style={{
                        fontFamily: "intersemibold",
                        fontSize: ".8vw",
                        color: "#775F0F"
                      }}
                    >
                      Back to All Users
                    </p>
                  </div>
                </Link>
        <Breadcrumb />

        <div className="filter-container">
          <div className="filter">
            <div className="views">
              <h3 className="h2_interbold" >Teams</h3>
            </div>

            <div className="createuser"></div>
          </div>
        </div>

        {loading ? (
          <div className="spinner-bounce-loader">
          <BounceLoader color="#a6fafa"/> 
          </div>
            
          ): APIData.id === 0 ? (
            <p className="data-error-text">No data found</p> 
          ) : (
        <div className="viewuserconatiner" style={{width: tableSize?"80vw":"90.5vw"}}>
         {/* <div className="delete_edit_close_btn">
         {editMode && <IconButton type="close" className='closeView' tooltip="Cancel"  onClick={handleCancelClick} />}
          {editMode ? (
            <IconButton type="save" tooltip="Save" onClick={updateAPIData} link="/" />
          ) : (
            <IconButton type="edit" tooltip="Edit" onClick={handleEditClick} />
          )}
          <IconButton type="forgetPassword" tooltip="Forgot Password" onClick={handlePassword} />
          <WindowComponent onDelete={onDelete} />
        </div> */}
        
          <form >
           

              
               
                  <div className="lbl_inpt">
                    
                    <label>First Name</label>
                    <input
                      style={{
                        border: firstNameError ? "1px solid #FF4646" : "",
                        backgroundColor:editMode ? "#EEF1FC" : "#e6e6e6",
                      }}
                      className={
                        editMode ? "editable-input" : "non-editable-input"
                      }
                      type="text"
                      placeholder="Jhon"
                      value={first_name}
                      onChange={handleFirstNameChange}
                      readOnly={!editMode}
                    />{" "}
                  </div>

                  <div className="lbl_inpt">
                    <label>Last Name</label>
                    <input
                      style={{
                        border: lastNameError ? "1px solid #FF4646" : "",
                        backgroundColor:editMode ? "#EEF1FC" : "#e6e6e6",
                      }}
                      type="text"
                      placeholder="Jhon"
                      value={last_name}
                      className={
                        editMode ? "editable-input" : "non-editable-input"
                      }
                      onChange={handleLastNameChange}
                      readOnly={!editMode}
                    />{" "}
                  </div>

                  
                    <SingleOptionSelector
                      apiUrl="/api/system/business-units/"
                      setSelectorId={setBusiness_unit_id}
                      setSelectorError={setSelectedUnitsError}
                      selectorError={selectedBuError}
                      Name="name"
                      title="Business Unit"
                      initialId={selectedButId}
                      initialName={selectedbu}
                      editMode={editMode}
                    />
                  

               
                      <CommonInput
                      labelName="Email"
                      error={emailError}
                      type="email"
                      placeHolder="noahfrost@example.com"
                      setText={setEmail}
                      value={email}
                      editMode={!editMode}
                      setTextError={setContactError}
                      commanDivClass='lbl_inpt'
                      
                    />
                  


                

                  
                    <SingleOptionSelector
                      apiUrl="/api/system/departments/"
                      setSelectorId={setDepartmentId}
                      setSelectorError={setSelectedDeptsError}
                      selectorError={selectedDeptsError}
                      Name="name"
                      title="Department"
                      initialId={selecteddeptId}
                      initialName={selecteddept}
                      editMode={editMode}
                    />
                  




               


      {/* <MultiOptionSelector
                    // apiUrl="/api/audit_universe/business-stakeholders/"
                    setSelectorId={setGroupData}
                    Name="name"
                    title="Roles"
                    initialIds={groups_id}
                    initialNames={selectedRoles}
                    classNameInput={"sub-divOks"}
                    rolesData={groupData}   
                    />  */}
            

                
                   
                    <MultiOptionSelector
                    // apiUrl="/api/audit_universe/business-stakeholders/"
                    setSelectorId={setRole}
                    Name="name"
                    title="Roles"
                    initialIds={groups_id}
                    initialNames={selectedRoles}
                    rolesData={groupData} 
                    editMode={editMode}  
                    />  
                  

                  

                 
                    <CommonInput 
                      labelName='Contact'
                      error={contactError}
                      type='number'
                      placeHolder='+918763456789'
                      setText={setContact}
                      value={contact}
                      editMode={!editMode}
                      setTextError={setContactError}
                       commanDivClass='lbl_inpt'
                    />
                  

                  
                   <CommonInput
                    labelName='Designation'
                    error={designationError}
                    type='text'
                    placeHolder='Senior Developer'
                    setText={setDesignation}
                    value={designation}
                    editMode={!editMode}
                    setTextError={setDesignationError}
                     commanDivClass='lbl_inpt'
                   />
                 

                 

                 


                    
                  

                  
                    {/* <label>Emp Id</label>
                    <input
                      style={{
                        border: employeed_idError ? "1px solid #FF4646" : "",
                      }}
                      type="text"
                      placeholder="541-AF-9466D"
                      value={employeed_id}
                      className={
                        editMode ? "editable-input" : "non-editable-input"
                      }
                      onChange={(e) => setEmpid(e.target.value)}
                      readOnly={!editMode}
                    /> */}
                       <CommonInput
                      labelName="Emp Id"
                      error={employeed_idError}
                      type="text"
                      placeHolder="541-FA-9466D"
                      setText={setEmpid}
                      value={employeed_id}
                      editMode={!editMode}
                      setTextError={setEmpidError}
                       commanDivClass='lbl_inpt'
                    />
                 
                
              
           
          </form>
          
      </div>
          )
        }

      </section>
    </>
  );
};

export default ViewTeams;
