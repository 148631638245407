import React, { useState } from "react";
import { NavLink, useLocation, Link, useNavigate, useParams } from "react-router-dom";
import SidebarMenu from "../../../SidebarMenu";
import { postData, updateData, deleteData, deleteData2 } from "../../../apiUtils";
import { auditExecution } from "../../../subroutes";
import backlogo from "../../../../images/backLogo.svg";
import AuditMemo from "../../../AuditMemo";
import TaskDetails from "../../../TaskDetails";
import IconButton from "../../../iconbutton/IconButton";
import SingleOptionSelector from "../../../SingleOptionSelector";
import WindowComponent from "../../../WindowComponent";
import Breadcrumb from "../../../Breadcrumb";
import "../viewTask/viewtask.css"
import RenderIcons from "../../../RenderIcons";

const ViewInitialReqList = ({ tableSize, APIData }) => {
  const [errors, setErrors] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [objective, setObjective] = useState(APIData.objective);
  const [list, setInitialReq] = useState(APIData.data.details);
  const [initialReqError, setInitialReqError] = useState(false);
  const [scope, setScope] = useState("");
  const [preparer_id, setPreparerId] = useState("");
  const [PreparerError, setPreparerError] = useState(false);
  const [reviewer_id, setReviewerId] = useState("");
  const [reviewerError, setReviewerError] = useState(false);
  const [assign_to_id, setAssign_To] = useState("");
  const [assignError, setAssignError] = useState(false);
  const [scheduled_start_date, setStartDate] = useState(APIData.scheduled_start_date);
  const [start_dateError, setStart_dateError] = useState(false);
  const [scheduled_end_date, setEndDate] = useState(APIData.scheduled_end_date);
  const [end_dateError, setEnd_dateError] = useState(false);
  const [editMode, setEditMode] = useState(false);

  const { id, planid } = useParams();
  const navigate = useNavigate();


  const updateAPIData = (e) => {
    e.preventDefault();
    if (!preparer_id || !reviewer_id || !assign_to_id ||
      !scheduled_start_date || !scheduled_end_date ||
      !id || !list) {
      setPreparerError(!preparer_id ? true : false);
      setReviewerError(!reviewer_id ? true : false);
      setAssignError(!assign_to_id ? true : false);
      setStart_dateError(!scheduled_start_date ? true : false)
      setEnd_dateError(!scheduled_end_date ? true : false)
      setInitialReqError(!list ? true : false);
      setErrors("Please fill in all required fields.");
      setTimeout(() => {
        setErrors(null);
      }, 5000);
      return;
    }
    updateData(`/api/audit_engagement/plan/${planid}`,

      {
        objective,
        preparer_id,
        reviewer_id,
        assign_to_id,
        scheduled_start_date,
        scheduled_end_date,
        type: "Send Initial List Of Requirements",
        audit_id: id,
        list

      },
      navigate,
      setErrors,
      `/AuditExecution/${id}/Planning`
    );

  };
  const handleEditClick = () => {
    setEditMode(!editMode);
  };
  const onDelete = (e) => {
    e.preventDefault();
    deleteData2(
      `/api/audit_engagement/plan/${planid}`,
      navigate,
      setErrors,
      `/AuditExecution/${id}/Planning`
    );
  };

  const handleCancelClick = () => {
    setEditMode(false)
    window.location.reload();
  }




  function convertDate(inputDate) {
    const date = new Date(inputDate);

    // Subtract 5 hours and 30 minutes for display
    date.setHours(date.getHours() - 5);
    date.setMinutes(date.getMinutes() - 30);

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');

    return `${year}-${month}-${day}T${hours}:${minutes}`;
  }

  function handleStartDateChange(e) {
    // Convert the input value back to UTC if needed or store as is
    const localDate = new Date(e.target.value);
    // You might need to adjust to UTC if your API requires UTC dates
    const utcDate = new Date(localDate.getTime() + (5 * 60 * 60 * 1000) + (30 * 60 * 1000));
    setStartDate(utcDate.toISOString());
    setStart_dateError(false)
  }

  function handleEndDateChange(e) {
    // Convert the input value back to UTC if needed or store as is
    const localDate = new Date(e.target.value);
    // You might need to adjust to UTC if your API requires UTC dates
    const utcDate = new Date(localDate.getTime() + (5 * 60 * 60 * 1000) + (30 * 60 * 1000));
    setEndDate(utcDate.toISOString());
    setEnd_dateError(false)
  }
  console.log("tableSize:", tableSize); // Check the value of tableSize

  

  return (
    <>
      {errors && <p className="errormessage">{errors}</p>}
      {successMessage && <p className="successmessage">{successMessage}</p>}

      <section className="navroutes" >
      <RenderIcons homeRoutes={auditExecution} id ={id}/>


        <div className="filter-container">
          <div className="filter">
              <div
                style={{
                  width: tableSize ? "79.5vw" : "90vw",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                      <Breadcrumb />

                <Link to={`/AuditExecution/${id}/Planning`}>
                  <div
                    className="backBtnDiv"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      gap: "1vw",
                      backgroundColor: "#FFF3CC",
                      padding: ".3vw 1.2vw",
                      borderRadius: "2.5vw",
                    }}
                  >
                    <img src={backlogo} style={{ width: "1.2vw" }} />
                    <p
                      style={{
                        fontFamily: "intersemibold",
                        fontSize: ".8vw",
                        color: "#775F0F",
                      }}
                    >
                      Back to all tasks
                    </p>
                  </div>
                </Link>
              </div>
          </div>
        </div>

        <h3>View Initial List Of Requirements</h3>


     

        <div
      className="createauditmemo"
      style={{
        width: tableSize ? "79.5vw" : "90vw"

      }}
    >
      <div className="delete_edit_close_btn">
        {editMode && <IconButton type="close" className='closeView' tooltip="Cancel" onClick={handleCancelClick} />}
        {editMode ? (
          <IconButton type="save" tooltip="Save" onClick={updateAPIData} link="/" />
        ) : (
          <IconButton type="edit" tooltip="Edit" onClick={handleEditClick} />
        )}
        <WindowComponent onDelete={onDelete} />
      </div>

      <form>
      <div
        className="lng_lbl_inpt"
      >
        <label>Objective</label>
        <input
          type="text"
          style={{
            backgroundColor: editMode ? "#EEF1FC" : "#e6e6e6",
          }}
          placeholder="Objective"
          value={objective}
          onChange={(e) => {
            setObjective(e.target.value);
          }}
          readOnly={!editMode}
        />
      </div>

     
        <div
          className="lbl_inpt"
        >
          <label> Start Date: </label>
          <input
            style={{
              backgroundColor: editMode ? "#EEF1FC" : "#e6e6e6",
              border: start_dateError ? "1px solid #FF4646" : "",


            }}
            type="datetime-local"
            value={scheduled_start_date ?convertDate(scheduled_start_date):''}
            onChange={handleStartDateChange}
            readOnly={!editMode}
          />
        </div>

        <div
          className="lbl_inpt"
        >
          <label> End Date: </label>
          <input
            style={{
              backgroundColor: editMode ? "#EEF1FC" : "#e6e6e6",
              border: end_dateError ? "1px solid #FF4646" : ""
            }}
            type="datetime-local"
            value={ scheduled_end_date ?convertDate(scheduled_end_date):''}
            onChange={handleEndDateChange}
            readOnly={!editMode}
          />
        </div>
     

          <SingleOptionSelector
            apiUrl="/api/audit_universe/audit-team/"
            setSelectorId={setPreparerId}
            setSelectorError={setPreparerError}
            selectorError={PreparerError}
            Name="name"
            title="Preparer"
            initialId={APIData.preparer ? APIData.preparer.id : ""}
            initialName={APIData.preparer ? APIData.preparer.name : ""}
            editMode={editMode}
          />
       

          <SingleOptionSelector
            apiUrl="/api/audit_universe/admin-audit-team/"
            setSelectorId={setReviewerId}
            setSelectorError={setReviewerError}
            selectorError={reviewerError}
            Name="name"
            title="Reviewer"
            initialId={APIData.reviewer ? APIData.reviewer.id : ""}
            initialName={APIData.reviewer ? APIData.reviewer.name : ""}
            editMode={editMode}
          />
        

          <SingleOptionSelector
            apiUrl="/api/audit_universe/business-stakeholders/"
            setSelectorId={setAssign_To}
            setSelectorError={setAssignError}
            selectorError={assignError}
            Name="name"
            title="Assign To"
            initialId={APIData.assign_to ? APIData.assign_to.id : ""}
            initialName={APIData.assign_to ? APIData.assign_to.name : ""}
            editMode={editMode}
          />
       
      
     

      
          <div className="lbl_txa">
            <label>List down the initial requirements </label>

            <textarea
              style={{
                border: initialReqError ? "1px solid #FF4646" : "",
                backgroundColor: editMode ? "#EEF1FC" : "#e6e6e6"
              }}
              placeholder="Lists"
              value={list}
              onChange={(e) => {
                setInitialReq(e.target.value);
                setInitialReqError(false)
              }}
              readOnly={!editMode}
            />
          </div>
       
      

      </form>
    </div>


      </section>
    </>
  );
};

export default ViewInitialReqList;
