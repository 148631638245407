import React, { useState, useEffect } from "react";
import { NavLink, useLocation, useNavigate, useParams } from "react-router-dom";
import Breadcrumb from "../../Breadcrumb";
import SidebarMenu from "../../SidebarMenu";
import "../../../css/viewuser.css";
import "../auditPlan/calenderviewpage.css";
import { auditRoutes } from "../../subroutes";
import Timeline from "./Timeline";
import { fetchData1, justFetchData } from "../../apiUtils";
import BounceLoader from "react-spinners/BounceLoader";
import RenderIcons from "../../RenderIcons"
const CalenderViewPage = ({ tableSize }) => {
  const [APIData, setAPIData] = useState([]);
  const [errors, setErrors] = useState("");
  const [auditStatus, setAuditStatus] = useState({});
  const [loading, setLoading] = useState(false);
  var audit =''
  const {auid}=useParams();
  const [selectedId, setSelectedId] = useState("");
  const [removeId, setRemoveId] = useState(false);
  const [isCreateClicked, setIsCreateClicked] = useState(false);
  const [APIAllData, setAPIAllData] = useState([]);

  const location = useLocation();
  const navigate = useNavigate();
  const successMessageColor = location.state?.successMessageColor;
  const successMessage = location.state?.successMessage;
  const userRoles = localStorage.getItem("userRoles");

  const Roles = userRoles.includes("Admin") && userRoles.includes("Audit Team");

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      navigate(".", { replace: true });
    }, 5000);

    return () => clearTimeout(timeoutId);
  }, [navigate]);

  useEffect(() => {
    fetchData1(
      `/api/audit_universe/audit-plan/${auid}`,
      setAPIAllData,
      setErrors,
      setLoading
    );
  }, []);

  useEffect(() => {
    if (APIAllData.length > 0 && !removeId) {
      const lastItemId = APIAllData[APIAllData.length - 1].id;
      setSelectedId(lastItemId);
    }
  }, [APIAllData, removeId]);

  useEffect(() => {
    if (selectedId) {
      justFetchData(`/api/audit_universe/audit-plan/${selectedId}`,
        setAPIData,
        setErrors
      );
    }
  }, [selectedId]);

  const handleChange = (e) => {
    setSelectedId(e.target.value);
    setRemoveId(true);
  };




  const renderIcons = () => {
    return auditRoutes.map((route) => {
      if (route.subRoutes) {
        return <SidebarMenu route={route} key={route.path} />;
      }

      return (
        <NavLink
          to={route.path}
          key={route.path}
          className="navlink"
          activeclassname="active1"
        >
          <div className="navicon">
            <img src={route.icon} alt="Dashboard Icon" />
          </div>
          <div className="navicon">{route.name}</div>
        </NavLink>
      );
    });
  };

  const [timelineData, setTimelineData] = useState([]);

  const parseDate = (dateStr) => {
    const date = new Date(dateStr);
    const month = date.toLocaleString("default", { month: "short" });
    return month;
  };

  const getYearRange = (plan) => {
    const planStartDate = new Date(plan.plan_start_date);
    const planEndDate = new Date(plan.plan_end_date);

    if (!isNaN(planStartDate) && !isNaN(planEndDate)) {
      const startYear = planStartDate.getFullYear();
      const endYear = planEndDate.getFullYear();
      const yearDifference = endYear - startYear;
      return Array.from(
        { length: yearDifference + 1 },
        (_, i) => startYear + i
      );
    } else {
      console.log(`Invalid dates for plan ${plan.id}`);
      return [];
    }
  };

  const handleCreateClick = () => {
    setIsCreateClicked(true);
  };

  const handleApproveClick = () => {
    console.log("Approved");
  };

  const handleRejectClick = () => {
    console.log("Rejected");
  };

  const [showPopup, setShowPopup] = useState(false);
  const handleSendClick = () => {
    setShowPopup(true);
    setTimeout(() => setShowPopup(false), 2000);
  };

  const renderAuditPlanCalenderData=(data)=>{
      return 
  }

  return (
    <>
      {showPopup && (
        <div className="popup">
          <p>Audit Plan Created</p>
        </div>
      )}
      <section className="navroutes">
      <RenderIcons homeRoutes={auditRoutes} />

      <Breadcrumb/>

        <div className="filter-container">
          <div className="filter">
            <div className="views">
              <h3 className="h2_interbold"  >Audit Plan</h3>
            </div>

            <div className="approve_n_assign_btn">
              <div className="searchapbtn">
                <select
                  id="names"
                  className="selectAP"
                  value={selectedId}
                  onChange={handleChange}
                >
                  {APIAllData
                    .slice()
                    .reverse()
                    .map((APIAllData, index) => (
                      <option key={index} value={APIAllData.id}>
                        {APIAllData.title}
                      </option>
                    ))}
                </select>
              </div>

              <div className="assign_section">
                <label htmlFor="mySelect">Request Approval : </label>
                <select className="mySelect_Approval" name="options">
                  <option value="option1">Option 1</option>
                  <option value="option2">Option 2</option>
                  <option value="option3">Option 3</option>
                  <option value="option4">Option 4</option>
                </select>
                <button>Send</button>
              </div>
              <div style={{ display: Roles ? '' : 'none' }}>
                {!isCreateClicked && (
                  <button
                    onClick={handleCreateClick}
                    style={{ backgroundColor: "rgb(23, 194, 71)" }}
                  >
                    Submit
                  </button>
                )}

                {isCreateClicked && (
                  <>
                    <button
                      onClick={handleApproveClick}
                      style={{
                        backgroundColor: "#22b3e2",
                        color: "white",
                        padding: ".7vw 2vw",
                        border: "none",
                        borderRadius: ".5vw",
                        cursor: "pointer",
                        marginLeft: "1vw",
                      }}
                    >
                      Approve
                    </button>

                    <button
                      onClick={handleRejectClick}
                      style={{
                        backgroundColor: "red",
                        color: "white",
                        padding: ".7vw 2vw",
                        border: "none",
                        borderRadius: ".5vw",
                        cursor: "pointer",
                        marginLeft: "1vw",
                      }}
                    >
                      Reject
                    </button>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>

        <div
          className="calendar_container"
          style={{ width: tableSize ? "80vw" : "90.5vw"}}
        >
            {loading ? (
          <div className="spinner-bounce-loader">
          <BounceLoader color="#a6fafa"/> 
          </div>
            
          ): APIData.length === 0 ? (
            <p className="data-error-text">No data found</p> 
          ) : (
            APIData.map((plan) => {
              const years = getYearRange(plan);
              return (
                
                plan.audit_events.length !== 0 &&
                years.map((year) => {
      
                  const relevantEvents = plan.audit_events.filter((event) => {
      
                    const eventStartYear = new Date(
                      event.planned_start_date
                    ).getFullYear();
                    const eventEndYear = new Date(
                      event.planned_end_date
                    ).getFullYear();
                    {/* const auditplanid=plan.id */}
                    return (
                      (eventStartYear <= year && eventEndYear >= year) ||
                      (eventStartYear < year && eventEndYear > year)
      
                    );
                  });
               
                  
      
                  if (relevantEvents.length > 0) {
                    return (
                      <div key={year} className="calendar">
                        <div className="year_entitieslist">
                          
                          <div className="calender_header">
                          <div className="year_con">
                            <span>{year}</span>
                          </div>
      
                          <div className="monthHeader">
                          <div className="yearlyQuarter_con">
                            <h6 className="quarter">Q1</h6>
                            <h6 className="quarter">Q2</h6>
                            <h6 className="quarter">Q3</h6>
                            <h6 className="quarter">Q4</h6>
                          </div>
                          <div className="months_con">
                            {Array.from({ length: 12 }, (_, month) => {
                              const monthStart = new Date(
                                Date.UTC(year, month, 1)
                              );
                              const monthEnd = new Date(
                                Date.UTC(year, month + 1, 0)
                              );
                              return (
                                <h6 key={month}>
                                  {monthStart.toLocaleString("default", {
                                    month: "short",
                                  })}
                                </h6>
                              );
                            })}
                          </div>
                          </div>
                          </div>
      
      
      
                          <div className="calender_content">
                          <div className="entities_list">
                            {relevantEvents.map((item, index) => (
                              <div className="audit_entity" key={index}>
                                <h6>{item.audit_entity.name}</h6>
                              </div>
                            ))}
                          </div>
      
                    <div className="timelineDiv">
                          {relevantEvents.map((event, index) => {
                            audit = event.audit ? event.audit : ""
                            const eventStartDate = new Date(
                              event.planned_start_date
                            );
                            const eventEndDate = new Date(event.planned_end_date);
                            const eventStartYear = eventStartDate.getFullYear();
                            const eventEndYear = eventEndDate.getFullYear();
                            {/* const auditplanid=event.id;
                            const auditentityid=event.audit_entity.id; */}
      
                            let adjustedStartDate = eventStartDate;
                            let adjustedEndDate = eventEndDate;
      
                            if (eventStartYear < year) {
                              adjustedStartDate = new Date(Date.UTC(year, 0, 1));
                            }
                            if (eventEndYear > year) {
      
                              adjustedEndDate = new Date(Date.UTC(year, 11, 31));
                            }else{
      
                            }
                             {/* console.log(event) */}
                          
      
                            return (
                              <Timeline
                                key={`${event.id}-${year}`}
                                APIData={{
                                  id: `${event.id}-${year}`,
                                  startDate: adjustedStartDate,
                                  endDate: adjustedEndDate,
                                  monthStart: parseDate(adjustedStartDate),
                                  monthEnd: parseDate(adjustedEndDate),
                                  showForm: false,
                                  
                                }}
                                timelineData={timelineData}
                                setTimelineData={setTimelineData}
                                handleSendClick={handleSendClick}
                                endyear={eventEndDate}
                                startyear={eventStartDate}
                                audit_event_id={event.id}
                                audit_entity={event.audit_entity.name}
                                auditplanid={plan.id}
                                auditentityid={event.audit_entity.id}
                                auditStatus={event.audit.status}
                                risk_assessment_id={plan.risk_assessment_id}
                              />
                            );
                          })}
                          </div>
      </div>
                          
                        </div>
      {/* 
                        <div
                          className="timeline_con"
                          style={{ width: tableSize ? "65vw" : "71vw" }}
                        >
      
                          
      
                          
                        </div> */}
                      </div>
                    );
                  } else {
                    return null;
                  }
                })
              );
            })
          )}
        </div>
      </section>
    </>
  );
};

export default CalenderViewPage;
