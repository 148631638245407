import React, { useState, useEffect } from "react";
import {
  NavLink,
  useLocation,
  Link,
  useNavigate,
  useParams,
} from "react-router-dom";
import axios from "axios";
import Breadcrumb from "../../Breadcrumb";
import SidebarMenu from "../../SidebarMenu";
import "../../../css/viewuser.css";
import { fetchDataObject } from "../../apiUtils";
import { auditExecution,auditExecutionInnerBU,auditExecutionInnerQA } from "../../subroutes";
import create from "../../../images/createbutton.svg";
import openarrow from "../../../images/openarrow.svg";
import "../createaudit/viewaudit.css";
import SingleOptionSelector from "../../SingleOptionSelector";
import MultiOptionSelector from "../../MultiOptionSelector";
import {
  postData,
  deleteData,
  deleteData2,
  updateData,
  justUpdateData,
} from "../../apiUtils";
import save_details from "../../../images/saveBtn.svg";
import pencil from "../../../images/edit_pencil.svg";
import backlogo from "../../../images/backLogo.svg";
import WindowComponent from "../../WindowComponent";
import RenderIcons from "../../RenderIcons";
import IconButton from "../../iconbutton/IconButton";

import AuditMemo from "../../AuditMemo";
import InitialSingleOptionSelector from "../../InitialSingleOptionSelector";

import BounceLoader from "react-spinners/BounceLoader";

const ViewAudits = ({ tableSize }) => {
  const [errors, setErrors] = useState("");
  const [firstName, setFirstName] = useState("");
  const [firstNameError, setFirstNameError] = useState(false);
  const [descriptionError, setDescriptionError] = useState(false);
  const [selectedKeyError, setSelectedKeyError] = useState(false);
  const [selectedPreparerError, setSelectedPreparerError] = useState(false);
  const [selectedReviewerError, setSelectedReviewerError] = useState(false);
  const [selectedassign_toError, setSelectedassign_toError] = useState(false);
  const [description, setDescription] = useState("");
  const [editMode, setEditMode] = useState(false);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const [name, setAuditName] = useState("");
  const [objectives, setObjectives] = useState("");
  const [introduction, setIntroduction] = useState("");
  const [audit_background, setAudit_background] = useState("");
  const [final_opinion, setFinal_opinion] = useState("");
  const [preparer_id, setPreparer_id] = useState([]);
  const [reviewer_id, setReviewer_id] = useState([]);
  const [assign_to_id, setAssign_to_id] = useState([]);
  const [APIData, setAPIData] = useState([]);
  const [file, setFile] = useState(null);
  const [uploadStatus, setUploadStatus] = useState("idle");
  const [uploadProgress, setUploadProgress] = useState(0);
  const location = useLocation();

  const [risk_assessment, setAPIRiskData] = useState([]);
  const [business_users_id, setAPIBSData] = useState([]);
  const [auditors_id, setAPIAuditorData] = useState([]);
  const [audit_entity_id, setAPIAuditData] = useState("");
  const [qa_id, setQAID] = useState("");


  const [end_date, setEndDate] = useState("");
  const [start_date, setStartDate] = useState("");

  const [actual_end_date, setActualEndDate] = useState("");
  const [actual_start_date, setActualStartDate] = useState("");

  const [AuditErrors, setAuditErrors] = useState(false);
  const [qaErrors, setQAErrors] = useState(false);

  const [selectedRiskError, setSelectedRiskError] = useState(false);

  const [stakeholders_ids, setstakeholders_ids] = useState([]);
  const [stakeholders_names, setstakeholdersnames] = useState([]);

  const [auIds, setAuIds] = useState([]);
  const [auNames, setAuNames] = useState([]);
  const [audit_scope, setScope] = useState();
  const [audit_phases, setPhases] = useState();
  const [other_relevant_information, setRelevant] = useState();
  const { id } = useParams();




  const userRoles = localStorage.getItem("userRoles");


  var routes=[];
  if (userRoles.includes("Admin")) {
  routes = auditExecution;
} else if (userRoles.includes("Business")) {
  routes = auditExecutionInnerBU;
}
//  else if (userRoles.includes("Audit Team")) {
//   routes = forAuditTeam;
// } else if (userRoles.includes("Audit Commission")) {
//   routes = forAuditCommission;
// } 
else if (userRoles.includes("Quality Assurance")) {
  routes = auditExecutionInnerQA;
} 
else {
  console.log("No matching role found");
}








  useEffect(() => {
    fetchDataObject(`/api/audit_engagement/audit/${id}/`, setAPIData, setErrors,setLoading);
  }, [id]);

  useEffect(() => {
    setScope(APIData.audit_scope);
    setPhases(APIData.audit_phases);
    setRelevant(APIData.other_relevant_information);
    setAuditName(APIData.name);
    setObjectives(APIData?.objectives);
    setIntroduction(APIData?.introduction);
    setAudit_background(APIData?.audit_background);
    setFinal_opinion(APIData?.final_opinion);
   


  }, [APIData]);

  const handleEditClick = () => {
    setEditMode(!editMode);
  };

  const Id = APIData.id;
  const riskTitle = APIData.risk_assessment
    ? APIData.risk_assessment.title
    : "";
  const riskId = APIData.risk_assessment ? APIData.risk_assessment.id : "";

  useEffect(() => {
    if (APIData && APIData.business_users) {
      const stakeholdersids = [];
      const stakeholdersnames = [];

      APIData.business_users.forEach((auditor) => {
        stakeholdersids.push(auditor.id);
        stakeholdersnames.push(auditor.name);
      });

      setstakeholders_ids(stakeholdersids);
      setstakeholdersnames(stakeholdersnames);
    }

    if (APIData && APIData.auditors) {
      const auids = [];
      const aunames = [];

      APIData.auditors.map((auditor) => {
        auids.push(auditor.id);
        aunames.push(auditor.name);
      });
      setAuIds(auids);
      setAuNames(aunames);
    }
  }, [
    APIData,
    setstakeholders_ids,
    setstakeholdersnames,
    setAuIds,
    setAuNames,
  ]);
  const auditEntityId = APIData.audit_entity ? APIData.audit_entity.id : "";
  const auditEntityName = APIData.audit_entity ? APIData.audit_entity.name : "";
  const prepareId= APIData.preparer?APIData.preparer.id:'';
  const prepareName= APIData.preparer?APIData.preparer.name:'';
  const reviewerId= APIData.reviewer?APIData.reviewer.id:'';
  const reviewerName= APIData.reviewer?APIData.reviewer.name:'';
  const assigned_Id= APIData.assign_to?APIData.assign_to.id:'';
  const assignedName= APIData.assign_to?APIData.assign_to.name:'';
  const qaId = APIData.qa ? APIData.qa.id : "";
  const qaName = APIData.qa ? APIData.qa.name : "";
   
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); 
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const actualAuditStartDate = APIData.actual_start_date
    ? formatDate(APIData.actual_start_date)
    : "";

    const auditStartDate = APIData.audit_event?.planned_start_date
    ? formatDate(APIData.audit_event?.planned_start_date)
    : "";

  const actualAuditEndDate = APIData.actual_end_date
    ? formatDate(APIData.actual_end_date)
    : "";

  const auditEndDate = APIData.audit_event?.planned_end_date
    ? formatDate(APIData.audit_event?.planned_end_date)
    : "";
  const eventStartDate = APIData.audit_event
    ? formatDate(APIData.audit_event.planned_start_date)
    : "";

  const eventEndDate = APIData.audit_event
    ? formatDate(APIData.audit_event.planned_end_date)
    : "";

  const eventId = APIData.audit_event ? APIData.audit_event.id : "";

  const onDelete = (e) => {
    e.preventDefault();
    deleteData2(
      `/api/audit_engagement/audit/${Id}`,
      navigate,
      setErrors,
      `/AuditExecution`
    );
  };

  const updateWithPhase = (e) => {
    e.preventDefault();
    updateData(
      `/api/audit_engagement/audit/${Id}`,
      {name,
        objectives,
        
        risk_assessment,
        audit_entity_id,
        auditors_id,
        business_users_id,
        actual_start_date: actual_start_date ? actual_start_date : actualAuditStartDate,
        actual_end_date: actual_end_date ? actual_end_date : actualAuditEndDate,
        planned_start_date : start_date ? start_date : auditStartDate,
        planned_end_date: end_date ? end_date : auditEndDate,
        audit_scope,
        audit_phases,
        other_relevant_information,
        phase:'Planning',
        qa_id
      },
      navigate,
      setErrors,
      `/auditExecution/${id}/detail/`,
    );
  };

  const updateAPIData = (e) => {
    console.log("got clicked ");
    e.preventDefault();

    if (!audit_entity_id) {
      setAuditErrors(!audit_entity_id ? true : false);
      setErrors("Please fill in all required fields.");
      setTimeout(() => {
        setErrors(null);
      }, 5000);
      return;
    }
    if (eventId) {
      justUpdateData(
        `/api/audit_universe/audit-events/${eventId}`,
        {
          planned_start_date: actual_start_date
            ? actual_start_date
            : eventStartDate,
          planned_end_date: actual_end_date ? actual_end_date : eventEndDate,
        },
        setErrors
      );
    }

    updateData(
      `/api/audit_engagement/audit/${Id}`,
      {
        name,
        objectives,
        introduction,
        audit_background,
        risk_assessment,
        audit_entity_id,
        auditors_id,
        business_users_id,
        actual_start_date: actual_start_date ? actual_start_date : actualAuditStartDate,
        actual_end_date: actual_end_date ? actual_end_date : actualAuditEndDate,
        planned_start_date : start_date ? start_date : auditStartDate,
        planned_end_date: end_date ? end_date : auditEndDate,
        audit_scope,
        audit_phases,
        other_relevant_information,
        qa_id,
        preparer_id,
        reviewer_id,
        assign_to_id,
      },
      navigate,
      setErrors,
      `/auditExecution/${id}/detail/`,
    );
  };

  const handleCancelClick=()=>{
    setEditMode(false);
    window.location.reload()
  }



  return (
    <>
      {errors && <p className="errormessage">{errors}</p>}

      <section className="navroutes">
      <RenderIcons homeRoutes={routes} id ={id}/>

        <div className="filter">
        <Breadcrumb />


<div  style={{ display: "flex"}}>
<span className="auditstatusphase"><p>{APIData.phase}</p></span>
<Link to={`/AuditExecution`}>
                  <div
                    className="backBtnDiv"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      gap: "1vw",
                      backgroundColor: "#FFF3CC",
                      padding: ".5vw 1vw",
                      borderRadius: "2.5vw",
                      // position:'absolute',
                      // top:'24.7%',
                      // right:'2%'
                    }}
                  >
                    <img src={backlogo} style={{ width: "1.2vw" }} />
                    <p
                      style={{
                        fontFamily: "intersemibold",
                        fontSize: ".8vw",
                        color: "#775F0F"
                      }}
                    >
                      Back to Audits
                    </p>
                  </div>
                </Link>
</div>
          
      
        </div>
      



        <div className="filter-container">

        {loading ? (
          <div className="spinner-bounce-loader">
          <BounceLoader color="#a6fafa"/> 
          </div>
            
          ): APIData.id===0 ? (
            <p className="data-error-text">No data found</p> 
          ) : (
        <div
        className="view_audit_container"
        style={{ width: tableSize ? "79.5vw" : "90vw" }}
      >
        <div className="delete_edit_close_btn" style={{ zIndex: "1000" }}>
        {editMode && <IconButton
            type="close"
            className="closeView"
            tooltip="Cancel"
            onClick={handleCancelClick}
          />}
          {editMode ? (
            <IconButton
              type="save"
              tooltip="Save"
              onClick={updateAPIData}
              link="/"
            />
          ) : (
            <IconButton type="edit" tooltip="Edit" onClick={handleEditClick} />
          )}
          <WindowComponent onDelete={onDelete} />
        </div>

        <h3>Audit Details</h3>
        <form action="">


              <div className="lng_lbl_inpt">
                <label>Audit Name</label>
                <input
                  type="text"
                  value={name}
                  placeholder="Audit Name"
                  disabled={!editMode}
                  onChange={(e) => setAuditName(e.target.value)}
                  style={{backgroundColor:editMode ? "#EEF1FC":"#e6e6e6"}}
                />
              </div>

              <div className="lbl_txa">
                <label>Objectives</label>
                <textarea
                  type="text"
                  value={objectives}
                  disabled={!editMode}
                  placeholder="Audit Objectives"
                  onChange={(e) => setObjectives(e.target.value)}
                  style={{backgroundColor:editMode ? "#EEF1FC":"#e6e6e6"}}
                />
              </div>
              

                <SingleOptionSelector
                  apiUrl="/api/audit_universe/risk-assessments/"
                  setSelectorId={setAPIRiskData}
                  setSelectorError={setSelectedRiskError}
                  Name="title"
                  title="Risk Assessments"
                  initialId={riskId}
                  initialName={riskTitle}
                  editMode={editMode}
                />
              
            

            
                <MultiOptionSelector
                  apiUrl="/api/audit_universe/business-stakeholders/"
                  setSelectorId={setAPIBSData}
                  Name="name"
                  title="StakeHolders"
                  initialIds={stakeholders_ids ? stakeholders_ids : []}
                  initialNames={stakeholders_names ? stakeholders_names : []}
                  editMode={editMode}
                />
              
           
          

          
            
            <div
              className="lbl_inpt"
            >
              <label> Actual Start Date: </label>{" "}
              <input
                style={{
                  backgroundColor: editMode ? "#EEF1FC" : "#e6e6e6",
                }}
                type="date"
                value={actual_start_date ? actual_start_date : actualAuditStartDate}
                onChange={(e) => setActualStartDate(e.target.value)}
                className={editMode ? "" : "noEditInput"}
                readOnly={!editMode}
              />
            </div>

            <div
              className="lbl_inpt"
            >
              <label>Actual End Date:</label>
              <input
                style={{
                  backgroundColor: editMode ? "#EEF1FC" : "#e6e6e6",
                }}
                type="date"
                value={actual_end_date ? actual_end_date : actualAuditEndDate}
                onChange={(e) => setActualEndDate(e.target.value)}
                className={editMode ? "" : "noEditInput"}
                readOnly={!editMode}
              />
            </div>
         

         
           
            <div
              className="lbl_inpt"
            >
              <label> Start Date: </label>{" "}
              <input
                style={{
                  backgroundColor: editMode ? "#EEF1FC" : "#e6e6e6",
                }}
                type="date"
                value={start_date ? start_date : auditStartDate}
                onChange={(e) => setStartDate(e.target.value)}
                className={editMode ? "" : "noEditInput"}
                readOnly={!editMode}
              />
            </div>

            <div
              className="lbl_inpt"
            >
              <label>End Date:</label>
              <input
                style={{
                  backgroundColor: editMode ? "#EEF1FC" : "#e6e6e6",
                }}
                type="date"
                value={end_date ? end_date : auditEndDate}
                onChange={(e) => setEndDate(e.target.value)}
                className={editMode ? "" : "noEditInput"}
                readOnly={!editMode}
              />
            </div>
          
          
          
              
              
                <SingleOptionSelector
                  apiUrl="/api/audit_universe/audit-entities/"
                  setSelectorId={setAPIAuditData}
                  setSelectorError={setAuditErrors}
                  selectorError={AuditErrors}
                  Name="name"
                  title="Audit Entity"
                  initialId={auditEntityId}
                  initialName={auditEntityName}
                  editMode={editMode}
                />
             
            

            
              
                <MultiOptionSelector
                  apiUrl="/api/audit_universe/audit-team/"
                  setSelectorId={setAPIAuditorData}
                  Name="name"
                  title="Auditor"
                  initialIds={auIds ? auIds : []}
                  initialNames={auNames ? auNames : []}
                  editMode={editMode}
                />
            
         
          
          
                  <InitialSingleOptionSelector
                    apiUrl="/api/audit_universe/audit-team/"
                    setSelectorId={setPreparer_id}
                    setSelectorError={setSelectedPreparerError}
                    Name="name"
                    title="Preparer"
                    initialIds={auditors_id}

                     initialId={prepareId}
                     initialName={prepareName}
                    editMode={editMode}
                  />
               

               
                  <InitialSingleOptionSelector
                    apiUrl="/api/audit_universe/audit-team/"
                    setSelectorId={setReviewer_id}
                    setSelectorError={setSelectedReviewerError}
                    Name="name"
                    title="Reviewer"
                    initialIds={auditors_id}
                    initialId={reviewerId}
                    initialName={reviewerName}
                    editMode={editMode}
                  />
               
         
          
          
         
                  <InitialSingleOptionSelector
                    apiUrl="/api/audit_universe/business-stakeholders/"
                    setSelectorId={setAssign_to_id}
                    setSelectorError={setSelectedassign_toError}
                    Name="name"
                    title="Business Owner"
                    initialIds={business_users_id}

                    initialId={assigned_Id}
                    initialName={assignedName}
                    editMode={editMode}
                  />
                
            
            
              
              
                <SingleOptionSelector
                  apiUrl="/api/audit_universe/quality-assurance/"
                  setSelectorId={setQAID}
                  setSelectorError={setQAErrors}
                  selectorError={qaErrors}
                  Name="name"
                  title="QA"
                  initialId={qaId}
                  initialName={qaName}
                  editMode={editMode}
                />
             

            
         
          
        </form>

        
         
            <h3>Audit Memo</h3>

            <form >
           
              <div className="lbl_txa">
                <label>Audit Scope</label>

                <textarea
                  style={{
                    backgroundColor: editMode ? "#EEF1FC" : "#e6e6e6",
                  }}
                  placeholder="Define Audit Scope"
                  value={audit_scope}
                  onChange={(e) => {
                    setScope(e.target.value);
                  }}
                  disabled={!editMode}

                />
              </div>
              
              <div className="lbl_txa">
                <label>Audit Key Phases/Duration</label>

                <textarea
                  style={{
                    backgroundColor: editMode ? "#EEF1FC" : "#e6e6e6",
                  }}
                  placeholder="Audit Key Phases/Duration"
                  value={audit_phases}
                  onChange={(e) => {
                    setPhases(e.target.value);
                  }}
                  disabled={!editMode}

                />
              </div>
             
              <div className="lbl_txa">
                <label>Other Relevant Information</label>

                <textarea
                  style={{
                    backgroundColor: editMode ? "#EEF1FC" : "#e6e6e6",
                  }}
                  placeholder="Other Relevant Information"
                  value={other_relevant_information}
                  onChange={(e) => {
                    setRelevant(e.target.value);
                  }}
                  disabled={!editMode}

                />
              </div>
            </form>
          

          {/* <Link to="/AuditExecution/:id/Planning/pdfpage">
            <button>View as PDF</button>
          </Link> */}
        
      </div>
          )
        }
          
        </div>
        
      </section>
    </>
  );
};
export default ViewAudits;
