import React, { useState, useEffect } from "react";
import Breadcrumb from "../../Breadcrumb";
// import "../schedulemeeting/schedulemeeting.css";
import SingleOptionSelector from "../../SingleOptionSelector";
import MultiOptionSelector from "../../MultiOptionSelector";
import { postData, deleteData, updateData } from "../../apiUtils";
import { Link, useParams, useNavigate,NavLink } from "react-router-dom";
import backlogo from "../../../images/backLogo.svg";
import IconButton from "../../iconbutton/IconButton";

import WindowComponent from "../../WindowComponent";
import SidebarMenu from "../../SidebarMenu";
import { auditExecution } from "../../subroutes";

const SchedulemeetingView = ({ tableSize, APIData }) => {
  const [errors, setErrors] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [objective, setObjective] = useState(APIData.objective);
  const [objectiveError, setObjectiveError] = useState(false);
  const [preparer_id, setPreparerId] = useState("");
  const [PreparerError, setPreparerError] = useState(false);
  const [reviewer_id, setReviewerId] = useState("");
  const [reviewerError, setReviewerError] = useState(false);
  const [participantError, setParticipantError] = useState(false);
  const [assign_to_id, setAssignTo] = useState("");
  const [assignError, setAssignError] = useState(false);
  const [participants_id, setParticipants] = useState([]);
  const [participants_id1, setParticipants1] = useState([]);
  const [scheduled_start_date, setStartDate] = useState(APIData.scheduled_start_date);
  const [start_dateError, setStart_dateError] = useState(false);
  const [scheduled_end_date, setEndDate] = useState(APIData.scheduled_end_date);
  const [end_dateError, setEnd_dateError] = useState(false);

  const [initialParticipantsId, setInitialParticipantsId] = useState([]);
  const [initialParticipantsName, setInitialParticipantsName] = useState([]);
  const [editMode, setEditMode] = useState(false);

  const [selectedOtherKey, setSelectedOtherKey] = useState([]);
  const [other_stakeholders_ids, setOther_stakeholders_ids] = useState(['242']);
  const [other_stakeholders_id, setOther_stakeholdersId] = useState(["Lokesh Sharma"]);
  const { id, planid } = useParams();
  const navigate = useNavigate();

  const updateAPIData = (e) => {
    e.preventDefault();
    if (!objective || !preparer_id || !reviewer_id || !assign_to_id || !id || !participants_id) {
      setObjectiveError(!objective ? true : false);
      setPreparerError(!preparer_id ? true : false);
      setReviewerError(!reviewer_id ? true : false);
      setAssignError(!assign_to_id ? true : false);
      setParticipantError(!participants_id ? true : false);
      setErrors("Please fill in all required fields.");
      setTimeout(() => {
        setErrors(null);
      }, 5000);
      return;
    }

    updateData(
      `/api/audit_engagement/plan/${planid}`,
      {
        objective,
        preparer_id,
        reviewer_id,
        assign_to_id,
        type: "Task",
        scheduled_start_date,
        scheduled_end_date,
        audit_id: id,
        participants_id,
      },
      navigate,
      setErrors,
      `/AuditExecution/${id}/Planning`
    );
  };

  const handleEditClick = () => {
    setEditMode(!editMode);
  };

  
  useEffect(() => {
    if (APIData.data && APIData.data.details && APIData.data.details.participants) {
      const auditSelectedKeysOther = APIData.data.details.participants;
      const otherIds = auditSelectedKeysOther.map((item) => item.user_id);
      const otherNames = auditSelectedKeysOther.map((item) => item.name);
      setOther_stakeholders_ids(otherIds);
      setSelectedOtherKey(otherNames);
    }
  }, [APIData]); 

  const onDelete = (e) => {
    e.preventDefault();
    deleteData(
      `/api/audit_engagement/plan/${planid}`,
      navigate,
      setErrors,
      `/AuditExecution/${id}/Planning`
    );
  };

  
  const handleCancelClick=() => {
    setEditMode(false)
    window.location.reload();
  }

  function convertDate(inputDate) {
    const date = new Date(inputDate);

    // Subtract 5 hours and 30 minutes for display
    date.setHours(date.getHours() - 5);
    date.setMinutes(date.getMinutes() - 30);

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');

    return `${year}-${month}-${day}T${hours}:${minutes}`;
}

function handleStartDateChange(e) {
  // Convert the input value back to UTC if needed or store as is
  const localDate = new Date(e.target.value);
  // You might need to adjust to UTC if your API requires UTC dates
  const utcDate = new Date(localDate.getTime() + (5 * 60 * 60 * 1000) + (30 * 60 * 1000));
  setStartDate(utcDate.toISOString());
  setStart_dateError(false)
}

function handleEndDateChange(e) {
  // Convert the input value back to UTC if needed or store as is
  const localDate = new Date(e.target.value);
  // You might need to adjust to UTC if your API requires UTC dates
  const utcDate = new Date(localDate.getTime() + (5 * 60 * 60 * 1000) + (30 * 60 * 1000));
  setEndDate(utcDate.toISOString());
  setEnd_dateError(false)
}
  

  return (
    <>
      {errors && <p className="errormessage">{errors}</p>}
      {successMessage && <p className="successmessage">{successMessage}</p>}

      <section className="navroutes">
        <div className="filter-container">
          <div className="filter">
            <div className="views">
              <div
                style={{
                  width: tableSize ? "79.5vw" : "90vw",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center"
                }}
              >           
                <h3 className="h2_interbold" >Generic Task View</h3>
                <Link to={`/AuditExecution/${id}/Planning`}>
                  <div
                    className="backBtnDiv"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      gap: "1vw",
                      backgroundColor: "#FFF3CC",
                      padding: ".5vw 1vw",
                      borderRadius: "2.5vw"
                    }}
                  >
                    <img src={backlogo} style={{ width: "1.2vw" }} />
                    <p
                      style={{
                        fontFamily: "intersemibold",
                        fontSize: ".8vw",
                        color: "#775F0F"
                      }}
                    >
                      Back to all tasks
                    </p>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>

        <hr className="breadcrumbabv" />

<Breadcrumb />

<div className="auditMenuCon">
          {auditExecution.map((route, index) => {
            if (route.subRoutes) {
              return <SidebarMenu route={route}/>;
            }

            return (
              <NavLink
                to={`${route.path.replace(":id", id)}`}
                key={index}
                className="navlink"
                activeclassname="active"
              >
                <div className="navicon">
                  <img src={route.icon} alt="Dashboard Icon" />
                </div>
                <div className="navicon">{route.name}</div>
              </NavLink>
            );
          })}
        </div>

        <div
          className="createmeeting"
          style={{ width: tableSize ? "79.5vw" : "90vw" }}
        >
          <div className="delete_edit_close_btn">
          {editMode && <IconButton type="close" className='closeView' tooltip="Cancel" onClick={handleCancelClick} />}

            {editMode ? (
              <IconButton type="save" tooltip="Save" onClick={updateAPIData} link="/" />
            ) : (
              <IconButton type="edit" tooltip="Edit" onClick={handleEditClick} />
            )}
            <WindowComponent onDelete={onDelete} />
          </div>
          <div
            className="meetTitle"
            style={{ width: tableSize ? "70vw" : "80vw" }}
          >
            <label>Objective</label>
            <input
              type="text"
              style={{ width: tableSize ? "62vw" : "69.5vw", border: objectiveError ? "1px solid #FF4646" : "",
                backgroundColor:editMode ? "#EEF1FC" : "#e6e6e6",
              }}
              placeholder="Objective"
              value={objective}
              onChange={(e) => {
                setObjective(e.target.value);
                setObjectiveError(false);
              }}
              readOnly={!editMode}
            />
          </div>

       

          <div
            className=""
            style={{
              width: tableSize ? "70vw" : "80vw",
              display: "flex",
              justifyContent: "space-between"
            }}
          >
            <div className="stakeholders" style={{ width: tableSize ? "28vw" : "31vw" }}>
              <SingleOptionSelector
                apiUrl="/api/audit_universe/audit-team/"
                setSelectorId={setPreparerId}
                setSelectorError={setPreparerError}
                selectorError={PreparerError}
                Name="name"
                title="Preparer"
                initialId={APIData.preparer ? APIData.preparer.id : ""}
                initialName={APIData.preparer ? APIData.preparer.name : ""}
                editMode={editMode}
              />
            </div>

            <div className="stakeholders" style={{ width: tableSize ? "28vw" : "31vw" }}>
              <SingleOptionSelector
                apiUrl="/api/audit_universe/admin-audit-team/"
                setSelectorId={setReviewerId}
                setSelectorError={setReviewerError}
                selectorError={reviewerError}
                Name="name"
                title="Reviewer"
                initialId={APIData.reviewer ? APIData.reviewer.id : ""}
                initialName={APIData.reviewer ? APIData.reviewer.name : ""}
                editMode={editMode}
              />
            </div>
          </div>
          <div
            className="meetdateSetterDiv"
            style={{ width: tableSize ? "70vw" : "79.5vw" }}
          >
            <div
              className="meetstartDate"
              style={{ width: tableSize ? "27.5vw" : "30.5vw" }}
            >
              <label> Start Date: </label>
              <input
                type="datetime-local"
                style={{ border: start_dateError ? "1px solid #FF4646" : "",
                backgroundColor:editMode ? "#EEF1FC" : "#e6e6e6",
                }}
                value={convertDate(scheduled_start_date)}
                onChange={handleStartDateChange}
                readOnly={!editMode}
              />
            </div>

            <div
              className="meetendDate"
              style={{ width: tableSize ? "28.5vw" : "30.5vw" }}
            >
              <label> End Date: </label>
              <input
                type="datetime-local"
                style={{ border: end_dateError ? "1px solid #FF4646" : "",
                  backgroundColor:editMode ? "#EEF1FC" : "#e6e6e6",
                }}
                value={convertDate(scheduled_end_date)}
                onChange={handleEndDateChange}
                readOnly={!editMode}
              />
            </div>
          </div>

          <div
            style={{
              width: tableSize ? "70vw" : "80vw",
              display: "flex",
              justifyContent: "space-between"
            }}
          >
            <div className="stakeholders" style={{ width: tableSize ? "28vw" : "31vw" }}>
              <SingleOptionSelector
                apiUrl="/api/audit_universe/business-stakeholders/"
                setSelectorId={setAssignTo}
                setSelectorError={setAssignError}
                selectorError={assignError}
                Name="name"
                title="Assign To"
                initialId={APIData.assign_to ? APIData.assign_to.id : ""}
                initialName={APIData.assign_to ? APIData.assign_to.name : ""}
                editMode={editMode}
              />
            </div>

            
          </div>
        </div>
      </section>
    </>
  );
};

export default SchedulemeetingView;
