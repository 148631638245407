import React, { useState, useEffect } from "react";
import {
  NavLink,
  useLocation,
  Link,
  useNavigate,
  useParams,
} from "react-router-dom";
import axios from "axios";
import Breadcrumb from "../../Breadcrumb";
import SidebarMenu from "../../SidebarMenu";
import "../../../css/viewuser.css";
import { fetchData } from "../../apiUtils";
import { auditExecution } from "../../subroutes";
import create from "../../../images/createbutton.svg";
import openarrow from "../../../images/openarrow.svg";
import "../auditplan/auditplan.css";
import ProgressBar from "../progressbar/ProgressBar";
import "./publishreport.css";
import sehati_logo from "../../../images/sehati_logo.png";
import heat_map from "../../../images/heat_map.png";
import npheis_report from "../../../images/NPHEIS Report.png";

import pencil from "../../../images/editpencil_wbg.svg";
import deleteicon from "../../../images/delete_wbg.svg";
import Final_Report from "../../../pdf/Final Legal Report.pdf";
import backBtn from "../../../images/backLogo.svg";

const PublishReport = ({ tableSize }) => {
  const [errors, setErrors] = useState("");
  const [titlePage, setTitlePage] = useState("");
  const [APIData, setAPIData] = useState([]);
  const [APIData1, setAPIData1] = useState([]);
  const [showSubmit, setShowSubmit] = useState(false);
  const [showTodo, setShowTodo] = useState(false);
  const [showApproved, setShowApproved] = useState(false);
  const [showGenerate, setShowGenerate] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();
  const successMessageColor = location.state?.successMessageColor;
  const successMessage = location.state?.successMessage;
  const [showMessage, setShowMessage] = useState(!!successMessage);
  const originalUrl = location.state?.from;
  const {
    id,
    ObservationUpdateId,
    ReportCreationId,
    ObservationCreationId,
    reportId,
  } = useParams();

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      navigate(".", { replace: true });
    }, 5000);

    return () => clearTimeout(timeoutId);
  }, []);

//   useEffect(() => {
//     const url = window.location.href;
//     const parts = url.split("/");
//     const desiredPart = parts[parts.length - 3];
//     console.log(desiredPart);

//     switch (desiredPart) {
//       case "ReportingObservationCreation":
//         setTitlePage("Initial Draft Observation");
//         setShowTodo(true);
//         break;

//       case "ReportingObservationUpdate":
//         setTitlePage("Finalize Observation & Action Plan");

//         setShowSubmit(true);
//         break;

//       case "ReportingReportCreation":
//         setTitlePage("Report Generation");

//         setShowApproved(true);
//         break;

//       default:
//         return "Invalid Task";
//     }
//   }, []);

  useEffect(() => {
    fetchData(
      `/api/audit_engagement/observation/?audit_id=${id}`,
      setAPIData1,
      setErrors
    );
  }, []);
  const [filteredDataDone, setFilteredData] = useState();

  useEffect(() => {
    setFilteredData(APIData1.filter((data) => data?.task?.status === "done"));
  }, [APIData1]);

  useEffect(() => {
    fetchData(`/api/audit_universe/audit-entities/`, setAPIData, setErrors);
  }, []);

  const [filters, setFilters] = useState({
    id: "",
    status: "",
    workpaper_id: "",
    summary: "",
    preparer: "",
    reviewer_name: "",
    created_at: "",
  });

  const handleFilterChange = (filterKey, e) => {
    setFilters({ ...filters, [filterKey]: e.target.value });
  };
  const renderIcons = () => {
    return auditExecution.map((route, index) => {
      if (route.subRoutes) {
        return <SidebarMenu route={route} key={index} />;
      }

      return (
        <NavLink
          to={`${route.path.replace(":id", id)}`}
          key={index}
          className="navlink"
          activeclassname="active1"
        >
          <div className="navicon">
            {<img src={route.icon} alt="Dashboard Icon" />}
          </div>
          <div className="navicon">{route.name}</div>
        </NavLink>
      );
    });
  };

  const renderAuditObservationStatus = (status) => {
    switch (status) {
      case "done":
        return (
          <div
            className="auditstatus completed"
            style={{ backgroundColor: "#65CC5C", color: "#fff" }}
          >
            Done
          </div>
        );
      case "review":
        return (
          <div
            className="auditstatus completed"
            style={{ backgroundColor: "#FFD300", color: "#fff" }}
          >
            Review
          </div>
        );
      case "rejected":
        return (
          <div
            className="auditstatus completed"
            style={{
              backgroundColor: "#D92121",
              color: "#fff",
              padding: "10px 302x",
            }}
          >
            Rejected
          </div>
        );
      case "todo":
        return (
          <div
            className="auditstatus open"
            style={{ backgroundColor: "#5771DF", color: "#fff" }}
          >
            Todo
          </div>
        );
      default:
        return (
          <div
            className="auditstatus open"
            style={{ backgroundColor: "#5771DF", color: "#fff" }}
          >
            ---
          </div>
        );
    }
  };

  const filteredData = APIData1.filter((data) => {
    const {
      id,
      status,
      workpaper_id,
      summary,
      preparer,
      reviewer,
      created_at,
      task,
      workpapertestcase,
    } = data;

    const matchesId = filters.id
      ? (id || "").toString().includes(filters.id)
      : true;

    const matchesStatus = filters.status
      ? task && task.status.toLowerCase().includes(filters.status.toLowerCase())
      : true;

    const matchesWorkpaperId = filters.workpaper_id
      ? workpapertestcase.some((wpTestCase) =>
          wpTestCase.workpaper.id
            .toString()
            .includes(filters.workpaper_id.toString())
        )
      : true;

    const matchesSummary = filters.summary
      ? (summary || "").toLowerCase().includes(filters.summary.toLowerCase())
      : true;

    const matchesPreparer = filters.preparer
      ? preparer &&
        preparer.name.toLowerCase().includes(filters.preparer.toLowerCase())
      : true;

    const matchesReviewer = filters.reviewer
      ? reviewer &&
        reviewer.name.toLowerCase().includes(filters.reviewer.toLowerCase())
      : true;

    const matchesCreatedAt = filters.created_at
      ? (created_at || "").toString().includes(filters.created_at)
      : true;

    return (
      matchesId &&
      matchesStatus &&
      matchesSummary &&
      matchesWorkpaperId &&
      matchesPreparer &&
      matchesReviewer &&
      matchesCreatedAt
    );
  });

  useEffect(() => {
    console.log("Filtered Data:", filteredData);
  }, [filteredData]);

  const viewObservation = (obejctionId) => {
    if (showTodo) {
      navigate(
        `/AuditExecution/${id}/Reporting/ReportingObservationCreation/${reportId}/${obejctionId}/`
      );
    } else if (showSubmit) {
      navigate(
        `/AuditExecution/${id}/Reporting/ReportingObservationUpdate/${reportId}/${obejctionId}/`
      );
    } else if (showApproved) {
      navigate(
        `/AuditExecution/${id}/Reporting/ReportingReportCreation/${reportId}/${obejctionId}/`
      );
    }
  };

  return (
    <>
      {errors && <p className="errormessage">{errors}</p>}
      {successMessage && <p className="successmessage">{successMessage}</p>}

      {successMessage && <p className="successmessage">{successMessage}</p>}

      <section className="navroutes">
        <div className="filter-container">
          <div className="filter">
            <div className="views">
              <h3 className="h2_interbold" >Publish Report</h3>
              </div>
              <div className="pub-report-div">
              {!showTodo && !showSubmit && showApproved && (
               
                  <button
                    className="publish-report-btn"
                  >
                    Publish Report
                  </button>
              
              )} 

        </div>
            
          </div>

          <hr />
        </div>
        <div
          className="publish_report"
          style={{ width: tableSize ? "79.5vw" : "90vw" }}
        >
            <Link to={`/AuditExecution/${id}/Reporting/`}>
      <div className="back-btn-report">
      <div className="backbtns">
            <img src={backBtn} />
            <p>Back </p>
          </div>
          </div>
          </Link>

          <iframe src={Final_Report} className="reportPdf" />

         
        </div>

       

        <div className="cardscontainer">
          {filteredData.map((data) => (
            <div className="aecard">
              <h1>{data.name} </h1>

              <img src={openarrow} />
            </div>
          ))}
        </div>
      </section>

      
    </>
  );
};

export default PublishReport;
