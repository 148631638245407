import React from 'react';
import '../../App.css'; 
import user from "../../images/user1.svg"


const AvatarBu = ({ firstName, backgroundColor }) => {
  const initialLetter = firstName ? firstName[0].toUpperCase() : <img src={user} style={{height:"5vh"}} alt="User Icon" />;

  const avatarStyle = {
    backgroundColor: backgroundColor,
  };

  return (
    <div className="avatarbu" style={avatarStyle}>
      {initialLetter}
    </div>
  );
};

export default AvatarBu;


