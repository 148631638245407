import React, { useState, useEffect } from "react";
import {
  NavLink,
  useLocation,
  Link,
  useNavigate,
  useParams,
} from "react-router-dom";

import axios from "axios";
import sendComment from "../../../images/send.svg";
import Breadcrumb from "../../Breadcrumb";
import SidebarMenu from "../../SidebarMenu";
import "../../../css/viewuser.css";
import cutbutton from "../../../images/cut.svg";
import SaveIcon from "../../../images/saveBtn.svg";
import EditIcon from "../../../images/edit_pencil.svg";
import CloseIcon from "../../../images/closeicon.svg";
import DeleteIcon from "../../../images/deleteBtn.svg";
import "../issuespage/issues.css";

import {
  deleteData,
  fetchData,
  updateData,
  justPostData,
} from "../../apiUtils";
import { issueTracking } from "../../subroutes";
import openarrow from "../../../images/openarrow.svg";
// import "../createObservation/createobservation.css";
import SingleOptionSelector from "../../SingleOptionSelector";
import SingleOptionSelectorWP from "../../SingleOptionSelectorWP";
import AuditReportingSeclector from "../../auditExecution/auditReporting/AuditReportingSelector";
import SecondaryAssignTo from "../../auditExecution/auditReporting/SecondaryAssignTo";
import { postData } from "../../apiUtils";
import MultiOptionTest from "../../MultiOptionTest";
import MultiOptionWorkPaper from "../../MultiOptionWorkPaper";
import SuperAvatar from "../../SuperAvatar";
import backBtn from "../../../images/backLogo.svg";
import IconButton from "../../iconbutton/IconButton";
import WindowComponent from "../../WindowComponent";
import addSubObv from "../../../images/cut.svg";
import deleteObv from "../../../images/deleteBtn.svg";
import savesubObv from "../../../images/saveBtn.svg";
import remove_button from "../../../images/remove_button.svg";
import blue_add_button from "../../../images/blue_plus_button.svg";
import RenderIcons from "../../RenderIcons";
const Issuedetailpage = ({ tableSize }) => {
  const lastName = localStorage.getItem("userLastName");
  const avaUserName = localStorage.getItem("userName");
  const [APIQData, setAPIQData] = useState("");

  const [errors, setErrors] = useState("");
  const [APIData, setAPIData] = useState([]);
  const [fileData, setFileData] = useState([]);
  const [selectedObservationId, setSelectedObservationId] = useState("");

  const location = useLocation();
  const navigate = useNavigate();

  const [preparer_id, setPreparerId] = useState("");
  const [PreparerError, setPreparerError] = useState(false);
  const [sassign_to_id, setSAssign_to_id] = useState("");
  const [assign_to_1_id, setAssign_to_id] = useState('');
  const [assign_to_idError, setAssign_to_idError] = useState(false);
  const [sassign_to_idError, setSAssign_to_idError] = useState(false);


  const [selectAuditId, setSelectAuditId] = useState('');
  const [selectAuditIdError, setSelectAuditIdError] = useState(false);


  const [reviewer_id, setReviewerId] = useState("");
  const [reviewerError, setReviewerError] = useState(false);
  const [editMode, setEditMode] = useState(true);

  const successMessageColor = location.state?.successMessageColor;
  const successMessage = location.state?.successMessage;
  const [showMessage, setShowMessage] = useState(!!successMessage);
  const originalUrl = location.state?.from;
  const { id, reportId, obejctionId } = useParams();
  const [sub_observation, setSubObservations] = useState([]);

  const [startdate, setStartdate] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [objective, setTitle] = useState("");
  const [auditData, setAuditData] = useState([]);
  const [audit, setAudit] = useState([]);
  const [rating, setObservationRating] = useState("");
  const [shortDescriptionError, setShortDescriptionError] = useState(false);
  const [description, setDescription] = useState("");
  const [issue_description, setIssueDescription] = useState("");
  const [recommendation, setRecommendation] = useState("");

  const [management_response, setActionPlan] = useState("");
  const [fileObjective, setFileObjective] = useState("");


  const [owner_id, setOwner] = useState("");
  const [level_1_ownerError, setLevel1OwnerError] = useState("");
  const [assign_to_2_id, setLevel2Owner] = useState("");
  const [level_2_ownerError, setLevel2OwnerError] = useState("");
  const [management_response_due_date, setActionDueDate] = useState("");
  const [response_audit, setAuditResponse] = useState("");
  const [response_audit_due_date, setResponseActionDueDate] = useState("");
  const [obname, setObName] = useState("");
  const [short_description, setShortDescription] = useState("");
  const [observationDetails, setObservationDetails] = useState(null);
  const [isOpen, setIsOpen] = useState(false);

  const userRoles = localStorage.getItem("userRoles");
  const Roles = userRoles.includes("Business");

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      navigate(".", { replace: true });
    }, 5000);

    return () => clearTimeout(timeoutId);
  }, []);


  useEffect(() => {
    setEditMode(false)
    console.log(selectAuditId)
    if(selectAuditId){
    fetchData(`/api/audit_engagement/observation/?audit_id=${selectAuditId}&status=Published`, setAPIData, setErrors);
    }
  }, [selectAuditId]);



  useEffect(() => {
    if (selectedObservationId) {
      setEditMode(false)
      fetchData(
        `/api/audit_engagement/observation/${selectedObservationId}/`,
        setObservationDetails,
        setErrors
      );
    }
  }, [selectedObservationId]);



  useEffect(() => {
    fetchData(`/api/audit_engagement/audit/`, setAuditData, setErrors);
  }, [id]);

  

  const [taskErrors, setTaskErrors] = useState("");

  useEffect(() => {
    if (observationDetails) {
      setTitle(observationDetails?.objective);

      setObservationRating(observationDetails?.rating);
      setShortDescription(observationDetails?.short_description);
  
      setAPIQData(observationDetails?.task?.id);
      setStartdate(observationDetails.due_date);
      setActionPlan(observationDetails?.action_plan);
      setActionDueDate(observationDetails?.management_response_due_date);
      // setSummary(APIData.summary);
      setRecommendation(observationDetails.recommendation);
      setAuditResponse(observationDetails?.response_audit);
      setResponseActionDueDate(observationDetails?.response_audit_due_date);

      // setPreliminaryCloseObservation(APIData?.preliminary_close_observation);
      // const workid = APIData.workpapertestcase ? APIData.workpapertestcase : [];
      // const workIds = workid.map((item) => item.workpaper?.id);
      // const workObjectives = workid.map((item) => item.workpaper?.objective);
      setSubObservations(observationDetails?.data?.data?.sub_observations);
      setSubObservations(observationDetails?.data?.subObservations || []);
      // setTestIds(
      //     APIData.workpapertestcase
      //         ? APIData.workpapertestcase.map((item) => item.id)
      //         : []
      // );
      // setWorkPId([...new Set(workIds)]);
      // setWorkPojcts([...new Set(workObjectives)]);

      if (
        APIData.task?.status === "review" ||
        APIData.task?.status === "done" ||
        APIData.task?.status === "todo" ||
        APIData.task?.status === "rejected"
      ) {
        setEditMode(false);
      }
    }
  }, [observationDetails]);

  const [taskTitle, setTaskTitle] = useState("");

  useEffect(() => {
    if (APIData) {
      setAPIQData(APIData?.task?.id);
      setTaskTitle(APIData?.task?.title); // Set task title from APIData
    }
  }, [APIData]);

  const [filters, setFilters] = useState({
    name: "",
    key_stakeholder: "",
  });
  const handleSelectionChange = (id) => {
    setSelectedObservationId(id);
  };

  const handleFilterChange = (filterKey, e) => {
    setFilters({ ...filters, [filterKey]: e.target.value });
  };

  const CreateIssue = () => {
   
      postData(
        `/api/issues/`,
        {
          objective,
          short_description,
          owner_id,
          assign_to_1_id,
          management_response_due_date,
          response_audit,
          response_audit_due_date,
          sub_observation,
          management_response,
          assign_to_2_id,
          observation_id: parseInt(selectedObservationId),



        },
        navigate,
        setErrors,
        `/issuetracking/issues`,
      );
    
  };



 
  const handleCloseClick = () => {
    setIsModalOpen(false);
  };

  const apiData = {
    data: {
      subObservations: ["zvvxc", "321132"],
    },
  };
  const [inputList, setInputList] = useState([]);

  useEffect(() => {
    if (sub_observation && sub_observation.length > 0) {
      // Populate the input list with the initial API data
      const initialInputs = sub_observation.map((observation) => ({
        value: observation,
        isEditing: false,
      }));
      setInputList(initialInputs);
    }
  }, [sub_observation]);

  const handleAdd = () => {
    setInputList([...inputList, { value: "", isEditing: true }]);
  };

  const handleSave = (index) => {
    const updatedList = [...inputList];
    updatedList[index].isEditing = false;
    setInputList(updatedList);

    // Update the subObservations array
    const updatedObservations = updatedList.map((input) => input.value);
    setSubObservations(updatedObservations);
  };

  const handleCancel = (index) => {
    const updatedList = inputList.filter((_, i) => i !== index);
    setInputList(updatedList);

    // Update the subObservations array
    const updatedObservations = updatedList.map((input) => input.value);
    setSubObservations(updatedObservations);
  };

  const handleRemove = (index) => {
    const updatedList = inputList.filter((_, i) => i !== index);
    setInputList(updatedList);

    const updatedObservations = sub_observation.filter((_, i) => i !== index);
    setSubObservations(updatedObservations);
  };

  const handleChange = (e, index) => {
    const updatedList = [...inputList];
    updatedList[index].value = e.target.value;
    setInputList(updatedList);
  };

  function convertDate(dateString) {
    if (dateString) {
      // Parse the input date string into a Date object
      const date = new Date(dateString);

      // Subtract 15 days from the date
      date.setDate(date.getDate() - 15);

      // Format the date as 'YYYY-MM-DD'
      const formattedDate = date.toISOString().split("T")[0];

      return formattedDate;
    }
  }

  const handleSaveObv = (e) => {
    e.preventDefault();
    console.log("In progress");
    updateData(
      `/api/system/task/${APIQData}`,
      {
        status: "in progress",
        title: taskTitle,
      },
      navigate,
      setErrors,
      window.location.reload()
    );
  };

  const handleDropdownClick = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (id) => {
    handleSelectionChange(id);
    setIsOpen(false); // Close the dropdown after selection
  };

  const selectedObservation = APIData.find(
    (obs) => obs.id === selectedObservationId
  );

  return (
    <>
      {errors && <p className="errormessage">{errors}</p>}
      {successMessage && <p className="successmessage">{successMessage}</p>}

      <section className="navroutes">
        <div className="filter-container">
        <div className="backToReport">
        <RenderIcons homeRoutes={issueTracking} />
        <div className="backbtnObv" onClick={() => navigate(-1)}>
            <img src={backBtn} />
            <p>Back to Issues</p>
          </div>
        </div>
        </div>


        <Breadcrumb />

        <div className="filter">
            <div className="views">
              <h3 className="h2_interbold" >Create Issue</h3>
            </div>

            <div className="btn-action-div">
              <div
                className="delete_edit_close_btn"
                style={{ display: "flex", flexDirection: "row" }}
              >
                <IconButton
                  type="close"
                  className="closeView"
                  tooltip="Close"
                  link="/issuetracking/issues"
                />

                <IconButton
                  type="save"
                  tooltip="Save"
                  onClick={CreateIssue}
                />
              </div>
            </div>
          </div>
        <div
          className="issues_container"
          style={{ width: tableSize ? "79.5vw" : "90vw" }}
        >
          

              <div className="audit-selector-issue">
                    <SingleOptionSelector
                      apiUrl="/api/audit_engagement/audit/"
                      setSelectorId={setSelectAuditId}
                      setSelectorError={setSelectAuditIdError}
                      Name="name"
                      title="Select Audit"
                      editMode={editMode}
                    />
                    </div>

                <div>
                  {/* <h3>Select Observation</h3> */}
                  <div
                    className="dropdown-container"
                    onChange={handleSelectionChange}
                    value={selectedObservationId}
                  >
                    <div
                      className="dropdown-selected"
                      onClick={handleDropdownClick}
                    >
                      {selectedObservation
                        ? `(${selectedObservation.id}): ${selectedObservation.summary}`
                        : "Select an observation"}
                      <span className="dropdown-arrow">
                        {isOpen ? "▲" : "▼"}
                      </span>
                    </div>



                    {isOpen && (
                      <div className="dropdown-options">
                        {APIData.map((observation) => (
                          <div
                            key={observation.id}
                            className="dropdown-option"
                            onClick={() => handleOptionClick(observation.id)} 
                          >
                            ({observation.id}): {observation.summary}
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
                <h5>
                 
                </h5>
              

              <form action="" className="create-issue-form">
                <div className="assign_reviewer-issue">
                
                  
                    {/* <h3>Owner</h3> */}

                    <AuditReportingSeclector
                      apiUrl="/api/audit_universe/admin-audit-team/"
                      setSelectorId={setOwner}
                      setSelectorError={setLevel1OwnerError}
                      selectorError={level_1_ownerError}
                      Name="name"
                      title="Owner"
                      editMode={editMode}
                      initialId={
                        observationDetails?.preparer ? observationDetails?.preparer?.id : ""
                      }
                      initialName={
                        observationDetails?.preparer ? observationDetails?.preparer?.name : ""
                      }
                    />
                  
                  
                  
                   
                    {/* <h3>Assign To</h3> */}

                    <AuditReportingSeclector
                      apiUrl="/api/audit_universe/business-stakeholders/"
                      setSelectorId={setAssign_to_id}
                      setSelectorError={setAssign_to_idError}
                      selectorError={assign_to_idError}
                      Name="name"
                      title="Assign To"
                      editMode={editMode}
                      initialId={
                        observationDetails?.assign_to
                          ? observationDetails?.assign_to.id
                          : ""
                      }
                      initialName={
                        observationDetails?.assign_to
                          ? observationDetails?.assign_to.name
                          : ""
                      }
                    />
                  
                </div>

                  <div className="issues_detail_section">
                    <h4>Overview</h4>
                    <div className="lng_lbl_inpt">
                      <label>Title</label>
                      <input
                        style={{
                          backgroundColor: editMode ? "#EEF1FC" : "#e6e6e6",
                        }}
                        type="text"
                        placeholder="Title"
                        value={objective}
                        disabled={!editMode}
                        onChange={(e) => setTitle(e.target.value)}
                      />
                    </div>{" "}
                    {/* <div className="description_recommendation">
                    </div> */}
                    {/* <div className="other_detail_bottom_div"></div> */}
                  </div>
                

                
                <div className="IA_response_section">
                  <h5>Issue Detail</h5>

                  <div className="lbl_txa">
                    <label>Issue description</label>
                    <textarea
                      type="text"
                      placeholder="Issue description"
                      value={issue_description}
                      disabled={!editMode}
                      onChange={(e) => setIssueDescription(e.target.value)}
                      style={{
                        backgroundColor: editMode ? "#EEF1FC" : "#e6e6e6",
                      }}
                    />
                  </div>

                  <div className="lbl_inpt">
                    <label>Issue Rating</label>
                    <input
                      type="text"
                      value={rating}
                      disabled={!editMode}
                      onChange={(e) => setObservationRating(e.target.value)}
                      style={{
                        width:'22vw',
                        backgroundColor: "#EEF1FC", borderRadius: "5px", backgroundColor: editMode ? "#EEF1FC" : "#e6e6e6",
                      }}
                    />
                  </div>
                </div>

                <div className="management_section">
                  <h5>Management Response</h5>

<form>
                  <div className="lbl_txa">
                    <label>Action Plan</label>
                    <textarea
                      style={{
                        backgroundColor: editMode ? "#EEF1FC" : "#e6e6e6",
                      }}
                      type="text"
                      placeholder="Action Plan"
                      value={management_response}
                      disabled={!editMode}
                      onChange={(e) => setActionPlan(e.target.value)}
                    />
                  </div>

                  <div
                    className="lbl_inpt"
                  >
                    <label>Due Date</label>
                    <input
                      type="date"

                      value={convertDate(management_response_due_date)}
                      disabled={!editMode}
                      onChange={(e) => setActionDueDate(e.target.value)}
                      style={{
                        backgroundColor: "#EEF1FC", borderRadius: "5px", backgroundColor: editMode ? "#EEF1FC" : "#e6e6e6",
                      }}
                    />
                  </div>


                
                     
                      {/* <h3>Level 2 Owner</h3> */}

                      <AuditReportingSeclector
                        apiUrl="/api/audit_universe/business-stakeholders/"
                        setSelectorId={setLevel2Owner}
                        setSelectorError={setLevel2OwnerError}
                        selectorError={level_2_ownerError}
                        Name="name"
                        title="Level 2 Owner"
                        editMode={editMode}
                        initialId={
                          observationDetails?.secondary_assign_to
                            ? observationDetails?.secondary_assign_to?.id
                            : ""
                        }
                        initialName={
                          observationDetails?.secondary_assign_to
                            ? observationDetails?.secondary_assign_to?.name
                            : ""
                        }
                      />
                    </form>
                  
                </div>

                <div className="IA_response_section">
                  <h5>Internal Audit Response</h5>

                  <div className="lbl_txa">
                    <label>Audit Response</label>
                    <textarea
                      type="text"
                      placeholder="Internal Audit Response"
                      value={response_audit}
                      disabled={!editMode}
                      onChange={(e) => setAuditResponse(e.target.value)}
                      style={{
                        backgroundColor: editMode ? "#EEF1FC" : "#e6e6e6",
                      }}
                    />
                  </div>

                  <div className="lbl_inpt">
                    <label>Due Date</label>
                    <input
                      type="date"
                      value={convertDate(response_audit_due_date)}
                      disabled={!editMode}
                      onChange={(e) => setResponseActionDueDate(e.target.value)}
                      style={{
                        backgroundColor: "#EEF1FC", borderRadius: "5px", backgroundColor: editMode ? "#EEF1FC" : "#e6e6e6",
                      }}
                    />
                  </div>
                </div>

                <div className="description_file_upload">
                  <div className="descon_sub_obs1">
                    <div className="lbl_txa">
                      
                        <label>Description</label>
                      

                      <textarea
                        type="text"
                        placeholder="Description"
                        value={short_description}
                        disabled={!editMode}
                        onChange={(e) => setShortDescription(e.target.value)}
                        style={{
                          backgroundColor: editMode ? "#EEF1FC" : "#e6e6e6",
                        }}
                      />
                    </div>

                    <div className="subSummaryDiv">
                      <div className="subSummaryDiv_head">
                        <h5>Sub Observation</h5>

                        <div className="addObv">
                          <button
                            type="button"
                            className="addSubObv"
                            onClick={handleAdd}
                            disabled={!editMode}
                          >
                            <img src={blue_add_button} />
                          </button>
                        </div>
                      </div>

                      {inputList.map((input, index) => (
                        <div key={index}>
                          {input.isEditing ? (
                            <>
                              <div
                                className="addsubObvDiv"
                                style={{ gap: "1vw" }}
                              >
                                <textarea
                                  type="text"
                                  value={input.value}
                                  onChange={(e) => handleChange(e, index)}
                                  style={{
                                    backgroundColor: editMode ? "#EEF1FC" : "#e6e6e6",
                                  }}
                                // style={{ width: tableSize ? "30vw" : "45vw" }}
                                />
                                <div className="addsubBtnDiv">
                                  <button
                                    type="button"
                                    className="saveSubObv"
                                    onClick={() => handleSave(index)}
                                    style={{
                                      border: "none",
                                      background: "transparent",
                                    }}
                                  >
                                    <img src={savesubObv} />
                                  </button>
                                  <button
                                    type="button"
                                    className="cancelSubObv"
                                    onClick={() => handleCancel(index)}
                                  >
                                    <img src={remove_button} />
                                  </button>
                                </div>
                              </div>
                            </>
                          ) : (
                            <div className="remove_sunobspara">
                              <p>{input.value}</p>

                              <button
                                type="button"
                                disabled={!editMode}
                                onClick={() => handleRemove(index)}
                              >
                                <img src={remove_button} />
                              </button>
                            </div>
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>

                <div className="observation_spacing">
                  <p>Spacing_Content</p>
                </div>
              </form>
            
         
        </div>

        <div className="cardscontainer"></div>
      </section>
    </>
  );
};

export default Issuedetailpage;
