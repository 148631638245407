import React, { useState, useEffect } from "react";
import { NavLink, useLocation, Link, useNavigate } from "react-router-dom";
import SidebarMenu from "../SidebarMenu";
import Breadcrumb from "../Breadcrumb";
import create from "../../images/createbutton.svg";
import openarrow from "../../images/openarrow.svg";
import { fetchData1 } from "../apiUtils";
import { homeRoutes } from "../subroutes";
import "../../css/businessunit.css";
import BounceLoader from "react-spinners/BounceLoader";
import RenderIcons from "../RenderIcons";
const Businessunit = ({ tableSize }) => {
  const [APIData, setAPIData] = useState([]);
  const [errors, setErrors] = useState("");

  const location = useLocation();
  const navigate = useNavigate();
  const successMessageColor = location.state?.successMessageColor;
  const successMessage = location.state?.successMessage;
  const [showMessage, setShowMessage] = useState(!!successMessage);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      navigate(".", { replace: true });
    }, 5000);

    return () => clearTimeout(timeoutId);
  }, []);

  useEffect(() => {
    fetchData1(`/api/system/business-units/`, setAPIData, setErrors,setLoading);
  }, []);

  const renderBusinessUnitData = (data) => {
    return filteredData
      .sort((a, b) => a.name.localeCompare(b.name))
      .map((data) => (
        <tr className="tabledata" onClick={() => Viewuser(data)} key={data.id}>
          <td>{data.name}</td>
          <td>{data.unit_head ? data.unit_head.name : ""}</td>
          <td>{data.department ? data.department.name : ""}</td>
        </tr>
      ));
  };

  const Viewuser = (data) => {
    const id = data.id;
    navigate(`/Administration/businessUnit/${id}/`);
  };

  const [filters, setFilters] = useState({
    name: "",
    department: "",
    unit_head: "",
  });

  const handleFilterChange = (filterKey, e) => {
    const value = e.target.value.toLowerCase();
    setFilters({ ...filters, [filterKey]: value });
  };

  const filteredData = APIData.filter((data) => {
    const fullName = data.name ? data.name.toLowerCase() : "";
    const firstNameMatch = fullName.includes(filters.name);

    const departmentName = data.department
      ? data.department.name.toLowerCase()
      : "--";
    const departmentMatch = departmentName.includes(filters.department);

    const unitHeadName = data.unit_head
      ? data.unit_head.name.toLowerCase()
      : "";
    const unitHeadMatch = unitHeadName.includes(filters.unit_head);

    return firstNameMatch && unitHeadMatch && departmentMatch;
  });

  return (
    <>
      {errors && <p className="errormessage">{errors}</p>}
      {successMessage && <p className="successmessage">{successMessage}</p>}
      <section className="navroutes">
      <RenderIcons homeRoutes={homeRoutes} />

        <div className="filter-container">
          <div className="filter">
            <div className="views">
              <h3 className="h2_interbold" >Business Units</h3>
              
            </div>
            <div className="searchncbtn">
              <input
                type="text"
                placeholder="Search"
                className="searchbar"
                onChange={(e) => handleFilterChange("name", e)}
                value={filters.name}
              />
              <div className="createuser">
                <Link to="/Administration/businessUnit/createBusinessUnit">
                  <button className="createbutton">
                    <img src={create} alt="" />
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="tablecontainer">
        <table style={{ width: tableSize ? "80vw" : "90.5vw" }}>
            <thead style={{ position: "sticky", top: "0", zIndex: 1000 }}>
              <tr className="tablehead">
                <th>
                  <h4 className="thhead">Name</h4>
                  <input
                    type="text"
                    placeholder="--"
                    className="thfilter"
                    onChange={(e) => handleFilterChange("name", e)}
                    value={filters.name}
                  />
                </th>
                <th>
                  <h4 className="thhead">Head</h4>
                  <input
                    type="text"
                    placeholder="--"
                    className="thfilter"
                    onChange={(e) => handleFilterChange("unit_head", e)}
                    value={filters.unit_head}
                  />
                </th>
                <th>
                  <h4 className="thhead">Department Name</h4>
                  <input
                    type="text"
                    placeholder="--"
                    className="thfilter"
                    onChange={(e) => handleFilterChange("department", e)}
                    value={filters.department}
                  />
                </th>
              </tr>
            </thead>
            <tbody>
        {loading ? (
          <div className="spinner-bounce-loader">
          <BounceLoader color="#a6fafa"/> 
          </div>
            
          ): filteredData.length === 0 ? (
            <p className="data-error-text">No data found</p> 
          ) : (
          
            filteredData
      .sort((a, b) => a.name.localeCompare(b.name))
      .map((data) => (
        <tr className="tabledata" onClick={() => Viewuser(data)} key={data.id}>
          <td>{data.name}</td>
          <td>{data.unit_head ? data.unit_head.name : ""}</td>
          <td>{data.department ? data.department.name : ""}</td>
        </tr>
      ))
    
            
          )}
          </tbody>
          </table>
        </div>

        <div className="cardscontainer">
          {filteredData
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((data) => (
              <div
                className="bucard"
                onClick={() => Viewuser(data)}
                key={data.id}
              >
                <div className="bunstkhlder">
                  <h4>
                    { data.name ? (data.name) : (<p className="buplacehldr">BU Name</p>) }
                  </h4>
                  <h5>
                    {data.unit_head ? (
                      data.unit_head.name
                    ) : (
                      <p className="buplacehldr">Keystakeholder</p>
                    )}
                  </h5>
                </div>
                <div className="deptnauthnbtn">
                  <h5>
                    {data.department ? (
                      data.department.name
                    ) : (
                      <p className="buplacehldr">Department</p>
                    )}
                  </h5>
                  <h6>

                  <p className="buplacehldr">Bu ID - </p>
                    {data.id ? data.id :  <p className="buplacehldr">Bu ID </p>}
                  </h6>
                </div>
                <img src={openarrow} alt="" />
              </div>
            ))}
        </div>
      </section>
    </>
  );
};

export default Businessunit;
