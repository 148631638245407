import React, { useState, useEffect } from "react";
import {
  NavLink,
  useLocation,
  Link,
  useNavigate,
  useParams,
} from "react-router-dom";
import axios from "axios";
import Breadcrumb from "../../Breadcrumb";
import SidebarMenu from "../../SidebarMenu";
import "../../../css/viewuser.css";
import { fetchData, updateData } from "../../apiUtils";
import { auditExecution } from "../../subroutes";
import create from "../../../images/createbutton.svg";
import openarrow from "../../../images/openarrow.svg";
import "../auditplan/auditplan.css";
import ProgressBar from "../progressbar/ProgressBar";
import "./auditreporting.css";

import pencil from "../../../images/editpencil_wbg.svg";
import deleteicon from "../../../images/delete_wbg.svg";
import createBtn from "../../../images/createbutton.svg";
import RenderIcons from "../../RenderIcons";
const AuditReporting = ({ tableSize }) => {
  const [errors, setErrors] = useState("");
  const [APIData, setAPIData] = useState([]);
  const [auditData, setAuditData] = useState([]);
  const [areAllTasksDone, setAreAllTasksDone] = useState();

  const location = useLocation();
  const navigate = useNavigate();
  const successMessageColor = location.state?.successMessageColor;
  const successMessage = location.state?.successMessage;
  const [showMessage, setShowMessage] = useState(!!successMessage);
  const originalUrl = location.state?.from;
  const { id, ObservationUpdateId, rid } = useParams();
  const [selectedTaskId, setSelectedTaskId] = useState(null);
  const [task, setTaskDetails] = useState();
  const [showPopup, setShowPopup] = useState(false);
  const [percentage, setPercentage] = useState(0);

  const [observationUrl, setObservationUrl] = useState("");

  useEffect(() => {
    // Get the current URL dynamically
    const url = window.location.href;

    // Extract the last part of the URL
    const parts = url.split("/");
    const observationValue = parts[parts.length - 1];

    // Set the state
    setObservationUrl(observationValue);
    console.log(observationUrl);
  }, []); // Empty dependency array ensures this runs only once when the component mounts

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      navigate(".", { replace: true });
    }, 5000);

    return () => clearTimeout(timeoutId);
  }, []);

  useEffect(() => {
    fetchData(
      `/api/audit_engagement/observation/?audit_id=${id}`,
      setAPIData,
      setErrors
    );
  }, []);

  useEffect(() => {
    fetchData(`/api/audit_engagement/audit/${id}/`, setAuditData, setErrors);
  }, [id]);

  useEffect(() => {
    setAreAllTasksDone(APIData.every((task) => task?.task?.status === "done"));
  }, [APIData]);

  const [filters, setFilters] = useState({
    id: "",
    status: "",
    workpaper_id: "",
    summary: "",
    preparer: "",
    reviewer_name: "",
    created_at: "",
  });

  const handleFilterChange = (filterKey, e) => {
    setFilters({ ...filters, [filterKey]: e.target.value });
  };

  const filteredData = APIData.filter((data) => {
    const {
      id,
      status,
      workpaper_id,
      summary,
      preparer,
      reviewer,
      created_at,
      task,
      workpapertestcase,
    } = data;

    const matchesId = filters.id
      ? (id || "").toString().includes(filters.id)
      : true;

    const matchesStatus = filters.status
      ? task && task.status.toLowerCase().includes(filters.status.toLowerCase())
      : true;

    const matchesWorkpaperId = filters.workpaper_id
      ? workpapertestcase.some((wpTestCase) =>
          wpTestCase.workpaper.id
            .toString()
            .includes(filters.workpaper_id.toString())
        )
      : true;

    const matchesSummary = filters.summary
      ? (summary || "").toLowerCase().includes(filters.summary.toLowerCase())
      : true;

    const matchesPreparer = filters.preparer
      ? preparer &&
        preparer.name.toLowerCase().includes(filters.preparer.toLowerCase())
      : true;

    const matchesReviewer = filters.reviewer
      ? reviewer &&
        reviewer.name.toLowerCase().includes(filters.reviewer.toLowerCase())
      : true;

    const matchesCreatedAt = filters.created_at
      ? (created_at || "").toString().includes(filters.created_at)
      : true;

    return (
      matchesId &&
      matchesStatus &&
      matchesSummary &&
      matchesWorkpaperId &&
      matchesPreparer &&
      matchesReviewer &&
      matchesCreatedAt
    );
  });

  const updateWithPhase = (e) => {
    e.preventDefault();
    updateData(
      `/api/audit_engagement/audit/${id}`,
      {
        risk_assessment: auditData?.risk_assessment?.id,
        audit_entity_id: auditData?.audit_entity?.id,
        auditors_id: auditData?.auditors.map((auditor) => auditor.id),
        business_users_id: auditData?.business_users.map(
          (business_users) => business_users.id
        ),
        actual_start_date: auditData?.actual_start_date,
        actual_end_date: auditData?.actual_end_date,
        planned_start_date: auditData?.audit_event?.planned_start_date,
        planned_end_date: auditData?.audit_event?.planned_end_date,
        audit_scope: auditData?.audit_scope,
        audit_phases: auditData?.audit_phases,
        other_relevant_information: auditData?.other_relevant_information,
        phase: "Reporting Complete",
      },
      navigate,
      setErrors,
      `/AuditExecution/${id}/Reporting/`,
      window.location.reload()
    );
  };

  const handlePopupClose = (task) => {
    console.log(task);

    updateData(
      `/api/system/task/${task?.task?.id}`,
      {
        title: task.objective,
        status: "done",
      },
      navigate,
      setErrors,
      `/AuditExecution/${id}/Reporting`
    );
    setAPIData((prevAPIData) =>
      prevAPIData.map((task) => {
        if (task.id === selectedTaskId) {
          return {
            ...task,
            task: {
              ...task.task,
              status: "Done",
            },
          };
        }
        return task;
      })
    );
    window.location.reload();

    setShowPopup(false);
  };

  const handlePopupCloseNo = () => {
    setShowPopup(false);
  };

  const viewObservation = (obejctionId, navigateTo) => {
    switch (navigateTo) {
      case "InitialObservation":
        navigate(
          `/AuditExecution/${id}/Reporting/InitialObservation/${obejctionId}`
        );
        break;

      case "QAApprovalInitialObservation":
        navigate(
          `/AuditExecution/${id}/Reporting/QAApprovalInitialObservation/${obejctionId}`
        );
        break;
      case "BusinessOwnerApproval":
        navigate(
          `/AuditExecution/${id}/Reporting/BusinessOwnerApproval/${obejctionId}`
        );
        break;
      case "FinalizeObservation":
        navigate(
          `/AuditExecution/${id}/Reporting/FinalizeObservation/${obejctionId}`
        );
        break;
      case "qaAPprovalFinalizeObservation":
        navigate(
          `/AuditExecution/${id}/Reporting/qaAPprovalFinalizeObservation/${obejctionId}`
        );
        break;
      case "AuditDirectorApproval":
        navigate(
          `/AuditExecution/${id}/Reporting/AuditDirectorApproval/${obejctionId}`
        );
        break;
      case "GenerateReportList":
        navigate(
          `/AuditExecution/${id}/Reporting/GenerateReportList/${obejctionId}`
        );
        break;
      default:
        console.log("");
    }
  };

  const renderAuditObservationStatus = (status) => {
    console.log("i am here ");
  };

  const userRoles = localStorage.getItem("userRoles");

  const Roles = userRoles.includes("Admin") && userRoles.includes("Admin");

  const navToReportPage = (navTo) => {
    switch (navTo) {
      case "Initial Observation":
        navigate(`/AuditExecution/${id}/Reporting/InitialObservation`);
        break;

      case "QA Approval For Initial Observation":
        navigate(
          `/AuditExecution/${id}/Reporting/QAApprovalInitialObservation`
        );
        break;

      case "Schedule Closing Meeting":
        navigate(`/AuditExecution/${id}/Reporting/ScheduleClosingMeeting`);
        break;

      case "Business Owner Approval":
        navigate(`/AuditExecution/${id}/Reporting/BusinessOwnerApproval`);
        break;

      case "Finalize Observation":
        navigate(`/AuditExecution/${id}/Reporting/FinalizeObservation`);
        break;

      case "Generate Report":
        navigate(`/AuditExecution/${id}/Reporting/GenerateReport`);
        break;

      case "QA Approval For Finalize  Observation":
        navigate(
          `/AuditExecution/${id}/Reporting/qaAPprovalFinalizeObservation`
        );
        break;

      case "Audit Director Approval For Finalize Observation":
        navigate(`/AuditExecution/${id}/Reporting/AuditDirectorApproval`);
        break;

      case "Publish Report":
        navigate(`/AuditExecution/${id}/Reporting/PublishReport`);
        break;

      default:
        return "Invalid Task";
    }
  };

  const moreDetails = () => {
    console.log("i ahahahaha");
    navigate(`/AuditExecution/${id}/Reporting/GenerateReportMoreDetails`);
  };

  return (
    <>
      {errors && <p className="errormessage">{errors}</p>}

      {successMessage && <p className="successmessage">{successMessage}</p>}
      {showPopup && (
        <div className="popupATP">
          <div className="subpopupATP">
            <p>Do you want the task to be done?</p>
            <div className="subpopupBtnDiv">
              <button onClick={() => handlePopupClose(task)}>Yes</button>
              <button onClick={handlePopupCloseNo}>No</button>
            </div>
          </div>
        </div>
      )}

      <section className="navroutes">
        <RenderIcons homeRoutes={auditExecution} id={id} />

        <div className="filter-container">
          <div className="filter">
            <Breadcrumb />

            <div className="searchncbtn">
              <div className="indication-section">
                {observationUrl === "InitialObservation" && (
                  <div className="prog-bar-div">
                    <ProgressBar
                      percentage={
                        auditData.report_status?.CREATE_INITIAL_OBSERVATION
                      }
                    />
                  </div>
                )}

                {observationUrl === "QAApprovalInitialObservation" && (
                  <div className="prog-bar-div">
                    <ProgressBar
                      percentage={
                        auditData.report_status?.APPROVE_INITIAL_OBSERVATION
                      }
                    />
                  </div>
                )}

                {observationUrl === "BusinessOwnerApproval" && (
                  <div className="prog-bar-div">
                    <ProgressBar
                      percentage={auditData.report_status?.BUSINESS_APPROVAL}
                    />
                  </div>
                )}

                {observationUrl === "FinalizeObservation" && (
                  <div className="prog-bar-div">
                    <ProgressBar
                      percentage={
                        auditData.report_status?.FINALIZED_OBSERVATION
                      }
                    />
                  </div>
                )}

                {observationUrl === "qaAPprovalFinalizeObservation" && (
                  <div className="prog-bar-div">
                    <ProgressBar
                      percentage={
                        auditData.report_status?.APPROVE_QA_FINAL_OBSERVATION
                      }
                    />
                  </div>
                )}

                {observationUrl === "AuditDirectorApproval" && (
                  <div className="prog-bar-div">
                    <ProgressBar
                      percentage={
                        auditData.report_status?.APPROVE_ADMIN_FINAL_OBSERVATION
                      }
                    />
                  </div>
                )}
              </div>
              <input type="text" placeholder="Search" className="searchbar" />
              
              <span className="auditstatusphase">
                <p>{auditData.phase}</p>
              </span>
              {observationUrl === "InitialObservation" && (
                <div className="top-con-obv">
                  <Link
                    to={`/AuditExecution/${id}/Reporting/CreateObservation`}
                  >
                    <img src={createBtn} className="createobv" />
                  </Link>
                </div>
              )}
            </div>
          </div>
        </div>

        <div
          className="reportingTaskTableDiv"
          style={{ width: tableSize ? "79.5vw" : "90vw" }}
        >
          <table>
            <thead style={{ position: "sticky", top: "0", zIndex: 1000 }}>
              <tr className="tablehead">
                <th>
                  <h4 className="thhead">Status</h4>
                  <input
                    type="text"
                    placeholder="--"
                    className="thfilter"
                    onChange={(e) => handleFilterChange("status", e)}
                    value={filters.status}
                  />
                </th>

                <th>
                  <h4 className="thhead"> UID </h4>
                  <input
                    type="text"
                    placeholder="--"
                    className="thfilter"
                    onChange={(e) => handleFilterChange("id", e)}
                    value={filters.id}
                  />
                </th>
                <th>
                  <h4 className="thhead"> WorkPaperId </h4>
                  <input
                    type="text"
                    placeholder="--"
                    className="thfilter"
                    onChange={(e) => handleFilterChange("workpaper_id", e)}
                    value={filters.workpaper_id}
                  />
                </th>

                <th>
                  <h4 className="thhead"> Title</h4>
                  <input
                    type="text"
                    placeholder="--"
                    className="thfilter"
                    onChange={(e) => handleFilterChange("summary", e)}
                    value={filters.summary}
                  />
                </th>

                <th>
                  <h4 className="thhead">Preparer</h4>
                  <input
                    type="text"
                    placeholder="--"
                    className="thfilter"
                    onChange={(e) => handleFilterChange("preparer", e)}
                    value={filters.preparer}
                  />
                </th>

                <th>
                  <h4 className="thhead">Reviewer</h4>
                  <input
                    type="text"
                    placeholder="--"
                    className="thfilter"
                    onChange={(e) => handleFilterChange("reviewer", e)}
                    value={filters.reviewer}
                  />
                </th>

                <th>
                  <h4 className="thhead">Certified On</h4>
                  <input
                    type="text"
                    placeholder="--"
                    className="thfilter"
                    onChange={(e) => handleFilterChange("created_at", e)}
                    value={filters.created_at}
                  />
                </th>
              </tr>
            </thead>

            <tbody>
              {observationUrl === "InitialObservation" &&
                filteredData
                  .filter(
                    (filterData) =>
                      filterData.status === "Initial Draft" ||
                      filterData.status === "Closed" ||
                      filterData.status === "Initial Draft Admin Approve"
                  )
                  .map((data) => {
                    const uniqueWorkpaperIds = data.workpapertestcase
                      ? [
                          ...new Set(
                            data.workpapertestcase.map(
                              (testcase) => testcase.workpaper?.id
                            )
                          ),
                        ]
                      : [];

                    return (
                      <tr
                        key={data.id}
                        onClick={() =>
                          viewObservation(data.id, "InitialObservation")
                        }
                      >
                        <td style={{ textAlign: "center" }}>
                          {data.status ? data.status : ""}
                        </td>
                        <td>
                          <span>{data.id}</span>
                        </td>

                        <td>
                          {uniqueWorkpaperIds.length > 0
                            ? uniqueWorkpaperIds.map((id) => (
                                <span key={id}>{id} </span>
                              ))
                            : ""}
                        </td>
                        <td>
                          <span className="truncate-wrap">
                            {data.summary ? data.summary : ""}
                          </span>
                        </td>
                        <td>{data.preparer ? data.preparer.name : ""}</td>
                        <td>{data.reviewer ? data.reviewer.name : ""}</td>
                        <td>
                          {data.created_at
                            ? new Date(data.created_at).toLocaleDateString()
                            : ""}
                        </td>
                      </tr>
                    );
                  })}

              {observationUrl === "QAApprovalInitialObservation" &&
                filteredData
                  .filter(
                    (filterData) =>
                      filterData.status === "Initial Draft Admin Approve" ||
                      filterData.status === "Initial Draft Qa Approve"
                  )
                  .map((data) => {
                    const uniqueWorkpaperIds = data.workpapertestcase
                      ? [
                          ...new Set(
                            data.workpapertestcase.map(
                              (testcase) => testcase.workpaper.id
                            )
                          ),
                        ]
                      : [];

                    return (
                      <tr
                        key={data.id}
                        onClick={() =>
                          viewObservation(
                            data.id,
                            "QAApprovalInitialObservation"
                          )
                        }
                      >
                        <td style={{ textAlign: "center" }}>
                          {data.status ? data.status : ""}
                        </td>
                        <td>
                          <span>{data.id}</span>
                        </td>

                        <td>
                          {uniqueWorkpaperIds.length > 0
                            ? uniqueWorkpaperIds.map((id) => (
                                <span key={id}>{id} </span>
                              ))
                            : ""}
                        </td>
                        <td>
                          <span className="truncate-wrap">
                            {data.summary ? data.summary : ""}
                          </span>
                        </td>
                        <td>{data.preparer ? data.preparer.name : ""}</td>
                        <td>{data.reviewer ? data.reviewer.name : ""}</td>
                        <td>
                          {data.created_at
                            ? new Date(data.created_at).toLocaleDateString()
                            : ""}
                        </td>
                      </tr>
                    );
                  })}

              {observationUrl === "BusinessOwnerApproval" &&
                filteredData
                  .filter(
                    (filterData) =>
                      filterData.status === "Initial Draft Qa Approve" ||
                      filterData.status === "Approved Draft"
                  )
                  .map((data) => {
                    const uniqueWorkpaperIds = data.workpapertestcase
                      ? [
                          ...new Set(
                            data.workpapertestcase.map(
                              (testcase) => testcase.workpaper.id
                            )
                          ),
                        ]
                      : [];

                    return (
                      <tr
                        key={data.id}
                        onClick={() =>
                          viewObservation(data.id, "BusinessOwnerApproval")
                        }
                      >
                        <td style={{ textAlign: "center" }}>
                          {renderAuditObservationStatus(
                            data.status ? data.status : ""
                          )}
                        </td>
                        <td>
                          <span>{data.id}</span>
                        </td>

                        <td>
                          {uniqueWorkpaperIds.length > 0
                            ? uniqueWorkpaperIds.map((id) => (
                                <span key={id}>{id} </span>
                              ))
                            : ""}
                        </td>
                        <td>
                          <span className="truncate-wrap">
                            {data.summary ? data.summary : ""}
                          </span>
                        </td>
                        <td>{data.preparer ? data.preparer.name : ""}</td>
                        <td>{data.reviewer ? data.reviewer.name : ""}</td>
                        <td>
                          {data.created_at
                            ? new Date(data.created_at).toLocaleDateString()
                            : ""}
                        </td>
                      </tr>
                    );
                  })}

              {observationUrl === "FinalizeObservation" &&
                filteredData
                  .filter(
                    (filterData) =>
                      filterData.status === "Approved Draft" ||
                      filterData.status === "Finalized Draft"
                  )
                  .map((data) => {
                    const uniqueWorkpaperIds = data.workpapertestcase
                      ? [
                          ...new Set(
                            data.workpapertestcase.map(
                              (testcase) => testcase.workpaper.id
                            )
                          ),
                        ]
                      : [];

                    return (
                      <tr
                        key={data.id}
                        onClick={() =>
                          viewObservation(data.id, "FinalizeObservation")
                        }
                      >
                        <td style={{ textAlign: "center" }}>
                          {renderAuditObservationStatus(
                            data.status ? data.status : ""
                          )}
                        </td>
                        <td>
                          <span>{data.id}</span>
                        </td>

                        <td>
                          {uniqueWorkpaperIds.length > 0
                            ? uniqueWorkpaperIds.map((id) => (
                                <span key={id}>{id} </span>
                              ))
                            : ""}
                        </td>
                        <td>
                          <span className="truncate-wrap">
                            {data.summary ? data.summary : ""}
                          </span>
                        </td>
                        <td>{data.preparer ? data.preparer.name : ""}</td>
                        <td>{data.reviewer ? data.reviewer.name : ""}</td>
                        <td>
                          {data.created_at
                            ? new Date(data.created_at).toLocaleDateString()
                            : ""}
                        </td>
                      </tr>
                    );
                  })}

              {observationUrl === "qaAPprovalFinalizeObservation" &&
                filteredData
                  .filter(
                    (filterData) =>
                      filterData.status === "Finalized Draft" ||
                      filterData.status === "Finalized Draft Admin Approve"
                  )
                  .map((data) => {
                    const uniqueWorkpaperIds = data.workpapertestcase
                      ? [
                          ...new Set(
                            data.workpapertestcase.map(
                              (testcase) => testcase.workpaper.id
                            )
                          ),
                        ]
                      : [];

                    return (
                      <tr
                        key={data.id}
                        onClick={() =>
                          viewObservation(
                            data.id,
                            "qaAPprovalFinalizeObservation"
                          )
                        }
                      >
                        <td style={{ textAlign: "center" }}>
                          {renderAuditObservationStatus(
                            data.status ? data.status : ""
                          )}
                        </td>
                        <td>
                          <span>{data.id}</span>
                        </td>

                        <td>
                          {uniqueWorkpaperIds.length > 0
                            ? uniqueWorkpaperIds.map((id) => (
                                <span key={id}>{id} </span>
                              ))
                            : ""}
                        </td>
                        <td>
                          <span className="truncate-wrap">
                            {data.summary ? data.summary : ""}
                          </span>
                        </td>
                        <td>{data.preparer ? data.preparer.name : ""}</td>
                        <td>{data.reviewer ? data.reviewer.name : ""}</td>
                        <td>
                          {data.created_at
                            ? new Date(data.created_at).toLocaleDateString()
                            : ""}
                        </td>
                      </tr>
                    );
                  })}

              {observationUrl === "AuditDirectorApproval" &&
                filteredData
                  .filter(
                    (filterData) =>
                      filterData.status === "Finalized Draft Admin Approve"
                  )
                  .map((data) => {
                    const uniqueWorkpaperIds = data.workpapertestcase
                      ? [
                          ...new Set(
                            data.workpapertestcase.map(
                              (testcase) => testcase.workpaper.id
                            )
                          ),
                        ]
                      : [];

                    return (
                      <tr
                        key={data.id}
                        onClick={() =>
                          viewObservation(data.id, "AuditDirectorApproval")
                        }
                      >
                        <td style={{ textAlign: "center" }}>
                          {data.status ? data.status : ""}
                        </td>
                        <td>
                          <span>{data.id}</span>
                        </td>

                        <td>
                          {uniqueWorkpaperIds.length > 0
                            ? uniqueWorkpaperIds.map((id) => (
                                <span key={id}>{id} </span>
                              ))
                            : ""}
                        </td>
                        <td>
                          <span className="truncate-wrap">
                            {data.summary ? data.summary : ""}
                          </span>
                        </td>
                        <td>{data.preparer ? data.preparer.name : ""}</td>
                        <td>{data.reviewer ? data.reviewer.name : ""}</td>
                        <td>
                          {data.created_at
                            ? new Date(data.created_at).toLocaleDateString()
                            : ""}
                        </td>
                      </tr>
                    );
                  })}
            </tbody>
          </table>

          {auditData.phase === "Fieldwork Complete" && areAllTasksDone && (
            <div onClick={(e) => updateWithPhase(e)}>
              <button className="phasestatus">Start WrapupQA</button>
            </div>
          )}

          {observationUrl === "InitialObservation" &&
            filteredData
              .filter(
                (filterData) =>
                  filterData.status === "Initial Draft" ||
                  filterData.status === "Closed" ||
                  filterData.status === "Initial Draft Admin Approve"
              )
              .map((data) => {
                const uniqueWorkpaperIds = data.workpapertestcase
                  ? [
                      ...new Set(
                        data.workpapertestcase.map(
                          (testcase) => testcase.workpaper?.id
                        )
                      ),
                    ]
                  : [];

                return (
                  <div
                    className="bucard"
                    onClick={() =>
                      viewObservation(data.id, "InitialObservation")
                    }
                    key={data.id}
                  >
                    <div className="bunstkhlder">
                      <h4>
                        Place for variable
                        <p className="buplacehldr">
                          {data.objective ? data.objective : data.risk_title}
                        </p>
                      </h4>
                      <h5>
                        <p className="buplacehldr">
                          {data.status ? data.status : ""}
                        </p>
                      </h5>
                    </div>
                    <div className="deptnauthnbtn">
                      <h5>
                        <p className="buplacehldr">
                          {data.department
                            ? data.department.name
                            : "Department"}
                        </p>
                      </h5>
                      <h6>
                        <p className="buplacehldr">Bu ID - </p>
                        <p className="buplacehldr">
                          {data.id ? data.id : "Bu ID"}
                        </p>
                      </h6>
                    </div>
                    <img src={openarrow} alt="Open Arrow" />

                    {/* <div style={{ textAlign: "center" }}>
                      {data.status ? data.status : ""}
                    </div> */}

                    {/* <div>
                      {uniqueWorkpaperIds.length > 0
                        ? uniqueWorkpaperIds.map((id) => (
                          <span key={id}>{id} </span>
                        ))
                        : ""}
                    </div> */}
                  </div>
                );
              })}
        </div>

        {/* <div className="cardscontainer">
                    {filteredData.map((data) => (
                        <div className="aecard" >
                            <h1>{data.name} </h1>

                            <img src={openarrow} />
                        </div>
                    ))}
                </div> */}
      </section>
    </>
  );
};

export default AuditReporting;
