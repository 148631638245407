import React, { useState, useEffect, useRef, useCallback } from "react";
import { fetchData } from "./apiUtils";
import cut from "../images/cut.svg";

import arrowToggle from "../images/toggle_side_down.svg";
import "../css/department.css";
import { createGlobalStyle } from "styled-components";
import "./MultiWork.css";
import MultiOptionTest from "./MultiOptionTest";

const MultiOptionWorkPaper = ({
  rolesData,
  apiUrl,
  Name,
  title,
  initialIds,
  initialNames,
  classNameInput,
  editMode,
  setTestIds,
  testIds,
  setItem,
  item,
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [expanded, setExpanded] = useState(false);
  const [data, setData] = useState([]);
  const [data1, setData1] = useState([]);

  const [selected, setSelected] = useState([]);
  const [selectedIds, setSelectedIds] = useState([]);

  const inputRef = useRef(null);
  const dropdownRef = useRef(null);
  const [toggledIndices, setToggledIndices] = useState(() =>
    initialIds ? [...initialIds] : []
  );

  const [fetchedTests, setFetchedTests] = useState(new Set());
  const [dataTest, setDataTest] = useState([]);

  useEffect(() => {
    if (apiUrl) {
      fetchData(apiUrl, setData);
    } else if (rolesData) {
      setData(rolesData);
    }
  }, [apiUrl, rolesData]);

  useEffect(() => {
    if (initialIds && initialNames) {
      const initialSelections = initialIds.map((id, index) => ({
        id,
        Name: initialNames[index],
      }));
      setSelected(initialSelections);
      setSelectedIds(initialIds);
      setToggledIndices(initialIds);

      initialIds.forEach((unitId) => {
        if (!fetchedTests.has(unitId)) {
          fetchData(
            `/api/audit_engagement/workpapertestcase/?workpaper_id=${unitId}`,
            (rawResponse) => {
              console.log("Fetched data for unit:", unitId, rawResponse);
              setDataTest((prevDataTest) => [...prevDataTest, rawResponse]);
              setFetchedTests((prevFetchedTests) =>
                new Set(prevFetchedTests.add(unitId))
              );
            },
            (error) => {
              console.log("Error", error);
            }
          );
        }
      });
    }
  }, [initialIds, initialNames]);

  useEffect(() => {
    if (expanded) {
      inputRef.current.focus();
    }
  }, [expanded]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setExpanded(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);



  const handleCheckboxChangeDepts = useCallback(
    async (unitId, unitName, risk_description, status, control_desired) => {
      const isChecked = selectedIds.some((item) => item.id === unitId);

      const updatedSelectedIds = isChecked
        ? selectedIds.filter((item) => item.id !== unitId)
        : [
          ...selectedIds,
          {
            id: unitId,
            Name: unitName,
            risk_description: risk_description,
            status: status,
            control_desired: control_desired,
            tests: [],
          },
        ];

      const updatedSelected = isChecked
        ? selected.filter((dept) => dept.id !== unitId)
        : [
          ...selected,
          {
            id: unitId,
            Name: unitName,
            risk_description: risk_description,
            status: status,
            control_desired: control_desired,
          },
        ];

      // Log the data after updating the arrays
      console.log("Updated SelectedIds:", updatedSelectedIds);
      console.log("Updated Selected:", updatedSelected);

      setSelected(updatedSelected);
      setSelectedIds(updatedSelectedIds);

      if (!isChecked) {
        try {
          if (!fetchedTests.has(unitId)) {
            fetchData(
              `/api/audit_engagement/workpapertestcase/?workpaper_id=${unitId}`,
              (rawResponse) => {
                console.log("Fetched data for unit:", unitId, rawResponse);
                setDataTest(rawResponse);
                setFetchedTests(
                  (prevFetchedTests) => new Set(prevFetchedTests.add(unitId))
                );
              },
              (error) => {
                console.log("Error", error);
              }
            );
          }

          setToggledIndices((prevIndices) =>
            prevIndices.includes(unitId)
              ? prevIndices
              : [...prevIndices, unitId]
          );
        } catch (error) {
          console.error("Failed to fetch workpaper tests:", error);
        }
      }
    },
    [selectedIds, selected, fetchedTests]
  );

  const handleToggleExpandTestSection = (unitId) => {
    setToggledIndices((prevIndices) =>
      prevIndices.includes(unitId)
        ? prevIndices.filter((i) => i !== unitId)
        : [...prevIndices, unitId]
    );
  };

  const handleSearchChangeDepts = (event) => {
    setSearchTerm(event.target.value);
    if (event.target.value.trim() !== "") {
      setExpanded(true);
    } else {
      setExpanded(false);
    }
  };

  const toggleExpandDept = () => {
    setExpanded(!expanded);
  };

  const handleRemoveSelectedDepts = useCallback(
    (unitToRemove) => {
      const updatedSelected = selected.filter(
        (dept) => dept.id !== unitToRemove.id
      );
      const updatedSelectedIds = selectedIds.filter(
        (id) => id !== unitToRemove.id
      );
      setSelected(updatedSelected);
      setSelectedIds(updatedSelectedIds);
      var newItems = { ...item };
      delete newItems[unitToRemove.id];
      setItem(newItems);
    },
    [selected, selectedIds]
  );

  return (
    <div className="inside_MulOP_pap_section">
      <h3>{title.split(" ")[1]}</h3><div className="select_wkpap_input">
        <input
          style={{
            backgroundColor: editMode ? "#E6E6E6" : "#EEF1FC",
          }}
          ref={inputRef}
          type="text"
          placeholder="Search workpaper by ID"
          className="wk_pap_searchbar"
          value={searchTerm}
          disabled={editMode}
          onChange={handleSearchChangeDepts} />
      </div>



      <div ref={dropdownRef}>
        <div
          onClick={toggleExpandDept}
          style={{ backgroundColor: editMode ? "#EEF1FC" : "#e6e6e6" }}
        ></div>

        {expanded && searchTerm && (
          <div
            className="Wkpap_multi_select_res"
            style={{
              visibility: expanded ? "visible" : "hidden",
              height: expanded ? "auto" : "0",
            }}
          >
            <h6>Search Results</h6>
            <div className="wk_pap_results">
              {data
                .filter((unit) =>
                  typeof unit.id === "string"
                    ? unit.id.includes(searchTerm)
                    : typeof unit.id === "number"
                      ? unit.id.toString().includes(searchTerm)
                      : false
                )
                .map((unit) => (
                  <div
                    key={unit.id}
                    className="create_wk_papr_checkbox"
                    onClick={() =>
                      handleCheckboxChangeDepts(
                        unit.id,
                        unit[Name],
                        unit.risk_description,
                        unit.status,
                        unit.control_desired
                      )
                    }
                  >
                    <span>{unit.id}</span>
                    <p>{unit[Name]}</p>
                    <label>
                      <input
                        type="checkbox"
                        id={unit.id}
                        value={unit.id}
                        checked={selectedIds.some((item) => item.id === unit.id) ? selectedIds.some((item) => item.id === unit.id) : selectedIds.includes(unit.id)}
                        readOnly
                        className="createusercb"
                      />
                    </label>
                  </div>
                ))}
            </div>
          </div>
        )}
      </div>

      <div className="selected_wk_pap_section">
        <h6>Selected Workpapers</h6>
        <div className="all_selected_wk_pap">
          {selected.length === 0 ? (
            <p className="wkp_no_sel_msg">Workpapers have not been selected!</p>
          ) : (
            selected.map((dept, index) => (
              <React.Fragment key={dept.id}>
                <div className="selected_wk_pap">
                  <div className="selected_wk_pap_header">
                    <div onClick={() => handleToggleExpandTestSection(dept.id)}>
                      <img src={arrowToggle} alt="Toggle" />
                    </div>
                    <span>{dept.id}</span>
                    <p>{dept.Name}</p>
                    <div
                      className="workapap_delete"
                      onClick={!editMode ? () => handleRemoveSelectedDepts(dept) : undefined}
                    >

                      <img src={cut} alt="Remove" />
                    </div>




                  </div>
                  <hr />
                  {toggledIndices.includes(dept.id) && (
                    <div className="selected_wkpaper">
                      <h5>Select Tests</h5>
                      <div>
                        <MultiOptionTest
                          setItem={setItem}
                          item={item}
                          setDataTest={setDataTest}
                          dataTest={dept.id}
                          setData1={setData1}
                          data1={data1}
                          testIds={testIds}
                          editMode={editMode}
                          setTestIds={setTestIds}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </React.Fragment>
            ))
          )}
        </div>
      </div>
    </div>
  );
};

export default MultiOptionWorkPaper;
