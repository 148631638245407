import React, { useState, useRef, useEffect } from "react";
import add_button from "../../../../images/add_button.svg";
import deleteQuestion from "../../../../images/delete_wbg.svg";
import saveQuestion from "../../../../images/confirm.svg";
import { justDeleteData, justPostData, fetchData, justUpdateData } from "../../../apiUtils";
import { useNavigate ,useLocation} from "react-router-dom";

function YourComponent() {
  const location = useLocation();
  const [inputs, setInputs] = useState({});
  const [trackId, setTrackId] = useState([]);
  const [errors, setErrors] = useState("");
  const navigate = useNavigate();

  const [showButton, setShowButton] = useState(false);
  const [inputValues, setInputValues] = useState({});
  const [editingInputId, setEditingInputId] = useState(null);

  const inputRefs = useRef([]);

  const [sectionsData, setSectionsData] = useState([]);
  const [questionsData, setQuestionsData] = useState([]);
  const [sectionErrors, setSectionErrors] = useState("");
  const [questionErrors, setQuestionErrors] = useState("");
  const [initialQuestions, setInitialQuestions] = useState([]);

  useEffect(() => {
    fetchData(
      `/api/audit_universe/sections/${location?.state?.data}/`,
      setSectionsData,
      setSectionErrors
    );
    fetchData(
      `/api/audit_universe/questions/questionnaire-template/${location?.state?.data}`,
      setQuestionsData,
      setQuestionErrors
    );
  }, [location?.state?.data]);

  useEffect(() => {
    const initialQuestions1 = sectionsData.map((section) => ({
      section: section.section_name,
      id: section.id,
      questions: questionsData
        .filter((question) => question.section_id === section.id)
        .map((question) => ({
          id: question.id,
          text: question.question,
        })),
    }));
    setInitialQuestions(initialQuestions1);
  }, [sectionsData, questionsData]);


  const addInput = (sectionId, inputId) => {
    const newId = Date.now();
    setInputs({
      ...inputs,
      [sectionId]: [
        ...(inputs[sectionId] || []),
        { id: newId, editing: true, value: "" },
      ],
    });
    setEditingInputId(newId);
  };

  const removeInput = (sectionId, inputId) => {
    setInputs({
      ...inputs,
      [sectionId]: inputs[sectionId].filter((input) => input.id !== inputId),
    });

    inputRefs.current = inputRefs.current.filter(
      (ref) => ref !== inputRefs.current[inputId]
    );
  };

  const removeQuestion = async (inputId) => {
    try {
      const status = await justDeleteData(
        `/api/audit_universe/questions/${inputId}`,
        setErrors
      );


      if (status === 204) {
        console.log("Delete successful, reloading page.");
        // Reload the page after successful deletion
        window.location.reload();
      }
    } catch (error) {
      console.error("Error deleting question:", error);
      // Handle error, maybe show a message to the user
    }
  };

  const handleInputChange = (sectionId, inputId, value) => {
    setInputValues({
      ...inputValues,
      [sectionId]: {
        ...inputValues[sectionId],
        [inputId]: value,
      },
    });
  };

  const handleEdit = (inputId) => {
    setEditingInputId(inputId);
  };

  const handshowHideButton = (inputId, lastQuestionIndex) => {


    // Check if the input ID is present in the trackId array
    if (trackId.includes(lastQuestionIndex)) {

      // Remove the input ID from the trackId array
      const updatedTrackId = trackId.filter((id) => id !== lastQuestionIndex);
      setTrackId(updatedTrackId, () => {
        // Callback function to ensure the button is shown after updating trackId
        setShowButton(true);
      });
    } else {
      console.log("ID does not exist in trackId array");

      // Hide the button
      setShowButton(false);
    }
  };

  const handleSave = (sectionId, inputId) => {
    setShowButton(false);


    justPostData(
      `/api/audit_universe/questions/`,
      {
        question: inputValues[sectionId]?.[inputId],
        section_id: sectionId,
        questionnaire_template_id: location?.state?.data,
      },
      setErrors
    );
    setEditingInputId(null);
    //  window.location.reload();
  };

  const saveInputValue = (sectionId, inputId) => {
    if (!trackId.includes(inputId)) {
      setTrackId([...trackId, inputId]);
    }
    if (inputValues[sectionId]?.[inputId] !== undefined) {
      handleSave(sectionId, inputId);
    }
    setShowButton(true);
  };

  const updateInputValue = async (sectionId, inputId) => {

    try {
      const status = await justUpdateData(
        `/api/audit_universe/questions/${inputId}`,{
          question: inputValues[sectionId]?.[inputId],
        },
        setErrors
      );


      if (status === 204) {
        // Reload the page after successful deletion
        window.location.reload();
      }
    } catch (error) {
      console.error("Error deleting question:", error);
      // Handle error, maybe show a message to the user
    }
  };

  return (
    <>
      {initialQuestions.map((section) => (
        <div className="section" key={section.id}>
          <div className="section_top">
            <input
              type="text"
              placeholder="section"
              value={section.section}
              readOnly
            />
          </div>
          <div className="questions_con">
            {section.questions.length > 0 ? (
              section.questions.map((question) => (
                <div key={question.id} className="question">
                  <input
                    type="text"
                    placeholder="Question"
                    value={
                      editingInputId === question.id
                        ? inputValues[section.id]?.[question.id] || question.text
                        : inputValues[section.id]?.[question.id]
                        ? inputValues[section.id]?.[question.id]
                        : question.text
                    }
                    readOnly={editingInputId !== question.id}
                    onClick={() => handleEdit(question.id)}
                    onChange={(e) =>
                      handleInputChange(section.id, question.id, e.target.value)
                    }
                    onBlur={() => updateInputValue(section.id, question.id)}
                    style={{
                      border:
                        editingInputId === question.id
                          ? " 2px solid #BECBF9"
                          : "none",
                    }}
                  />
                  {editingInputId === question.id && (
                    <>
                      <div className="buttonCQ">
                        <button
                          onClick={() => updateInputValue(section.id, question.id)}
                        >
                          <img src={saveQuestion} className="saveCQ" />
                        </button>
                        <button
                          onClick={() => {
                            removeQuestion(question.id);
                          }}
                        >
                          <img src={deleteQuestion} className="deleteCQ" />
                        </button>
                      </div>
                    </>
                  )}
                </div>
              ))
            ) : (
              <p></p>
            )}
            {inputs[section.id]?.map((input, index) => (
              <div key={input.id} className="questionCQ">
                <input
                  type="text"
                  value={inputValues[section.id]?.[input.id] || ""}
                  onChange={(e) => {
                    handleInputChange(section.id, input.id, e.target.value);
                    handshowHideButton(section.id, input.id);
                  }}
                  onClick={() => handshowHideButton(section.id, input.id)}
                  onFocus={() => handshowHideButton(section.id, input.id)}
                  autoFocus
                />

                {!showButton && !trackId.includes(input.id) && (
                  <>
                    <div className="buttonCQ">
                      {inputValues[section.id]?.[input.id] && (
                        <button
                          onClick={() => saveInputValue(section.id, input.id)}
                        >
                          <img src={saveQuestion} className="saveCQ" />
                        </button>
                      )}
                      <button onClick={() => removeInput(section.id, input.id)}>
                        <img src={deleteQuestion} className="deleteCQ" />
                      </button>
                    </div>
                  </>
                )}
              </div>
            ))}
          </div>
          <div className="add_question_con">
            <button onClick={() => addInput(section.id, inputs.id)}>
              <img src={add_button} alt="Add" /> Add Question
            </button>
          </div>
        </div>
      ))}
    </>
  );
}

export default YourComponent;
