import React, { useState, useEffect } from "react";
import {
  NavLink,
  useLocation,
  Link,
  useNavigate,
  useParams,
} from "react-router-dom";
import axios from "axios";
import Breadcrumb from "../../../Breadcrumb";
import SidebarMenu from "../../../SidebarMenu";
import "../../../../css/viewuser.css";
import { fetchData, updateData } from "../../../apiUtils";
import { auditExecution ,auditExecutionInnerBU} from "../../../subroutes";
import create from "../../../../images/createbutton.svg";
import openarrow from "../../../../images/openarrow.svg";
import "../../auditplan/auditplan.css";
import "./../auditreporting.css";
import "./reportingtaskspage.css";
import ProgressBar from "../../progressbar/ProgressBar";
import RenderIcons from "../../../RenderIcons";
import pencil from "../../../../images/editpencil_wbg.svg";
import deleteicon from "../../../../images/delete_wbg.svg";

const ReportingTasksPage = ({ tableSize }) => {
  const [errors, setErrors] = useState("");
  const [APIData, setAPIData] = useState([]);
  const [auditData, setAuditData] = useState([]);
  const [areAllTasksDone, setAreAllTasksDone] = useState();

  const location = useLocation();
  const navigate = useNavigate();
  const successMessageColor = location.state?.successMessageColor;
  const successMessage = location.state?.successMessage;
  const [showMessage, setShowMessage] = useState(!!successMessage);
  const originalUrl = location.state?.from;
  const { id, ObservationUpdateId, rid } = useParams();
  const [selectedTaskId, setSelectedTaskId] = useState(null);
  const [task, setTaskDetails] = useState();
  const [showPopup, setShowPopup] = useState(false);
  const [percentage, setPercentage] = useState(0);
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      navigate(".", { replace: true });
    }, 5000);

    return () => clearTimeout(timeoutId);
  }, []);

  useEffect(() => {
    fetchData(
      `/api/audit_engagement/reportingtask/?audit_id=${id}`,
      setAPIData,
      setErrors
    );
  }, []);

  const Viewuser = (data) => {
    const workId = data;
    navigate(`/AuditExecution/${id}/Reporting/${workId}`);
  };

  useEffect(() => {
    fetchData(`/api/audit_engagement/audit/${id}/`, setAuditData, setErrors);
  }, [id]);

  const userRolesCheck = localStorage.getItem("userRoles");


var routes=[];
if (userRolesCheck.includes("Admin")) {
routes = auditExecution;
} else if (userRolesCheck.includes("Business")) {
routes = auditExecutionInnerBU;
}
//  else if (userRoles.includes("Audit Team")) {
//   routes = forAuditTeam;
// } else if (userRoles.includes("Audit Commission")) {
//   routes = forAuditCommission;
// } else if (userRoles.includes("Quality Assurance")) {
//   routes = forQA;
// } 
else {
console.log("No matching role found");
}
  

  useEffect(() => {
    setAreAllTasksDone(APIData.every((task) => task.task.status === "done"));
  }, [APIData]);

  const [filters, setFilters] = useState({
    name: "",
    key_stakeholder: "",
  });

  const handleFilterChange = (filterKey, e) => {
    setFilters({ ...filters, [filterKey]: e.target.value });
  };
 

  const filteredData = APIData.filter((data) => {
    const type = data.type.toLowerCase();
    const taskType = filters.type
      ? type.includes(filters.type.toLowerCase())
      : true;

    return taskType;
  });

  const ViewReports = (type, index) => {
    console.log(index);

    switch (index) {
      case (index = 0):
        var ObservationUpdateId = type;
        return navigate(
          `/AuditExecution/${id}/Reporting/ReportingObservationCreation/${ObservationUpdateId}/`
        );
      case (index = 1):
        var ReportCreationId = type;
        return navigate(
          `/AuditExecution/${id}/Reporting/ReportingObservationUpdate/${ReportCreationId}/`
        );
      case (index = 2):
        var ObservationCreationId = type;
        return navigate(
          `/AuditExecution/${id}/Reporting/ReportingReportCreation/${ObservationCreationId}/`
        );

      default:
        return navigate(
          `/AuditExecution/${id}/Reporting/ObservationCreation/${ObservationCreationId}/`
        );
    }
  };

  const showType = (desiredPart) => {
    console.log(desiredPart);
    switch (desiredPart) {
      case "Observation Creation":
        return "Initial Draft Observation";

        break;

      case "Observation Update":
        return "Finalize Observation & Action Plan";

        break;

      case "Report Creation":
        return "Report Generation";

        break;

      default:
        return "Invalid Task";
    }
  };

  const updateWithPhase = (e) => {
    e.preventDefault();
    updateData(
      `/api/audit_engagement/audit/${id}`,
      {
        risk_assessment: auditData?.risk_assessment?.id,
        audit_entity_id: auditData?.audit_entity?.id,
        auditors_id: auditData?.auditors.map((auditor) => auditor.id),
        business_users_id: auditData?.business_users.map(
          (business_users) => business_users.id
        ),
        actual_start_date: auditData?.actual_start_date,
        actual_end_date: auditData?.actual_end_date,
        planned_start_date: auditData?.audit_event?.planned_start_date,
        planned_end_date: auditData?.audit_event?.planned_end_date,
        audit_scope: auditData?.audit_scope,
        audit_phases: auditData?.audit_phases,
        other_relevant_information: auditData?.other_relevant_information,
        phase: "Reporting Complete",
      },
      navigate,
      setErrors,
      `/AuditExecution/${id}/Reporting/`,
      window.location.reload()
    );
  };

  const handleCheckboxChange = (taskId, status, task) => {
    setTaskDetails(task);
    setSelectedTaskId(taskId);
    if (status === "Done") {
      setShowPopup(false);
    } else {
      setShowPopup(true);
    }
  };

  const handlePopupClose = (task) => {
    console.log(task);

    updateData(
      `/api/system/task/${task?.task?.id}`,
      {
        title: task.objective,
        status: "done",
      },
      navigate,
      setErrors,
      `/AuditExecution/${id}/Reporting`
    );
    setAPIData((prevAPIData) =>
      prevAPIData.map((task) => {
        if (task.id === selectedTaskId) {
          return {
            ...task,
            task: {
              ...task.task,
              status: "Done",
            },
          };
        }
        return task;
      })
    );
    window.location.reload();

    setShowPopup(false);
  };

  const handlePopupCloseNo = () => {
    setShowPopup(false);
  };
  const userRoles = localStorage.getItem("userRoles");

  const Roles = userRoles.includes("Admin") && userRoles.includes("Admin");


  const navToReportPage = (navTo) => {
    switch (navTo) {
      case "Initial Observation":
      navigate(`/AuditExecution/${id}/Reporting/InitialObservation`)
      break;

      case "QA Approval For Initial Observation":
        navigate(`/AuditExecution/${id}/Reporting/QAApprovalInitialObservation`)
        break;

      case "Schedule Closing Meeting":
        navigate(`/AuditExecution/${id}/Reporting/ScheduleClosingMeeting`)
        break;

        case "Business Owner Approval":
          navigate(`/AuditExecution/${id}/Reporting/BusinessOwnerApproval`)
          break;
  
        case "Finalize Observation":
          navigate(`/AuditExecution/${id}/Reporting/FinalizeObservation`)
          break;
  
        case "Generate Report":
          navigate(`/AuditExecution/${id}/Reporting/GenerateReportList`)
          break;

          case "QA Approval For Finalize  Observation":
            navigate(`/AuditExecution/${id}/Reporting/qaAPprovalFinalizeObservation`)
            break;
    
          case "Audit Director Approval For Finalize Observation":
            navigate(`/AuditExecution/${id}/Reporting/AuditDirectorApproval`)
            break;
    
          case "Publish Report":
            navigate(`/AuditExecution/${id}/Reporting/PublishReport`)
            break;

    default:
        return "Invalid Task";
    }
  };

  const pwa_report_data = [
    {
      id: 1,
      objective: "Create Initial Observation and Approval",
      risk_title: null,
      unit_head: { name: "John Doe" },
      department: { name: "Audit" },
      navTo: "Initial Observation",
    },
    {
      id: 2,
      objective: "Initial Draft for QA Approval",
      risk_title: null,
      unit_head: { name: "Jane Smith" },
      department: { name: "Compliance" },
      navTo: "QA Approval For Initial Observation",
    },
    {
      id: 3,
      objective: "Schedule Closing Meeting & Send Initial Draft",
      risk_title: null,
      unit_head: { name: "Robert Brown" },
      department: { name: "Finance" },
      navTo: "Schedule Closing Meeting",
    },
    {
      id: 4,
      objective: "Drafts for Business Approval",
      risk_title: null,
      unit_head: { name: "Emily White" },
      department: { name: "Operations" },
      navTo: "Business Owner Approval",
    },
    {
      id: 5,
      objective: "Draft Finalization",
      risk_title: null,
      unit_head: { name: "Michael Green" },
      department: { name: "HR" },
      navTo: "Finalize Observation",
    },
    {
      id: 6,
      objective: "Generate Report",
      risk_title: null,
      unit_head: { name: "Sara Black" },
      department: { name: "IT" },
      navTo: "Generate Report",
    },
    {
      id: 7,
      objective: "Final Report (QA Approval)",
      risk_title: null,
      unit_head: { name: "David Lee" },
      department: { name: "Legal" },
      navTo: "QA Approval For Finalize Observation",
    },
    {
      id: 8,
      objective: "Final Report (Audit Director Approval)",
      risk_title: null,
      unit_head: { name: "Sophia Wilson" },
      department: { name: "Marketing" },
      navTo: "Audit Director Approval For Finalize Observation",
    },
    {
      id: 9,
      objective: "Publish Final Report",
      risk_title: null,
      unit_head: { name: "Liam Davis" },
      department: { name: "Sales" },
      navTo: "Publish Report",
    }
  ];


  const renderCards = () => {
    return pwa_report_data.map((item) => (
      
      <div className="bucard" onClick={()=>navToReportPage(item?.navTo)} key={item.id}>
        <div className="bunstkhlder">
          <h4>
            {/* Display objective or risk title */}
            <p className="buplacehldr">
              {item.objective ? item.objective : item.risk_title}
            </p>
          </h4>
          <h5>
            <p className="buplacehldr">
              {item.unit_head ? item.unit_head.name : "Keystakeholder"}
            </p>
          </h5>
        </div>
        <div className="deptnauthnbtn">
          <h5>
            <p className="buplacehldr">
              {item.department ? item.department.name : "Department"}
            </p>
          </h5>
          <h6>
            <p className="buplacehldr">Bu ID - </p>
            <p className="buplacehldr">{item.id ? item.id : "Bu ID"}</p>
          </h6>
        </div>
        {/* <img src={openarrow} alt="Open Arrow" /> */}
      </div>
    ));
  };

  const userRole = localStorage.getItem("userRoles").split(",");

// Function to determine the highest priority role
const getHighestPriorityRole = () => {
  if (userRole.includes("Admin")) return "Admin";
  if (userRole.includes("Quality Assurance")) return "Quality Assurance";
  if (userRole.includes("Business")) return "Business";
  return null; // No valid role found
};

const highestRole = getHighestPriorityRole();


  return (
    <>
      {errors && <p className="errormessage">{errors}</p>}

      {successMessage && <p className="successmessage">{successMessage}</p>}
      {showPopup && (
        <div className="popupATP">
          <div className="subpopupATP">
            <p>Do you want the task to be done?</p>
            <div className="subpopupBtnDiv">
              <button onClick={() => handlePopupClose(task)}>Yes</button>
              <button onClick={handlePopupCloseNo}>No</button>
            </div>
          </div>
        </div>
      )}
      <section className="navroutes">
      <RenderIcons homeRoutes={routes} id ={id}/>

        <div className="filter-container">
          <div className="filter">
            <Breadcrumb />


            <div className="searchncbtn">
              {/* <input type="text" placeholder="Search" className="searchbar, searchbar_hide" /> */}
              <span className="auditstatusphase">
                <p>{auditData.phase}</p>
              </span>
            </div>
          </div>
        </div>






        <div className="reportingTaskTableDiv" style={{ width: tableSize ? "79.5vw" : "90vw" }}>

          <table>
            <thead style={{ position: "sticky", top: "0", zIndex: 1000 }}>
              <tr className="tablehead">
                <th>
                  <h4 className="thhead">Action</h4>
                </th>
                <th>
                  <h4 className="thhead"> Phase </h4>
                </th>
                <th>
                  <h4 className="thhead"> Task </h4>
                </th>
                <th>
                  <h4 className="thhead"> Progress </h4>
                </th>
                {/* <th></th> */}
                
              </tr>
            </thead>

            <tbody style={{ display: highestRole === "Admin" ? "" : "none" }}>
              <tr onClick={()=>navToReportPage("Initial Observation")}>
                <td>
                  <input type="checkbox"  />
                </td>

                <td>
                  <span className="initial-obv">
                    Initial Observation
                  </span>
                </td>

                <td>
                <span>Create Initial Observation and Approval</span>
                </td>
                <td>
                <ProgressBar 
                percentage={auditData.report_status?.CREATE_INITIAL_OBSERVATION} 
                />
                </td>
              </tr>


              <tr onClick={()=>navToReportPage("QA Approval For Initial Observation")} >
                  <td>
                    <input
                      type="checkbox"
                        
                    />
                  </td>

                  <td>
                  <span className="initial-obv">
                    Initial Observation
                  </span>
                </td>

                  <td
                   >
                    <span>Initial Draft for QA Approval</span>
                  </td>
                  <td>
                  <ProgressBar 
                percentage={auditData.report_status?.APPROVE_INITIAL_OBSERVATION} 
                />
                  </td>
                </tr> 

                <tr onClick={()=>navToReportPage("Schedule Closing Meeting")} >
                  <td>
                    <input
                      type="checkbox"
                        
                    />
                  </td>
                  <td>
                  <span className="draft-rep">
                    Draft Report
                  </span>
                </td>

                  <td
                   >
                    <span>Schedule Closing Meeting & Sent Initial Draft</span>
                  </td>
                  <td>
                  <ProgressBar 
                percentage={auditData.report_status?.SEND_CLOSING_MEETING  ? 100 : 0} 
                />
                  </td>
                </tr>

                 <tr onClick={()=>navToReportPage("Business Owner Approval")}>
                  <td>
                    <input
                      type="checkbox"
                        
                    />
                  </td>
                  <td>
                  <span className="draft-rep">
                    Draft Report
                  </span>
                </td>

                  <td
                   >
                    <span>Drafts for Business Approval  </span>
                  </td>
                  <td>
                  <ProgressBar 
                percentage={auditData.report_status?.BUSINESS_APPROVAL} 
                />
                  </td>
                </tr>

                 <tr onClick={()=>navToReportPage("Finalize Observation")} >
                  <td>
                    <input
                      type="checkbox"
                        
                    />
                  </td>
                  <td>
                  <span className="draft-rep">
                    Draft Report
                  </span>
                </td>

                  <td
                   >
                    <span>Draft Finalization</span>
                  </td>
                  <td>
                  <ProgressBar 
                percentage={auditData.report_status?.FINALIZED_OBSERVATION} 
                />
                  </td>
                </tr>

                 <tr onClick={()=>navToReportPage("Generate Report")}>
                  <td>
                    <input
                      type="checkbox"
                        
                    />
                  </td>

                  <td>
                  <span className="draft-fin">
                   Final Report
                  </span>
                </td>

                  <td
                   >
                    <span>Generate Report</span>
                  </td>
                  <td>
                    <ProgressBar
                    percentage={auditData.report_status?.REPORT_GENERATED ? 100 : 0} 
                    />
                  </td>
                </tr> 

                <tr onClick={()=>navToReportPage("QA Approval For Finalize  Observation")}>
                  <td>
                    <input
                      type="checkbox"
                        
                    />
                  </td>
                  <td>
                  <span className="draft-fin">
                   Final Report
                  </span>
                </td>

                  <td
                   >
                    <span>Final Report (QA Approval)</span>
                  </td>
                  <td>
                  <ProgressBar 
                percentage={auditData.report_status?.APPROVE_QA_FINAL_OBSERVATION} 
                />
                  </td>
                </tr>

                 <tr onClick={()=>navToReportPage("Audit Director Approval For Finalize Observation")}>
                  <td>
                    <input
                      type="checkbox"
                        
                    />
                  </td>
                  <td>
                  <span className="draft-fin">
                   Final Report
                  </span>
                </td>

                  <td
                   >
                    <span>Final Report (Audit Director Approval)</span>
                  </td>
                  <td><ProgressBar 
                percentage={auditData.report_status?.APPROVE_ADMIN_FINAL_OBSERVATION} 
                /></td>
                </tr> 

                <tr onClick={()=>navToReportPage("Publish Report")} >
                  <td>
                    <input
                      type="checkbox"
                        
                    />
                  </td>
                  <td>
                  <span className="draft-fin">
                   Final Report
                  </span>
                </td>

                  <td
                   >
                    <span>Publish Final Report</span>
                  </td>
                  <td><ProgressBar 
                percentage={auditData.report_status?.PUBLISHED  ? 100 : 0} 
                /></td>
                </tr> 
              {/* {APIData.map((data, index) => (
                <tr key={data.id}>
                  <td>
                    <input
                      type="checkbox"
                      checked={
                        data?.task ? data?.task?.status === "done" : false
                      }
                      onChange={() =>
                        handleCheckboxChange(data?.id, data?.task?.status, data)
                      }
                    />
                  </td>

                  <td onClick={() => ViewReports(data?.id, index)}>
                    <span>{data?.type}</span>
                  </td>
                  <td onClick={() => ViewReports(data?.id, index)}>
                    {data?.task?.title}
                  </td>
                </tr>
              ))} */}
            </tbody>
            <tbody style={{ display: highestRole === "Business" ? "" : "none" }}>


                 <tr onClick={()=>navToReportPage("Business Owner Approval")}>
                  <td>
                    <input
                      type="checkbox"
                        
                    />
                  </td>
                  <td>
                  <span className="draft-rep">
                    Draft Report
                  </span>
                </td>

                  <td
                   >
                    <span>Drafts for Business Approval  </span>
                  </td>
                  <td>
                  <ProgressBar 
                percentage={auditData.report_status?.BUSINESS_APPROVAL} 
                />
                  </td>
                </tr>
           
            </tbody>

            <tbody style={{ display: highestRole === "Quality Assurance" ? "" : "none" }}>


                            <tr onClick={()=>navToReportPage("QA Approval For Initial Observation")} >
                  <td>
                    <input
                      type="checkbox"
                        
                    />
                  </td>

                  <td>
                  <span className="initial-obv">
                    Initial Observation
                  </span>
                </td>

                  <td
                   >
                    <span>Initial Draft for QA Approval</span>
                  </td>
                  <td>
                  <ProgressBar 
                percentage={auditData.report_status?.APPROVE_INITIAL_OBSERVATION} 
                />
                  </td>
                </tr> 

                
                <tr onClick={()=>navToReportPage("QA Approval For Finalize  Observation")}>
                  <td>
                    <input
                      type="checkbox"
                        
                    />
                  </td>
                  <td>
                  <span className="draft-fin">
                   Final Report
                  </span>
                </td>

                  <td
                   >
                    <span>Final Report (QA Approval)</span>
                  </td>
                  <td>
                  <ProgressBar 
                percentage={auditData.report_status?.APPROVE_QA_FINAL_OBSERVATION} 
                />
                  </td>
                </tr>
           
            </tbody>
          </table>

          {renderCards()}

          {/* {auditData.phase ==="Reporting Complete" &&( <div><span>Reporting Completed</span></div>)}  */}
          
          {auditData.phase === "Fieldwork Complete" && areAllTasksDone && (
            <div onClick={(e) => updateWithPhase(e)}>
              <button className="phasestatus">Start WrapupQA</button>
            </div>
          )}
        </div>

        {/* <div className="cardscontainer">
                    {filteredData.map((data) => (
                        <div className="aecard" >
                            <h1>{data.name} </h1>

                            <img src={openarrow} />
                        </div>
                    ))}
                </div> */}
      </section>
    </>
  );
};

export default ReportingTasksPage;
