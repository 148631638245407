import React from "react";
import "../css/accountwindow.css";
import { useNavigate } from "react-router-dom";

const AccountDetails = () => {
  const clearStorage = () => {
    localStorage.removeItem("isLoggedIn");
    localStorage.clear();
    window.location.href = "/";
  };

  const username = localStorage.getItem("userName");
  const userLastName = localStorage.getItem("userLastName");
  const userRoles = localStorage.getItem("userRoles");

  return (
    <div className="account-details-container">
      <div className="triangle-indicator"></div>
      <div className="account-details-content">
        <p className="account-details-title">Account Details</p>
        <div className="account-details-options">
          <h3>{username + " " + userLastName}</h3>
          {userRoles &&
            userRoles.split(",").map((role, index) => (
              <React.Fragment key={index}>
                <div className="account-roles">
                <span className="user-role">{role.trim()}</span>
                <br /> 
                </div>
              </React.Fragment>
            ))}
        </div>
        <button className="logout-button" onClick={clearStorage}>
          Logout
        </button>
      </div>
    </div>
  );
};

export default AccountDetails;