import React, { useState, useEffect } from "react";
import axios from "axios";
import { NavLink, useLocation, useNavigate, useParams } from "react-router-dom";
import Breadcrumb from "../../Breadcrumb";
import "../../../css/viewuser.css";
import "../rcm/listofrcap.css";
import { fetchData, updateData, deleteData, postData } from "../../apiUtils";
import create from "../../../images/createbutton.svg";
import { auditExecution } from "../../subroutes";
import corporate from "../../../images/corporate.svg"; // Adjust path as necessary
import star from "../../../images/star.svg"; // Adjust path as necessary
import risk from "../../../images/risk.svg"; // Adjust path as necessary
import auditPlan from "../../../images/auditPlan.svg"; // Adjust path as necessary
import audit_icon from "../../../images/audit_icon.svg"; // Adjust path as necessary
import planning_icon from "../../../images/planning_icon.svg"; // Adjust path as necessary
import fieldwork_icon from "../../../images/fieldwork_icon.svg"; // Adjust path as necessary
import reporting_icon from "../../../images/reporting_icon.svg"; // Adjust path as necessary
import wrapupqa_icon from "../../../images/wrapup_icon.svg"; // Adjust path as necessary
import EditRow from "../../../images/edit_pencil.svg";
import SaveRow from "../../../images/saveBtn.svg";
import WindowComponent from "../../WindowComponent";
import OptionDropDown from "../../OptionDropDown";
import AfSpinner from "../../AfSpinner";

const ListOfRcapPage = ({ tableSize }) => {
  const [errors, setErrors] = useState("");
  const [showDone, setShowDone] = useState(false);
  const [APIData, setAPIData] = useState([]);
  const [businessUnitOptions, setBusinessUnitOptions] = useState([]);

  const [APIAuditData, setAPIAuditData] = useState([]);
  const [APIEntityData, setAPIEntityData] = useState([]);
  const [APIbusData, setAPIbusData] = useState("");
  const [editingRowIndex, setEditingRowIndex] = useState(null);
  const [editedRowData, setEditedRowData] = useState({});
  // const [errors, setErrors] = useState("");
  const [objective, setObjective] = useState("");
  const [risk_id, setRiskId] = useState("");
  const [entityId, setEntityId] = useState("");

  const [risk_category, setRiskCategory] = useState("");
  const [risk_description, setDescribeRisk] = useState("");
  const [risk_title, setTitleRisk] = useState("");
  const [business_units_id, setBusinessUnit_id] = useState([]);

  const [inherent_assessment_likelihood, setLikelikhood] = useState("");
  const [inherent_assessment_impact, setImpact] = useState("");

  const [control_existing, setExistingControl] = useState("");
  const [control_desired, setDesiredControl] = useState("");
  const [control_evaluation, setEvaluation] = useState("");
  const [control_design, setControlDesign] = useState("");
  const [control_type, setcontrolType] = useState("");
  const [control_frequency, setcontrolFrequency] = useState("");
  const [control_gap, setControlGap] = useState("");
  const [residual_assessment_likelihood, setResLikelihood] = useState("");
  const [residual_assessment_impact, setResImpact] = useState("");
  const [audit_procedure_step, setAuditStepDetails] = useState("");
  const [audit_procedure_data, setDataRequired] = useState("");

  const [likelikhoodError, setLikelikhoodError] = useState(false);
  const [impactError, setImpactError] = useState(false);
  const [existingControlError, setExistingControlError] = useState(false);
  const [desiredControlError, setDesiredControlError] = useState(false);
  const [evaluationError, setEvaluationError] = useState(false);
  const [controlDesignError, setControlDesignError] = useState(false);
  const [controlTypeError, setcontrolTypeError] = useState(false);
  const [controlFrequencyError, setcontrolFrequencyError] = useState(false);
  const [controlGapError, setControlGapError] = useState(false);
  const [resLikelihoodError, setResLikelihoodError] = useState(false);
  const [resImpactError, setResImpactError] = useState(false);
  const [auditStepDetailsError, setAuditStepDetailsError] = useState(false);
  const [dataRequiredError, setDataRequiredError] = useState(false);

  const [objectiveError, setObjectiveError] = useState(false);
  const [riskIdError, setRiskIdError] = useState(false);
  const [riskCategoryError, setRiskCategoryError] = useState(false);
  const [describeRiskError, setDescribeRiskError] = useState(false);
  const [titleRiskError, setTitleRiskError] = useState(false);
  const [businessUnitId, setBusinessUnitId] = useState("");
  const [businessUnitIdError, setBusinessUnitIdError] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();
  const successMessageColor = location.state?.successMessageColor;
  const successMessage = location.state?.successMessage;
  const [showMessage, setShowMessage] = useState(!!successMessage);
  const originalUrl = location.state?.from;
  const { id, rid, planid, tempId } = useParams();
  const [uploadStatus, setUploadStatus] = useState("idle");
  const [file, setFile] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);

  const fullUrl = window.location.origin + location.pathname;
  const segments = location.pathname.split("/");
  const auditUniverse = segments.find((segment) => segment === "auditUniverse");
  const [showNewRow, setShowNewRow] = useState(false);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      navigate(".", { replace: true });
    }, 5000);

    return () => clearTimeout(timeoutId);
  }, []);

  useEffect(() => {
    if (APIbusData) {
      fetchData(
        `/api/audit_universe/rcap/?department_ids=14`,
        setAPIData,
        setErrors
      );
    }
  }, [APIbusData]);

  useEffect(() => {
    // fetchData(`/api/system/business-units/`, setBusinessUnitOptions, setErrors);
    if (APIEntityData) {
      setBusinessUnitOptions(
        APIEntityData.audit_unit ? APIEntityData.audit_unit : []
      );
    }
  }, [APIEntityData]);

  useEffect(() => {
    fetchData(`/api/audit_engagement/audit/${id}/`, setAPIAuditData, setErrors);
  }, []);

  useEffect(() => {
    if (APIAuditData.audit_entity) {
      fetchData(
        `/api/audit_universe/audit-entities/${APIAuditData.audit_entity.id}/`,
        setAPIEntityData,
        setErrors
      );
    }
  }, [APIAuditData]);

  useEffect(() => {
    if (APIEntityData.audit_unit) {
      const businessUnitIds = APIEntityData.audit_unit.map(
        (unit) => unit.business_units_id
      );

      const businessUnitIdsString = businessUnitIds.join(",");

      setAPIbusData(businessUnitIdsString);
    }
  }, [APIEntityData]);

  useEffect(() => {
    if (APIData.length > 0 && APIAuditData.rcm_approved === false) {

      setShowDone(true)
    }
  }, [APIData]);

  const Category = [
    { label: ' Financial', value: 'Financial' },
    { label: 'Operational', value: 'Operational' },
    { label: 'Strategic', value: 'Strategic' },
    { label: 'Compliance', value: 'Compliance' },
    { label: 'Reputational', value: 'Reputational' }
  ];
  const inherentAssessmentImpactOptions = [
    { label: 'Insignificant (1)', value: 1 },
    { label: 'Minor (2)', value: 2 },
    { label: 'Moderate (3)', value: 3 },
    { label: 'Major (4)', value: 4 },
    { label: 'Severe (5)', value: 5 },
  ];

  const inherentAssessmentLikeOptions = [
    { label: 'Remote (1)', value: 1 },
    { label: 'Unlikely (2)', value: 2 },
    { label: 'Possible (3)', value: 3 },
    { label: 'Likely (4)', value: 4 },
    { label: 'Almost Certain (5)', value: 5 },
  ];

  const residualAssessmentImpactOptions = [
    { label: 'Insignificant (1)', value: 1 },
    { label: 'Minor (2)', value: 2 },
    { label: 'Moderate (3)', value: 3 },
    { label: 'Major (4)', value: 4 },
    { label: 'Severe (5)', value: 5 },
  ];

  const residualAssessmentLikeOptions = [
    { label: 'Remote (1)', value: 1 },
    { label: 'Unlikely (2)', value: 2 },
    { label: 'Possible (3)', value: 3 },
    { label: 'Likely (4)', value: 4 },
    { label: 'Almost Certain (5)', value: 5 },
  ];

  const controlEvaluationOptions = [
    // 'Effective', 'Ineffective', 'Needs Improvement'
    { label: 'Effective', value: 'Effective' },
    { label: 'Ineffective', value: 'Ineffective' },
    { label: 'Needs Improvement', value: 'Needs Improvement' },
  ];
  const controlDesignOptions = [
    // 'Manual', 'Automated'
    { label: 'Manual', value: 'Manual' },
    { label: 'Automated', value: 'Automated' }
  ];
  const controlTypeOptions = [
    // 'Preventive', 'Detective', 'Corrective'
    { label: 'Preventive', value: 'Preventive' },
    { label: 'Detective', value: 'Detective' },
    { label: 'Corrective', value: 'Corrective' }
  ];
  const controlGapOptions = [
    { label: 'No Gap', value: 'No Gap' },
    { label: 'Minor Gap', value: 'Minor Gap' },
    { label: 'Significant Gap', value: 'Significant Gap' }
  ];

  const controlFrequencyOptions = [
    { label: 'Daily', value: 'Daily' },
    { label: 'Weekly', value: 'Weekly' },
    { label: 'Monthly', value: 'Monthly' },
    { label: 'Quarterly', value: 'Quarterly' },
    { label: 'Annually', value: 'Annually' }
  ];
  const handleInputChange = (e, field) => {
    const { value } = e.target;
    setEditedRowData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };
  
  const Viewuser = (data) => {
    const rcapId = data.id;

    if (auditUniverse) {
      const rcapId = data.id;
      navigate(`/auditUniverse/auditEntities/${id}/Racp/${rcapId}/`);
    }
    else {
      navigate(`/AuditExecution/${id}/Planning/${planid}/Racp/${rcapId}/`);
    }
  };
  const [newRow, setNewRow] = useState({
    business_unit: "", // Initialize as an empty string
    process: "",
    sub_process: "",
    risk_id: "",
    objective: "",
    risk_title: "",
    risk_category: "",
    risk_description: "",
    inherent_assessment_likelihood: "",
    inherent_assessment_impact: "",
    control_existing: "",
    control_desired: "",
    control_evaluation: "",
    control_design: "",
    control_type: "",
    control_frequency: "",
    control_gap: "",
    residual_assessment_likelihood: "",
    residual_assessment_impact: "",
    audit_procedure_step: "",
    audit_procedure_data: "",
  });
  
  const handleCreateRow = () => {
    setShowNewRow(true);
    setNewRow({
      business_unit: "", // Reset as an empty string
      process: "",
      sub_process: "",
      risk_id: "",
      objective: "",
      risk_title: "",
      risk_category: "",
      risk_description: "",
      inherent_assessment_likelihood: "",
      inherent_assessment_impact: "",
      control_existing: "",
      control_desired: "",
      control_evaluation: "",
      control_design: "",
      control_type: "",
      control_frequency: "",
      control_gap: "",
      residual_assessment_likelihood: "",
      residual_assessment_impact: "",
      audit_procedure_step: "",
      audit_procedure_data: "",
    });
  };

  const addRCap = (e) => {
    // e.preventDefault();
    if
     (
      !newRow.objective ||
      !newRow.risk_id ||
      !newRow.risk_title ||
      !newRow.inherent_assessment_likelihood ||
      !newRow.inherent_assessment_impact ||
      !newRow.residual_assessment_likelihood ||
      !newRow.residual_assessment_impact ||
      !newRow.audit_procedure_step ||
      !newRow.business_unit
    ) {
      setObjectiveError(!newRow.objective ? true : false);
      setRiskIdError(!newRow.risk_id ? true : false);
      setTitleRiskError(!newRow.risk_title ? true : false);
      setLikelikhoodError(
        !newRow.inherent_assessment_likelihood ? true : false
      );
      setImpactError(!newRow.inherent_assessment_impact ? true : false);
      setResLikelihoodError(
        !newRow.residual_assessment_likelihood ? true : false
      );
      setResImpactError(!newRow.residual_assessment_impact ? true : false);
      setAuditStepDetailsError(!newRow.audit_procedure_step ? true : false);
      setBusinessUnitIdError(!newRow.business_unit ? true : false);
      setErrors("Please fill in all required fields.");
      setTimeout(() => {
        setErrors(null);
      }, 5000);
      return;
    }

    const payload1 = {
      department_id : 28,
      business_unit_id: newRow.business_unit, // Add this line to include business_unit_id
      process: newRow.process,
      sub_process: newRow.sub_process,
      objective: newRow.objective,
      risk_id: newRow.risk_id,
      risk_category: newRow.risk_category,
      risk_description: newRow.risk_description,
      risk_title: newRow.risk_title,
      inherent_assessment_likelihood: parseInt(newRow.inherent_assessment_likelihood),
      inherent_assessment_impact: parseInt(newRow.inherent_assessment_impact),
      control_existing: newRow.control_existing,
      control_desired: newRow.control_desired,
      control_evaluation: newRow.control_evaluation,
      control_design: newRow.control_design,
      control_type: newRow.control_type,
      control_frequency: newRow.control_frequency,
      control_gap: newRow.control_gap,
      residual_assessment_likelihood: parseInt(newRow.residual_assessment_likelihood),
      residual_assessment_impact: parseInt(newRow.residual_assessment_impact),
      audit_procedure_step: newRow.audit_procedure_step,
      audit_procedure_data: newRow.audit_procedure_data,
      audit_entity: entityId ? entityId : id,
      rcm_template_id: tempId,
    };
    
    postData(
      "/api/audit_universe/rcap/",
      payload1,
      navigate,
      setErrors,
      `/AuditExecution/${id}/Planning/${planid}/RiskControlMatrix/`
    );
    // window.location.reload();
    
    // console.log("auditUniverse : ", auditUniverse);
    // console.log("New Row", newRow);
    
  };

  const onDelete = (rcap_id) => {
    //e.preventDefault();
    deleteData(
      `/api/audit_universe/rcap/${rcap_id}`,
      navigate,
      setErrors,
      `/AuditExecution/${id}/Planning/${planid}/RiskControlMatrix`
    );
    window.location.reload();

  };

  const updateAPIData = (e) => {
    e.preventDefault();
    let navigateTo = "";
    if (auditUniverse) {
      navigateTo = `/auditUniverse/auditEntities/${id}/`;
    } else {
      navigateTo = `/AuditExecution/${id}/Planning/${planid}/RiskControlMatrix/`;
    }
    updateData(
      `/api/audit_engagement/audit/${id}`,
      {
        "approved": true,
        audit_entity_id: APIAuditData.audit_entity.id,
        risk_assessment_id: APIAuditData.risk_assessment.id,
        auditors_id: APIAuditData.auditors.map(auditor => auditor.id),
        business_users_id: APIAuditData.business_users.map(business_user => business_user.id),
        actual_start_date: APIAuditData.actual_start_date,
        actual_end_date: APIAuditData.actual_end_date,
      },
      navigate,
      setErrors,
      navigateTo
    );
  };

  const handleEditClick = (index, data) => {
    setEditingRowIndex(index);
    setEditedRowData({ ...data });
  };

 

  const handleDoneClick = () => {
    // Construct the payload with correct field names
    const payload = {
      ...editedRowData,
      business_unit_id: editedRowData.business_unit?.id, // Extract business_unit_id from business_unit object
    };

    const updatedAPIData = APIData.map((data, index) => {
      if (index === editingRowIndex) {
        return payload;
      }
      return data;
    });
    setAPIData(updatedAPIData);

    // Reset the editing state
    setEditingRowIndex(null);
    setEditedRowData({});

    // Make an API call to update the data on the server
    updateData(
      `/api/audit_universe/rcap/${payload.id}`,
      payload,
      navigate,
      setErrors,
      `/AuditExecution/${id}/Planning/${planid}/Racp/`
    );
    window.location.reload();
  };

  const auditUniverseNavLinks = [
    {
      path: "/AuditUniverse/corporateStructure",
      name: "Corporate Structure",
      icon: corporate,
    },
    {
      path: "/AuditUniverse/AuditEntities",
      name: "Audit Entities",
      icon: star,
    },
    {
      path: "/AuditUniverse/riskAssessment",
      name: "Risk Assessment",
      icon: risk,
    },
    {
      path: "/AuditUniverse/auditPlanning",
      name: "Audit Planning",
      icon: auditPlan,
    },
  ];

  const auditExecutionNavLinks = [
    { path: `/AuditExecution/${id}/Detail`, name: "Detail", icon: audit_icon },
    {
      path: `/AuditExecution/${id}/Planning`,
      name: "Planning",
      icon: planning_icon,
    },
    {
      path: `/AuditExecution/${id}/Fieldwork`,
      name: "Fieldwork",
      icon: fieldwork_icon,
    },
    {
      path: `/AuditExecution/${id}/Reporting`,
      name: "Reporting",
      icon: reporting_icon,
    },
    {
      path: `/AuditExecution/${id}/WrapupQA`,
      name: "WrapupQA",
      icon: wrapupqa_icon,
    },
  ];

  const navLinks = auditUniverse
    ? auditUniverseNavLinks
    : auditExecutionNavLinks;

  function afSpinner() {
    if (!APIData || APIData.length === 0) {
      return (
        <tr>
          <td colSpan="3" className="spinner-container">
            <div className="spinner"></div>
          </td>
        </tr>
      );
    }
  }

  const handleFileUpload = async (e) => {
    if (file) {
      const formData = new FormData();
      formData.append("file", file);

      setUploadStatus("uploading");
      setUploadProgress(0);

      axios
        .post(`${process.env.REACT_APP_API_URL}/api/audit_universe/rcap-upload/`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            setUploadProgress(percentCompleted);
          },
        })
        .then((response) => {
          setUploadStatus("success");
        })
        .catch((error) => {
          console.error("Error uploading file:", error);
          setUploadStatus("error");
        });
    } else {
      console.error("No file selected");
    }
  };

  const handleFilterChange = (filterKey, e) => {
    if (filterKey === "business_unit") {
      setFilters({
        ...filters,
        business_unit: { name: e.target.value },
      });
    } else {
      setFilters({ ...filters, [filterKey]: e.target.value });
    }
  };

  const [filters, setFilters] = useState({
    business_unit: "",
    process: "",
    sub_process: "",
    risk_id: "",
    objective: "",
    risk_title: "",
    risk_category: "",
    risk_description: "",
    inherent_assessment_likelihood: "",
    inherent_assessment_impact: "",
    control_existing: "",
    control_desired: "",
    control_evaluation: "",
    control_design: "",
    control_type: "",
    control_frequency: "",
    control_gap: "",
    residual_assessment_likelihood: "",
    residual_assessment_impact: "",
  });

  const filteredData = APIData.filter((data) => {
    return Object.keys(filters).every((key) => {
      if (!filters[key]) return true;
      if (key === "business_unit") {
        return data.business_unit?.name
          .toLowerCase()
          .includes(filters.business_unit.name.toLowerCase());
      }
      return data[key]
        ?.toString()
        .toLowerCase()
        .includes(filters[key].toLowerCase());
    });
  });
  const options = businessUnitOptions;



  const handleSelectChange = (e) => {
    const selectedValue = parseInt(e.target.value, 10);
    const selectedOption = businessUnitOptions.find(option => option.business_units_id === selectedValue);
    
    setNewRow({
      ...newRow,
      business_unit: selectedOption ? selectedOption.business_units_id : null,
    });
  };
  
  const renderAuditRCMData =(data)=>{
    return filteredData.sort((a, b) => a.risk_id.localeCompare(b.risk_id)).map((data, index) => (
        <tr key={data.id}>
          {editingRowIndex === index ? (
            <>
              <td>
                <button onClick={handleDoneClick}>
                  <img src={SaveRow} style={{ width: "2vw" }} />
                </button>
              </td>
              <td>
                {/* <select
                  id="dropdown"
                  value={selectedValue}
                // onChange={handleChange}
                >
                  <option value="" disabled>
                    Select an option
                  </option>
                  {options.map((option, index) => {
                    const key = option.name;
                    const value = option.name;
                    return (
                      <option key={key} value={value}>
                        {value}
                      </option>
                    );
                  })}
                </select> */}
                <OptionDropDown
                  // label="Risk Category"
                  inputValue={editedRowData.business_unit}
                  onInputChange={(e) => handleInputChange(e, "risk_category")}
                  options={Category} 
                  inputStyle={{
                    width: tableSize ? "12vw" : "15vw",
                    border: riskCategoryError ? "1px solid #FF4646" : "",
                  }}
                />


              </td>
              <td>
                <input
                  type="text"
                  value={editedRowData.process}
                  onChange={(e) => handleInputChange(e, "process")}
                />
              </td>
              <td>
                <input
                  type="text"
                  value={editedRowData.sub_process}
                  onChange={(e) =>
                    handleInputChange(e, "sub_process")
                  }
                />
              </td>
              <td>
                <input
                  type="text"
                  value={editedRowData.risk_id}
                  onChange={(e) => handleInputChange(e, "risk_id")}
                />
              </td>
              <td>
                <input
                  type="text"
                  style={{
              width: tableSize ? "50vw" : "60vw",
              border: objectiveError ? "1px solid #FF4646" : "",
            }}
                  value={editedRowData.objective}
                  onChange={(e) => handleInputChange(e, "objective")}
                />
              </td>
              <td>
                <input
                  type="text"
                  style={{
              width: tableSize ? "50vw" : "60vw",
              border: objectiveError ? "1px solid #FF4646" : "",
            }}
                  value={editedRowData.risk_title}
                  onChange={(e) => handleInputChange(e, "risk_title")}
                />
              </td>
              
              <td>
                <OptionDropDown
                  // label="Risk Category"
                  inputValue={editedRowData.risk_category}
                  onInputChange={(e) => handleInputChange(e, "risk_category")}
                  options={Category} 
                  inputStyle={{
                    width: tableSize ? "12vw" : "15vw",
                    border: riskCategoryError ? "1px solid #FF4646" : "",
                  }}
                />
              </td>

              <td>
                <textarea
                  type="text"
                  style={{
              width: tableSize ? "35vw" : "38vw",
              border: describeRiskError ? "1px solid #FF4646" : "",
            }}
                  value={editedRowData.risk_description}
                  onChange={(e) =>
                    handleInputChange(e, "risk_description")
                  }
                />
              </td>
               <td>
               <OptionDropDown
                  // label=" inherent_assessment_likelihood"
                  inputValue={editedRowData.inherent_assessment_likelihood}
                  onInputChange={(e) => handleInputChange(e, "inherent_assessment_likelihood")}
                  options={inherentAssessmentLikeOptions} 
                  inputStyle={{
                    width: tableSize ? "12vw" : "15vw",
                    border: likelikhoodError ? "1px solid #FF4646" : "",
                  }}
                />
              </td> 
           
              <td>
              
                <OptionDropDown
                  // label="Risk Category"
                  inputValue={editedRowData.inherent_assessment_impact}
                  onInputChange={(e) => handleInputChange(e, "inherent_assessment_impact")}
                  options={inherentAssessmentLikeOptions}
                  inputStyle={{
                    width: tableSize ? "12vw" : "15vw",
                    border: impactError ? "1px solid #FF4646" : "",
                  }}
                />
              </td>
              <td>
                <textarea
                  type="text"
                  style={{
              width: tableSize ? "50vw" : "85vw",
              border: existingControlError ? "1px solid #FF4646" : "",
            }}
                  value={editedRowData.control_existing}
                  onChange={(e) =>
                    handleInputChange(e, "control_existing")
                  }
                />
              </td>
              <td>
                <textarea
                  type="text"
                  style={{
              width: tableSize ? "50vw" : "85vw",
              border: desiredControlError ? "1px solid #FF4646" : "",
            }}
                  value={editedRowData.control_desired}
                  onChange={(e) =>
                    handleInputChange(e, "control_desired")
                  }
                />
              </td>
              <td>
                <OptionDropDown
                  // label="Risk Category"
                  inputValue={editedRowData.control_evaluation}
                  onInputChange={(e) => handleInputChange(e, "control_evaluation")}
                  options={controlEvaluationOptions} 
                  inputStyle={{
                    width: tableSize ? "12vw" : "15vw",
                    border: evaluationError ? "1px solid #FF4646" : "",
                  }}
                />

              </td>
              <td>
               
                    <OptionDropDown
                  // label="Risk Category"
                  inputValue={editedRowData.control_design}
                  onInputChange={(e) => handleInputChange(e, "control_design")}
                  options={controlDesignOptions} 
                  inputStyle={{
                    width: tableSize ? "12vw" : "15vw",
                    border: controlDesignError ? "1px solid #FF4646" : "",
                  }}
                />
              </td>
              <td>
            
                <OptionDropDown
                  // label="Risk Category"
                  inputValue={editedRowData.control_type}
                  onInputChange={(e) => handleInputChange(e, "control_type")}
                  options={controlTypeOptions} 
                  inputStyle={{
                    width: tableSize ? "12vw" : "15vw",
                    border: controlTypeError ? "1px solid #FF4646" : "",
                  }}
                />
              </td>
              <td>
               
                <OptionDropDown
                  // label="Risk Category"
                  inputValue={editedRowData.control_frequency}
                  onInputChange={(e) => handleInputChange(e, "control_frequency")}
                  options={controlFrequencyOptions} 
                  inputStyle={{
                    width: tableSize ? "12vw" : "15vw",
                    border: controlFrequencyError ? "1px solid #FF4646" : "",
                  }}
                />
              </td>
              <td>
             
                <OptionDropDown
                  // label="Risk Category"
                  inputValue={editedRowData.control_gap}
                  onInputChange={(e) => handleInputChange(e, "control_gap")}
                  options={controlGapOptions} 
                  inputStyle={{
                    width: tableSize ? "12vw" : "15vw",
                    border: controlGapError ? "1px solid #FF4646" : "",
                  }}
                />
              </td>
              <td>
                
                <OptionDropDown
                  // label="Risk Category"
                  inputValue={editedRowData.residual_assessment_likelihood}
                  onInputChange={(e) => handleInputChange(e, "residual_assessment_likelihood")}
                  options={residualAssessmentLikeOptions}
                  inputStyle={{
                    width: tableSize ? "12vw" : "15vw",
                    border: resLikelihoodError ? "1px solid #FF4646" : "",}}
                  />
              </td>
              <td>
                <OptionDropDown
                  // label="Risk Category"
                  inputValue={editedRowData.residual_assessment_impact}
                  onInputChange={(e) => handleInputChange(e, "residual_assessment_impact")}
                  options={residualAssessmentImpactOptions}
                  inputStyle={{
              width: tableSize ? "12vw" : "15vw",
              border: resImpactError ? "1px solid #FF4646" : "",
            }}  
                  />
              </td>
              <td>
                <textarea
                  type="text"
                  style={{
              width: tableSize ? "50vw" : "85vw",
              border: auditStepDetailsError ? "1px solid #FF4646" : "",
            }}
                  value={editedRowData.audit_procedure_step}
                  onChange={(e) =>
                    handleInputChange(e, "audit_procedure_step")
                  }
                />
              </td>
              <td>
                <input
                  type="type"
                  inputStyle={{
              width: tableSize ? "12vw" : "15vw",
              border: dataRequiredError ? "1px solid #FF4646" : "",
            }}
                  value={editedRowData.audit_procedure_data}
                  onChange={(e) =>
                    handleInputChange(e, "audit_procedure_data")
                  }
                />
              </td>
            </>
          ) : (
            <>
              <td>
                {" "}
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    gap: "1vw",
                  }}
                >
                  <WindowComponent
                    onDelete={() => onDelete(data.id)}
                  />
                  <button
                    onClick={() => handleEditClick(index, data)}
                    style={{
                      background: "transparent",
                      border: "none",
                    }}
                  >
                    <img src={EditRow} style={{ width: "2vw" }} />
                  </button>
                  {/* <button onClick={() => Viewuser(data)}>
    <img src={rcap_openbutton} className="rcap-openbutton" alt="RCAP Open Button" />
  </button> */}
                </div>
              </td>
              <td>{data.business_unit.name}</td>
              <td>{data.process}</td>
              <td>{data.sub_process}</td>
              <td>{data.risk_id}</td>
              <td>{data.objective}</td>
              <td>{data.risk_title}</td>
              <td>{data.risk_category}</td>
              <td>{data.risk_description}</td>
              <td>{data.inherent_assessment_likelihood}</td>
              <td>{data.inherent_assessment_impact}</td>
              <td>{data.control_existing}</td>
              <td>{data.control_desired}</td>
              <td>{data.control_evaluation}</td>
              <td>{data.control_design}</td>
              <td>{data.control_type}</td>
              <td>{data.control_frequency}</td>
              <td>{data.control_gap}</td>
              <td>{data.residual_assessment_likelihood}</td>
              <td>{data.residual_assessment_impact}</td>
              <td>{data.audit_procedure_step}</td>
              <td>{data.audit_procedure_data}</td>
            </>
          )}
        </tr>
      ));
    
  };
  
  
  return (
    <>
      {errors && <p className="errormessage">{errors}</p>}
      {successMessage && <p className="successmessage">{successMessage}</p>}

      <section className="navroutes">
        <div className="auditMenuCon">
          {navLinks.map((route, index) => (
            <NavLink
              to={route.path}
              key={index}
              className="navlink"
              activeclassname="active"
            >
              <div className="navicon">
                <img src={route.icon} alt="Dashboard Icon" />
              </div>
              <div className="navicon">{route.name}</div>
            </NavLink>
          ))}
        </div>
        <hr className="breadcrumbabv" />
        <Breadcrumb />

        <div className="filter-container">
          <div className="filter">
            <div className="views">
              <h3 className="h2_interbold" >Risk Control Matrix Template</h3>
            </div>

            
          </div>
        </div>

        <div
          className="allrcap_con"
          style={{ width: tableSize ? "80vw" : "90.5vw" }}
        >
          <div className="rcmt_table">
            <table style={{ width: tableSize ? "80vw" : "90.5vw" }}>
              <thead style={{ position: "sticky", top: "0", zIndex: 1000 }}>
                <tr>
                  <th>
                    <h4>
                      <h4 className="thhead">Action</h4>
                    </h4>
                  </th>
                  <th>
                    <h4 className="thhead">Business Unit</h4>
                    <input
                      type="text"
                      placeholder="--"
                      className="thfilter"
                      onChange={(e) => handleFilterChange("business_unit", e)}
                      value={filters.business_unit.name}
                    />
                  </th>
                  <th>
                    <h4 className="thhead">Process</h4>
                    <input
                      type="text"
                      placeholder="--"
                      className="thfilter"
                      onChange={(e) => handleFilterChange("process", e)}
                      value={filters.process}
                    />
                  </th>
                  <th>
                    <h4 className="thhead">Sub Process</h4>
                    <input
                      type="text"
                      placeholder="--"
                      className="thfilter"
                      onChange={(e) => handleFilterChange("sub_process", e)}
                      value={filters.sub_process}
                    />
                  </th>
                  <th>
                    <h4 className="thhead">Risk ID</h4>
                    <input
                      type="text"
                      placeholder="--"
                      className="thfilter"
                      onChange={(e) => handleFilterChange("risk_id", e)}
                      value={filters.risk_id}
                    />
                  </th>
                  <th>
                    <h4 className="thhead">Objective</h4>
                    <input
                      type="text"
                      placeholder="--"
                      className="thfilter"
                      onChange={(e) => handleFilterChange("objective", e)}
                      value={filters.objective}
                    />
                  </th>
                  <th>
                    <h4 className="thhead">Risk Title</h4>
                    <input
                      type="text"
                      placeholder="--"
                      className="thfilter"
                      onChange={(e) => handleFilterChange("risk_title", e)}
                      value={filters.risk_title}
                    />
                  </th>
                  <th>
                    <h4 className="thhead">Risk Category</h4>
                    <input
                      type="text"
                      placeholder="--"
                      className="thfilter"
                      onChange={(e) => handleFilterChange("risk_category", e)}
                      value={filters.risk_category}
                    />
                  </th>
                  <th>
                    <h4 className="thhead">Risk Description</h4>
                    <input
                      type="text"
                      placeholder="--"
                      className="thfilter"
                      onChange={(e) =>
                        handleFilterChange("risk_description", e)
                      }
                      value={filters.risk_description}
                    />
                  </th>
                  <th>
                    <h4 className="thhead">Inherent Assessment Likelihood</h4>
                    <input
                      type="text"
                      placeholder="--"
                      className="thfilter"
                      onChange={(e) =>
                        handleFilterChange("inherent_assessment_likelihood", e)
                      }
                      value={filters.inherent_assessment_likelihood}
                    />
                  </th>
                  <th>
                    <h4 className="thhead">Inherent Assessment Impact</h4>
                    <input
                      type="text"
                      placeholder="--"
                      className="thfilter"
                      onChange={(e) =>
                        handleFilterChange("inherent_assessment_impact", e)
                      }
                      value={filters.inherent_assessment_impact}
                    />
                  </th>
                  <th>
                    <h4 className="thhead">Control Existing</h4>
                    <input
                      type="text"
                      placeholder="--"
                      className="thfilter"
                      onChange={(e) =>
                        handleFilterChange("control_existing", e)
                      }
                      value={filters.control_existing}
                    />
                  </th>
                  <th>
                    <h4 className="thhead">Control Desired</h4>
                    <input
                      type="text"
                      placeholder="--"
                      className="thfilter"
                      onChange={(e) => handleFilterChange("control_desired", e)}
                      value={filters.control_desired}
                    />
                  </th>
                  <th>
                    <h4 className="thhead">Control Evaluation</h4>
                    <input
                      type="text"
                      placeholder="--"
                      className="thfilter"
                      onChange={(e) =>
                        handleFilterChange("control_evaluation", e)
                      }
                      value={filters.control_evaluation}
                    />
                  </th>
                  <th>
                    <h4 className="thhead">Control Design</h4>
                    <input
                      type="text"
                      placeholder="--"
                      className="thfilter"
                      onChange={(e) => handleFilterChange("control_design", e)}
                      value={filters.control_design}
                    />
                  </th>
                  <th>
                    <h4 className="thhead">Control Type</h4>
                    <input
                      type="text"
                      placeholder="--"
                      className="thfilter"
                      onChange={(e) => handleFilterChange("control_type", e)}
                      value={filters.control_type}
                    />
                  </th>
                  <th>
                    <h4 className="thhead">Control Frequency</h4>
                    <input
                      type="text"
                      placeholder="--"
                      className="thfilter"
                      onChange={(e) =>
                        handleFilterChange("control_frequency", e)
                      }
                      value={filters.control_frequency}
                    />
                  </th>
                  <th>
                    <h4 className="thhead">Control Gap</h4>
                    <input
                      type="text"
                      placeholder="--"
                      className="thfilter"
                      onChange={(e) => handleFilterChange("control_gap", e)}
                      value={filters.control_gap}
                    />
                  </th>
                  <th>
                    <h4 className="thhead">Residual Assessment Likelihood</h4>
                    <input
                      type="text"
                      placeholder="--"
                      className="thfilter"
                      onChange={(e) =>
                        handleFilterChange("residual_assessment_likelihood", e)
                      }
                      value={filters.residual_assessment_likelihood}
                    />
                  </th>
                  <th>
                    <h4 className="thhead">Residual Assessment Impact</h4>
                    <input
                      type="text"
                      placeholder="--"
                      className="thfilter"
                      onChange={(e) =>
                        handleFilterChange("residual_assessment_impact", e)
                      }
                      value={filters.residual_assessment_impact}
                    />
                  </th>
                  <th>
                    <h4 className="thhead"> Audit Step Details</h4>
                    <input
                      type="text"
                      placeholder="--"
                      className="thfilter"
                      onChange={(e) =>
                        handleFilterChange("audit_procedure_step", e)
                      }
                      value={filters.audit_procedure_step}
                    />
                  </th>
                  <th>
                    <h4 className="thhead"> Data Required</h4>
                    <input
                      type="text"
                      placeholder="--"
                      className="thfilter"
                      onChange={(e) =>
                        handleFilterChange("audit_procedure_data", e)
                      }
                      value={filters.audit_procedure_data}
                    />
                  </th>
                </tr>
              </thead>
             
                <tbody>
      <tr style={{ display: showNewRow ? "" : "none" }}>
        <td>
          <button
            onClick={() => addRCap()}
            style={{ border: "none", background: "transparent" }}
          >
            <img src={SaveRow} style={{ width: "2vw" }} />
          </button>
        </td>

<td>
<OptionDropDown
tableSize={tableSize}
inputType="text"
inputPlaceholder="Select a Business Unit"
inputStyle={{
width: tableSize ? "19vw" : "22vw",
border: riskCategoryError ? "1px solid #FF4646" : "",
}}
inputValue={newRow.business_unit ? newRow.business_unit : ""}
onInputChange={(e) => {
handleSelectChange(e);
}}
options={businessUnitOptions.map(option => ({
value: option.business_units_id,
label: option.name
}))}
/>
</td>

        <td>
          <input
            type="text"
            placeholder="Process"
            style={{
              width: tableSize ? "12vw" : "15vw",
              border: objectiveError ? "1px solid #FF4646" : "",
            }}
            value={newRow.process}
            onChange={(e) => {
              setNewRow({ ...newRow, process: e.target.value });
              setObjectiveError(false);
            }}
          />
        </td>

        <td>
          <input
            type="text"
            placeholder="SubProcess"
            style={{
              width: tableSize ? "12vw" : "15vw",
              border: objectiveError ? "1px solid #FF4646" : "",
            }}
            value={newRow.sub_process}
            onChange={(e) => {
              setNewRow({ ...newRow, sub_process: e.target.value });
              setObjectiveError(false);
            }}
          />
        </td>

        <td>
          <input
            type="text"
            placeholder="NG57FRE"
            style={{
              width: tableSize ? "19vw" : "22vw",
              border: riskIdError ? "1px solid #FF4646" : "",
            }}
            value={newRow.risk_id}
            onChange={(e) => {
              setNewRow({ ...newRow, risk_id: e.target.value });
              setRiskIdError(false);
            }}
          />
        </td>

        <td>
          <input
            type="text"
            placeholder="Unauthorized Access to Financial Data"
            style={{
              width: tableSize ? "50vw" : "60vw",
              border: objectiveError ? "1px solid #FF4646" : "",
            }}
            value={newRow.objective}
            onChange={(e) => {
              setNewRow({ ...newRow, objective: e.target.value });
              setObjectiveError(false);
            }}
          />
        </td>

        <td>
          <input
            type="text"
            placeholder="Unauthorized Access to Financial Data"
            style={{
              width: tableSize ? "50vw" : "60vw",
              border: objectiveError ? "1px solid #FF4646" : "",
            }}
            value={newRow.risk_title}
            onChange={(e) => {
              setNewRow({ ...newRow, risk_title: e.target.value });
              setTitleRiskError(false);
            }}
          />
        </td>
        <td>
          <OptionDropDown
            tableSize={tableSize}
            inputType="text"
            inputPlaceholder="Financial"
            inputStyle={{
              width: tableSize ? "19vw" : "22vw",
              border: riskCategoryError ? "1px solid #FF4646" : "",
            }}
            inputValue={newRow.risk_category}
            onInputChange={(e) => {
              setNewRow({ ...newRow, risk_category: e.target.value });
              setRiskCategoryError(false);
            }}
            options={Category}
          />
        </td>

        <td>
          <textarea
            type="text"
            placeholder="Describe Risk"
            style={{
              width: tableSize ? "35vw" : "38vw",
              border: describeRiskError ? "1px solid #FF4646" : "",
            }}
            value={newRow.risk_description}
            onChange={(e) => {

              setNewRow({ ...newRow, risk_description: e.target.value });
            }}
          />
        </td>

        <td>
          <OptionDropDown
            tableSize={tableSize}
            inputType="text"
            inputPlaceholder="NG57FRE"
            inputStyle={{
              width: tableSize ? "19vw" : "22vw",
              border: likelikhoodError ? "1px solid #FF4646" : "",
            }}
            inputValue={newRow.inherent_assessment_likelihood}
            onInputChange={(e) => {
              setNewRow({ ...newRow, inherent_assessment_likelihood: e.target.value });
              setLikelikhoodError(false);
            }}
            options={inherentAssessmentLikeOptions}
          />
        </td>

        <td>
          <OptionDropDown
            tableSize={tableSize}
            inputType="text"
            inputPlaceholder="NG57FRE"
            inputStyle={{
              width: tableSize ? "19vw" : "22vw",
              border: impactError ? "1px solid #FF4646" : "",
            }}
            inputValue={newRow.inherent_assessment_impact}
            onInputChange={(e) => {
              setNewRow({ ...newRow, inherent_assessment_impact: e.target.value });
              setImpactError(false);
            }}
            options={inherentAssessmentImpactOptions}
          />
        </td>

        <td>
          <textarea
            type="text"
            placeholder="NG57FRE"
            style={{
              width: tableSize ? "50vw" : "85vw",
              border: existingControlError ? "1px solid #FF4646" : "",
            }}
            value={newRow.control_existing}
            onChange={(e) => {
              setNewRow({ ...newRow, control_existing: e.target.value })
              setExistingControl(e.target.value);
            }}
          />
        </td>

        <td>
          <textarea
            type="text"
            placeholder="NG57FRE"
            style={{
              width: tableSize ? "50vw" : "85vw",
              border: desiredControlError ? "1px solid #FF4646" : "",
            }}
            value={newRow.control_desired}
            onChange={(e) => {
              setNewRow({ ...newRow, control_desired: e.target.value });
              setDesiredControl(e.target.value);
            }}
          />
        </td>

        <td>
          <OptionDropDown
            tableSize={tableSize}
            // label="Control Evaluation"
            inputType="text"
            inputPlaceholder="NG57FRE"
            inputStyle={{
              width: tableSize ? "12vw" : "15vw",
              border: evaluationError ? "1px solid #FF4646" : "",
            }}
            inputValue={newRow.control_evaluation}
            onInputChange={(e) => {
              setNewRow({ ...newRow, control_evaluation: e.target.value })
            }}
            options={controlEvaluationOptions}
          />
        </td>

        <td>
          <OptionDropDown
            tableSize={tableSize}
            // label="Control Design"
            inputType="text"
            inputPlaceholder="NG57FRE"
            inputStyle={{
              width: tableSize ? "12vw" : "15vw",
              border: controlDesignError ? "1px solid #FF4646" : "",
            }}
            inputValue={control_design}
            onInputChange={(e) => {
              setControlDesign(e.target.value);
            }}
            options={controlDesignOptions}
          />
        </td>

        <td>
          <OptionDropDown
            tableSize={tableSize}
            inputType="text"
            inputPlaceholder="NG57FRE"
            inputStyle={{
              width: tableSize ? "12vw" : "15vw",
              border: controlTypeError ? "1px solid #FF4646" : "",
            }}
            inputValue={newRow.control_type}
            onInputChange={(e) => {
              setNewRow({ ...newRow, control_type: e.target.value })
            }}
            options={controlTypeOptions}
          />
        </td>

        <td>
          <OptionDropDown
            tableSize={tableSize}
            inputType="text"
            inputPlaceholder="NG57FRE"
            inputStyle={{
              width: tableSize ? "12vw" : "15vw",
              border: controlFrequencyError ? "1px solid #FF4646" : "",
            }}
            inputValue={newRow.control_frequency}
            onInputChange={(e) => {
              setNewRow({ ...newRow, control_frequency: e.target.value })
            }}
            options={controlFrequencyOptions}
          />
        </td>

        <td>
          <OptionDropDown
            tableSize={tableSize}
            // label="Control Gap"
            inputType="text"
            inputPlaceholder="NG57FRE"
            inputStyle={{
              width: tableSize ? "12vw" : "15vw",
              border: controlGapError ? "1px solid #FF4646" : "",
            }}
            inputValue={newRow.control_gap}
            onInputChange={(e) => {
              setNewRow({ ...newRow, control_gap: e.target.value })
            }}
            options={controlGapOptions}
          />
        </td>

        <td>
          <OptionDropDown
            tableSize={tableSize}
            inputType="text"
            inputPlaceholder="NG57FRE"
            inputStyle={{
              width: tableSize ? "12vw" : "15vw",
              border: resLikelihoodError ? "1px solid #FF4646" : "",
            }}
            inputValue={newRow.residual_assessment_likelihood}
            onInputChange={(e) => {
              setNewRow({ ...newRow, residual_assessment_likelihood: e.target.value })
              // setResLikelihood(e.target.value);
              setResLikelihoodError(false);
            }}
            options={residualAssessmentLikeOptions}
          />
        </td>

        <td>
          <OptionDropDown
            tableSize={tableSize}
            inputType="text"
            inputPlaceholder="NG57FRE"
            inputStyle={{
              width: tableSize ? "12vw" : "15vw",
              border: resImpactError ? "1px solid #FF4646" : "",
            }}
            inputValue={newRow.residual_assessment_impact}
            onInputChange={(e) => {
              setNewRow({ ...newRow, residual_assessment_impact: e.target.value })
              // setResImpact(e.target.value);
              setResImpactError(false);
            }}
            options={residualAssessmentImpactOptions}
          />
        </td>

        <td>
          <textarea
            type="text"
            placeholder="NG57FRE"
            style={{ width: tableSize ? "50vw" : "60vw", 
            border: auditStepDetailsError? "1px solid #FF4646" : "",
            }}
            value={newRow.audit_procedure_step}
            onChange={(e) => {
              setNewRow({ ...newRow, audit_procedure_step: e.target.value })
              // setAuditStepDetails(e.target.value);
              setAuditStepDetailsError(false);
            }}
          />
        </td>

        <td>
          <input
            type="text"
            placeholder="NG57FRE"
            style={{
              width: tableSize ? "12vw" : "15vw",
              border: dataRequiredError ? "1px solid #FF4646" : "",
            }}
            value={newRow.audit_procedure_data}
            onChange={(e) => {
              setNewRow({ ...newRow, audit_procedure_data: e.target.value })
              setDataRequired(e.target.value);
              // setDataRequiredError(false);
            }}
          />
        </td>
      </tr>

      <AfSpinner
                url="/api/audit_universe/rcap/"
                renderData={renderAuditRCMData}
                loadingMessage="Loading RCM..."
                errorMessage="Error loading RCM"
                style={{top:"30%"}}
              />

     
    </tbody>
            </table>
          </div>
        </div>

        <div className="cardscontainer"></div>
      </section>
    </>
  );
};

export default ListOfRcapPage;
