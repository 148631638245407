import React, { useState, useEffect } from "react";
import {
  Link,
  NavLink,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";

import sendComment from "../../../../images/send.svg";
import Breadcrumb from "../../../Breadcrumb";
import SidebarMenu from "../../../SidebarMenu";
import "../../../../css/viewuser.css";
import cutbutton from "../.../../../../../images/cut.svg";
import SaveIcon from "../.../../../../../images/saveBtn.svg";
import EditIcon from "../.../../../../../images/edit_pencil.svg";
import CloseIcon from "../.../../../../../images/closeicon.svg";
import DeleteIcon from "../.../../../../../images/deleteBtn.svg";
import {
  deleteData,
  fetchData,
  fetchDataObject,
  updateData,
  justPostData,
} from "../../../apiUtils";
import { auditExecution } from "../../../subroutes";
import "../createObservation/createobservation.css";
import AuditReportingSeclector from "../AuditReportingSelector";
import MultiOptionWorkPaper from "../../../MultiOptionWorkPaper";
import SuperAvatar from "../../../SuperAvatar";
import backBtn from "../../../../images/backLogo.svg";
import savesubObv from "../../../../images/saveBtn.svg";
import remove_button from "../../../../images/remove_button.svg";
import backlogo from "../../../../images/backLogo.svg";
import blue_add_button from "../../../../images/blue_plus_button.svg";
import OptionDropDown2 from "../../../OptionDropDown2";
import createBtn from "../../../../images/createbutton.svg";
import arrowFlip from "../../../../images/arrow-left.svg";
import BounceLoader from "react-spinners/BounceLoader";
import RenderIcons from "../../../RenderIcons";
const ViewObservation = ({ tableSize }) => {
  const lastName = localStorage.getItem("userLastName");
  const avaUserName = localStorage.getItem("userName");
  const [APIQData, setAPIQData] = useState("");
  const [errors, setErrors] = useState("");
  const [APIData, setAPIData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [fileData, setFileData] = useState([]);
  const location = useLocation();
  const navigate = useNavigate();
  const [summary, setSummary] = useState("");
  const [item, setItem] = useState({});
  const [preparer_id, setPreparerId] = useState("");
  const [PreparerError, setPreparerError] = useState(false);
  const [assign_to_business_teammate_id, setTeammate_id] = useState("");
  const [teammate_idError, setTeammate_idError] = useState(false);
  const [reviewer_id, setReviewerId] = useState("");
  const [reviewerError, setReviewerError] = useState(false);
  const [testIds, setTestIds] = useState([]);
  const [workPId, setWorkPId] = useState([]);
  const [workPojcts, setWorkPojcts] = useState([]);
  const [workPIdError, setWorkPIdError] = useState("");
  const [editMode, setEditMode] = useState(true);
  const [editModeBusiness, setEditModeBusiness] = useState(true);

  const [taskData, setTaskData] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [comments, setComments] = useState([]);
  // const [backTo, setBackto] = useState("");
  const successMessage = location.state?.successMessage;
  const { id, reportId, obejctionId } = useParams();
  const [subObservations, setSubObservations] = useState([]);
  const [startdate, setStartdate] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [objective, setTitle] = useState("");
  const [rating, setObservationRating] = useState("");
  const [shortDescriptionError, setShortDescriptionError] = useState(false);
  const [recommendation, setRecommendation] = useState("");
  const [exposure_to_losses, setExposureLosses] = useState("");
  const [potential_impact, setPotentialImpact] = useState("");
  const [likelyhood, setLikelihood] = useState("");
  const [risk1, setRisk01] = useState("");
  const [risk2, setRisk02] = useState("");
  const [risk3, setRisk03] = useState("");
  const [action_plan, setActionPlan] = useState("");
  const [action_planError, setActionPlanError] = useState(false);

  const [fileObjective, setFileObjective] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploadPercentage, setUploadPercentage] = useState(0);
  const [assign_to_id, setLevel1Owner] = useState("");
  const [level_1_ownerError, setLevel1OwnerError] = useState(null);
  const [secondary_assign_to_id, setLevel2Owner] = useState(null);
  const [level_2_ownerError, setLevel2OwnerError] = useState("");
  const [management_response_due_date, setActionDueDate] = useState("");
  const [management_response_due_dateError, setActionDueDateError] =
    useState("");

  const [response_audit, setAuditResponse] = useState("");
  const [response_audit_due_date, setResponseActionDueDate] = useState("");
  const [preliminary_close_observation, setPreliminaryCloseObservation] =
    useState("");
  const [
    preliminary_close_observationError,
    setPreliminaryCloseObservationError,
  ] = useState(false);

  const [short_description, setShortDescription] = useState("");
  const [taskTitle, setTaskTitle] = useState("");
  const [inputList, setInputList] = useState([]);
  const [backto, setBackto] = useState("Initial Observation");

  const [qa_id, setQAID] = useState("");
  const [qa_idError, setQAIDError] = useState("");

  const userRoles = localStorage.getItem("userRoles");
  const userId = localStorage.getItem("user_id");
  const [showbtns, setShowbtns] = useState();

  const businessRole = userRoles.includes("Business");

  const qaRole = userRoles.includes("Quality Assurance");

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      navigate(".", { replace: true });
    }, 5000);

    return () => clearTimeout(timeoutId);
  }, []);

  const [commetIdTask, setCommentIdTask] = useState();
  useEffect(() => {
    if (APIData) {
      switch (observationUrl) {
        case "InitialObservation":
          setCommentIdTask(APIData?.task_initial_admin_approval?.id);
          setShowbtns(APIData?.task_initial_admin_approval?.status === "done");
          break;
        case "QAApprovalInitialObservation":
          setCommentIdTask(APIData?.task_initial_qa_approval?.id);
          setShowbtns(APIData?.task_initial_qa_approval?.status === "done");

          break;
        case "BusinessOwnerApproval":
          setCommentIdTask(APIData?.task_initial_business_approval?.id);
          setShowbtns(
            APIData?.task_initial_business_approval?.status === "done"
          );

          break;

        case "qaAPprovalFinalizeObservation":
          setCommentIdTask(APIData?.task_final_qa_approval?.id);
          setShowbtns(APIData?.task_final_qa_approval?.status === "done");

          break;

        case "AuditDirectorApproval":
          setCommentIdTask(APIData?.task_final_admin_approval?.id);
          setShowbtns(APIData?.task_final_admin_approval?.status === "done");

          break;
        default:
          setCommentIdTask(APIData?.task_initial_admin_approval?.id);
          setShowbtns(APIData?.task_initial_admin_approval?.status === "done");
      }
      if (commetIdTask) {
        fetchData(`/api/system/comments/?task_id=${commetIdTask}`, setTaskData);
      }
    }
  }, [APIData, commetIdTask]);

  useEffect(() => {
    if (errors) {
      const timer = setTimeout(() => {
        setErrors("");
      }, 5000);

      return () => clearTimeout(timer); // Cleanup the timer when component unmounts or error changes
    }
  }, [errors]);

  useEffect(() => {
    fetchDataObject(
      `/api/audit_engagement/observation/${obejctionId}/`,
      setAPIData,
      setErrors,
      setLoading
    );
  }, []);

  useEffect(() => {
    fetchData(
      `/api/audit_engagement/observationfile/?observation_id=${obejctionId}`,
      setFileData,
      setErrors
    );
  }, []);

  useEffect(() => {
    if (APIData) {
      setTitle(APIData?.objective);
      setObservationRating(APIData?.rating);
      setShortDescription(APIData?.short_description);
      setExposureLosses(APIData?.exposure_to_losses);
      setPotentialImpact(APIData?.potential_impact);
      setLikelihood(APIData?.likelyhood);
      setRisk01(APIData?.risks?.risk1);
      setRisk02(APIData?.risks?.risk2);
      setRisk03(APIData?.risks?.risk3);

      switch (observationUrl) {
        case "InitialObservation":
          setAPIQData(APIData?.task_initial_admin_approval?.id);
          break;
        case "QAApprovalInitialObservation":
          setAPIQData(APIData?.task_initial_qa_approval?.id);
          break;
        case "BusinessOwnerApproval":
          setAPIQData(APIData?.task_initial_business_approval?.id);
          break;

        case "qaAPprovalFinalizeObservation":
          setAPIQData(APIData?.task_final_qa_approval?.id);
          break;

        case "AuditDirectorApproval":
          setAPIQData(APIData?.task_final_admin_approval?.id);
          break;
        default:
          setAPIQData(APIData?.task_initial_admin_approval?.id);
      }

      setStartdate(APIData.due_date);
      setActionPlan(APIData?.action_plan);
      setActionDueDate(APIData?.management_response_due_date);
      setSummary(APIData.summary);
      setRecommendation(APIData.recommendation);
      setAuditResponse(APIData?.response_audit);
      setResponseActionDueDate(APIData?.response_audit_due_date);
      setPreliminaryCloseObservation(APIData?.preliminary_close_observation);
      const workid = APIData.workpapertestcase ? APIData.workpapertestcase : [];
      const workIds = workid.map((item) => item.workpaper?.id);
      const workObjectives = workid.map((item) => item.workpaper?.objective);
      setSubObservations(APIData?.data?.data?.sub_observations);
      setSubObservations(APIData?.data?.subObservations || []);
      setTaskTitle(APIData?.task_initial_admin_approval?.title);
      setTestIds(
        APIData.workpapertestcase
          ? APIData.workpapertestcase.map((item) => item.id)
          : []
      );
      setWorkPId([...new Set(workIds)]);
      setWorkPojcts([...new Set(workObjectives)]);

      if (APIData.task_initial_admin_approval?.status !== "todo") {
        setEditMode(false);
      } else {
        setEditMode(true);
      }
    }
  }, [APIData]);

  const [filters, setFilters] = useState({
    id: "",
    status: "",
    workpaper_id: "",
    summary: "",
    preparer: "",
    reviewer_name: "",
    created_at: "",
  });

  const changeStatus = (status) => {
    switch (status) {
      case "Initial Draft":
        return "Create Initial Observation";

      case "Initial Draft Admin Approve":
        return "Approve Initial Observation";

      case "Initial Draft Qa Approve":
        return "Business Approval For Draft Report";

      case "Approved Draft":
        return "Finalize Observation";

      case "Finalized Draft Qa Approve":
        return "QA Approval For Final Report";

      case "Finalized Draft Admin Approve":
        return "Admin Approval For Final Report";

        case "Closed":
          return "Closed";

      case "Rejected":
        return "Rejected";

      default:
        return status;
    }
  };

  const closeObservation = () => {
    if (!preliminary_close_observation) {
      setErrors("Please Fill the Field");
      setPreliminaryCloseObservationError(
        !preliminary_close_observation ? true : false
      );
    } else {
      updateData(
        `/api/audit_engagement/observation/${obejctionId}`,
        {
          audit_id: id,
          objective,
          short_description,
          rating,
          recommendation,
          exposure_to_losses,
          potential_impact,
          likelyhood,
          risks: { risk1: risk1, risk2: risk2, risk3: risk3 },
          action_plan,
          summary,
          preparer_id,
          reviewer_id,
          assign_to_id,
          assign_to_id,
          management_response_due_date,
          response_audit,
          response_audit_due_date,
          preliminary_close_observation,
          workpapertestcase_id: Object.values(item).flat(),
          data: { subObservations },
          action_plan,
          due_date: startdate,
          secondary_assign_to_id,
          status: "Closed",
        },
        navigate,
        setErrors
      );
    }
  };

  const handleFilterChange = (filterKey, e) => {
    setFilters({ ...filters, [filterKey]: e.target.value });
  };
  

  const handlepopup = () => {
    setIsModalOpen(true);
  };

  const handleAddComment = () => {
    if (inputValue.trim()) {
      const currentDateTime = new Date();
      const formattedDate = currentDateTime.toLocaleDateString("en-GB", {
        day: "2-digit",
        month: "short",
        year: "2-digit",
      });
      const formattedTime = currentDateTime.toLocaleTimeString("en-GB", {
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      });

      const newComment = {
        text: inputValue,
        date: formattedDate,
        time: formattedTime,
      };

      setComments([...comments, newComment]);
      setTaskData([...taskData, newComment]);

      justPostData(
        `/api/system/comments/`,
        {
          user_id: localStorage.getItem("user_id"),
          task_id: APIQData,
          text: inputValue,
        },
        navigate,
        setErrors
      );

      // window.location.reload();
      setInputValue("");
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleAddComment();
    }
  };
  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };
  const daysSince = (dateString) => {
    const date = new Date(dateString);
    const now = new Date();
    const timeDiff = now - date;
    const daysDiff = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
    if (daysDiff <= 0) return "today";
    return daysDiff === Number.NaN ? 0 : "" + " days ago";
  };

  const handleSave = (index) => {
    const updatedList = [...inputList];
    updatedList[index].isEditing = false;
    setInputList(updatedList);

    // Update the subObservations array
    const updatedObservations = updatedList.map((input) => input.value);
    setSubObservations(updatedObservations);
  };

  const handleCancel = (index) => {
    const updatedList = inputList.filter((_, i) => i !== index);
    setInputList(updatedList);

    // Update the subObservations array
    const updatedObservations = updatedList.map((input) => input.value);
    setSubObservations(updatedObservations);
  };

  const handleRemove = (index) => {
    const updatedList = inputList.filter((_, i) => i !== index);
    setInputList(updatedList);

    const updatedObservations = subObservations.filter((_, i) => i !== index);
    setSubObservations(updatedObservations);
  };

  const handleChange = (e, index) => {
    const updatedList = [...inputList];
    updatedList[index].value = e.target.value;
    setInputList(updatedList);
  };

  function convertDate(dateString) {
    if (dateString) {
      // Parse the input date string into a Date object
      const date = new Date(dateString);

      // Subtract 15 days from the date
      

      // Format the date as 'YYYY-MM-DD'
      const formattedDate = date.toISOString().split("T")[0];

      return formattedDate;
    }
  }

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [fileToDelete, setFileToDelete] = useState(null);

  const handleDeleteModalOpen = (fileId) => {
    setFileToDelete(fileId);
    setIsDeleteModalOpen(true);
  };

  const handleDeleteModalClose = () => {
    setIsDeleteModalOpen(false);
    setFileToDelete(null);
  };

  const confirmDeleteFile = () => {
    if (fileToDelete) {
      removeFile(fileToDelete);
      handleDeleteModalClose();
    }
  };

  const removeFile = (file_id) => {
    deleteData(
      `/api/audit_engagement/observationfile/${file_id}`,
      navigate,
      setErrors,
      `/AuditExecution/${id}/Reporting/ReportingObservationUpdate/${reportId}/${obejctionId}/`
    );
    // window.location.reload();
  };

  const handleAdd = () => {
    setInputList([...inputList, { value: "", isEditing: true }]);
  };

  // const bactTo = (desiredPart) => {
  //   console.log(desiredPart);

  //   switch (desiredPart) {
  //     case "Observation Creation":
  //       navigate(
  //         `/AuditExecution/${id}/Reporting/ReportingObservationCreation/${reportId}/`
  //       );
  //       break;

  //     case "Observation Update":
  //       navigate(
  //         `/AuditExecution/${id}/Reporting/ReportingObservationUpdate/${reportId}/`
  //       );

  //       break;

  //     case "Report Creation":
  //       navigate(
  //         `/AuditExecution/${id}/Reporting/ReportingReportCreation/${reportId}/`
  //       );
  //       break;

  //     default:
  //       return "Invalid Task";
  //   }
  // };

  const bactTo = (desiredPart) => {
    switch (desiredPart) {
      case "InitialObservation":
        navigate(`/AuditExecution/${id}/Reporting/InitialObservation`);
        break;
      case `ReportingObservationCreation/${obejctionId}`:
        navigate(
          `/AuditExecution/${id}/Reporting/ReportingObservationCreation`
        );
        break;

      case "QAApprovalInitialObservation":
        navigate(
          `/AuditExecution/${id}/Reporting/QAApprovalInitialObservation`
        );
        break;

      case "Schedule Closing Meeting":
        navigate(`/AuditExecution/${id}/Reporting/ScheduleClosingMeeting`);
        break;

      case "BusinessOwnerApproval":
        navigate(`/AuditExecution/${id}/Reporting/BusinessOwnerApproval`);
        break;

      case "FinalizeObservation":
        navigate(`/AuditExecution/${id}/Reporting/FinalizeObservation`);
        break;

      case "GenerateReportList":
        navigate(`/AuditExecution/${id}/Reporting/GenerateReportList`);
        break;

      case "Generate Report More Details":
        navigate(`/AuditExecution/${id}/Reporting/GenerateReportMoreDetails`);
        break;

      case "qaAPprovalFinalizeObservation":
        navigate(
          `/AuditExecution/${id}/Reporting/QaApprovalFinalizeObservation`
        );
        break;

      case "AuditDirectorApproval":
        navigate(`/AuditExecution/${id}/Reporting/AuditDirectorApproval`);
        break;

      case "PublishReport":
        navigate(`/AuditExecution/${id}/Reporting/PublishReport`);
        break;

      case "ReportingObservationCreation":
        navigate(`/AuditExecution/${id}/Reporting/`);
        break;

      default:
        navigate(`/AuditExecution/${id}/Reporting/`);
    }
  };

  const showType = (desiredPart) => {
    switch (desiredPart) {
      case "InitialObservation":
        return "Initial  Observation";
        break;

      case "QAApprovalInitialObservation":
        return "QA Approval Initial Observation";

        break;

      case "BusinessOwnerApproval":
        return "BusinessOwner Approval";

        break;
      case "FinalizeObservation":
        return "Finalize Observation";

        break;
      case "GenerateReportList":
        return "GenerateReport List";

        break;
      case "qaAPprovalFinalizeObservation":
        return "QA Approval Finalize Observation";

        break;

      default:
        return "Reporting";
    }
  };
  const UpdateObservationTest = () => {
    setEditMode(true);
    if (editMode) {
      
        updateData(
          `/api/audit_engagement/observation/${obejctionId}`,
          {
            audit_id: id,
            objective,
            short_description,
            rating,
            recommendation,
            exposure_to_losses,
            potential_impact,
            likelyhood,
            risks: { risk1: risk1, risk2: risk2, risk3: risk3 },
            action_plan,
            summary,
            preparer_id,
            reviewer_id,
            assign_to_id,
            assign_to_id,
            management_response_due_date,
            response_audit,
            response_audit_due_date,
            preliminary_close_observation,
            workpapertestcase_id: Object.values(item).flat(),
            data: { subObservations },
            action_plan,
            due_date: startdate,
            secondary_assign_to_id,
            assign_to_business_teammate_id: assign_to_business_teammate_id
              ? assign_to_business_teammate_id
              : "",
          },
          navigate,
          setErrors
        );

        updateData(
          `/api/audit_engagement/audit/${id}`,
          {
            risk_assessment: auditData?.risk_assessment?.id,
            audit_entity_id: auditData?.audit_entity?.id,
            auditors_id: auditData?.auditors.map((auditor) => auditor.id),
            business_users_id: auditData?.business_users.map(
              (business_users) => business_users.id
            ),
            actual_start_date: auditData?.actual_start_date,
            actual_end_date: auditData?.actual_end_date,
            planned_start_date: auditData?.audit_event?.planned_start_date,
            planned_end_date: auditData?.audit_event?.planned_end_date,
            audit_scope: auditData?.audit_scope,
            audit_phases: auditData?.audit_phases,
            other_relevant_information: auditData?.other_relevant_information,
            phase: "Reporting Complete",
            qa_id: auditData?.qa ? auditData?.qa.id : "",
          },
          navigate,
          setErrors
        );

        updateData(
          `/api/system/task/${APIQData}`,
          {
            status: "in progress",
            title: taskTitle,
          },
          navigate,
          setErrors
        );
     
    }
  };

  const UpdateObservationTestId = (task) => {
   
      if (
        observationUrl === "BusinessOwnerApproval" &&
        assign_to_business_teammate_id
      ) {
        updateData(
          `/api/audit_engagement/observation/${obejctionId}`,
          {
            audit_id: id,
            objective,
            short_description,
            rating,
            recommendation,
            exposure_to_losses,
            potential_impact,
            likelyhood,
            risks: { risk1: risk1, risk2: risk2, risk3: risk3 },
            action_plan,
            summary,
            preparer_id,
            reviewer_id,
            assign_to_id,
            assign_to_id,
            management_response_due_date,
            response_audit,
            response_audit_due_date,
            preliminary_close_observation,
            workpapertestcase_id: Object.values(item).flat(),
            data: { subObservations },
            action_plan,
            due_date: startdate,
            secondary_assign_to_id,
            assign_to_business_teammate_id: assign_to_business_teammate_id
              ? assign_to_business_teammate_id
              : "",
          },
          navigate,
          setErrors
        );
        updateData(
          `/api/audit_engagement/audit/${id}`,
          {
            risk_assessment: auditData?.risk_assessment?.id,
            audit_entity_id: auditData?.audit_entity?.id,
            auditors_id: auditData?.auditors.map((auditor) => auditor.id),
            business_users_id: auditData?.business_users.map(
              (business_users) => business_users.id
            ),
            actual_start_date: auditData?.actual_start_date,
            actual_end_date: auditData?.actual_end_date,
            planned_start_date: auditData?.audit_event?.planned_start_date,
            planned_end_date: auditData?.audit_event?.planned_end_date,
            audit_scope: auditData?.audit_scope,
            audit_phases: auditData?.audit_phases,
            other_relevant_information: auditData?.other_relevant_information,
            phase: "Reporting Complete",
            qa_id: auditData?.qa ? auditData?.qa.id : "",
          },
          navigate,
          setErrors
        );
      } else {
        updateData(
          `/api/audit_engagement/observation/${obejctionId}`,
          {
            audit_id: id,
            objective,
            short_description,
            rating,
            recommendation,
            exposure_to_losses,
            potential_impact,
            likelyhood,
            risks: { risk1: risk1, risk2: risk2, risk3: risk3 },
            action_plan,
            summary,
            preparer_id,
            reviewer_id,
            assign_to_id,
            assign_to_id,
            management_response_due_date,
            response_audit,
            response_audit_due_date,
            preliminary_close_observation,
            workpapertestcase_id: Object.values(item).flat(),
            data: { subObservations },
            action_plan,
            due_date: startdate,
            secondary_assign_to_id,
          },
          navigate,
          setErrors
        );
        updateData(
          `/api/audit_engagement/audit/${id}`,
          {
            risk_assessment: auditData?.risk_assessment?.id,
            audit_entity_id: auditData?.audit_entity?.id,
            auditors_id: auditData?.auditors.map((auditor) => auditor.id),
            business_users_id: auditData?.business_users.map(
              (business_users) => business_users.id
            ),
            actual_start_date: auditData?.actual_start_date,
            actual_end_date: auditData?.actual_end_date,
            planned_start_date: auditData?.audit_event?.planned_start_date,
            planned_end_date: auditData?.audit_event?.planned_end_date,
            audit_scope: auditData?.audit_scope,
            audit_phases: auditData?.audit_phases,
            other_relevant_information: auditData?.other_relevant_information,
            phase: "Reporting Complete",
            qa_id: auditData?.qa ? auditData?.qa.id : "",
          },
          navigate,
          setErrors
        );
      }

      updateData(
        `/api/system/task/${APIQData}`,
        {
          status: task,
          title: taskTitle,
        },
        navigate,
        setErrors
      );
    
  };

  const UpdateObservationTask = () => {
    if (businessRole) {
      setEditModeBusiness(false);
    } else {
      if (qaRole) {
        setEditMode(false);
      } else {
        setEditMode(true);
      }
    }
    if (editMode) {
        if (
          observationUrl === "BusinessOwnerApproval" &&
          assign_to_business_teammate_id
        ) {
          updateData(
            `/api/audit_engagement/observation/${obejctionId}`,
            {
              audit_id: id,
              objective,
              short_description,
              rating,
              recommendation,
              exposure_to_losses,
              potential_impact,
              likelyhood,
              risks: { risk1: risk1, risk2: risk2, risk3: risk3 },
              action_plan,
              summary,
              preparer_id,
              reviewer_id,
              assign_to_id,
              assign_to_id,
              management_response_due_date,
              response_audit,
              response_audit_due_date,
              preliminary_close_observation,
              workpapertestcase_id: Object.values(item).flat(),
              data: { subObservations },
              action_plan,
              due_date: startdate,
              secondary_assign_to_id,
              assign_to_business_teammate_id: assign_to_business_teammate_id
                ? assign_to_business_teammate_id
                : "",
            },
            navigate,
            setErrors
          );
          updateData(
            `/api/audit_engagement/audit/${id}`,
            {
              risk_assessment: auditData?.risk_assessment?.id,
              audit_entity_id: auditData?.audit_entity?.id,
              auditors_id: auditData?.auditors.map((auditor) => auditor.id),
              business_users_id: auditData?.business_users.map(
                (business_users) => business_users.id
              ),
              actual_start_date: auditData?.actual_start_date,
              actual_end_date: auditData?.actual_end_date,
              planned_start_date: auditData?.audit_event?.planned_start_date,
              planned_end_date: auditData?.audit_event?.planned_end_date,
              audit_scope: auditData?.audit_scope,
              audit_phases: auditData?.audit_phases,
              other_relevant_information: auditData?.other_relevant_information,
              phase: "Reporting Complete",
              qa_id: auditData?.qa ? auditData?.qa.id : "",
            },
            navigate,
            setErrors
          );
        } else {
          updateData(
            `/api/audit_engagement/observation/${obejctionId}`,
            {
              audit_id: id,
              objective,
              short_description,
              rating,
              recommendation,
              exposure_to_losses,
              potential_impact,
              likelyhood,
              risks: { risk1: risk1, risk2: risk2, risk3: risk3 },
              action_plan,
              summary,
              preparer_id,
              reviewer_id,
              assign_to_id,
              assign_to_id,
              management_response_due_date,
              response_audit,
              response_audit_due_date,
              preliminary_close_observation,
              workpapertestcase_id: Object.values(item).flat(),
              data: { subObservations },
              action_plan,
              due_date: startdate,
              secondary_assign_to_id,
            },
            navigate,
            setErrors
          );
          updateData(
            `/api/audit_engagement/audit/${id}`,
            {
              risk_assessment: auditData?.risk_assessment?.id,
              audit_entity_id: auditData?.audit_entity?.id,
              auditors_id: auditData?.auditors.map((auditor) => auditor.id),
              business_users_id: auditData?.business_users.map(
                (business_users) => business_users.id
              ),
              actual_start_date: auditData?.actual_start_date,
              actual_end_date: auditData?.actual_end_date,
              planned_start_date: auditData?.audit_event?.planned_start_date,
              planned_end_date: auditData?.audit_event?.planned_end_date,
              audit_scope: auditData?.audit_scope,
              audit_phases: auditData?.audit_phases,
              other_relevant_information: auditData?.other_relevant_information,
              phase: "Reporting Complete",
              qa_id: auditData?.qa ? auditData?.qa.id : "",
            },
            navigate,
            setErrors
          );
        }
      
    }

    if (!editModeBusiness) {
      if (
        secondary_assign_to_id &&
        action_plan &&
        management_response_due_date &&
        assign_to_id
      ) {
        if (
          observationUrl === "BusinessOwnerApproval" &&
          assign_to_business_teammate_id
        ) {
          updateData(
            `/api/audit_engagement/observation/${obejctionId}`,
            {
              audit_id: id,
              objective,
              short_description,
              rating,
              recommendation,
              exposure_to_losses,
              potential_impact,
              likelyhood,
              risks: { risk1: risk1, risk2: risk2, risk3: risk3 },
              action_plan,
              summary,
              preparer_id,
              reviewer_id,
              assign_to_id,
              management_response_due_date,
              response_audit,
              response_audit_due_date,
              preliminary_close_observation,
              workpapertestcase_id: Object.values(item).flat(),
              data: { subObservations },
              action_plan,
              due_date: startdate,
              secondary_assign_to_id,
              assign_to_business_teammate_id: assign_to_business_teammate_id
                ? assign_to_business_teammate_id
                : "",
            },
            navigate,
            setErrors
          );
          updateData(
            `/api/audit_engagement/audit/${id}`,
            {
              risk_assessment: auditData?.risk_assessment?.id,
              audit_entity_id: auditData?.audit_entity?.id,
              auditors_id: auditData?.auditors.map((auditor) => auditor.id),
              business_users_id: auditData?.business_users.map(
                (business_users) => business_users.id
              ),
              actual_start_date: auditData?.actual_start_date,
              actual_end_date: auditData?.actual_end_date,
              planned_start_date: auditData?.audit_event?.planned_start_date,
              planned_end_date: auditData?.audit_event?.planned_end_date,
              audit_scope: auditData?.audit_scope,
              audit_phases: auditData?.audit_phases,
              other_relevant_information: auditData?.other_relevant_information,
              phase: "Reporting Complete",
              qa_id: auditData?.qa ? auditData?.qa.id : "",
            },
            navigate,
            setErrors
          );
        } else {
          updateData(
            `/api/audit_engagement/observation/${obejctionId}`,
            {
              audit_id: id,
              objective,
              short_description,
              rating,
              recommendation,
              exposure_to_losses,
              potential_impact,
              likelyhood,
              risks: { risk1: risk1, risk2: risk2, risk3: risk3 },
              action_plan,
              summary,
              preparer_id,
              reviewer_id,
              assign_to_id,
              assign_to_id,
              management_response_due_date,
              response_audit,
              response_audit_due_date,
              preliminary_close_observation,
              workpapertestcase_id: Object.values(item).flat(),
              data: { subObservations },
              action_plan,
              due_date: startdate,
              secondary_assign_to_id,
            },
            navigate,
            setErrors
          );
          updateData(
            `/api/audit_engagement/audit/${id}`,
            {
              risk_assessment: auditData?.risk_assessment?.id,
              audit_entity_id: auditData?.audit_entity?.id,
              auditors_id: auditData?.auditors.map((auditor) => auditor.id),
              business_users_id: auditData?.business_users.map(
                (business_users) => business_users.id
              ),
              actual_start_date: auditData?.actual_start_date,
              actual_end_date: auditData?.actual_end_date,
              planned_start_date: auditData?.audit_event?.planned_start_date,
              planned_end_date: auditData?.audit_event?.planned_end_date,
              audit_scope: auditData?.audit_scope,
              audit_phases: auditData?.audit_phases,
              other_relevant_information: auditData?.other_relevant_information,
              phase: "Reporting Complete",
              qa_id: auditData?.qa ? auditData?.qa.id : "",
            },
            navigate,
            setErrors
          );
        }
      } else {
        setErrors("Please Fill Required Field");
        setLevel2OwnerError(secondary_assign_to_id ? false : true);
        setActionDueDateError(management_response_due_date ? false : true);
        setActionPlanError(action_plan ? false : true);
        setLevel1OwnerError(assign_to_id ? false : true);
      }
    }
  };

  const submithandler = () => {
    updateData(
      `/api/system/task/${APIQData}`,
      {
        title: "update data",
        status: "review",
      },
      navigate,
      setErrors
    );
  };

  const Donehandler = () => {
    updateData(
      `/api/system/task/${APIQData}`,
      {
        title: "update data",
        status: "done",
      },
      navigate,
      setErrors
    );
  };

  const DeleteObservationTest = (e) => {
    e.preventDefault();

    deleteData(
      `/api/audit_engagement/observation/${obejctionId}`,
      navigate,
      setErrors,
      `/AuditExecution/${id}/Reporting/ReportingObservationCreation/`
    );
  };

  const handleCancelClick = () => {
    setEditMode(false);
    window.location.reload();
  };

  const handleAcceptObv = (tastId, approvedBy) => {
    if (
      observationUrl === "BusinessOwnerApproval" &&
      assign_to_business_teammate_id
    ) {

        if (tastId) {
          updateData(
            `/api/system/task/${tastId}`,
            {
              status: "done",
              title: taskTitle,
            },
            navigate,
            setErrors
          );
        }

        updateData(
          `/api/audit_engagement/observation/${obejctionId}`,
          {
            audit_id: id,
            objective,
            short_description,
            rating,
            recommendation,
            exposure_to_losses,
            potential_impact,
            likelyhood,
            risks: { risk1: risk1, risk2: risk2, risk3: risk3 },
            action_plan,
            summary,
            preparer_id,
            reviewer_id,
            assign_to_id,
            assign_to_id,
            management_response_due_date,
            response_audit,
            response_audit_due_date,
            preliminary_close_observation,
            workpapertestcase_id: Object.values(item).flat(),
            data: { subObservations },
            action_plan,
            due_date: startdate,
            secondary_assign_to_id,
            status: approvedBy,
            assign_to_business_teammate_id: assign_to_business_teammate_id
              ? assign_to_business_teammate_id
              : "",
          },
          navigate,
          setErrors
        );
        updateData(
          `/api/audit_engagement/audit/${id}`,
          {
            risk_assessment: auditData?.risk_assessment?.id,
            audit_entity_id: auditData?.audit_entity?.id,
            auditors_id: auditData?.auditors.map((auditor) => auditor.id),
            business_users_id: auditData?.business_users.map(
              (business_users) => business_users.id
            ),
            actual_start_date: auditData?.actual_start_date,
            actual_end_date: auditData?.actual_end_date,
            planned_start_date: auditData?.audit_event?.planned_start_date,
            planned_end_date: auditData?.audit_event?.planned_end_date,
            audit_scope: auditData?.audit_scope,
            audit_phases: auditData?.audit_phases,
            other_relevant_information: auditData?.other_relevant_information,
            phase: "Reporting Complete",
            qa_id: auditData?.qa ? auditData?.qa.id : "",
          },
          navigate,
          setErrors
        );
     
    } else {

        if (tastId) {
          updateData(
            `/api/system/task/${tastId}`,
            {
              status: "done",
              title: taskTitle,
            },
            navigate,
            setErrors
          );
        }

        updateData(
          `/api/audit_engagement/observation/${obejctionId}`,
          {
            audit_id: id,
            objective,
            short_description,
            rating,
            recommendation,
            exposure_to_losses,
            potential_impact,
            likelyhood,
            risks: { risk1: risk1, risk2: risk2, risk3: risk3 },
            action_plan,
            summary,
            preparer_id,
            reviewer_id,
            assign_to_id,
            assign_to_id,
            management_response_due_date,
            response_audit,
            response_audit_due_date,
            preliminary_close_observation,
            workpapertestcase_id: Object.values(item).flat(),
            data: { subObservations },
            action_plan,
            due_date: startdate,
            secondary_assign_to_id,
            status: approvedBy,
          },
          navigate,
          setErrors
        );
        updateData(
          `/api/audit_engagement/audit/${id}`,
          {
            risk_assessment: auditData?.risk_assessment?.id,
            audit_entity_id: auditData?.audit_entity?.id,
            auditors_id: auditData?.auditors.map((auditor) => auditor.id),
            business_users_id: auditData?.business_users.map(
              (business_users) => business_users.id
            ),
            actual_start_date: auditData?.actual_start_date,
            actual_end_date: auditData?.actual_end_date,
            planned_start_date: auditData?.audit_event?.planned_start_date,
            planned_end_date: auditData?.audit_event?.planned_end_date,
            audit_scope: auditData?.audit_scope,
            audit_phases: auditData?.audit_phases,
            other_relevant_information: auditData?.other_relevant_information,
            phase: "Reporting Complete",
            qa_id: auditData?.qa ? auditData?.qa.id : "",
          },
          navigate,
          setErrors
        );
      
    }
  };

  const handleRejectObv = (e) => {
    e.preventDefault();
   
      updateData(
        `/api/system/task/${APIQData}`,
        {
          status: "done",
          title: taskTitle,
        },
        navigate,
        setErrors
      );

      updateData(
        `/api/audit_engagement/observation/${obejctionId}`,
        {
          audit_id: id,
          objective,
          short_description,
          rating,
          recommendation,
          exposure_to_losses,
          potential_impact,
          likelyhood,
          risks: { risk1: risk1, risk2: risk2, risk3: risk3 },
          action_plan,
          summary,
          preparer_id,
          reviewer_id,
          assign_to_id,
          assign_to_id,
          management_response_due_date,
          response_audit,
          response_audit_due_date,
          preliminary_close_observation,
          workpapertestcase_id: Object.values(item).flat(),
          data: { subObservations },
          action_plan,
          due_date: startdate,
          secondary_assign_to_id,
          status: "Closed",
        },
        navigate,
        setErrors
      );
      updateData(
        `/api/audit_engagement/audit/${id}`,
        {
          risk_assessment: auditData?.risk_assessment?.id,
          audit_entity_id: auditData?.audit_entity?.id,
          auditors_id: auditData?.auditors.map((auditor) => auditor.id),
          business_users_id: auditData?.business_users.map(
            (business_users) => business_users.id
          ),
          actual_start_date: auditData?.actual_start_date,
          actual_end_date: auditData?.actual_end_date,
          planned_start_date: auditData?.audit_event?.planned_start_date,
          planned_end_date: auditData?.audit_event?.planned_end_date,
          audit_scope: auditData?.audit_scope,
          audit_phases: auditData?.audit_phases,
          other_relevant_information: auditData?.other_relevant_information,
          phase: "Reporting Complete",
          qa_id: auditData?.qa ? auditData?.qa.id : "",
        },
        navigate,
        setErrors
      );
    
  };
  const handleReqChObv = (tastId, task) => {
    updateData(
      `/api/system/task/${tastId}`,
      {
        status: task,
        title: taskTitle,
      },
      navigate,
      setErrors
    );
  };

  const handleReqChObv1 = (tastId) => {
    updateData(
      `/api/system/task/${tastId}`,
      {
        status: "in progress",
        title: taskTitle,
      },
      navigate,
      setErrors
    );

    window.location.reload();
  };

  const handleSubmitObv = (e) => {
    e.preventDefault();
    updateData(
      `/api/system/task/${APIQData}`,
      {
        status: "review",
        title: taskTitle,
      },
      navigate,
      setErrors
    );
  };
  const handleSaveObv = (e) => {
    e.preventDefault();
  };

  const handleFileUpload = () => {
    if (!selectedFile || !fileObjective) {
      setErrors("Please Select Image And Fill Description");
    } else {
      const formData = new FormData();
      formData.append("observation_id", obejctionId);
      formData.append("text", fileObjective);
      formData.append("file", selectedFile);

      const xhr = new XMLHttpRequest();
      xhr.open(
        "POST",
        `${process.env.REACT_APP_API_URL}/api/audit_engagement/observationfile/`,
        true
      );

      xhr.upload.onprogress = (event) => {
        if (event.lengthComputable) {
          const percentComplete = Math.round(
            (event.loaded / event.total) * 100
          );
          console.log(
            `Progress: ${percentComplete}% (Loaded: ${event.loaded}, Total: ${event.total})`
          );
          setUploadPercentage(percentComplete);
        }
      };

      xhr.onload = () => {
        if (xhr.status === 201) {
          const response = JSON.parse(xhr.responseText);
          fetchData(
            `/api/audit_engagement/observationfile/?observation_id=${obejctionId}`,
            setFileData,
            setErrors
          );
        } else {
          setErrors(`Upload failed: ${xhr.statusText}`);
        }
      };

      xhr.onerror = () => {
        setErrors("An error occurred during the file upload.");
      };

      xhr.send(formData);

      setFileObjective("");
      setIsModalOpen(false);
    }
  };


  const handleCloseClick = () => {
    setIsModalOpen(false);
  };

  const [observationUrl, setObservationUrl] = useState("");

  useEffect(() => {
    const url = window.location.href;

    const parts = url.split("/");
    const observationValue = parts[parts.length - 2];

    setObservationUrl(observationValue);
  }, []);

  const options = [
    { label: " Financial", value: "Financial" },
    { label: "Operational", value: "Operational" },
    { label: "Strategic", value: "Strategic" },
    { label: "Compliance", value: "Compliance" },
    { label: "Reputational", value: "Reputational" },
  ];

  const PotetntialImpactOptions = [
    { label: "Low", value: 1 },
    { label: "Medium", value: 2 },
    { label: "High", value: 3 },
    { label: "VeryHigh", value: 4 },
    { label: "Critical", value: 5 },
  ];

  const LikeOptions = [
    { label: "Low", value: 1 },
    { label: "Medium", value: 2 },
    { label: "High", value: 3 },
    { label: "VeryHigh", value: 4 },
    { label: "Critical", value: 5 },
  ];

  const [auditData, setAuditData] = useState([]);
  useEffect(() => {
    fetchData(`/api/audit_engagement/audit/${id}/`, setAuditData, setErrors);
  }, [id]);

  const [isCommentVisible, setIsCommentVisible] = useState(false);

  const toggleCommentVisibility = () => {
    setIsCommentVisible(!isCommentVisible);
  };

  const [isPopupVisible, setPopupVisible] = useState(false);

  const handleRequestChanges = () => {
    setPopupVisible(true);
  };

  const handlePopupRequestChanges = () => {
    console.log(observationUrl)
    if (APIData) {
      switch (observationUrl) {
        case "InitialObservation":
          handleReqChObv(
            APIData.task_initial_admin_approval?.id,
            "in progress"
          );
          break;
        case "QAApprovalInitialObservation":
          handleReqChObv(APIData.task_initial_qa_approval?.id, "in progress");
          break;
        case "BusinessOwnerApproval":
          handleReqChObv(APIData.task_initial_business_approval?.id, "review");
          break;

        case "qaAPprovalFinalizeObservation":
          handleReqChObv(APIData.task_final_qa_approval?.id, "in progress");

          break;

        case "AuditDirectorApproval":
          handleReqChObv(APIData.task_final_admin_approval?.id, "review");

          break;
        default:
          setErrors("Somting went Wrong");
      }
    }

    setPopupVisible(false);
  };

  const handleClosePopup = () => {
    setPopupVisible(false);
  };

  return (
    <>
      {isDeleteModalOpen && (
        <div className="popupATP">
          <div className="subpopupATP">
            <p>Are you sure you want to delete this file?</p>
            <div className="subpopupBtnDiv">
              <button onClick={confirmDeleteFile}> Delete</button>
              <button onClick={handleDeleteModalClose}>Cancel</button>
            </div>
          </div>
        </div>
      )}

      {isPopupVisible && (
        <div className="popupObv">
          <div className="subpopupObv">
            <label>Please add commnet before requesting changes:</label>
            <div className="subpopupObvBtnDiv">
              <button onClick={handlePopupRequestChanges}>Ok</button>
              <button onClick={handleClosePopup}>Cancel</button>
            </div>
          </div>
        </div>
      )}

      {isModalOpen && (
        <div className="popUPModal">
          <div onClick={handleCloseClick} className="closeBtnModalDiv">
            <img src={cutbutton} />
          </div>

          <div className="lbl_txa">
            <div className="ap-top-div">
              <label>Image Description</label>
              <img src={createBtn} />
              <div className="fileUpBtn">
                <input
                  type="file"
                  accept="image/*"
                  onChange={(e) => {
                    const file = e.target.files[0];
                    if (file && file.type.startsWith("image/")) {
                      setSelectedFile(file);
                      setErrors("");
                    } else {
                      setErrors("Please upload only image files.");
                      e.target.value = "";
                    }
                  }}
                />
              </div>
            </div>
            <textarea
              type="text"
              placeholder="Description of the image"
              value={fileObjective}
              onChange={(e) => {
                setFileObjective(e.target.value);
              }}
            />
          </div>

          <div className="actionPlanBtnDiv">
            <h6>
              {selectedFile
                ? `Selected file: ${selectedFile.name}`
                : "No file selected"}
            </h6>
            <button className="actionPlanSubmitBtn" onClick={handleFileUpload}>
              Upload
            </button>
          </div>
        </div>
      )}

      {errors && <p className="errormessage">{errors}</p>}
      {successMessage && <p className="successmessage">{successMessage}</p>}

      <section className="navroutes">
      <RenderIcons homeRoutes={auditExecution} id ={id}/>
      <div
          className="breadback"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Breadcrumb />
          <div className="backBtnDiv">
            <button
              className="file_button1"
              onClick={() => bactTo(observationUrl)}
            >
              <img src={backlogo} style={{ width: "1.2vw" }} />
              <p>Back to {showType(observationUrl)}</p>
            </button>
          </div>
        </div>


        <div className="filter-container">
          <div className="filter">
            <div className="views">
              <h3 className="h2_interbold" >View Observation</h3>
            </div>

            <div className="btn-action-div">
              {APIData?.task_initial_admin_approval?.status === "todo" ? (
                <></>
              ) : APIData.task_initial_admin_approval?.status ===
                "in progress" ? (
                <></>
              ) : ["review"].includes(
                  APIData.task_initial_admin_approval?.status
                ) &&
                APIData.task_initial_admin_approval?.owner?.id == userId &&
                observationUrl === "InitialObservation" &&
                APIData?.status !== "Closed" ? (
                <>
                  <button
                    className="acceptObv"
                    onClick={(e) =>
                      handleAcceptObv(
                        APIData.task_initial_admin_approval?.id,
                        "Initial Draft Admin Approve"
                      )
                    }
                  >
                    Accept
                  </button>
                  <button className="rejectObv" onClick={handleRejectObv}>
                    Reject
                  </button>
                  <button
                    className="reqchObv"
                  
                    onClick={handleRequestChanges}
                  >
                    Request Changes
                  </button>
                </>
              ) : ["review"].includes(
                  APIData.task_initial_qa_approval?.status
                ) &&
                APIData.task_initial_qa_approval?.owner?.id == userId &&
                observationUrl === "QAApprovalInitialObservation" ? (
                <>
                  <button
                    className="acceptObv"
                    onClick={(e) =>
                      handleAcceptObv(
                        APIData.task_initial_qa_approval?.id,
                        "Initial Draft Qa Approve"
                      )
                    }
                  >
                    Accept
                  </button>
                  <button className="reqchObv" onClick={handleRequestChanges}>
                    Request Changes
                  </button>
                </>
              ) : ["in progress"].includes(
                  APIData.task_initial_business_approval?.status
                ) &&
                APIData.task_initial_business_approval?.owner?.id == userId &&
                observationUrl === "BusinessOwnerApproval" ? (
                <>
                  <button
                    className="acceptObv"
                    onClick={(e) =>
                      handleAcceptObv(
                        APIData.task_initial_business_approval?.id,
                        "Approved Draft"
                      )
                    }
                  >
                    Accept
                  </button>
                  <button className="reqchObv" onClick={handleRequestChanges}>
                    Request Changes
                  </button>
                </>
              ) : ["Approved Draft"].includes(APIData?.status) &&
                observationUrl === "FinalizeObservation" ? (
                <>
                  <button
                    className="acceptObv"
                    onClick={(e) => handleAcceptObv("", "Finalized Draft")}
                  >
                    Finalize
                  </button>
                </>
              ) : ["review"].includes(APIData.task_final_qa_approval?.status) &&
                APIData.task_final_qa_approval?.owner?.id == userId &&
                observationUrl === "qaAPprovalFinalizeObservation" ? (
                <>
                  <button
                    className="acceptObv"
                    onClick={(e) =>
                      handleAcceptObv(
                        APIData.task_final_qa_approval?.id,
                        "Finalized Draft Admin Approve"
                      )
                    }
                  >
                    Accept
                  </button>
                  <button className="reqchObv" onClick={handleRequestChanges}>
                    Request Changes
                  </button>{" "}
                </>
              ) : ["in progress"].includes(
                  APIData.task_final_admin_approval?.status
                ) &&
                APIData.task_final_admin_approval?.owner?.id == userId &&
                observationUrl === "AuditDirectorApproval" ? (
                <>
                  <button
                    className="acceptObv"
                    onClick={(e) =>
                      handleAcceptObv(
                        APIData.task_final_admin_approval?.id,
                        "Published"
                      )
                    }
                  >
                    Published
                  </button>
                  <button className="reqchObv" onClick={handleRequestChanges}>
                    Request Changes
                  </button>{" "}
                </>
              ) : null}
            </div>
          </div>
        </div>

     

        <div className="backToReport">
        {qaRole ? (
            <></>
          ) : businessRole || APIData?.task_initial_business_approval?.status !== "in progress" ? (
      
            
            <div
              style={{
                display: !showbtns ? "flex" : "none",
                justifyContent: "space-between",
                alignItems: "center",
                zIndex:100,
              }}
            >
              <>

              {!editMode && APIData?.task_initial_business_approval?.status == "in progress" || APIData?.task_final_admin_approval?.status == "in progress" && observationUrl == "BusinessOwnerApproval" || observationUrl == "AuditDirectorApproval"
                   && APIData?.task_initial_business_approval?.assigned_to?.id ==
                      userId && (
                      <button
                        onClick={() => UpdateObservationTestId("in progress")}
                        className="user-submit-obv"
                      >
                        Submit
                      </button>
                    )}

                <div
                  className="createuser"
                  onClick={() => UpdateObservationTask()}
                 
                >
                  <button
                    style={{
                      marginRight: "1vw",
                      border: "none",
                      background: "transparent",
                    }}
                  >
                    {editModeBusiness ? (
                      <img
                        src={EditIcon}
                        alt="Edit"
                        style={{ width: "2vw" }}
                        title="Edit"
                      />
                    ) : (
                      <img
                        src={SaveIcon}
                        alt="Save"
                        style={{ width: "2vw" }}
                        title="Save"
                      />
                    )}
                  </button>
                </div>

                {editModeBusiness ? (
                  <div
                    className="createuser"
                    onClick={(e) => DeleteObservationTest(e)}
                  >
                    <button
                      style={{
                        marginRight: "1vw",
                        border: "none",
                        background: "transparent",
                      }}
                    >
                      <img
                        src={DeleteIcon}
                        alt="Save"
                        style={{ width: "2vw" }}
                      />
                    </button>
                  </div>
                ) : (
                  <div
                    className="createuser"
                    onClick={() => handleCancelClick()}
                  >
                    <button
                      style={{
                        marginRight: "1vw",
                        border: "none",
                        background: "transparent",
                      }}
                    >
                      <img
                        src={CloseIcon}
                        alt="Save"
                        style={{ width: "2vw" }}
                      />
                    </button>
                  </div>
                )}
              </>
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",

              }}
            >
              {APIData.task_initial_admin_approval?.status === "in progress" ? (
                <>
                  {!editMode &&
                    APIData?.task_initial_admin_approval?.assigned_to?.id ==
                      userId && (
                      <button
                        onClick={() => UpdateObservationTestId("review")}
                        className="user-submit-obv"
                      >
                        Submit
                      </button>
                    )}
                  <div
                    className="createuser"
                    onClick={() => UpdateObservationTask()}
                  >
                    <button
                      style={{
                        marginRight: "1vw",
                        border: "none",
                        background: "transparent",
                      }}
                    >
                      {!editMode ? (
                        <img
                          src={EditIcon}
                          alt="Edit"
                          style={{ width: "2vw" }}
                          title="Edit"
                        />
                      ) : (
                        <img
                          src={SaveIcon}
                          alt="Save"
                          style={{ width: "2vw" }}
                          title="Save"
                        />
                      )}
                    </button>
                  </div>

                  {!editMode ? (
                    <div
                      className="createuser"
                      onClick={(e) => DeleteObservationTest(e)}
                    >
                      <button
                        style={{
                          marginRight: "1vw",
                          border: "none",
                          background: "transparent",
                        }}
                      >
                        <img
                          src={DeleteIcon}
                          alt="Save"
                          style={{ width: "2vw" }}
                        />
                      </button>
                    </div>
                  ) : (
                    <div
                      className="createuser"
                      onClick={() => handleCancelClick()}
                    >
                      <button
                        style={{
                          marginRight: "1vw",
                          border: "none",
                          background: "transparent",
                        }}
                      >
                        <img
                          src={CloseIcon}
                          alt="Save"
                          style={{ width: "2vw" }}
                        />
                      </button>
                    </div>
                  )}
                </>
              ) : null}

              {APIData.task_final_admin_approval?.status === "review" &&
              observationUrl === "AuditDirectorApproval" ? (
                <>
                  {!editMode &&
                    APIData?.task_final_admin_approval?.assigned_to?.id ==
                      userId && (
                      <button
                        onClick={() => UpdateObservationTestId("in progress")}
                        className="user-submit-obv"
                      >
                        Submit
                      </button>
                    )}
                  <div
                    className="createuser"
                    onClick={() => UpdateObservationTask("in progress")}
                  >
                    <button
                      style={{
                        marginRight: "1vw",
                        border: "none",
                        background: "transparent",
                      }}
                    >
                      {!editMode ? (
                        <img
                          src={EditIcon}
                          alt="Edit"
                          style={{ width: "2vw" }}
                          title="Edit"
                        />
                      ) : (
                        <img
                          src={SaveIcon}
                          alt="Save"
                          style={{ width: "2vw" }}
                          title="Save"
                        />
                      )}
                    </button>
                  </div>

                  {!editMode ? (
                    <div
                      className="createuser"
                      onClick={(e) => DeleteObservationTest(e)}
                    >
                      <button
                        style={{
                          marginRight: "1vw",
                          border: "none",
                          background: "transparent",
                        }}
                      >
                        <img
                          src={DeleteIcon}
                          alt="Save"
                          style={{ width: "2vw" }}
                        />
                      </button>
                    </div>
                  ) : (
                    <div
                      className="createuser"
                      onClick={() => handleCancelClick()}
                    >
                      <button
                        style={{
                          marginRight: "1vw",
                          border: "none",
                          background: "transparent",
                        }}
                      >
                        <img
                          src={CloseIcon}
                          alt="Save"
                          style={{ width: "2vw" }}
                        />
                      </button>
                    </div>
                  )}
                </>
              ) : null}

              {APIData.task_initial_business_approval?.status === "review" &&
              observationUrl === "BusinessOwnerApproval" ? (
                <>
                  {!editMode &&
                    APIData?.task_initial_business_approval?.assigned_to?.id ==
                      userId && (
                      <button
                        onClick={() => UpdateObservationTestId("in progress")}
                        className="user-submit-obv"
                      >
                        Submit
                      </button>
                    )}
                  <div
                    className="createuser"
                    onClick={() => UpdateObservationTask()}
                  >
                    <button
                      style={{
                        marginRight: "1vw",
                        border: "none",
                        background: "transparent",
                      }}
                    >
                      {!editMode ? (
                        <img
                          src={EditIcon}
                          alt="Edit"
                          style={{ width: "2vw" }}
                          title="Edit"
                        />
                      ) : (
                        <img
                          src={SaveIcon}
                          alt="Save"
                          style={{ width: "2vw" }}
                          title="Save"
                        />
                      )}
                    </button>
                  </div>

                  {!editMode ? (
                    <div
                      className="createuser"
                      onClick={(e) => DeleteObservationTest(e)}
                    >
                      <button
                        style={{
                          marginRight: "1vw",
                          border: "none",
                          background: "transparent",
                        }}
                      >
                        <img
                          src={DeleteIcon}
                          alt="Save"
                          style={{ width: "2vw" }}
                        />
                      </button>
                    </div>
                  ) : (
                    <div
                      className="createuser"
                      onClick={() => handleCancelClick()}
                    >
                      <button
                        style={{
                          marginRight: "1vw",
                          border: "none",
                          background: "transparent",
                        }}
                      >
                        <img
                          src={CloseIcon}
                          alt="Save"
                          style={{ width: "2vw" }}
                        />
                      </button>
                    </div>
                  )}
                </>
              ) : null}

              {APIData.task_initial_qa_approval?.status === "in progress" &&
              observationUrl === "QAApprovalInitialObservation" ? (
                <>
                  {!editMode &&
                    APIData?.task_initial_qa_approval?.assigned_to?.id ==
                      userId && (
                      <button
                        onClick={() => UpdateObservationTestId("review")}
                        className="user-submit-obv"
                      >
                        Submit
                      </button>
                    )}
                  <div
                    className="createuser"
                    onClick={() => UpdateObservationTask()}
                  >
                    <button
                      style={{
                        marginRight: "1vw",
                        border: "none",
                        background: "transparent",
                      }}
                    >
                      {!editMode ? (
                        <img
                          src={EditIcon}
                          alt="Edit"
                          style={{ width: "2vw" }}
                          title="Edit"
                        />
                      ) : (
                        <img
                          src={SaveIcon}
                          alt="Save"
                          style={{ width: "2vw" }}
                          title="Save"
                        />
                      )}
                    </button>
                  </div>

                  {!editMode ? (
                    <div
                      className="createuser"
                      onClick={(e) => DeleteObservationTest(e)}
                    >
                      <button
                        style={{
                          marginRight: "1vw",
                          border: "none",
                          background: "transparent",
                        }}
                      >
                        <img
                          src={DeleteIcon}
                          alt="Save"
                          style={{ width: "2vw" }}
                        />
                      </button>
                    </div>
                  ) : (
                    <div
                      className="createuser"
                      onClick={() => handleCancelClick()}
                    >
                      <button
                        style={{
                          marginRight: "1vw",
                          border: "none",
                          background: "transparent",
                        }}
                      >
                        <img
                          src={CloseIcon}
                          alt="Save"
                          style={{ width: "2vw" }}
                        />
                      </button>
                    </div>
                  )}
                </>
              ) : null}

              {APIData.task_final_qa_approval?.status === "in progress" &&
              observationUrl === "qaAPprovalFinalizeObservation" ? (
                <>
                  {!editMode &&
                    APIData?.task_final_qa_approval?.assigned_to?.id ==
                      userId && (
                      <button
                        onClick={() => UpdateObservationTestId("review")}
                        className="user-submit-obv"
                      >
                        Submit
                      </button>
                    )}
                  <div
                    className="createuser"
                    onClick={() => UpdateObservationTask()}
                  >
                    <button
                      style={{
                        marginRight: "1vw",
                        border: "none",
                        background: "transparent",
                      }}
                    >
                      {!editMode ? (
                        <img
                          src={EditIcon}
                          alt="Edit"
                          style={{ width: "2vw" }}
                          title="Edit"
                        />
                      ) : (
                        <img
                          src={SaveIcon}
                          alt="Save"
                          style={{ width: "2vw" }}
                          title="Save"
                        />
                      )}
                    </button>
                  </div>

                  {!editMode ? (
                    <div
                      className="createuser"
                      onClick={(e) => DeleteObservationTest(e)}
                    >
                      <button
                        style={{
                          marginRight: "1vw",
                          border: "none",
                          background: "transparent",
                        }}
                      >
                        <img
                          src={DeleteIcon}
                          alt="Save"
                          style={{ width: "2vw" }}
                        />
                      </button>
                    </div>
                  ) : (
                    <div
                      className="createuser"
                      onClick={() => handleCancelClick()}
                    >
                      <button
                        style={{
                          marginRight: "1vw",
                          border: "none",
                          background: "transparent",
                        }}
                      >
                        <img
                          src={CloseIcon}
                          alt="Save"
                          style={{ width: "2vw" }}
                        />
                      </button>
                    </div>
                  )}
                </>
              ) : null}
            </div>
          )}
        </div>


        {loading ? (
          <div className="spinner-bounce-loader">
          <BounceLoader color="#a6fafa"/> 
          </div>
            
          ): APIData.id === 0 ? (
            <p className="data-error-text">No data found</p> 
          ) : (
        <div
          className="create_observation_con"
          style={{ width: tableSize ? "79.5vw" : "90vw" }}
        >
          <div className="createuser">
            {APIData?.task_initial_admin_approval?.status === "done" &&
              APIData?.status === "Initial Draft Admin Approve" &&
              observationUrl === "InitialObservation" && (
                <button
                  className="create_obsr_button"
                  style={{
                    backgroundColor: "grey",
                    position: "absolute",
                    bottom: "10%",
                    right: "5%",
                  }}
                >
                  {APIData.status == "Closed" ? "Closed" : APIData.status == "Rejected" ? "Rejected": "Accepted"}
                </button>
              )}

            {APIData?.task_initial_qa_approval?.status === "done" &&
              APIData?.status === "Initial Draft Qa Approve" &&
              observationUrl === "QAApprovalInitialObservation" && (
                <button
                  className="create_obsr_button"
                  style={{
                    backgroundColor: "grey",
                    position: "absolute",
                    bottom: "10%",
                    right: "5%",
                  }}
                >
                  {APIData.status == "Closed" ? "Closed" : APIData.status == "Rejected" ? "Rejected": "Accepted"}
                  </button>
              )}
            {APIData?.task_final_admin_approval?.status === "done" &&
              APIData?.status === "Published" &&
              observationUrl === "AuditDirectorApproval" && (
                <button
                  className="create_obsr_button"
                  style={{
                    backgroundColor: "grey",
                    position: "absolute",
                    bottom: "10%",
                    right: "5%",
                  }}
                >
                  {APIData.status == "Closed" ? "Closed" : APIData.status == "Rejected" ? "Rejected": "Accepted"}
                  </button>
              )}
            {APIData?.task_initial_business_approval?.status === "done" &&
              APIData?.status === "Approved Draft" &&
              observationUrl === "BusinessOwnerApproval" && (
                <button
                  className="create_obsr_button"
                  style={{
                    backgroundColor: "grey",
                    position: "absolute",
                    bottom: "10%",
                    right: "5%",
                  }}
                >
                  {APIData.status == "Closed" ? "Closed" : APIData.status == "Rejected" ? "Rejected": "Accepted"}
                  </button>
              )}
            {APIData?.status === "Finalized Draft" &&
              observationUrl === "FinalizeObservation" && (
                <button
                  className="create_obsr_button"
                  style={{
                    backgroundColor: "grey",
                    position: "absolute",
                    bottom: "10%",
                    right: "5%",
                  }}
                >
                  {APIData.status == "Closed" ? "Closed" : APIData.status == "Rejected" ? "Rejected": "Finalized"}

                </button>
              )}

            {APIData?.task_final_qa_approval?.status === "done" &&
              APIData?.status === "Finalized Draft Admin Approve" &&
              observationUrl === "qaAPprovalFinalizeObservation" && (
                <button
                  className="create_obsr_button"
                  style={{
                    backgroundColor: "grey",
                    position: "absolute",
                    bottom: "10%",
                    right: "5%",
                  }}
                >
                  {APIData.status == "Closed" ? "Closed" : APIData.status == "Rejected" ? "Rejected": "Accepted"}
                  </button>
              )}
          </div>
          <div className="createuser">
            {APIData?.status === "Closed" && (
              <button
                className="create_obsr_button"
                style={{
                  backgroundColor: "grey",
                  position: "absolute",
                  bottom: "10%",
                  right: "5%",
                }}
              >
                {APIData.status == "Closed" ? "Closed" : APIData.status == "Rejected" ? "Rejected": "Accepted"}
              </button>
            )}
          </div>

          <div className="createuser" onClick={Donehandler}>
            {APIData.task?.status === "review" && (
              <button
                className="create_obsr_button"
                style={{
                  backgroundColor: "#5771DF",
                  position: "absolute",
                  bottom: "20%",
                  right: "5%",
                }}
              >
                Done
              </button>
            )}
          </div>

          
            <div className="comment_toggle_btn">
              <div className="toggle_img_div" onClick={toggleCommentVisibility}>
                <img
                  src={arrowFlip}
                  alt="Toggle Comments"
                  className={isCommentVisible ? "img-flipped" : ""}
                  style={{
                    cursor: "pointer",
                    transform: isCommentVisible
                      ? "rotate(180deg)"
                      : "rotate(0deg)",
                    transition: "transform 0.3s ease",
                  }}
                />
                <h3>{isCommentVisible ? "Hide Comments" : "Show Comments"}</h3>
              </div>
            </div>
            
              <form action="">
                {/* <h5>
                  Review all the outstanding Tax liability and prepare a report
                  for the board
                </h5> */}

                {observationUrl === "BusinessOwnerApproval" && (
                  <div className="label_selector-business-teammate">
                    <div
                      className={!editModeBusiness ? "noEdit" : "edit"}
                      style={{ position: editModeBusiness ? "" : "absolute" }}
                    ></div>

                    {/* <h3>Assign To Business Teammate</h3> */}

                    <AuditReportingSeclector
                      apiUrl="/api/audit_universe/business-stakeholders/"
                      setSelectorId={setTeammate_id}
                      setSelectorError={setTeammate_idError}
                      selectorError={teammate_idError}
                      Name="name"
                      title="Assign To Business  Teammate"
                      editMode={businessRole ? !editModeBusiness : !editMode}
                      initialId={
                        APIData.assign_to_business_teammate
                          ? APIData.assign_to_business_teammate.id
                          : ""
                      }
                      initialName={
                        APIData.assign_to_business_teammate
                          ? APIData.assign_to_business_teammate.name
                          : ""
                      }
                    />
                  </div>
                )}

                <div className="assign_reviewer">
                  <div
                    className="label_selector"
                    style={{ width: tableSize ? "28vw" : "31vw" }}
                  >
                    

                    <h3>Preparer</h3>

                    <AuditReportingSeclector
                      apiUrl="/api/audit_universe/audit-team/"
                      setSelectorId={setPreparerId}
                      setSelectorError={setPreparerError}
                      selectorError={PreparerError}
                      Name="name"
                      editMode={editMode}
                      initialId={APIData.preparer ? APIData.preparer.id : ""}
                      initialName={
                        APIData.preparer ? APIData.preparer.name : ""
                      }
                    />
                  </div>

                  <div
                    className="label_selector"
                    style={{ width: tableSize ? "28vw" : "31vw" }}
                  >


                    <h3>Reviewer</h3>

                    <AuditReportingSeclector
                      apiUrl="/api/audit_universe/admin-audit-team/"
                      setSelectorId={setReviewerId}
                      setSelectorError={setReviewerError}
                      selectorError={reviewerError}
                      Name="name"
                      editMode={editMode}
                      initialId={APIData?.reviewer ? APIData?.reviewer.id : ""}
                      initialName={
                        APIData?.reviewer ? APIData?.reviewer.name : ""
                      }
                    />
                  </div>

                  {(observationUrl === "QAApprovalInitialObservation" ||
                    observationUrl === "qaAPprovalFinalizeObservation") && (
                    <div
                      className="label_selector"
                      style={{ width: tableSize ? "28vw" : "31vw" }}
                    >
                      
                      <h3>Q.A</h3>

                      <AuditReportingSeclector
                        apiUrl="/api/audit_universe/quality-assurance/"
                        setSelectorId={setQAID}
                        setSelectorError={setQAIDError}
                        selectorError={qa_idError}
                        Name="name"
                        editMode={editMode}
                        initialId={auditData?.qa ? auditData?.qa.id : ""}
                        initialName={auditData?.qa ? auditData?.qa.name : ""}
                      />
                    </div>
                  )}

                  {observationUrl === "BusinessOwnerApproval" && (
                    <div
                      className="label_selector"
                      style={{ width: tableSize ? "28vw" : "31vw" }}
                    >
                      
                      <h3>Business Owner Approval</h3>

                      <AuditReportingSeclector
                        apiUrl="/api/audit_universe/business-stakeholders/"
                        setSelectorId={setLevel1Owner}
                        setSelectorError={setLevel1OwnerError}
                        selectorError={level_1_ownerError}
                        Name="name"
                        editMode={editMode}
                        initialId={
                          APIData?.assign_to ? APIData?.assign_to.id : ""
                        }
                        initialName={
                          APIData?.assign_to ? APIData?.assign_to.name : ""
                        }
                      />
                    </div>
                  )}

                  <div
                    className="label_selector"
                    style={{ width: tableSize ? "28vw" : "31vw", gap: "2vh" }}
                  >
                    <h3>Status</h3>

                    <div className="observation_status_con">
                      {changeStatus(APIData?.status)}
                    </div>
                  </div>
                </div>

                <div className="observation_main_starts">
                  <div className="other_detail_section">
                    <h4>Overview</h4>
                    <div className="lng_lbl_inpt">
                      <label>Title</label>
                      <input
                        type="text"
                        placeholder="Title"
                        value={objective}
                        disabled={!editModeBusiness}
                        onChange={(e) => setTitle(e.target.value)}
                        style={{
                          backgroundColor: editMode ? "#EEF1FC" : "#e6e6e6",
                        }}
                      />
                    </div>
                    <div className="lng_lbl_inpt">
                      <label>Observation Rating</label>
                      <input
                        type="text"
                        placeholder="Observation Rating"
                        value={rating}
                        disabled={!editMode}
                        onChange={(e) => setObservationRating(e.target.value)}
                        style={{
                          backgroundColor: editMode ? "#EEF1FC" : "#e6e6e6",
                        }}
                      />
                    </div>
                    <div className="lbl_txa">
                      <label>Short Description</label>
                      <textarea
                        type="text"
                        placeholder="Short Description"
                        value={short_description}
                        style={{
                          border: shortDescriptionError
                            ? "1px solid #FF4646"
                            : "",
                          backgroundColor: editMode ? "#EEF1FC" : "#e6e6e6",
                        }}
                        disabled={!editMode}
                        onChange={(e) => {
                          setShortDescription(e.target.value);
                          setShortDescriptionError(false);
                        }}
                      />{" "}
                    </div>{" "}
                    {/* <div className="description_recommendation">
                    </div> */}
                    <div className="other_detail_bottom_div"></div>
                  </div>

                  {!businessRole && (
                    <div className="workpap_file_upload">
                      <div className="referance_data">
                        <MultiOptionWorkPaper
                          apiUrl="/api/audit_engagement/workpaper/"
                          setSelectorError={setWorkPIdError}
                          selectorError={workPIdError}
                          title="Select Workpaper"
                          Name="objective"
                          initialIds={workPId}
                          initialNames={workPojcts}
                          setTestIds={setTestIds}
                          testIds={testIds}
                          editMode={!editMode}
                          
                          item={item}
                          setItem={setItem}
                        />
                      </div>
                    </div>
                  )}
                </div>

                <div className="description_file_upload">
                  <div className="descon_sub_obs">
                    <div className="lbl_txa">
                      <div className="des_ai_button">
                        <label>Description</label>

                        <div className="label_askai_button">
                          <button>Ask AI</button>
                        </div>
                      </div>

                      <textarea
                        type="text"
                        placeholder="Description"
                        value={summary}
                        disabled={!editMode}
                        onChange={(e) => setSummary(e.target.value)}
                        style={{
                          backgroundColor: editMode ? "#EEF1FC" : "#e6e6e6",
                        }}
                      />
                    </div>

                    <div className="subSummaryDiv">
                      <div className="subSummaryDiv_head">
                        <label>Sub Observation</label>

                        <div className="addObv">
                          <button
                            type="button"
                            className="addSubObv"
                            onClick={handleAdd}
                          >
                            <img src={blue_add_button} />
                          </button>
                        </div>
                      </div>

                      {inputList.map((input, index) => (
                        <div key={index}>
                          {input.isEditing ? (
                            <>
                              <div
                                className="addsubObvDiv"
                                style={{ gap: "1vw" }}
                              >
                                <textarea
                                  type="text"
                                  value={input.value}
                                  onChange={(e) => handleChange(e, index)}
                                  // style={{ width: tableSize ? "30vw" : "45vw" }}
                                />
                                <div className="addsubBtnDiv">
                                  <button
                                    type="button"
                                    className="saveSubObv"
                                    onClick={() => handleSave(index)}
                                    style={{
                                      border: "none",
                                      background: "transparent",
                                    }}
                                  >
                                    <img src={savesubObv} />
                                  </button>
                                  <button
                                    type="button"
                                    className="cancelSubObv"
                                    onClick={() => handleCancel(index)}
                                  >
                                    <img src={remove_button} />
                                  </button>
                                </div>
                              </div>
                            </>
                          ) : (
                            <div className="remove_sunobspara">
                              <p>{input.value}</p>

                              <button
                                type="button"
                                onClick={() => handleRemove(index)}
                              >
                                <img src={remove_button} />
                              </button>
                            </div>
                          )}
                        </div>
                      ))}
                    </div>
                  </div>

                  <div className="fil_upload_obs_con">
                    <div className="obs_file_upload_header">
                      <h5> Upload Images</h5>

                      <span
                        className="file_button"
                        onClick={() => handlepopup()}
                      >
                        Select Images
                      </span>
                    </div>

                    {fileData?.map((file, index) => (
                      <div key={index} className="main-fileData">
                        <div className="fileData">
                          <div className="fileData-name">
                            {file?.documents?.filename}
                          </div>
                          <div className="delete-image-file-btn">
                            <img
                              src={cutbutton}
                              onClick={() =>
                                handleDeleteModalOpen(
                                  file?.documents?.associated_with_id
                                )
                              }
                            />
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>

                <div className="obs_risk_con">
                  <h5>Risk Assessment</h5>

                 
                    <form>
                      <OptionDropDown2
                        tableSize={tableSize}
                        label="Exposure to Losses"
                        inputType="text"
                        inputPlaceholder="Title"
                        inputStyle={{
                          width: tableSize ? "19vw" : "22vw",
                          backgroundColor: editMode ? "#EEF1FC" : "#e6e6e6",
                          // border: riskCategoryError ? "1px solid #FF4646" : "",
                        }}
                        inputValue={exposure_to_losses}
                        onInputChange={(e) => {
                          if (editMode) {
                            setExposureLosses(e.target.value);
                          }
                        }}
                        options={options}
                        editMode={editMode}
                      />
                

                    
                      <OptionDropDown2
                        tableSize={tableSize}
                        label="Potential Impact"
                        inputType="text"
                        inputPlaceholder="Title"
                        inputStyle={{
                          width: tableSize ? "19vw" : "22vw",
                          backgroundColor: editMode ? "#EEF1FC" : "#e6e6e6",
                          // border: riskCategoryError ? "1px solid #FF4646" : "",
                        }}
                        inputValue={parseInt(potential_impact)}
                        onInputChange={(e) => {
                          if (editMode) {
                            setPotentialImpact(e.target.value);
                          }
                        }}
                        options={PotetntialImpactOptions}
                        editMode={editMode} // Pass the editMode state
                      />
                    

                    
                      <OptionDropDown2
                        tableSize={tableSize}
                        label="Likelihood"
                        inputType="text"
                        inputPlaceholder="Title"
                        inputStyle={{
                          width: tableSize ? "19vw" : "22vw",
                          backgroundColor: editMode ? "#EEF1FC" : "#e6e6e6",
                        }}
                        inputValue={parseInt(likelyhood)}
                        onInputChange={(e) => {
                          if (editMode) {
                            setLikelihood(e.target.value);
                          }
                        }}
                        options={LikeOptions}
                        editMode={editMode} // Pass the editMode state
                      />
                    
                 

                  <div className="lng_lbl_inpt">
                    <label>Risk 01</label>
                    <input
                      type="text"
                      placeholder="Risk 01"
                      value={risk1}
                      disabled={!editMode}
                      onChange={(e) => setRisk01(e.target.value)}
                      style={{
                        backgroundColor: editMode ? "#EEF1FC" : "#e6e6e6",
                      }}
                    />
                  </div>
                  <div className="lng_lbl_inpt">
                    <label>Risk 02</label>
                    <input
                      type="text"
                      placeholder="Risk 02"
                      value={risk2}
                      disabled={!editMode}
                      onChange={(e) => setRisk02(e.target.value)}
                      style={{
                        backgroundColor: editMode ? "#EEF1FC" : "#e6e6e6",
                      }}
                    />
                  </div>
                  <div className="lng_lbl_inpt">
                    <label>Risk 03</label>
                    <input
                      type="text"
                      placeholder="Risk 03"
                      value={risk3}
                      disabled={!editMode}
                      onChange={(e) => setRisk03(e.target.value)}
                      style={{
                        backgroundColor: editMode ? "#EEF1FC" : "#e6e6e6",
                      }}
                    />
                  </div>
                  </form>
                </div>

                <div className="obs_recomm_con">
                  <div className="lbl_txa">
                    <h4>Recommendation</h4>
                    <textarea
                      type="text"
                      placeholder="Recommendation"
                      value={recommendation}
                      disabled={!editMode}
                      onChange={(e) => setRecommendation(e.target.value)}
                      style={{
                        backgroundColor: editMode ? "#EEF1FC" : "#e6e6e6",
                      }}
                    />
                  </div>
                </div>

                <div className="management_section">
                  <h5>Management Response</h5>
                  <form>
                  <div className="lbl_txa">
                    <label>Action Plan</label>
                    <textarea
                      type="text"
                      placeholder="Action Plan"
                      value={action_plan}
                      disabled={businessRole ? editModeBusiness : !editMode}
                      onChange={(e) => setActionPlan(e.target.value)}
                      style={{
                        backgroundColor: businessRole
                          ? editModeBusiness
                          : editMode
                          ? "#EEF1FC"
                          : "#e6e6e6",
                        border: action_planError ? "1px solid #FF4646" : "",
                      }}
                    />
                  </div>

                  
                   
                      {/* <h3>Level 1 Owner</h3> */}

                      <AuditReportingSeclector
                        apiUrl="/api/audit_universe/business-stakeholders/"
                        setSelectorId={setLevel1Owner}
                        setSelectorError={setLevel1OwnerError}
                        selectorError={level_1_ownerError}
                        Name="name"
                        title="Level 1 Owner"
                        editMode={businessRole ? !editModeBusiness : editMode}
                        initialId={
                          APIData?.assign_to ? APIData?.assign_to.id : ""
                        }
                        initialName={
                          APIData?.assign_to ? APIData?.assign_to.name : ""
                        }
                      />
                   

                  
                      {/* <h3>Level 2 Owner</h3> */}

                      <AuditReportingSeclector
                        apiUrl="/api/audit_universe/business-stakeholders/"
                        setSelectorId={setLevel2Owner}
                        setSelectorError={setLevel2OwnerError}
                        selectorError={level_2_ownerError}
                        Name="name"
                        title="Level 2 Owner"
                        editMode={businessRole ? !editModeBusiness : editMode}
                        initialId={
                          APIData?.secondary_assign_to
                            ? APIData?.secondary_assign_to.id
                            : ""
                        }
                        initialName={
                          APIData?.secondary_assign_to
                            ? APIData?.secondary_assign_to.name
                            : ""
                        }
                      />
                    
                  

                  <div className="lbl_inpt">
                    <label>Due Date</label>
                    <input
                      type="date"
                      value={convertDate(management_response_due_date)}
                      disabled={businessRole ? editModeBusiness : !editMode}
                      onChange={(e) => setActionDueDate(e.target.value)}
                      style={{
                        backgroundColor: businessRole
                          ? editModeBusiness
                          : editMode
                          ? "#EEF1FC"
                          : "#e6e6e6",
                        border: management_response_due_dateError
                          ? "1px solid #FF4646"
                          : "",
                      }}
                    />
                  </div>
                  </form>
                </div>

                <div className="IA_response_section">
                  <h5>Internal Audit Response</h5>
                  <form>
                  <div className="lbl_txa">
                    <label>Audit Response</label>
                    <textarea
                      type="text"
                      placeholder="Internal Audit Response"
                      value={response_audit}
                      disabled={!editMode}
                      onChange={(e) => setAuditResponse(e.target.value)}
                      style={{
                        backgroundColor: editMode ? "#EEF1FC" : "#e6e6e6",
                      }}
                    />
                  </div>

                  <div className="lbl_inpt">
                    <label>Due Date</label>
                    <input
                      type="date"
                      value={convertDate(response_audit_due_date)}
                      disabled={!editMode}
                      onChange={(e) => setResponseActionDueDate(e.target.value)}
                      style={{
                        backgroundColor: editMode ? "#EEF1FC" : "#e6e6e6",
                      }}
                    />
                  </div>
                  </form>
                </div>

                <div className="pre_close_section">
                  <h5>Preliminary Close Observation</h5>

                  <div className="lbl_txa">
                    <label>Audit Response</label>
                    <textarea
                      type="text"
                      placeholder="Preliminary Close Observation"
                      value={preliminary_close_observation}
                      disabled={!editMode}
                      onChange={(e) =>
                        setPreliminaryCloseObservation(e.target.value)
                      }
                      style={{
                        backgroundColor: editMode ? "#EEF1FC" : "#e6e6e6",
                        border: preliminary_close_observationError
                          ? "1px solid #FF4646"
                          : "",
                      }}
                    />
                  </div>

                  <div className="close_obv_button_div">
                    <button onClick={() => closeObservation()}>
                    Close Observation
                    </button>
                  </div>
                </div>

                {APIData && (
                  // .task?.status === "rejected" ||
                  // APIData.task?.status === "review" ||
                  // APIData.task?.status === "done"
                  <div
                    className={`commentContainer ${
                      isCommentVisible ? "slide-in" : "slide-out"
                    }`}
                    style={{
                      width: tableSize ? "34.7vw" : "40vw",
                      position: "absolute",
                      top: "35%",
                      overflowY: "scroll",
                      right: "0%",
                      height: "55vh",
                      backgroundColor: "#fff",
                      zIndex: "100",
                      transition: "transform 0.3s ease",
                      transform: isCommentVisible
                        ? "translateX(0)"
                        : "translateX(100%)",
                      boxShadow:
                        "0 2px 12px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                    }}
                  >
                    <h3>Comments & Activity</h3>

                    <div className="commentInputCon">
                      <input
                        type="text"
                        placeholder="Enter a comment..."
                        value={inputValue}
                        onChange={handleInputChange}
                        onKeyDown={handleKeyDown}
                        // disabled={
                        //   APIData.task?.status !== "rejected" &&
                        //   APIData.task?.status !== "review"
                        // }
                        // style={{ width: tableSize ? "33vw" : "37vw" }}
                      />
                      <div className="sendCommobv">
                        <img
                          onClick={handleAddComment}
                          src={sendComment}
                          alt="Send Comment"
                        />
                      </div>
                    </div>

                    <div className="all_comments">
                      {taskData
                        .slice()
                        .reverse()
                        .map((comment, index) => {
                          const { text, date, time } = comment;
                          return (
                            <div className="comment_wrapper_" key={index}>
                              <div className="comment_head">
                                <div className="avatar_name_AQ">
                                  <SuperAvatar
                                    firstName={
                                      comment.user
                                        ? comment.user.name
                                        : `${avaUserName} ${lastName}`
                                    }
                                    classOfAvatar="Avtaraudit"
                                  />
                                  <h5>
                                    {comment.user
                                      ? comment.user.name
                                      : `${avaUserName} ${lastName}`}
                                  </h5>
                                </div>

                                <div className="time_date_AQ">
                                  <span>
                                    <sub>{daysSince(comment.created_at)}</sub>
                                  </span>
                                </div>
                              </div>
                              <div className="comment_AQ_observation">
                                <p>{text}</p>
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                )}

                <div className="observation_spacing">
                  <p>Spacing_Content</p>
                </div>
              </form>
            
          
        </div>
          )
        }

        <div className="cardscontainer"></div>
      </section>
    </>
  );
};

export default ViewObservation;
