import React, { useState, useEffect } from "react";
import {
  NavLink,
  useLocation,
  Link,
  useNavigate,
  useParams,
} from "react-router-dom";
import Breadcrumb from "../../../Breadcrumb";
import SidebarMenu from "../../../SidebarMenu";
import "../../../../css/viewuser.css";
import "../../rcm/listofrcap.css";
import rcap_openbutton from "../../../../images/rcap_openbutton.svg";
import openarrow from "../../../../images/openarrow.svg";
import { fetchData, postData } from "../../../apiUtils";
import { auditRoutes } from "../../../subroutes";
import create from "../../../../images/createbutton.svg";
import "./creatercap.css";
import IconButton from "../../../iconbutton/IconButton";
import OptionDropDown from "../../../OptionDropDown";
import { auditExecution } from "../../../subroutes";
import corporate from "../../../../images/corporate.svg"; // Adjust path as necessary
import star from "../../../../images/star.svg"; // Adjust path as necessary
import risk from "../../../../images/risk.svg"; // Adjust path as necessary
import auditPlan from "../../../../images/auditPlan.svg"; // Adjust path as necessary
import audit_icon from "../../../../images/audit_icon.svg"; // Adjust path as necessary
import planning_icon from "../../../../images/planning_icon.svg"; // Adjust path as necessary
import fieldwork_icon from "../../../../images/fieldwork_icon.svg"; // Adjust path as necessary
import reporting_icon from "../../../../images/reporting_icon.svg"; // Adjust path as necessary
import wrapupqa_icon from "../../../../images/wrapup_icon.svg"; // Adjust path as necessary
import MultiOptionSelector from "../../../MultiOptionSelector";

const CreateRCAP = ({ tableSize }) => {
  const [errors, setErrors] = useState("");
  const [objective, setObjective] = useState("");
  const [risk_id, setRiskId] = useState("");
  const [entityId, setEntityId] = useState("");

  const [risk_category, setRiskCategory] = useState("");
  const [risk_description, setDescribeRisk] = useState("");
  const [risk_title, setTitleRisk] = useState("");
  const [business_units_id, setBusinessUnit_id] = useState([]);

  const [inherent_assessment_likelihood, setLikelikhood] = useState("");
  const [inherent_assessment_impact, setImpact] = useState("");

  const [control_existing, setExistingControl] = useState("");
  const [control_desired, setDesiredControl] = useState("");
  const [control_evaluation, setEvaluation] = useState("");
  const [control_design, setControlDesign] = useState("");
  const [control_type, setcontrolType] = useState("");
  const [control_frequency, setcontrolFrequency] = useState("");
  const [control_gap, setControlGap] = useState("");
  const [residual_assessment_likelihood, setResLikelihood] = useState("");
  const [residual_assessment_impact, setResImpact] = useState("");
  const [audit_procedure_step, setAuditStepDetails] = useState("");
  const [audit_procedure_data, setDataRequired] = useState("");

  const [likelikhoodError, setLikelikhoodError] = useState(false);
  const [impactError, setImpactError] = useState(false);
  const [existingControlError, setExistingControlError] = useState(false);
  const [desiredControlError, setDesiredControlError] = useState(false);
  const [evaluationError, setEvaluationError] = useState(false);
  const [controlDesignError, setControlDesignError] = useState(false);
  const [controlTypeError, setcontrolTypeError] = useState(false);
  const [controlFrequencyError, setcontrolFrequencyError] = useState(false);
  const [controlGapError, setControlGapError] = useState(false);
  const [resLikelihoodError, setResLikelihoodError] = useState(false);
  const [resImpactError, setResImpactError] = useState(false);
  const [auditStepDetailsError, setAuditStepDetailsError] = useState(false);
  const [dataRequiredError, setDataRequiredError] = useState(false);

  const [objectiveError, setObjectiveError] = useState(false);
  const [riskIdError, setRiskIdError] = useState(false);
  const [riskCategoryError, setRiskCategoryError] = useState(false);
  const [describeRiskError, setDescribeRiskError] = useState(false);
  const [titleRiskError, setTitleRiskError] = useState(false);
  const [businessUnitId, setBusinessUnitId] = useState("");
const [businessUnitIdError, setBusinessUnitIdError] = useState(false);


  const [APIData, setAPIData] = useState([]);
  const location = useLocation();
  const navigate = useNavigate();
  const successMessageColor = location.state?.successMessageColor;
  const successMessage = location.state?.successMessage;
  const [showMessage, setShowMessage] = useState(!!successMessage);
  const originalUrl = location.state?.from;
  const { id, planid, tempId } = useParams();



  const fullUrl = window.location.origin + location.pathname;
  const segments = location.pathname.split('/');
  const auditUniverse = segments.find(segment => segment === 'auditUniverse');


  const options = [
    // 'Financial',
    // 'Operational',
    // 'Strategic',
    // 'Compliance',
    // 'Reputational',
    { label: ' Financial', value: 'Financial' },
    { label: 'Operational', value: 'Operational' },
    { label: 'Strategic', value: 'Strategic' },
    { label: 'Compliance', value: 'Compliance' },
    { label: 'Reputational', value: 'Reputational' }
  ];
  const inherentAssessmentImpactOptions = [
    { label: 'Low', value: 1 },
    { label: 'Medium', value: 2 },
    { label: 'High', value: 3 },
    { label: 'VeryHigh', value: 4 },
    { label: 'Critical', value: 5 },
  ];

  const inherentAssessmentLikeOptions = [
    { label: 'Low', value: 1 },
    { label: 'Medium', value: 2 },
    { label: 'High', value: 3 },
    { label: 'VeryHigh', value: 4 },
    { label: 'Critical', value: 5 },
  ];

  const residualAssessmentImpactOptions = [
    { label: 'Low', value: 1 },
    { label: 'Medium', value: 2 },
    { label: 'High', value: 3 },
    { label: 'VeryHigh', value: 4 },
    { label: 'Critical', value: 5 },
    ];

  const residualAssessmentLikeOptions = [
    { label: 'Low', value: 1 },
    { label: 'Medium', value: 2 },
    { label: 'High', value: 3 },
    { label: 'VeryHigh', value: 4 },
    { label: 'Critical', value: 5 },
    ];

  const controlEvaluationOptions = [
    // 'Effective', 'Ineffective', 'Needs Improvement'
    { label: 'Effective', value: 'Effective' },
    { label: 'Ineffective', value: 'Ineffective' },
    { label: 'Needs Improvement', value: 'Needs Improvement' },
  ];
  const controlDesignOptions = [
    // 'Manual', 'Automated'
    { label: 'Manual', value: 'Manual' },
    { label: 'Automated', value: 'Automated' }
  ];
  const controlTypeOptions = [
    // 'Preventive', 'Detective', 'Corrective'
    { label: 'Preventive', value: 'Preventive' },
    { label: 'Detective', value: 'Detective' },
    { label: 'Corrective', value: 'Corrective' }
  ];
  const controlGapOptions = [
    { label: 'No Gap', value: 'No Gap' },
    { label: 'Minor Gap', value: 'Minor Gap' },
    { label: 'Significant Gap', value: 'Significant Gap' }
  ];

  const controlFrequencyOptions = [
    { label: 'Daily', value: 'Daily' },
    { label: 'Weekly', value: 'Weekly' },
    { label: 'Monthly', value: 'Monthly' },
    { label: 'Quarterly', value: 'Quarterly' },
    { label: 'Annually', value: 'Annually' }
  ];


  useEffect(() => {
    const timeoutId = setTimeout(() => {
      navigate(".", { replace: true });
    }, 5000);

    return () => clearTimeout(timeoutId);
  }, []);

  useEffect(() => {
    fetchData(`/api/audit_engagement/audit/${id}/`, setAPIData, setErrors);
  }, []);

  useEffect(() => {
    if (APIData.audit_entity) {
      if (!auditUniverse) {
        setEntityId(APIData.audit_entity.id);
      }
    }
  }, [APIData]);

  const Viewuser = (data) => {
    const id = data.id;
    navigate(`/auditUniverse/auditEntities/${id}/`);
  };


  function afSpinner() {
    if (!APIData || APIData.length === 0) {
      return (
        <tr>
          <td colSpan="3" className="spinner-container">
            <div className="spinner"></div>
          </td>
        </tr>
      );
    }


  }
  const [urlNav, setUrlNav] = useState('')


  useEffect(() => {
    if (entityId) {
      setUrlNav(`/AuditExecution/${id}/Planning/${planid}/${tempId}/`)
    }
    else {
      setUrlNav(`/auditUniverse/auditEntities/${id}/${tempId}/`)
    }
  }, [entityId]);

  const postApi = (e) => {
    e.preventDefault();
    if (
      !objective ||
      !risk_id ||
      // !risk_category ||
      // !risk_description ||
      !risk_title ||
      !inherent_assessment_likelihood ||
      !inherent_assessment_impact ||
      // !control_existing ||
      // !control_desired ||
      // !control_evaluation ||
      // !control_design ||
      // !control_type ||
      // !control_frequency ||
      // !control_gap ||
      !residual_assessment_likelihood ||
      !residual_assessment_impact ||
      // !audit_procedure_data ||
      !audit_procedure_step

    ) {
      setObjectiveError(!objective ? true : false);
      setRiskIdError(!risk_id ? true : false);
      // setRiskCategoryError(!risk_category ? true : false);
      // setDescribeRiskError(!risk_description ? true : false);
      setTitleRiskError(!risk_title ? true : false);
      setLikelikhoodError(!inherent_assessment_likelihood ? true : false);
      setImpactError(!inherent_assessment_impact ? true : false);
      // setExistingControlError(!control_existing ? true : false);
      // setDesiredControlError(!control_desired ? true : false);
      // setEvaluationError(!control_evaluation ? true : false);
      // setControlDesignError(!control_design ? true : false);
      // setcontrolTypeError(!control_type ? true : false);
      // setcontrolFrequencyError(!control_frequency ? true : false);
      // setControlGapError(!control_gap ? true : false);
      setResLikelihoodError(!residual_assessment_likelihood ? true : false);
      setResImpactError(!residual_assessment_impact ? true : false);
      setAuditStepDetailsError(!audit_procedure_step ? true : false);
      // setDataRequiredError(!audit_procedure_data ? true : false);
      setErrors("Please fill in all required fields.");
      setTimeout(() => {
        setErrors(null);
      }, 5000);
      return;
    }
    postData(
      '/api/audit_universe/rcap/',
      {
        objective,
        risk_id,
        risk_category,
        risk_description,
        risk_title,
        inherent_assessment_likelihood,
        inherent_assessment_impact,
        control_existing,
        control_desired,
        control_evaluation,
        control_design,
        control_type,
        control_frequency,
        control_gap,
        residual_assessment_likelihood,
        residual_assessment_impact,
        audit_procedure_step,
        audit_procedure_data,
        audit_entity: entityId ? entityId : id,
        rcm_template_id: tempId,
        
        

      },

      navigate,
      setErrors,
      urlNav,
    );
  };

  const auditUniverseNavLinks = [
    { path: "/AuditUniverse/corporateStructure", name: "Corporate Structure", icon: corporate },
    { path: "/AuditUniverse/AuditEntities", name: "Audit Entities", icon: star },
    { path: "/AuditUniverse/riskAssessment", name: "Risk Assessment", icon: risk },
    { path: "/AuditUniverse/auditPlanning", name: "Audit Planning", icon: auditPlan }
  ];

  const auditExecutionNavLinks = [
    { path: "/AuditExecution/${id}/Detail", name: "Detail", icon: audit_icon },
    { path: "/AuditExecution/${id}/Planning", name: "Planning", icon: planning_icon },
    { path: "/AuditExecution/${id}/Fieldwork", name: "Fieldwork", icon: fieldwork_icon },
    { path: "/AuditExecution/${id}/Reporting", name: "Reporting", icon: reporting_icon },
    { path: "/AuditExecution/${id}/WrapupQA", name: "WrapupQA", icon: wrapupqa_icon }
  ];

  const navLinks = auditUniverse ? auditUniverseNavLinks : auditExecutionNavLinks;

  return (
    <>
      {errors && <p className="errormessage">{errors}</p>}
      {successMessage && <p className="successmessage">{successMessage}</p>}

      <section className="navroutes">
        {/* <div className="bumenucontainer">{renderIcons()}</div> */}
        <div className="auditMenuCon">
          {navLinks.map((route, index) => (
            <NavLink
              to={route.path}
              key={index}
              className="navlink"
              activeclassname="active"
            >
              <div className="navicon">
                <img src={route.icon} alt="Dashboard Icon" />
              </div>
              <div className="navicon">{route.name}</div>
            </NavLink>
          ))}
        </div>
        <hr className="breadcrumbabv" />
        <Breadcrumb />

        <div className="filter-container">
          <div className="filter">
            <div className="views">
              <h3 className="h2_interbold" >Create RCAP</h3>

            </div>
          </div>
        </div>

        <div
          className="RCM_template_con"
          style={{ width: tableSize ? "80vw" : "90.5vw" }}
        >
          <div className="delete_edit_close_btn">
            <IconButton type="close" className='closeView' tooltip="Close" link={urlNav} />

            <IconButton type="save" tooltip="Save" onClick={postApi} link="/" />
          </div>
          <form action="">
            <div className="rcap_labelandinputs">
              <label htmlFor="">Objective</label>
              <input
                type="text"
                placeholder="Unauthorized Access to Financial Data"
                style={{
                  width: tableSize ? "75vw" : "84.5vw",
                  border: objectiveError ? "1px solid #FF4646" : "",
                }}
                value={objective}
                onChange={(e) => {
                  setObjective(e.target.value);
                  setObjectiveError(false);
                }}
              />
            </div>

            <div
              className="rcap_section"
              style={{ width: tableSize ? "75vw" : "84vw" }}
            >
              <h3>Risk</h3>

              <div className="rcap_title_description">
                <div className="rcap_id_category_title">
                  <div className="rcap_labelandinputs">
                    <label htmlFor="">Risk ID</label>
                    <input
                      type="text"
                      placeholder="NG57FRE"
                      style={{
                        width: tableSize ? "19vw" : "22vw",
                        border: riskIdError ? "1px solid #FF4646" : "",
                      }}
                      value={risk_id}
                      onChange={(e) => {
                        setRiskId(e.target.value);
                        setRiskIdError(false);
                      }}
                    />
                  </div>
                  <div className="rcap_labelandinputs">
                
                  <OptionDropDown
                    tableSize={tableSize}
                    label="Risk Category"
                    inputType="text"
                    inputPlaceholder="Financial"
                    inputStyle={{
                      width: tableSize ? "19vw" : "22vw",
                      border: riskCategoryError ? "1px solid #FF4646" : "",
                    }}
                    inputValue={risk_category}
                    onInputChange={(e) => {
                      setRiskCategory(e.target.value);
                      // setRiskCategoryError(false);
                    }}
                    options={options}
                  />
                  </div>



                  <div className="rcap_labelandinputs">
                    <label htmlFor="">Risk Title</label>
                    <input
                      type="text"
                      placeholder="Unauthorized Access to Financial Data"
                      style={{
                        width: tableSize ? "39vw" : "45vw",
                        border: titleRiskError ? "1px solid #FF4646" : "",
                      }}
                      value={risk_title}
                      onChange={(e) => {
                        setTitleRisk(e.target.value);
                        setTitleRiskError(false);
                      }}
                    />
                  </div>
                </div>

                <div className="rcap_labelandinputs">
                  <label htmlFor="">Describe Risk</label>
                  <textarea
                    type="text"
                    placeholder="Describe Risk"
                    style={{
                      width: tableSize ? "35vw" : "38vw",
                      border: describeRiskError ? "1px solid #FF4646" : "",
                    }}
                    value={risk_description}
                    onChange={(e) => {
                      setDescribeRisk(e.target.value);
                      // setDescribeRiskError(false);
                    }}
                  />
                </div>
              </div>
            </div>

            <div
              className="rcap_section"
              style={{ width: tableSize ? "75vw" : "84vw" }}
            >
              <h3>Inherit Assessment</h3>

              <div className="inherit_risk_section">
                <div className="rcap_labelandinputs">
                  <OptionDropDown
                    tableSize={tableSize}
                    label="Inh. Likelikhood"
                    inputType="text"
                    inputPlaceholder="NG57FRE"
                    inputStyle={{
                      width: tableSize ? "19vw" : "22vw",
                      border: likelikhoodError ? "1px solid #FF4646" : "",
                    }}
                    inputValue={inherent_assessment_likelihood}
                    onInputChange={(e) => {
                      setLikelikhood(e.target.value);
                      setLikelikhoodError(false);
                    }}
                    options={inherentAssessmentLikeOptions}
                  />
                </div>

                <div className="rcap_labelandinputs">


                  <OptionDropDown
                    tableSize={tableSize}
                    label="Inh. Impact"
                    inputType="text"
                    inputPlaceholder="NG57FRE"
                    inputStyle={{
                      width: tableSize ? "19vw" : "22vw",
                      border: impactError ? "1px solid #FF4646" : "",
                    }}
                    inputValue={inherent_assessment_impact}
                    onInputChange={(e) => {
                      setImpact(e.target.value);
                      setImpactError(false);
                    }}
                    options={inherentAssessmentImpactOptions}
                  />
                </div>
              </div>
            </div>

            <div
              className="rcap_section"
              style={{ width: tableSize ? "75vw" : "84vw" }}
            >
              <h3>Control</h3>

              <div className="control_section">
                <div className="rcap_labelandinputs">
                  <label htmlFor="">Control Statement (Existing Control)</label>
                  <textarea
                    type="text"
                    placeholder="NG57FRE"
                    style={{
                      width: tableSize ? "75vw" : "85vw",
                      border: existingControlError ? "1px solid #FF4646" : "",
                    }}
                    value={control_existing}
                    onChange={(e) => {
                      setExistingControl(e.target.value);
                      // setExistingControlError(false);
                    }}
                  />
                </div>

                <div className="rcap_labelandinputs">
                  <label htmlFor="">
                    Best practices for refrence (Desired Control)
                  </label>
                  <textarea
                    type="text"
                    placeholder="NG57FRE"
                    style={{
                      width: tableSize ? "75vw" : "85vw",
                      border: desiredControlError ? "1px solid #FF4646" : "",
                    }}
                    value={control_desired}
                    onChange={(e) => {
                      setDesiredControl(e.target.value);
                      // setDesiredControlError(false);
                    }}
                  />
                </div>

                <div
                  className="control_section_selections"
                  style={{ width: tableSize ? "75vw" : "85vw" }}
                >
                  <div className="rcap_labelandinputs">
                    <OptionDropDown
                      tableSize={tableSize}
                      label="Control Evaluation"
                      inputType="text"
                      inputPlaceholder="NG57FRE"
                      inputStyle={{
                        width: tableSize ? "12vw" : "15vw",
                        border: evaluationError ? "1px solid #FF4646" : "",
                      }}
                      inputValue={control_evaluation}
                      onInputChange={(e) => {
                        setEvaluation(e.target.value);
                        // setEvaluationError(false);
                      }}
                      options={controlEvaluationOptions}
                    />
                  </div>

                  <div className="rcap_labelandinputs">
                    <OptionDropDown
                      tableSize={tableSize}
                      label="Control Design"
                      inputType="text"
                      inputPlaceholder="NG57FRE"
                      inputStyle={{
                        width: tableSize ? "12vw" : "15vw",
                        border: controlDesignError ? "1px solid #FF4646" : "",

                      }}
                      inputValue={control_design}
                      onInputChange={(e) => {
                        setControlDesign(e.target.value);
                        // setControlDesignError(false);
                      }}
                      options={controlDesignOptions}
                    />
                  </div>

                  <div className="rcap_labelandinputs">
                    <OptionDropDown
                      tableSize={tableSize}
                      label="Control Type"
                      inputType="text"
                      inputPlaceholder="NG57FRE"
                      inputStyle={{
                        width: tableSize ? "12vw" : "15vw",
                        border: controlTypeError ? "1px solid #FF4646" : "",
                      }}
                      inputValue={control_type}
                      onInputChange={(e) => {
                        setcontrolType(e.target.value);
                        // setcontrolTypeError(false);
                      }}
                      options={controlTypeOptions}
                    />
                  </div>

                  <div className="rcap_labelandinputs">
                    <OptionDropDown
                      tableSize={tableSize}
                      label="Control Frequency"
                      inputType="text"
                      inputPlaceholder="NG57FRE"
                      inputStyle={{
                        width: tableSize ? "12vw" : "15vw",
                        border: controlFrequencyError ? "1px solid #FF4646" : "",
                      }}
                      inputValue={control_frequency}
                      onInputChange={(e) => {
                        setcontrolFrequency(e.target.value);
                        // setcontrolFrequencyError(false);
                      }}
                      options={controlFrequencyOptions}
                    />

                  </div>

                  <div className="rcap_labelandinputs">
                    <OptionDropDown
                      tableSize={tableSize}
                      label="Control Gap"
                      inputType="text"
                      inputPlaceholder="NG57FRE"
                      inputStyle={{
                        width: tableSize ? "12vw" : "15vw",
                        border: controlGapError ? "1px solid #FF4646" : "",
                      }}
                      inputValue={control_gap}
                      onInputChange={(e) => {
                        setControlGap(e.target.value);
                        // setControlGapError(false);
                      }}
                      options={controlGapOptions}
                    />

                  </div>
                </div>
              </div>
            </div>

            <div className="rcap_labelandinputs">

              <OptionDropDown
                tableSize={tableSize}
                label="Res. Likelihood"
                inputType="text"
                inputPlaceholder="NG57FRE"
                inputStyle={{
                  width: tableSize ? "12vw" : "15vw",
                  border: resLikelihoodError ? "1px solid #FF4646" : "",
                }}
                inputValue={residual_assessment_likelihood}
                onInputChange={(e) => {
                  setResLikelihood(e.target.value);
                  setResLikelihoodError(false);
                }}
                options={residualAssessmentLikeOptions}
              />
            </div>

            <div className="rcap_labelandinputs">

              <OptionDropDown
                tableSize={tableSize}
                label="Res. Impact"
                inputType="text"
                inputPlaceholder="NG57FRE"
                inputStyle={{
                  width: tableSize ? "12vw" : "15vw",
                  border: resImpactError ? "1px solid #FF4646" : "",
                }}
                inputValue={residual_assessment_impact}
                onInputChange={(e) => {
                  setResImpact(e.target.value);
                  setResImpactError(false);
                }}
                options={residualAssessmentImpactOptions}
              />
            </div>

            <div className="rcap_labelandinputs">
              <label htmlFor="">Audit Step Details</label>
              <input
                type="text"
                placeholder="NG57FRE"
                style={{ width: tableSize ? "12vw" : "15vw" }}
                value={audit_procedure_step}
                onChange={(e) => {
                  setAuditStepDetails(e.target.value);
                  setAuditStepDetailsError(false);
                }}
              />
            </div>

            <div className="rcap_labelandinputs">
              <label htmlFor="">Data Required</label>
              <input
                type="text"
                placeholder="NG57FRE"
                style={{
                  width: tableSize ? "12vw" : "15vw",
                  border: dataRequiredError ? "1px solid #FF4646" : "",
                }}
                value={audit_procedure_data}
                onChange={(e) => {
                  setDataRequired(e.target.value);
                  // setDataRequiredError(false);
                }}
              />
              
            </div>
           


          </form>
        </div>

        <div className="cardscontainer">

        </div>
      </section>
    </>
  );
};

export default CreateRCAP;