import React from "react";

const CommonInput = ({
  labelName,
  error,
  type,
  placeHolder,
  setText,
  setTextError,
  value,
  editMode,
  commanDivClass
}) => {
  return (
    <div className={commanDivClass}>
      <label>{labelName}</label>
      <input
        style={{ border: error ? "1px solid #FF4646" : "", 
          backgroundColor:editMode ? "#e6e6e6" : "#EEF1FC",
        }}
        required  
        type={type} 
        placeholder={placeHolder}
        value={value}
        className={editMode ? "editable-input" : "non-editable-input"}
        readOnly={editMode ? editMode : ''}
        
        onChange={(e) => {
          setText(e.target.value);
          if (setTextError) setTextError(false);
        }}
      />
    </div>
  );
};

export default CommonInput;
