import React, { useEffect, useMemo, useState } from 'react'
import { Link, NavLink, useLocation, useNavigate, useParams } from "react-router-dom";
import Breadcrumb from "../../Breadcrumb";
import SidebarMenu from "../../SidebarMenu";
import "../../../css/viewuser.css";
import "../riskAssesment/riskassessment.css";
import toggle_btn from "../../../images/toggle_side_down.svg";
import { fetchData, fetchData1, justPostData, postData, postDataRisk } from "../../apiUtils";
import { auditRoutes } from "../../subroutes";
import BounceLoader from "react-spinners/BounceLoader";
import RenderIcons from "../../RenderIcons";


const AuditPlanTable = ({ tableSize }) => {
    const { id } = useParams();
    const [APIData, setAPIData] = useState([]);
    const [errors, setErrors] = useState("");
    const navigate = useNavigate();

    const Viewuser = (data) => {
        console.log(data);
        navigate(`/AuditUniverse/auditPlanning/${data.id}`);
    };

    useEffect(() => {
        fetchData(
            `/api/audit_universe/audit-plan/`,
            setAPIData,
            setErrors
        );
    }, [id]);

    const [filters, setFilters] = useState({
        AuditTitle: "",
        audit_entityName: "",
        status: "",
    });

    const handleFilterChange = (filterKey, e) => {
        const value = e.target.value.toLowerCase();
        setFilters({ ...filters, [filterKey]: value });
    };

    const filteredData = useMemo(() => {
        return APIData.filter((data) => {
            const title = data?.risk_assessment?.title?.toLowerCase() || "";
            const status = data?.status?.toLowerCase() || "--";
            const auditEntityMatch = data?.audit_events?.some((event) =>
                event?.audit_entity?.name?.toLowerCase().includes(filters.audit_entityName)
            );

            return (
                (!filters.AuditTitle || title.includes(filters.AuditTitle)) &&
                (!filters.status || status.includes(filters.status)) &&
                (!filters.audit_entityName || auditEntityMatch)
            );
        });
    }, [APIData, filters]);

    return (
        <>
            <RenderIcons homeRoutes={auditRoutes} />
            <div className="filter-container">
                <div className="filter">
                    <div className="views">
                        <h3>Risk Assessment</h3>
                    </div>
                </div>
            </div>
            <div className="tablecontainer">
                <table style={{ width: tableSize ? "80vw" : "90.5vw" }}>
                    <thead style={{ position: "sticky", top: "0", zIndex: 1000 }}>
                        <tr className="tablehead">
                            <th>
                                <h4 className="thhead">Name</h4>
                                <input
                                    type="text"
                                    placeholder="--"
                                    className="thfilter"
                                    onChange={(e) => handleFilterChange("audit_entityName", e)}
                                    value={filters.audit_entityName}
                                />
                            </th>
                            <th>
                                <h4 className="thhead">Year</h4>
                                <input
                                    type="text"
                                    placeholder="--"
                                    className="thfilter"
                                    onChange={(e) => handleFilterChange("AuditTitle", e)}
                                    value={filters.AuditTitle}
                                />
                            </th>
                            <th>
                                <h4 className="thhead">Status</h4>
                                <input
                                    type="text"
                                    placeholder="--"
                                    className="thfilter"
                                    onChange={(e) => handleFilterChange("status", e)}
                                    value={filters.status}
                                />
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredData.map((data, index) => (
                            <tr onClick={() => Viewuser(data)} key={index}>
                                <td style={{ display: 'flex', gap: '.5vw' }}>
                                    <div className="tagscontainer" style={{ display: 'flex', gap: '.5vw' }}>
                                        {data.audit_events
                                            .slice(0, 3)
                                            .map((event, eventIndex) => (
                                                <div
                                                    key={eventIndex}
                                                    style={{
                                                        backgroundColor: '#12b4bf',
                                                        borderRadius: '15px',
                                                        padding: '.4vw 1.2vw',
                                                        color: '#fff'
                                                    }}
                                                >
                                                    <span>{event.audit_entity.name}</span>
                                                </div>
                                            ))
                                            .concat(data.audit_events.length > 3 ? (
                                                <div
                                                    key="ellipsis"
                                                    style={{
                                                        backgroundColor: '#12b4bf',
                                                        borderRadius: '15px',
                                                        padding: '.4vw 1.2vw',
                                                        color: '#fff'
                                                    }}
                                                >
                                                    <span>...</span>
                                                </div>
                                            ) : null)}
                                    </div>
                                </td>
                                <td>{data.risk_assessment.title}</td>
                                <td>{data.status}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </>
    );
}




export default AuditPlanTable
