import React, { useState, useEffect } from "react";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import Breadcrumb from "../../../Breadcrumb";
import SidebarMenu from "../../../SidebarMenu";
import "../answerQuestionnaire/AnswerQuestionnaire.css";
import "../../../../css/viewuser.css";
import QuestionsComps from "./QuestionsComps";
import avatar_comment from "../../../../images/letter placeholder/e.png";
import requested_arrow from "../../../../images/requested_arrow.svg";
import sendComment from "../../../../images/send.svg";
import SuperAvatar from "../../../SuperAvatar";
import { fetchData,fetchData1, postData, justPostData } from "../../../apiUtils";
import UserDropdown from "./UserDropdown";
import { auditRoutes } from "../../../subroutes";
import BounceLoader from "react-spinners/BounceLoader";

const renderIcons = (auditRoutes) => {
  return auditRoutes.map((route, index) => {
    if (route.subRoutes) {
      return <SidebarMenu route={route} key={index} />;
    }

    return (
      <NavLink
        to={route.path}
        key={index}
        className="navlink"
        activeclassname="active1"
      >
        <div className="navicon">
          <img src={route.icon} alt={`${route.name} Icon`} />
        </div>
        <div className="navtext">{route.name}</div>
      </NavLink>
    );
  });
};

const AnswerQuestionnaire = ({ tableSize }) => {
  const [APIData, setAPIData] = useState([]);
  const [APIQData, setAPIQData] = useState([]);
  const [formStatus, setFormStatus] = useState('');
  const [otherKeysData, setOtherKeysData] = useState([]);
  const [taskName, settaskName] = useState([]);
  const [taskNameError, setTaskNameError] = useState('');
  const [assigned_to, setAssigned_to] = useState('');
  const [assigned_toName, setAssigned_toName] = useState('');
  const [otherData, setOtherData] = useState([]);
  const [taskData, setTaskData] = useState([]);
  const [errors, setErrors] = useState("");
  const [qErrors, setQErrors] = useState("");
  const [loading, setLoading] = useState(false);

  const [taskErrors, setTaskErrors] = useState("");
  const [otherKeyErrors, setOtherKeyErrors] = useState("");
  const [showComments, setShowComments] = useState(true);
  const [comments, setComments] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const avaUserName = localStorage.getItem("userName");
  const lastName = localStorage.getItem("userLastName");
  const taskID = localStorage.getItem("taskID");
  const taskTitle = localStorage.getItem("taskTitle");
  const location = useLocation();
  const userRoles = localStorage.getItem("userRoles");

  const Roles = userRoles.includes("Admin") && userRoles.includes("Audit Team");

  const handleCommentsToggle = () => {
    setShowComments(!showComments);
  };

  const navigate = useNavigate();

  useEffect(() => {
    fetchData(`/api/audit_universe/questionnaire/${location?.state?.questionnaire_id}/`, setAPIQData, setQErrors,setLoading);
    if (APIQData && APIData.status) {
      setFormStatus(APIData.status);
    }
  }, [location?.state?.questionnaire_id]);

  useEffect(() => {
    if (APIQData.task) {
      console.log(APIQData.task)
      fetchData(
        `/api/system/task/${APIQData?.task?.id}/`,
        settaskName,
        setTaskNameError
      );
    }
      
  }, [APIQData]);

  useEffect(() => {
    if (taskName.assigned_to) {
       setAssigned_toName(taskName ? taskName.assigned_to.name : '');
      console.log(taskName.assigned_to.name)
    }
      
  }, [taskName]);

  useEffect(() => {
    fetchData(
      `/api/audit_universe/answers/questionnaire/${location?.state?.questionnaire_id}`,
      setAPIData,
      setErrors
    );

    if (APIQData.task) {
      fetchData(
        `/api/system/comments/?task_id=${APIQData?.task?.id}`,
        setTaskData,
        setTaskErrors
      );
    }

    if (APIQData.audit_entity) {
      fetchData(`/api/audit_universe/audit-entities/${APIQData.audit_entity.id}/`, setOtherKeysData, setOtherKeyErrors);
    }
  }, [APIQData.audit_entity]);

  useEffect(() => {
    setOtherData(otherKeysData.other_stakeholders);
  }, [otherKeysData]);

  useEffect(() => {
    if (APIData && APIData.length > 0) {
      const questionnaireID = APIData[0].questionnaire;
      localStorage.setItem("questionnaireID", questionnaireID);
    }
  }, [APIData]);

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleAddComment = () => {
    if (inputValue.trim()) {
      const currentDateTime = new Date();
      const formattedDate = currentDateTime.toLocaleDateString("en-GB", {
        day: "2-digit",
        month: "short",
        year: "2-digit",
      });
      const formattedTime = currentDateTime.toLocaleTimeString("en-GB", {
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      });

      const newComment = {
        text: inputValue,
        date: formattedDate,
        time: formattedTime,
      };

      setComments([...comments, newComment]);
      setTaskData([...taskData, newComment]);

      justPostData(
        `/api/system/comments/`,
        {
          user_id: localStorage.getItem("user_id"),
          task_id: APIQData.task,
          text: inputValue,
        },
        navigate,
        setErrors
      );

      // window.location.reload();
      setInputValue("");
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleAddComment();
    }
  };

  const transformData = (data) => {
    return data.reduce((acc, item) => {
      const sectionName = item.section.section_name;
      const questionId = item.question.id;
      const businessUnit = {
        id: item.audit_unit.id,
        name: item.audit_unit.name,
      };
      const remark = item.remark;

      if (!acc[sectionName]) {
        acc[sectionName] = {
          section_name: sectionName,
          questions: {},
          remark,
        };
      }

      if (!acc[sectionName].questions[questionId]) {
        acc[sectionName].questions[questionId] = {
          question_id: questionId,
          question: item.question.question,
          answers: [],
          remark,
        };
      }

      const selectedOption =
        ["Yes", "No", "Partially Yes", "Don't Know"][item.answer - 1] || null;
      acc[sectionName].questions[questionId].answers.push({
        id: item.id,
        audit_unit: businessUnit,
        selected_option: selectedOption,
      });

      return acc;
    }, {});
  };

  const transformedData = transformData(APIData);

  const sections = Object.values(transformedData).map((section) => ({
    section_name: section.section_name,
    questions: Object.values(section.questions),
  }));

  const data = { sections };

  const daysSince = (dateString) => {
    const date = new Date(dateString);
    const now = new Date();
    const timeDiff = now - date;
    const daysDiff = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
    if (daysDiff <= 0) return 'today';
    return daysDiff === Number.NaN? 0 : '' + " days ago";
  };

  return (
    <>
      {errors && <p className="errormessage">{errors}</p>}

      <section className="navroutes">
        <div style={{ display: "flex" }} className="navlinkTop">
          {auditRoutes.map((route, index) => {
            if (route.subRoutes) {
              return <SidebarMenu route={route} key={index} />;
            }

            return (
              <NavLink
                to={route.path}
                key={index}
                className="navlink"
                activeclassname="active"
              >
                <div className="navicon">
                  <img src={route.icon} alt="Dashboard Icon" />
                </div>
                <div className="navicon">{route.name}</div>
              </NavLink>
            );
          })}
        </div>

        <hr className="breadcrumbabv" />
        <Breadcrumb />
        <div className="filter-container">
          <div className="filter">
            <div className="views">
              <h3 className="h2_interbold" >Answer Questionnaire</h3>
            </div>
          </div>
        </div>

        {loading ? (
          <div className="spinner-bounce-loader">
          <BounceLoader color="#a6fafa"/> 
          </div>
            
          ): APIQData.id===0 ? (
            <p className="data-error-text">No data found</p> 
          ) : (
        <div
        className="ans_questionniarecontainer"
        style={{ width: tableSize ? "80vw" : "90.5vw" }}
      >
        <div className="heading_ass">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "inherit",
              padding: "0vw 3vw 0vw 0vw",
              alignItems: "center",
              margin:"1vw 0vw"
            }}
          >
            <h3
              style={{
                textAlign: "left",
                align: "left",
                fontFamily: "intersemibold",
              }}
            >
              {APIQData?.audit_entity?.name}
            </h3>
            <div className="assigningsection_show_com">
              <div className="assigning_section">
                <label htmlFor="mySelect">
                  <sub style={{ fontFamily: "intersemibold" }}>Assign to</sub>{" "}
                </label>
                {otherData && (
                  <UserDropdown
                    taskId={APIQData.task}
                    otherKeyData={otherData}
                    task={APIQData.id}
                    title={APIQData.title}
                    assigned_toName={assigned_toName}
                  />
                )}
              </div>

              <button onClick={handleCommentsToggle}>
                {showComments ? "Show Comments" : "Hide Comments" }
              </button>
            </div>
          </div>

          <div className="answer_sec__con_sec">
            <div className="ans_section_container">
              <div
                className="ans_section"
                style={{
                  width: tableSize
                    ? "77vw"
                    : "87vw",
                }}
              >
                {APIData.length > 0 && APIQData.status && (
                  <QuestionsComps
                    data={data}
                    formStatus={formStatus}
                    APIQData={APIQData}
                    showComments={showComments}
                  />
                )}{" "}
              </div>
            </div>

            <div
              className={`comment_con_AQ ${
                showComments ? "hidden" : "visible"
              }`}
            >
              <h3>Comments & Activity</h3>

              <div className="initial_comment">
                <input
                  type="text"
                  placeholder="Enter a comment..."
                  value={inputValue}
                  onChange={handleInputChange}
                  onKeyDown={handleKeyDown}
                />
                <div className="sendComm">
                  <img
                    onClick={handleAddComment}
                    src={sendComment}
                    alt="Send Comment"
                  />
                </div>
              </div>

              <div className="all_comments">
                {taskData.slice().reverse().map((comment, index) => {
                  const { text, date, time } = comment;
                  return (
                    <div className="comment_wrapper_" key={index}>
                      <div className="comment_head">
                        <div className="avatar_name_AQ">
                          <SuperAvatar
                            firstName={
                              comment.user
                                ? comment.user.name
                                : `${avaUserName} ${lastName}`
                            }
                            classOfAvatar="Avtaraudit"
                          />
                          <h5>
                            {comment.user
                              ? comment.user.name
                              : `${avaUserName} ${lastName}`}
                          </h5>
                        </div>
                        <div className="time_date_AQ">
                          <span>
                            <sub>{daysSince(comment.created_at)}</sub>
                          </span>
                        </div>
                      </div>
                      <div className="comment_AQ">
                        <p>{text}</p>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
          )
        }

      </section>
    </>
  );
};

export default AnswerQuestionnaire;
