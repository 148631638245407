import React, { useState, navigate } from "react";
import Breadcrumb from "../../../Breadcrumb";
import "../schedulemeeting/schedulemeeting.css";
import SingleOptionSelector from "../../../SingleOptionSelector";
import MultiObjectSelector from "../../../MultiObjectSelector";
import { postData } from "../../../apiUtils";
import {
  NavLink,
  useLocation,
  Link,
  useNavigate,
  useParams,
} from "react-router-dom";
import backlogo from "../../../../images/backLogo.svg"
import MultiOptionSelector from "../../../MultiOptionSelector";
import IconButton from "../../../iconbutton/IconButton";

const Schedulemeeting = ({ tableSize}) => {
  const [errors, setErrors] = useState("");
  const [successMessage, setSuccessMessage] = useState(""); 
  const [objective, setObjective] = useState("");
  const [objectiveError, setObjectiveError] = useState("");
  const [preparer_id, setPreparerId] = useState("");
  const [PreparerError, setPreparerError] = useState(false);
  const [reviewer_id, setReviewerId] = useState("");
  const [reviewerError, setReviewerError] = useState(false);
  const [assign_to_id, setAssign_To] = useState("");
  const [assignError, setAssignError] = useState(false);
  const [participantError, setParticipantError] = useState(false);
  const [participants_id, setParticipants] = useState([]);
  const [scheduled_start_date, setStartDate] = useState("");
  const [start_dateError, setStart_dateError] = useState(false);
  const [scheduled_end_date, setEndDate] = useState("");
  const [end_dateError, setEnd_dateError] = useState(false);
  const [editMode, setEditMode] = useState(true);

  const { id } = useParams();
  const navigate=useNavigate()

    const postApi = (e) => {
      e.preventDefault();
      if (!objective || !preparer_id || !reviewer_id || !assign_to_id || !scheduled_start_date || !scheduled_end_date || !id || !participants_id ) {
        setObjectiveError(!objective ? true : false);
        setPreparerError(!preparer_id ? true : false);
        setReviewerError(!reviewer_id ? true : false);
        setAssignError(!assign_to_id ? true : false);
        setParticipantError(!participants_id? true : false)
        setStart_dateError(!scheduled_start_date ? true : false)
        setEnd_dateError(!scheduled_end_date ? true : false)
        setErrors("Please fill in all required fields.");
        setTimeout(() => {
          setErrors(null);
        }, 5000);
        return;
      }
      postData(`/api/audit_engagement/plan/`,
      {
        objective,
        preparer_id,
        reviewer_id,
        assign_to_id,
        scheduled_start_date,
        scheduled_end_date,
        type :"Schedule Meeting",
        audit_id :id,
        participants_id
     },
     navigate,
     setErrors,
     `/AuditExecution/${id}/Planning`
   );
    };

  return (
    <>
      {errors && <p className="errormessage">{errors}</p>}
      {successMessage && <p className="successmessage">{successMessage}</p>}

      <section className="navroutes">
        <div className="filter-container">
          <div className="filter">
            <div className="views">
            <div style={{ width: tableSize ? "79.5vw" : "90vw",display:"flex",justifyContent:"space-between",alignItems:"center"}}>
              <h3 className="h2_interbold" >Schedule Meeting</h3>
              <Link to ={`/AuditExecution/${id}/Planning`}>
              <div className="backBtnDiv" style={{display:"flex",justifyContent:"space-between",alignItems:"center",gap:"1vw",backgroundColor:"#FFF3CC",padding:".3vw 1.2vw",borderRadius:"2.5vw"}}>
                <img src={backlogo} style={{width:"1.2vw"}}/>
                <p style={{fontFamily:"intersemibold",fontSize:".8vw",color:"#775F0F"}}>Back to all tasks</p>
              </div>
              </Link> 
              </div>
            </div>
          </div>
        </div>

      
        <div
          className="createmeeting"
          style={{ width: tableSize ? "79.5vw" : "90vw" }}
        >

<div className="delete_edit_close_btn" style={{marginBottom:"20vh"}}>
            
            <IconButton type="save" tooltip="Save" onClick={postApi} link="/" />
          </div>

<form>
          <div
            className="lng_lbl_inpt"
          >
            <label>Objective</label>
            <input
              type="text"
              style={{   border: objectiveError ? "1px solid #FF4646" : "", }}

              placeholder="Objective"
              value={objective}
              onChange={(e) => {
                  setObjective(e.target.value);
                  setObjectiveError(false)
                }}
            />
          </div>

         
            <div
              className="lbl_inpt"
            >
              <label> Start Date: </label>{" "}
              <input
              style={{border: start_dateError ? "1px solid #FF4646" : "",}}
                type="datetime-local"
                value={scheduled_start_date}
                onChange={(e) => {
                  setStartDate(e.target.value);
                  setStart_dateError(false)
                }}
              />
            </div>

            <div
              className="lbl_inpt"
            >
              <label> End Date: </label> 
              <input type="datetime-local"
              style={{
                border: end_dateError ? "1px solid #FF4646" : "", 
              }}
                value={scheduled_end_date}
                onChange={(e) => {
                  setEndDate(e.target.value);
                  setEnd_dateError(false);


                }}
               />
            </div>
          

         
            
              <SingleOptionSelector
                apiUrl="/api/audit_universe/audit-team/"
                setSelectorId={setPreparerId}
                setSelectorError={setPreparerError}
                selectorError={PreparerError}
                Name="name"
                title="Preparer"
                editMode={editMode}
              />
            

              <SingleOptionSelector
                apiUrl="/api/audit_universe/admin-audit-team/"
                setSelectorId={setReviewerId}
                setSelectorError={setReviewerError}
                selectorError={reviewerError}
                Name="name"
                title="Reviewer"
                editMode={editMode}
              />
           
        

          
            
              <SingleOptionSelector
                apiUrl="/api/audit_universe/business-stakeholders/"
                setSelectorId={setAssign_To}
                setSelectorError={setAssignError}
                selectorError={assignError}
                Name="name"
                title="Assign To"
                editMode={editMode}
              />
            

           
              <MultiOptionSelector
                apiUrl="/api/audit_universe/business-stakeholders/"
                setSelectorId={setParticipants}
                setSelectorError={setParticipantError}
                selectorError={participantError}
                Name="name"
                title="Participants"
                editMode={editMode}
              />
            
         
          </form>
   
        </div>
      </section>
    </>
  );
};

export default Schedulemeeting;
