import React, { useEffect, useMemo, useState } from 'react'
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import Breadcrumb from "../../Breadcrumb";
import SidebarMenu from "../../SidebarMenu";
import "../../../css/viewuser.css";
import "../riskAssesment/riskassessment.css";
import toggle_btn from "../../../images/toggle_side_down.svg";
import { fetchData, justPostData, postData, postDataRisk } from "../../apiUtils";
import { auditRoutes } from "../../subroutes";
import BounceLoader from "react-spinners/BounceLoader";
import RenderIcons from "../../RenderIcons";


const RiskassesmentTable = ({ tableSize }) => {
    const [APIAllData, setAPIAllData] = useState([]);
    const [Errors, setErrors] = useState("");
    const navigate = useNavigate();


    useEffect(() => {
        fetchData(`/api/audit_universe/risk-assessments/`, setAPIAllData, setErrors)
    }, [])

    const Viewuser = (data) => {
        const raid = data.id;
        console.log(data);
        navigate(`/AuditUniverse/riskAssessment/${raid}`); // Make sure `raid` is valid
    };

    const [filters, setFilters] = useState({
        AuditTitle: "",
        assessment_date: "", 
        status: "",
    });

    const handleFilterChange = (filterKey, e) => {
        const value = e.target.value.toLowerCase();
        setFilters({ ...filters, [filterKey]: value });
    };

    const filteredData = useMemo(() => {
        return APIAllData.filter((data) => {
            const title = data?.title?.toLowerCase() || "";
            const status = data?.status?.toLowerCase() || "--";
            const assessmentDate = data?.assessment_date?.toLowerCase() ||"";
            
            return (
                (!filters.AuditTitle || title.includes(filters.AuditTitle)) &&
                (!filters.assessment_date || assessmentDate.includes(filters.assessment_date)) &&
                (!filters.status || status.includes(filters.status))
            );
        });
    }, [APIAllData, filters]);
    
    return (
        <>
            <RenderIcons homeRoutes={auditRoutes} />
            <div className="filter-container">
                <div className="filter">
                    <div className="views">
                        <h3>Risk Assesment</h3>
                    </div>
                </div>
            </div>
            <div className="tablecontainer">
                <table style={{ width: tableSize ? "80vw" : "90.5vw" }}>
                    <thead style={{ position: "sticky", top: "0", zIndex: 1000 }}>
                        <tr className="tablehead">
                            <th>
                                <h4 className="thhead"> Name</h4>
                                <input
                                    type="text"
                                    placeholder="--"
                                    className="thfilter"
                                    onChange={(e) => handleFilterChange("AuditTitle", e)}
                                    value={filters.AuditTitle}
                                />
                            </th>
                            <th>
                                <h4 className="thhead"> Year</h4>
                                <input
                                    type="text"
                                    placeholder="--"
                                    className="thfilter"
                                    onChange={(e) => handleFilterChange("assessment_date", e)}
                                    value={filters.assessment_date}
                                />
                            </th>
                            <th>
                                <h4 className="thhead"> Status</h4>
                                <input
                                    type="text"
                                    placeholder="--"
                                    className="thfilter"
                                    onChange={(e) => handleFilterChange("status", e)}
                                    value={filters.status}
                                />
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredData.map((data,index)=>(
                            <tr onClick={() => Viewuser(data)} key={index}>
                                <td>
                                    {data.title}
                                </td>
                                <td>{data.assessment_date}</td>
                                <td>{data.status}</td>
                            </tr>
                        ))}
                    </tbody>

                </table>
            </div>
        </>
    )
}

export default RiskassesmentTable
